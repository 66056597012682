import ClinicalTrialProcessingEstimate from 'components/clinicalTrial/process/ClinicalTrialProcessingEstimate';
import PageTemplate from 'templates/PageTemplate';

const ClinicalTrialProcessingEstimatePage = () => {
  return (
    <PageTemplate title="시험대행 진행 관리">
      <ClinicalTrialProcessingEstimate />
    </PageTemplate>
  );
};

export default ClinicalTrialProcessingEstimatePage;
