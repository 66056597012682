import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  FilePdfOutlined,
  FileSearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Table,
  Typography as Typo,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';

import {
  EEstimateSearchType,
  IEstimate,
  IEstimateDeposit,
} from 'types/estimate';
import { useStores } from 'stores/Context';
import { useEstimates } from 'hooks/estimate';
import PageTemplate from 'templates/PageTemplate';
import EstimateAdditionalListModal from 'components/estimate/EstimateAdditionalListModal';
import EstimateDepositCheckModal from 'components/estimate/EstimateDepositCheckModal';
import EstimateDepositHistoryModal from 'components/estimate/EstimateDepositHistoryModal';
import EstimateHistoriesModal from 'components/estimate/EstimateHistoriesModal';
import EstimateUpdateModal from 'components/estimate/EstimateUpdateModal';
import SearchInput from 'components/form/SearchInput';
import { Typography } from 'components/system';

const EstimatePage = () => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] = useState<EEstimateSearchType>(
    EEstimateSearchType.COMPANY_NAME_KO,
  );
  const [searchValue, setSearchValue] = useState('');
  const [isDepositFinish, setIsDepositFinish] = useState('all');
  const [isReceiveContractFinish, setIsReceiveContractFinish] = useState('all');
  const {
    estimates,
    totalElements,
    refetch,
    checkEstimateDeposit,
    receiptEstimateContract,
  } = useEstimates({
    page,
    isDepositFinish,
    isReceiveContractFinish,
    [searchType]: searchValue,
  });
  const [
    selectedEstimateForViewAdditionals,
    setSelectedEstimateForViewAdditionals,
  ] = useState<IEstimate | null>(null);
  const [
    selectedEstimateIdForUpdate,
    setSelectedEstimateIdForUpdate,
  ] = useState<number | null>(null);
  const [
    selectedEstimateIdForUpdateHistory,
    setSelectedEstimateIdForUpdateHistory,
  ] = useState<number | null>(null);
  const [
    selectedEstimateForDepositCheck,
    setSelectedEstimateForDepositCheck,
  ] = useState<IEstimate | null>(null);
  const [
    selectedEstimateIdForDepositHistory,
    setSelectedEstimateIdForDepositHistory,
  ] = useState<number | null>(null);

  const { etcStore } = useStores();
  const columns: ColumnsType<IEstimate> = useMemo(
    () => [
      {
        title: '회사명',
        align: 'center',
        render: ({ company }: any) => company.companyNameKo,
      },
      {
        title: '견적서 번호',
        dataIndex: 'estimateCode',
        align: 'center',
      },
      {
        title: '우편 받으실 주소',
        align: 'center',
        render: ({ postAddress }: IEstimate) => (
          <Typo.Link
            underline
            onClick={() => Modal.info({ content: postAddress, icon: null })}
          >
            자세히 보기
          </Typo.Link>
        ),
      },
      {
        title: '견적 발행일',
        align: 'center',
        render: ({ registerDt }: IEstimate) => registerDt.slice(0, 10),
      },
      {
        title: '견적서',
        align: 'center',
        render: ({ fileUrl }: IEstimate) => (
          <FilePdfOutlined
            style={{ fontSize: 16, cursor: 'pointer' }}
            onClick={() => {
              etcStore.previewedFiles = [{ url: fileUrl }];
            }}
          />
        ),
      },
      {
        title: '견적 수정 이력',
        align: 'center',
        render: ({ estimateId }: IEstimate) => (
          <Row wrap={false} gutter={8} justify="center" align="middle">
            <Col>
              <Button
                onClick={() => setSelectedEstimateIdForUpdate(estimateId)}
              >
                견적서 수정
              </Button>
            </Col>

            <Col>
              <FileSearchOutlined
                style={{ cursor: 'pointer', fontSize: 16 }}
                onClick={() =>
                  setSelectedEstimateIdForUpdateHistory(estimateId)
                }
              />
            </Col>
          </Row>
        ),
      },
      {
        title: '입금 처리 / 견적 금액',
        align: 'right',
        render: ({ paidAmount, totalPrice }: IEstimate) => (
          <>
            <Typography.Text type="secondary" inline bold>
              {paidAmount.toLocaleString()}원
            </Typography.Text>{' '}
            /{' '}
            <Typography.Text
              type="secondary"
              inline
              bold={paidAmount === totalPrice}
            >
              {totalPrice.toLocaleString()} 원
            </Typography.Text>
          </>
        ),
      },
      {
        title: '입금 상태',
        align: 'center',
        render: ({ paidAmount, totalPrice }: IEstimate) => (
          <Typography.Text type="secondary" bold>
            {paidAmount === 0
              ? '미납'
              : totalPrice === paidAmount
              ? '완납'
              : '분납'}
          </Typography.Text>
        ),
      },
      {
        title: '입금 확인',
        align: 'center',
        render: (estimate: IEstimate) => (
          <Row align="middle" justify="center" gutter={8}>
            <Col>
              {!estimate.isDepositFinish ? (
                <Button
                  onClick={() => setSelectedEstimateForDepositCheck(estimate)}
                >
                  입금 확인
                </Button>
              ) : (
                <Typography.Text type="secondary" inline>
                  입금 완료
                </Typography.Text>
              )}
            </Col>
            <FileSearchOutlined
              style={{ cursor: 'pointer', fontSize: 16 }}
              onClick={() =>
                setSelectedEstimateIdForDepositHistory(estimate.estimateId)
              }
            />
          </Row>
        ),
      },
      {
        title: '계약서 수령',
        align: 'center',
        render: ({ estimateId, isReceiveContractFinish }: IEstimate) =>
          !isReceiveContractFinish ? (
            <Button
              onClick={() =>
                Modal.confirm({
                  content: '정말로 계약서 수령 완료 처리를 하시겠습니까?',
                  onOk: () => receiptEstimateContract(estimateId),
                })
              }
            >
              계약서 수령 확인
            </Button>
          ) : (
            <Typography.Text type="secondary">계약서 수령 완료</Typography.Text>
          ),
      },
      {
        title: '추가 견적',
        align: 'center',
        render: (estimate: IEstimate) =>
          estimate.estimateAdditionals && (
            <Typo.Link
              underline
              onClick={() => setSelectedEstimateForViewAdditionals(estimate)}
            >
              자세히 보기
            </Typo.Link>
          ),
      },
    ],
    [],
  );
  const searchTypeOptions = useMemo(
    () => [
      { label: '회사명(국문)', value: EEstimateSearchType.COMPANY_NAME_KO },
      { label: '회사명(영문)', value: EEstimateSearchType.COMPANY_NAME_EN },
      { label: '견적서 번호', value: EEstimateSearchType.ESTIMATE_CODE },
    ],
    [],
  );
  const handleChangeSearchType = (type: EEstimateSearchType) => {
    setSearchType(type);
    setSearchValue('');
  };
  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleSearch = (keyword: string) => {
    setSearchValue(keyword);
    setPage(1);
    setTimeout(refetch);
  };
  const handleRefresh = () => {
    setPage(1);
    setIsDepositFinish('all');
    setIsReceiveContractFinish('all');
    setSearchType(EEstimateSearchType.COMPANY_NAME_KO);
    setSearchValue('');
    setTimeout(refetch);
  };
  const handleCheckEstimateDeposit = (estimateDeposit: IEstimateDeposit) => {
    checkEstimateDeposit(estimateDeposit);
  };

  useEffect(() => {
    if (estimates && selectedEstimateForViewAdditionals) {
      // HINT: 견적 목록이 refetch되었고 추가견적 목록이 보여지고 있을 경우 추가견적 목록을 갱신
      setSelectedEstimateForViewAdditionals(
        estimates.find(
          ({ estimateId }) =>
            estimateId === selectedEstimateForViewAdditionals.estimateId,
        ) || null,
      );
    }
  }, [estimates]);

  useEffect(() => {
    handleRefresh();
  }, [location]);

  return (
    <PageTemplate title="견적서 및 입금 확인">
      {selectedEstimateForViewAdditionals && (
        <EstimateAdditionalListModal
          estimate={selectedEstimateForViewAdditionals}
          onClose={() => setSelectedEstimateForViewAdditionals(null)}
          onChangeStatusSuccess={refetch}
        />
      )}
      {selectedEstimateIdForUpdate && (
        <EstimateUpdateModal
          estimateId={selectedEstimateIdForUpdate}
          onClose={() => setSelectedEstimateIdForUpdate(null)}
        />
      )}
      {selectedEstimateIdForUpdateHistory && (
        <EstimateHistoriesModal
          estimateId={selectedEstimateIdForUpdateHistory}
          onClose={() => setSelectedEstimateIdForUpdateHistory(null)}
        />
      )}
      {selectedEstimateForDepositCheck && (
        <EstimateDepositCheckModal
          estimate={selectedEstimateForDepositCheck}
          onCheckEstimateDeposit={handleCheckEstimateDeposit}
          onClose={() => setSelectedEstimateForDepositCheck(null)}
        />
      )}
      {selectedEstimateIdForDepositHistory && (
        <EstimateDepositHistoryModal
          estimateId={selectedEstimateIdForDepositHistory}
          onClose={() => setSelectedEstimateIdForDepositHistory(null)}
        />
      )}
      <Row justify="end" style={{ marginBottom: 8 }} gutter={8}>
        <Col flex="auto">
          <Button icon={<SyncOutlined />} onClick={handleRefresh}>
            새로 고침
          </Button>
        </Col>
        <Col>
          <Form.Item label="입금" colon={false} style={{ marginBottom: 0 }}>
            <Select
              style={{ width: 120 }}
              value={isDepositFinish}
              onChange={(value) => {
                setPage(1);
                setIsDepositFinish(value);
              }}
            >
              <Select.Option value={'all'}>전체</Select.Option>
              <Select.Option value={'false'}>대기중</Select.Option>
              <Select.Option value={'true'}>완료</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="계약서" colon={false} style={{ marginBottom: 0 }}>
            <Select
              style={{ width: 120 }}
              value={isReceiveContractFinish}
              onChange={(value) => {
                setPage(1);
                setIsReceiveContractFinish(value);
              }}
            >
              <Select.Option value={'all'}>전체</Select.Option>
              <Select.Option value={'false'}>대기중</Select.Option>
              <Select.Option value={'true'}>완료</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Select
            value={searchType}
            options={searchTypeOptions}
            style={{ width: 120, marginRight: 4 }}
            onChange={handleChangeSearchType}
          />
          <SearchInput
            value={searchValue}
            onChange={handleChangeSearchValue}
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={estimates}
        rowKey={(estimate) => estimate.estimateId}
        pagination={{
          current: page,
          total: totalElements,
          pageSize: 10,
          onChange: setPage,
        }}
      />
    </PageTemplate>
  );
};

export default EstimatePage;
