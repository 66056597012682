import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import parse from 'html-react-parser';
import { Drawer, List, Row, Col, Tag, Typography, Button } from 'antd';

import palette from 'lib/styles/palette';
import { useNotifications } from 'hooks/notification';
import { useStores } from 'stores/Context';
import { INotification } from 'types/notification';
import CloseIcon from './CloseIcon';

const DrawerHeader = styled(Row)`
  padding: 16px;
`;

const NotificationList = styled(List)`
  height: calc(100vh - 60px);
  overflow: auto;
`;

const NotificationItem = styled(List.Item)<{
  $isConfirm: boolean;
  $hasLink: boolean;
}>`
  display: block;
  cursor: ${(props) =>
    !props.$isConfirm || props.$hasLink ? 'pointer' : 'default'};
  padding: 16px;
  background-color: #f6f6f6;
  border: 1px solid #f3f3f3;
  color: #4e5968;

  ${(props) =>
    props.$isConfirm &&
    css`
      .ant-tag {
        color: ${palette.text.disabled};
      }
    `}

  &:last-child {
    border-bottom: 1px solid rgb(240, 240, 240) !important;
  }

  p {
    margin-bottom: 0;
    max-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 16px;
  }
`;

const ReadAllButton = styled(Button)`
  position: relative;
  top: -2px;
  height: 20px;
  font-size: 10px;
  padding: 2px 4px 3px;
  border-color: ${palette.primary};
`;

const NotificationDrawer = () => {
  const history = useHistory();
  const { notificationStore } = useStores();
  const {
    notifications,
    readNotification,
    readAllNotifications,
  } = useNotifications();
  const handleClickClose = () => {
    notificationStore.setNotificationDrawerVisible(false);
  };
  const handleClickNotification = (notification: INotification) => {
    if (!notification.isConfirm) {
      readNotification(notification.userNoticeId);
    }
    if (notification.noticePath) {
      history.push(notification.noticePath);
      notificationStore.setNotificationDrawerVisible(false);
    }
  };
  return (
    <Drawer
      width={320}
      visible={notificationStore.notificationDrawerVisible}
      closable={false}
      maskClosable={true}
      bodyStyle={{ padding: 0 }}
      onClose={handleClickClose}
    >
      <DrawerHeader align="middle">
        <Col>
          <Typography.Title
            style={{
              fontSize: 18,
              flex: 'auto',
              marginBottom: 0,
              fontWeight: 400,
            }}
          >
            알림 목록
          </Typography.Title>
        </Col>
        <Col flex="auto" style={{ marginLeft: 12 }}>
          <ReadAllButton onClick={() => readAllNotifications()}>
            모두 읽음
          </ReadAllButton>
        </Col>
        <Col>
          <CloseIcon onClick={handleClickClose} />
        </Col>
      </DrawerHeader>
      <NotificationList
        dataSource={notifications}
        renderItem={(notification: any) => (
          <NotificationItem
            key={notification.userNoticeId}
            $isConfirm={notification.isConfirm}
            $hasLink={notification.noticePath !== null}
            onClick={() => handleClickNotification(notification)}
          >
            <Row gutter={8} style={{ marginBottom: 8 }} justify="space-between">
              <Col>
                <Tag
                  color={notification.isConfirm ? palette.disabled : '#4e5968'}
                  style={{
                    margin: 0,
                    padding: '0 4px',
                    height: 24,
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  {notification.title || '알림'}
                </Tag>
              </Col>
              <Col>
                <Typography.Text
                  style={{
                    fontSize: 10,
                    color: palette.text.disabled,
                  }}
                >
                  {moment(notification.registerDt).fromNow()}
                </Typography.Text>
              </Col>
            </Row>
            <Typography.Text style={{ color: '#4e5968' }}>
              {parse(notification.content || '')}
            </Typography.Text>
          </NotificationItem>
        )}
      />
    </Drawer>
  );
};

export default observer(NotificationDrawer);
