class Storage {
  static getItem<T extends any>(key: string): T | null {
    const stringifiedItem = sessionStorage.getItem(key);
    return stringifiedItem ? (JSON.parse(stringifiedItem) as T) : null;
  }

  static setItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  constructor() {
    throw new Error("Don't create an instance");
  }
}

export default Storage;
