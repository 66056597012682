import { Input, InputProps } from 'antd';
import { useEffect, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const SearchInputWrap = styled.div`
  display: inline-block;
  position: relative;

  .ant-input {
    padding-right: 40px;
  }
`;
const IconBlock = styled.i`
  position: absolute;
  top: 6px;
  right: 16px;
  cursor: pointer;
`;

interface SearchInputProps extends InputProps {
  defaultValue?: string;
  onSearch: (keyword: string) => void;
  containerStyle?: React.CSSProperties;
}

const SearchInput = ({
  defaultValue = '',
  onSearch,
  containerStyle,
  ...restProps
}: SearchInputProps) => {
  const inputRef = useRef<Input>(null);
  useEffect(() => {
    if (defaultValue !== inputRef.current?.input.value) {
      inputRef.current?.setValue(defaultValue);
    }
  }, [defaultValue]);
  return (
    <SearchInputWrap style={containerStyle}>
      <Input
        {...restProps}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch(inputRef.current?.input.value || '');
          }
        }}
      />
      <IconBlock
        onClick={() => onSearch(inputRef.current?.input.value || '')}
        style={{ color: '#a2a2a2' }}
      >
        <SearchOutlined />
      </IconBlock>
    </SearchInputWrap>
  );
};

export default SearchInput;
