import { MenuOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import styled from 'styled-components';

export const ClinicalTrialEstimateHeaderContainer = styled.div`
  box-shadow: 0 1px 0 #dfdfdf;
  background-color: #fff;
  overflow: visible;
  z-index: 50;
`;

export const ClinicalTrialEstimateHeaderRow = styled(Row)`
  height: 48px;
  width: 100%;
  margin: 0 auto;
  padding: 12px 20px;
`;

export const LogoImage = styled.img`
  width: 90px;
`;

export const ListIcon = styled(MenuOutlined)`
  font-size: 22px;
`;
