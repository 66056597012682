import { Button, Form, Input } from 'antd';
import { Typography } from 'components/system';
import { useClinicalTrialEstimateClientInfo } from 'hooks/clinicalTrial/clinicalTrial';
import palette from 'lib/styles/palette';
import { requireRule } from 'lib/validate';
import { useEffect } from 'react';
import styled from 'styled-components';
import { IClinicalTrialEstimateClientInfo } from 'types/clinicalTrial';
import ClinicalTrialModal from '../ClinicalTrialModal';

const ClientInfoModalContainer = styled.div`
  .ant-form-item-label > label {
    font-size: 16px;
    font-weight: 700;
    color: ${palette.text.darkNavy};
  }

  .ant-input {
    height: 44px;
  }

  .ant-form-item-explain {
    font-size: 10px;
  }
`;

const ClientInfoModal = ({
  clinicalTrialEstimateId,
  onSuccess,
  onClose,
}: {
  clinicalTrialEstimateId: number;
  onSuccess: () => void;
  onClose: () => void;
}) => {
  const {
    clinicalTrialEstimateClientInfo,
    updateClinicalTrialEstimateClientInfo,
    updateLoading,
  } = useClinicalTrialEstimateClientInfo(clinicalTrialEstimateId);
  const [form] = Form.useForm<IClinicalTrialEstimateClientInfo>();
  const handleSubmit = (clientInfo: IClinicalTrialEstimateClientInfo) => {
    updateClinicalTrialEstimateClientInfo(clientInfo, {
      onSuccess: () => {
        onSuccess();
        onClose();
      },
    });
  };
  useEffect(() => {
    if (clinicalTrialEstimateClientInfo) {
      form.setFieldsValue(clinicalTrialEstimateClientInfo);
    }
  }, [clinicalTrialEstimateClientInfo]);
  return (
    <ClientInfoModalContainer>
      <ClinicalTrialModal
        style={{ paddingLeft: 120, paddingRight: 120, height: 560 }}
        onClose={onClose}
      >
        <Typography.Title color="darkNavy" medium align="center">
          의뢰자 정보
        </Typography.Title>
        <Form
          form={form}
          layout="vertical"
          style={{ marginTop: 24 }}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="업체명"
            name="clientCompanyName"
            rules={[requireRule]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="의뢰자" name="clientName" rules={[requireRule]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="의뢰자 연락처"
            name="clientTel"
            rules={[requireRule]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="의뢰자 이메일"
            name="clientEmail"
            rules={[requireRule]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="업체 주소" name="clientCompanyAddress">
            <Input />
          </Form.Item>
          <Form.Item label="비고" name="remark">
            <Input.TextArea
              autoSize={{ minRows: 4, maxRows: 4 }}
              maxLength={100}
              showCount
            />
          </Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={updateLoading}
            style={{ display: 'block', width: 200, margin: '40px auto 0' }}
          >
            입력
          </Button>
        </Form>
      </ClinicalTrialModal>
    </ClientInfoModalContainer>
  );
};

export default ClientInfoModal;
