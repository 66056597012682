import axios, { AxiosInstance } from 'axios';
import { message } from 'antd';

import { createFormData } from 'lib/file';
import Storage from 'lib/storage';
import { reissueToken } from 'lib/api/auth';
import history from 'lib/history';
import path from 'lib/path';
import { rootStore } from 'index';

/**
 * 에러 코드
 * NO_ERROR = "0";
 * NO_ERROR_CUSTOM = "1";
 * REGISTER_ALREADY_BIZ_NUMBER = "-1";
 * REGISTER_ALREADY_USERNAME = "-2";
 * REGISTER_ALREADY_SUPERUSER = "-3";
 * LOGIN_UNAUTHORIZED = "-5";
 * INVALID_REFRESHTOKEN_ERROR = "-6";
 * JWT_EXPIRE_ERROR = "-7";
 * VALIDATE_INVALID_EMAIL = "-10";
 * AUTHENTICATION_FAILED = "-13";
 * NOT_REGISTERED_USERNAME = "-14";
 * FILE_UPLOAD_ERROR = "-15";
 * INVALID_PRODUCT = "-20";
 * EMPTY_CART = "-25";
 * INVALID_PRODUCT_COMPONENT = "-26";
 * NOT_REGISTERED_DEVICE = "-30";
 * NO_SUCH_COMPANY_ERROR = "-31";
 * NO_SUCH_PRODUCT_ERROR = "-32";
 * REGISTER_ALREADY_COUNTRY_ERROR = "-33";
 * REGISTER_ALREADY_CATEGORY_ERROR = "-34";
 * REGISTER_ALREADY_CHEMICAL_PROPERTY_ERROR = "-35";
 * REGISTER_ALREADY_CHALLENGE_TEST_ERROR = "-36";
 * REGISTER_ALREADY_MICROBIOLOGICAL_PROPERTY_ERROR = "-37";
 * REGISTER_ALREADY_PHYSICAL_PROPERTY_ERROR = "-38";
 * REGISTER_ALREADY_STABILITY_TEST_ERROR = "-39";
 * BAD_REQUEST = "-90";
 * SERVER_ERROR = "-99";
 */

const baseURL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

const client: AxiosInstance = axios.create({
  baseURL,
});

client.interceptors.request.use((config) => {
  if (/^\/v\d+/.test(config.url || '')) {
    config.baseURL = `${process.env.REACT_APP_SERVER_URL}/api`;
  }
  if (
    config.data &&
    Object.values(config.data).find((value) => {
      return (
        value instanceof File ||
        (value instanceof Array && value[0] instanceof File)
      );
    })
  ) {
    config.data = createFormData(config.data);
  }
  return config;
});

client.interceptors.response.use(
  (response) => {
    const [error] = response.data.errors;
    const { code } = error;
    const { url } = response.config;

    if (code === '0') {
      return response;
    }
    if (
      (url && url.startsWith('/auth/login/auto') && code === '-99') ||
      code === '-5' ||
      code === '-13' ||
      code === '-14' ||
      code === '-16'
    ) {
      clearToken();
      history.replace(path.login);
      throw error;
    }

    if (code === '-6' || code === '-7') {
      history.replace(`${path.login}?reason=expire`);
      clearToken();
      throw error;
    }

    // 공통 에러 처리
    console.error('[RESPONSE ERROR]', response.data.errors[0]);
    message.error(response.data.errors[0].message);
    throw error;
  },
  async (error) => {
    console.error('[RESPONSE ERROR]', error);
    const originalRequest = error.config;
    if (
      error.response &&
      !originalRequest._retry &&
      error.response.status === 401
    ) {
      originalRequest._retry = true;
      const storedRefreshToken = Storage.getItem<string>('auth.refreshToken');
      if (!storedRefreshToken) {
        clearToken();
        history.replace(`${path.login}?reason=expire`);
        return Promise.reject(error);
      }
      clearToken();
      const {
        data: { result },
      } = await reissueToken(storedRefreshToken);
      const { token, refreshToken } = result;
      setToken(token, refreshToken);

      originalRequest.headers.Authorization = `Bearer ${token}`;
      return client(originalRequest);
    }
    if (error.message) {
      if (error.message === 'Network Error') {
        message.error('Network Error : 잠시 후 다시 시도해주세요.');
      } else {
        message.error(error.message);
      }
    }
    return Promise.reject(error);
  },
);

export default client;

export function setToken(token: string, refreshToken: string) {
  client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  Storage.setItem('auth.refreshToken', refreshToken);
}

export function clearToken() {
  delete client.defaults.headers.common['Authorization'];
  Storage.removeItem('auth.refreshToken');
  rootStore.authStore.logout();
  history.push('/login');
}
