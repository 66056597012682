import styled from 'styled-components';

import ClinicalTrialCart from 'components/clinicalTrial/estimate/clinicalTrialCart/ClinicalTrialCart';

const ClinicalTrialCartPageContainer = styled.div``;

const ClinicalTrialCartPage = () => {
  return (
    <ClinicalTrialCartPageContainer>
      <ClinicalTrialCart />
    </ClinicalTrialCartPageContainer>
  );
};

export default ClinicalTrialCartPage;
