import { Button, Card, Col, Row } from 'antd';
import { Typography } from 'components/system';
import styled from 'styled-components';
import { useMainStat } from 'hooks/stats';
import history from 'lib/history';
import path from 'lib/path';
import { SwapRightOutlined } from '@ant-design/icons';

const DaySalesBlock = styled(Card)`
  height: 100%;

  .ant-card-body {
    padding: 32px 40px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #f1f4f6;
  border-radius: 16px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.5px;
  padding: 8px;
`;

const DaySales = () => {
  const { yesterdaySales } = useMainStat();
  const handleClickMove = () => {
    history.push(path.stats.sales);
  };
  return (
    <DaySalesBlock>
      <Row justify="space-between" align="middle" gutter={14}>
        <Col>
          <Typography.Headline type="secondary">
            어제 일자 하루 매출액입니다.
          </Typography.Headline>
        </Col>
        <Col>
          <StyledButton onClick={handleClickMove}>
            매출 보러가기 <SwapRightOutlined />
          </StyledButton>
        </Col>
      </Row>
      <Typography.Headline
        gutter={{ top: 28 }}
        style={{ fontSize: 56, textAlign: 'center' }}
      >
        {yesterdaySales}
      </Typography.Headline>
    </DaySalesBlock>
  );
};

export default DaySales;
