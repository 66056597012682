import styled from 'styled-components';
import { Typography } from 'components/system';
import SalesChart from 'components/stats/SalesChart';
import TotalIncome from 'components/stats/TotalSales';
import SalesProgressChart from 'components/stats/SalesProgressChart';
import CountryAndCompanySalesStats from 'components/stats/CountryAndCompanySalesStats';

const SalesStatsPageBlock = styled.div``;

const SalesStatsPage = () => {
  return (
    <SalesStatsPageBlock>
      <Typography.Title gutter={{ bottom: 8 }}>
        매출 분석 - 총 매출액
      </Typography.Title>
      <TotalIncome />
      <Typography.Title gutter={{ bottom: 8, top: 36 }}>
        매출 분석 - 상세 조회
      </Typography.Title>
      <SalesChart />
      <Typography.Title gutter={{ bottom: 8, top: 36 }}>
        매출 분석 - 매출 추이
      </Typography.Title>
      <SalesProgressChart />
      <Typography.Title gutter={{ bottom: 8, top: 36 }}>
        매출 분석 - 국가 / 브랜드사별 조회
      </Typography.Title>
      <CountryAndCompanySalesStats />
    </SalesStatsPageBlock>
  );
};

export default SalesStatsPage;
