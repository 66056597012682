import { useMemo, useState } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';

import PageSizeSelect, {
  pageSizeOptions,
} from 'components/system/data/PageSizeSelect';
import { IProductRegisterStatWithShare } from 'types/stats';
import { nanoid } from 'nanoid';
import { ColumnsType } from 'antd/lib/table';

const ShareStatsTableBlock = styled.div`
  padding: 0px 40px;
`;
interface IShareStatsTable {
  data: IProductRegisterStatWithShare[];
  type: 'manufacturerId' | 'brandCompanyId';
}

const ShareStatsTable = ({ data, type }: IShareStatsTable) => {
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [page, setPage] = useState(1);

  const columns: ColumnsType<IProductRegisterStatWithShare> = useMemo(
    () => [
      {
        title: '구분',
        dataIndex: `${type}`,
        align: 'center' as const,
        render: (_, __, index) => (page - 1) * 10 + index + 1,
      },
      {
        title: `${type === 'manufacturerId' ? '제조사' : '브랜드사'}`,
        dataIndex: 'companyNameKo',
        align: 'center' as const,
      },
      {
        title: '점유율',
        dataIndex: 'share',
        align: 'center' as const,
        render: (productOnCount: number) => `${productOnCount}%`,
      },
      {
        title: '제품 등록 수',
        dataIndex: 'countProductReg',
        align: 'center' as const,
      },
    ],
    [type, page],
  );

  return (
    <ShareStatsTableBlock>
      <PageSizeSelect pageSize={pageSize} onChange={setPageSize} />
      <Table
        columns={columns}
        dataSource={data}
        rowKey={() => nanoid()}
        pagination={{
          pageSize,
          onChange(cur) {
            setPage(cur);
          },
        }}
      />
    </ShareStatsTableBlock>
  );
};

export default ShareStatsTable;
