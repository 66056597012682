export function getUpdatingObject<T>(
  newObject: T,
  originObject: any,
): Partial<T> | undefined {
  const updatedObject: Partial<T> = {};
  (Object.keys(newObject) as Array<keyof T>).forEach((key) => {
    const newValue = newObject[key];
    const originValue = originObject[key];
    if (newValue !== originValue) {
      updatedObject[key] = newValue;
    }
  });
  return Object.keys(updatedObject).length > 0 ? updatedObject : undefined;
}
