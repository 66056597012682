import client from 'lib/api/client';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { ILoginForm, IUser } from 'types/auth';
import { IAPIResponse } from 'types/common';

export const login = (
  body: ILoginForm,
): Promise<AxiosResponse<IAPIResponse<IUser>>> =>
  client.post(`/admin/auth/login?${qs.stringify(body, {})}`);

export const reissueToken = (body: string) =>
  client.post(`/admin/auth/login/auto?refreshToken=${body}`);
