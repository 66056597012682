import { Route, Switch } from 'react-router-dom';

import path from 'lib/path';
import ClinicalTrialSelectPage from 'pages/clinicalTrial/ClinicalTrialSelectPage';
import ClinicalTrialCartPage from 'pages/clinicalTrial/ClinicalTrialCartPage';
import ClinicalTrialEstimateListPage from 'pages/clinicalTrial/ClinicalTrialEstimateListPage';
import ClinicalTrialEstimatePage from 'pages/clinicalTrial/ClinicalTrialEstimatePage';
import ClinicalTrialToast from 'components/clinicalTrial/estimate/clinicalTrialSelect/ClinicalTrialToast';
import { useEffect } from 'react';
import { useStores } from 'stores/Context';

const ClinicalTrialEstimateRouter = () => {
  const { clinicalTrialStore } = useStores();
  useEffect(
    () => () => {
      clinicalTrialStore.emptyClinicalTrialCartItems();
      clinicalTrialStore.emptyClinicalTrialProducts();
      clinicalTrialStore.emptyClinicalTrialPackageSelections();
    },
    [],
  );
  return (
    <>
      <ClinicalTrialToast />
      <Switch>
        <Route
          path={path.clinicalTrial.estimate.select}
          component={ClinicalTrialSelectPage}
        />
        <Route
          path={path.clinicalTrial.estimate.cart}
          component={ClinicalTrialCartPage}
        />
        <Route
          path={path.clinicalTrial.estimate.list}
          component={ClinicalTrialEstimateListPage}
        />
        <Route
          path={`${path.clinicalTrial.estimate.root}/:clinicalTrialEstimateId`}
          component={ClinicalTrialEstimatePage}
        />
      </Switch>
    </>
  );
};

export default ClinicalTrialEstimateRouter;
