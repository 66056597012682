import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, Row } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { useStores } from 'stores/Context';
import ClinicalTrialPackage from 'components/clinicalTrial/estimate/clinicalTrialSelect/ClinicalTrialPackage';
import palette from 'lib/styles/palette';
import ClinicalTrialModal from 'components/clinicalTrial/estimate/ClinicalTrialModal';
import { useClinicalTrialPackages } from 'hooks/clinicalTrial/clinicalTrial';

const ClinicalTrialPackageListContainer = styled.div`
  height: 450px;
  padding: 0 80px;
  overflow: auto;
  margin-bottom: 24px;
`;

const ActionButton = styled(Button)`
  display: block;
  width: 200px;
  border: none;
`;

const ClinicalTrialRecommendedPackageModal = ({
  proposalMode, // HINT: true일 경우 시험항목이 하나라도 선택된 패키지들 노출, false이면 패키지의 모든 제품이 선택된 경우에만 노출
  onAddToCart,
  onClose,
}: {
  proposalMode: boolean;
  onAddToCart: () => void;
  onClose: () => void;
}) => {
  const clinicalTrialPackages = useClinicalTrialPackages();
  const { clinicalTrialStore } = useStores();
  const selectedClinicalTrialProductIds = clinicalTrialStore.selectedClinicalTrialProducts.map(
    ({ clinicalTrialProductId }) => clinicalTrialProductId,
  );
  const recommendedPackages = useMemo(() => {
    if (proposalMode) {
      return clinicalTrialPackages.filter(({ clinicalTrialPackageProducts }) =>
        clinicalTrialPackageProducts.some((clinicalTrialProducts) =>
          clinicalTrialProducts.some((clinicalTrialProduct) =>
            selectedClinicalTrialProductIds.includes(
              clinicalTrialProduct.clinicalTrialProductId,
            ),
          ),
        ),
      );
    } else {
      return clinicalTrialPackages.filter(({ clinicalTrialPackageProducts }) =>
        clinicalTrialPackageProducts.every((clinicalTrialProducts) =>
          clinicalTrialProducts.some(({ clinicalTrialProductId }) =>
            selectedClinicalTrialProductIds.includes(clinicalTrialProductId),
          ),
        ),
      );
    }
  }, [proposalMode, selectedClinicalTrialProductIds]);
  const hasSelectedPackage =
    clinicalTrialStore.clinicalTrialPackageSelections.length > 0;
  const handleCloseModal = () => {
    clinicalTrialStore.emptyClinicalTrialPackageSelections();
    onClose();
  };
  const handleAddToCartWithoutPackage = () => {
    clinicalTrialStore.addClinicalTrialCartItems(
      clinicalTrialStore.selectedClinicalTrialProducts,
    );
    onClose();
    onAddToCart();
  };
  const handleAddToCartWithPackage = () => {
    clinicalTrialStore.clinicalTrialPackageSelections.forEach(
      ({ clinicalTrialProducts }) => {
        clinicalTrialStore.deselectClinicalTrialProducts(clinicalTrialProducts);
      },
    );
    clinicalTrialStore.addClinicalTrialCartItems([
      ...clinicalTrialStore.clinicalTrialPackageSelections,
      ...clinicalTrialStore.selectedClinicalTrialProducts,
    ]);
    onClose();
    onAddToCart();
  };
  const handleAddCartOnlyPackage = () => {
    clinicalTrialStore.addClinicalTrialCartItems(
      clinicalTrialStore.clinicalTrialPackageSelections,
    );
    onClose();
    onAddToCart();
  };
  return (
    <ClinicalTrialModal width={880} onClose={handleCloseModal}>
      <ClinicalTrialPackageListContainer>
        {proposalMode ? (
          <>
            <Typography.Title align="center" medium color="darkNavy">
              추천 패키지
            </Typography.Title>
            <Typography.Text
              type="secondary"
              medium
              color="darkNavy"
              align="center"
              gutter={{ bottom: 36 }}
            >
              선택하신 시험항목에 관련된 패키지 상품이 있습니다.
            </Typography.Text>
          </>
        ) : (
          <Typography.Title
            type="secondary"
            align="center"
            medium
            color="darkNavy"
            gutter={{ bottom: 36 }}
          >
            선택하신 시험항목 중 적용 가능한 패키지 상품이 있습니다.
          </Typography.Title>
        )}
        {recommendedPackages.map((clinicalTrialPackage) => (
          <ClinicalTrialPackage
            key={clinicalTrialPackage.clinicalTrialPackageId}
            clinicalTrialPackage={clinicalTrialPackage}
          />
        ))}
      </ClinicalTrialPackageListContainer>
      <Row gutter={8} justify="center" style={{ width: '100%' }}>
        {proposalMode ? (
          <Col>
            <ActionButton
              type="primary"
              size="large"
              disabled={!hasSelectedPackage}
              onClick={handleAddCartOnlyPackage}
            >
              장바구니 담기
            </ActionButton>
          </Col>
        ) : (
          <>
            <Col>
              <ActionButton
                size="large"
                style={{ backgroundColor: palette.darkNavy, color: '#fff' }}
                onClick={handleAddToCartWithoutPackage}
              >
                패키지 없이 진행
              </ActionButton>
            </Col>
            <Col>
              <ActionButton
                type="primary"
                size="large"
                disabled={!hasSelectedPackage}
                onClick={handleAddToCartWithPackage}
              >
                패키지 적용
              </ActionButton>
            </Col>
          </>
        )}
      </Row>
    </ClinicalTrialModal>
  );
};

export default observer(ClinicalTrialRecommendedPackageModal);
