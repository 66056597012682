import { Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { Typography } from 'components/system';
import { useMemo } from 'react';
import { ICategoryCertificateStat } from 'types/stats';

interface IProps {
  categoryCertificateStats: ICategoryCertificateStat[];
  visible: boolean;
  onClose: () => void;
}

const CategoryCertificateModal = ({
  visible,
  categoryCertificateStats,
  onClose,
}: IProps) => {
  const totalCertFin = useMemo(
    () =>
      categoryCertificateStats.reduce(
        (total, { countCertFin }) => total + countCertFin,
        0,
      ) || 0,
    [categoryCertificateStats],
  );
  const categoryCertificateStatsWithSum = useMemo(
    () =>
      categoryCertificateStats.length
        ? categoryCertificateStats.concat({
            cosmeticCategoryIdDepth2: 0,
            categoryName: '합계',
            countCertFin: totalCertFin,
          })
        : [],
    [categoryCertificateStats],
  );
  const columns: ColumnsType<ICategoryCertificateStat> = [
    {
      title: '순위',
      align: 'center',
      dataIndex: 'cosmeticCategoryIdDepth2',
      render: (cosmeticCategoryIdDepth2, _, index) =>
        cosmeticCategoryIdDepth2 ? index + 1 : '-',
    },
    {
      title: '카테고리 - 중분류',
      dataIndex: 'categoryName',
      align: 'center',
    },
    {
      title: '인증 완료 수',
      dataIndex: 'countCertFin',
      align: 'center',
    },
    {
      title: '비율 (%)',
      dataIndex: 'countCertFin',
      align: 'center',
      render: (countCertFin) =>
        `${Math.floor((countCertFin / totalCertFin) * 100)}%`,
    },
  ];
  return (
    <Modal visible={visible} footer={null} width={800} onCancel={onClose}>
      <Typography.Title gutter={{ bottom: 24 }}>
        올해 카테고리 누적
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={categoryCertificateStatsWithSum}
        pagination={false}
      />
    </Modal>
  );
};

export default CategoryCertificateModal;
