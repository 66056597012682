import qs from 'qs';

import {
  ECertificateInfoFormType,
  ECertificateInfoStatus,
  ECertificateStatus,
  EDocumentCode,
  ICertificate,
  ICertificateInfo,
  ICountryVendorInfo,
  IProductKeepWarning,
  IStat,
} from 'types/certificate';
import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import client from 'lib/api/client';
import { createFormData } from 'lib/file';

export const getStats = async ({
  page,
  companyNameKo,
  companyNameEn,
  bizNumber,
  ceoNameKo,
}: {
  page: number;
  companyNameKo?: string;
  companyNameEn?: string;
  bizNumber?: string;
  ceoNameKo?: string;
}) => {
  const res = await client.post<IAPIPageableResponse<IStat[]>>(
    `/admin/company-stats/all/${page}/10/registerDt/D`,
    {
      ...(companyNameKo && { companyNameKo }),
      ...(companyNameEn && { companyNameEn }),
      ...(bizNumber && { bizNumber }),
      ...(ceoNameKo && { ceoNameKo }),
    },
  );
  return res.data;
};

export const getCertificates = async ({
  page,
  companyId,
  isFinish,
  productNameEn,
  countryNameKo,
  certRequestNo,
}: {
  page: number;
  companyId: number;
  isFinish: boolean;
  productNameEn?: string;
  countryNameKo?: string;
  certRequestNo?: string;
}) => {
  const res = await client.post<IAPIPageableResponse<ICertificate[]>>(
    `/admin/cert/all/${page}/10`,
    {
      companyId,
      isFinish,
      ...(productNameEn && { productNameEn }),
      ...(countryNameKo && { countryNameKo }),
      ...(certRequestNo && { certRequestNo }),
    },
  );
  return res.data;
};

export const getAllCertificates = async ({
  companyId,
  isFinish,
}: {
  companyId: number;
  isFinish: boolean;
}) => {
  const res = await client.post<IAPIPageableResponse<ICertificate[]>>(
    `/admin/cert/all/1/1000000`,
    {
      companyId,
      isFinish,
    },
  );
  return res.data;
};

export const getCertificate = (certTargetId: number) =>
  client.get<IAPIResponse<ICertificate>>(`/cert/${certTargetId}`);

export const changeCertificateStatus = ({
  certTargetId,
  status,
}: {
  certTargetId: number;
  status: ECertificateStatus;
}) => client.patch(`/admin/cert/status/${certTargetId}?status=${status}`);

export const getCertificateInfos = async (certTargetId: number) => {
  const res = await client.get<IAPIResponse<ICertificateInfo[]>>(
    `/admin/cert/on/detail/${certTargetId}`,
  );

  return {
    errors: res.data.errors,
    result: res.data.result.map((certificateInfo, index) => ({
      ...certificateInfo,
      no: index + 1,
    })),
  };
};

export const getCertificateDocuments = async (certTargetId: number) => {
  const res = await client.get<IAPIResponse<ICertificateInfo[]>>(
    `/admin/cert/fin/detail/${certTargetId}`,
  );

  return {
    errors: res.data.errors,
    result: res.data.result.map((certificateInfo, index) => ({
      ...certificateInfo,
      no: index + 1,
    })),
  };
};

export const getCertificateDocument = ({
  certTargetId,
  documentCode,
}: {
  certTargetId: number;
  documentCode: EDocumentCode;
}) =>
  client.get<IAPIResponse<ICertificateInfo>>(
    `/admin/cert/${certTargetId}/doc-status?documentCode=${documentCode}`,
  );

export const changeForm = ({
  certTargetId,
  formType,
  documentCodes,
}: {
  certTargetId: number;
  formType: ECertificateInfoFormType;
  documentCodes: string[];
}) =>
  client.put(`/admin/cert/${certTargetId}/document-form`, {
    formType,
    documentCodes,
  });

export const finishCertification = (params: {
  certTargetId: number;
  certRegisterNo: string;
  certFinYmd: string;
  isCertDocAutoPublish: boolean;
  docFile?: File;
  cpsrUploadFile?: File;
}) => client.post('/admin/cert/finish', createFormData(params));

export const changeCertificateInfoStatus = (params: {
  productDocStatusId: number;
  correctRequestMessage: string;
}) =>
  client.patch('/admin/doc-status/status/confirm', {
    ...params,
    status: ECertificateInfoStatus.MOD,
  });

export const uploadDocument = ({
  documentCode,
  productId,
  countryId,
  documentFile,
}: {
  documentCode: EDocumentCode;
  productId: number;
  countryId: number;
  documentFile: File;
}) =>
  client.post(`/admin/doc-status/${documentCode}`, {
    productId,
    countryId,
    documentFile,
  });

export const displayDocuments = (params: {
  certTargetId: number;
  documentCodes: EDocumentCode[];
  isDisplayed: boolean;
}) => client.patch(`/admin/product-doc-statuses/display`, params);

export const changeDocStatusToFinish = (productDocStatusId: number) =>
  client.patch('/admin/doc-status/status/confirm', {
    productDocStatusId,
    status: ECertificateInfoStatus.FIN,
  });

export const issueCpsr = (params: {
  certTargetId: number;
  cpsrIssueYmd: string;
}) => client.post('/admin/cert/cpsr/issue', params);

export const getSeriesInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<{ isSeriesProduct: boolean }>>(
    `/admin/products/${productId}/series-info?${qs.stringify({ countryId })}`,
  );

export const getCountryVendorInfo = (params: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<ICountryVendorInfo>>(
    `/admin/products/${params.productId}/country-vendor?${qs.stringify({
      countryId: params.countryId,
    })}`,
  );

export const getProductKeepWarning = (params: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IProductKeepWarning>>(
    `/admin/products/${params.productId}/keep-warning?${qs.stringify({
      countryId: params.countryId,
    })}`,
  );

export const getSeparateWasteCategoryIds = (params: {
  productId: number;
  countryId: number;
}) =>
  client.get<
    IAPIResponse<{
      categoryDataIds: number[];
    }>
  >(
    `/admin/products/${params.productId}/separate-waste?${qs.stringify({
      countryId: params.countryId,
    })}`,
  );
