import styled, { css } from 'styled-components';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';

import HomeHeader from 'components/HomeHeader';
import Navigator from 'components/Navigator';
import HomeRouter from 'routers/HomeRouter';
import LoadingIndicator from 'components/LoadingIndicator';
import FilePreviewModal from 'components/FilePreviewModal';
import { useNotificationSocket } from 'hooks/notification';
import path from 'lib/path';
import ClinicalTrialEstimateHeader from 'components/clinicalTrial/layout/ClinicalTrialEstimateHeader';

const HomeTemplateBlock = styled(Layout)`
  min-height: 100vh;
`;
const HomeBodyContainer = styled(Layout)<{ backgroundcolor?: string }>`
  ${({ backgroundcolor }) =>
    backgroundcolor &&
    css`
      background-color: ${backgroundcolor};
    `}
`;
const SiderBlock = styled(Layout.Sider)`
  height: calc(100vh - 48px);
  overflow: auto;
`;
const ContentBlock = styled(Layout.Content)`
  width: 100%;
  height: calc(100vh - 48px);
  overflow-y: scroll;
`;

const HomeTemplate = () => {
  const { pathname } = useLocation();
  const isClinicalTrialEstimatePage = pathname.startsWith(
    path.clinicalTrial.estimate.root,
  );
  useNotificationSocket();
  return (
    <HomeTemplateBlock>
      <LoadingIndicator />
      <FilePreviewModal />
      {!isClinicalTrialEstimatePage ? (
        <HomeHeader />
      ) : (
        <ClinicalTrialEstimateHeader />
      )}
      <HomeBodyContainer
        backgroundcolor={isClinicalTrialEstimatePage ? '#fff' : undefined}
      >
        {!isClinicalTrialEstimatePage && (
          <SiderBlock>
            <Navigator />
          </SiderBlock>
        )}
        <ContentBlock
          style={{ padding: !isClinicalTrialEstimatePage ? 40 : 0 }}
        >
          <HomeRouter />
        </ContentBlock>
      </HomeBodyContainer>
    </HomeTemplateBlock>
  );
};

export default HomeTemplate;
