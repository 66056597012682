import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import client from 'lib/api/client';
import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import {
  EClinicalTrialEstimateRequestStatus,
  IClinicalTrialEstimateClientInfo,
  IClinicalTrialEstimateRequest,
  IClinicalTrialEstimateRequestDetail,
} from 'types/clinicalTrial/process/estimateRequest';

const useEstimateRequestsKey = 'clinical-trial/estimate-requests';
export const useEstimateRequests = ({
  page,
  size = 10,
  status,
}: {
  page: number;
  size?: number;
  status?: string;
}) => {
  const { data, isLoading: getLoading } = useQuery(
    [useEstimateRequestsKey, page, size, status],
    () =>
      client.get<IAPIPageableResponse<IClinicalTrialEstimateRequest[]>>(
        '/v1/admin/clinical-trial/estimate-requests',
        {
          params: {
            page,
            size,
            status,
          },
        },
      ),
    { select: (res) => res.data.result },
  );

  const { content: estimateRequests, totalElements } = data || {};

  return useMemo(() => ({ estimateRequests, totalElements, getLoading }), [
    estimateRequests,
    totalElements,
    getLoading,
  ]);
};

export const useGetEstimateRequestDetails = () => {
  const {
    mutate: getEstimateRequestDetails,
    isLoading,
  } = useMutation((clinicalTrialEstimateRequestId: number | null) =>
    client.get<IAPIResponse<IClinicalTrialEstimateRequestDetail[]>>(
      `/v1/admin/clinical-trial/estimate-requests/${clinicalTrialEstimateRequestId}`,
    ),
  );

  return useMemo(
    () => ({
      getEstimateRequestDetails,
      isLoading,
    }),
    [getEstimateRequestDetails, isLoading],
  );
};

export const useEstimateRequestDetails = (
  clinicalTrialEstimateRequestId?: number,
) => {
  const { data: estimateRequestDetails = [], isLoading } = useQuery(
    ['clinical-trial/estimate-requests/detail', clinicalTrialEstimateRequestId],
    () =>
      client.get<IAPIResponse<IClinicalTrialEstimateRequestDetail[]>>(
        `/v1/admin/clinical-trial/estimate-requests/${clinicalTrialEstimateRequestId}`,
      ),
    {
      select: (res) => res.data.result,
      enabled: typeof clinicalTrialEstimateRequestId !== 'undefined',
    },
  );

  const estimateRequestDetailMap = estimateRequestDetails.reduce(
    (map, detail) => {
      const details = map.get(detail.categoryDataName);
      return map.set(
        detail.categoryDataName,
        details ? details.concat(detail) : [detail],
      );
    },
    new Map<string, IClinicalTrialEstimateRequestDetail[]>(),
  );

  return useMemo(
    () => ({
      estimateRequestDetails,
      estimateRequestDetailMap,
      isLoading,
    }),
    [estimateRequestDetails, estimateRequestDetailMap, isLoading],
  );
};

export const useUpdateEstimateRequestsStatus = () => {
  const queryClient = useQueryClient();
  const { mutate: updateEstimateRequestsStatus, isLoading } = useMutation(
    (
      params: {
        clinicalTrialEstimateRequestId: number;
        status: EClinicalTrialEstimateRequestStatus;
      }[],
    ) =>
      client.patch<IAPIResponse<null>>(
        '/v1/admin/clinical-trial/estimate-requests',
        params,
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries(useEstimateRequestsKey);
      },
    },
  );
  return useMemo(() => ({ updateEstimateRequestsStatus, isLoading }), [
    updateEstimateRequestsStatus,
    isLoading,
  ]);
};

export const useEstimateRequestClientInfo = (
  clinicalTrialEstimateRequestId?: number,
) => {
  const { data: estimateRequestClientInfo, isLoading } = useQuery(
    [
      'clinical-trial/estimate-requests',
      'client-info',
      clinicalTrialEstimateRequestId,
    ],
    () =>
      client.get<IAPIResponse<IClinicalTrialEstimateClientInfo>>(
        `/v1/admin/clinical-trial/estimate-requests/${clinicalTrialEstimateRequestId}/client-info`,
      ),
    {
      select: (res) => res.data.result,
      enabled: typeof clinicalTrialEstimateRequestId !== 'undefined',
    },
  );

  return useMemo(
    () => ({
      estimateRequestClientInfo,
      isLoading,
    }),
    [estimateRequestClientInfo, isLoading],
  );
};
