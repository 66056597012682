import { Col, Row } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { IClinicalTrialEstimateRequestDetail } from 'types/clinicalTrial/process/estimateRequest';

const Container = styled.div`
  & ~ & {
    margin-top: 16px;
  }
`;

const ItemContainer = styled.div`
  width: 352px;
  background-color: #fff;
  padding: 10px 16px;
  border-radius: 8px;
  margin-top: 8px;
`;

const ClinicalTrialRequestDetail = ({
  categoryName,
  items,
  onClick,
}: {
  categoryName: string;
  items: IClinicalTrialEstimateRequestDetail[];
  onClick?: (item: IClinicalTrialEstimateRequestDetail) => void;
}) => {
  return (
    <Container key={categoryName}>
      <Typography.Text bold>{categoryName}</Typography.Text>
      {items.map((item) => (
        <ItemContainer
          key={item.clinicalTrialEstimateRequestDetailId}
          onClick={() => onClick?.(item)}
          style={{
            cursor: typeof onClick !== 'undefined' ? 'pointer' : 'default',
          }}
        >
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Text style={{ wordBreak: 'break-word' }}>
                {item.clinicalTrialName}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text color="primary">
                {item.itemQuantity}
              </Typography.Text>
            </Col>
          </Row>
        </ItemContainer>
      ))}
    </Container>
  );
};

export default ClinicalTrialRequestDetail;
