import { useQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';

import * as productApi from 'lib/api/product';
import client from 'lib/api/client';
import { IAPIResponse } from 'types/common';
import {
  ICosmeticCategory,
  IFunctionalCategory,
  IProductNecessaryInfo,
} from 'types/product';

export const useProductDetail = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/productDetail', productId, countryId],
    () => productApi.getProductDetail({ productId, countryId }),
    { select: (data) => data.result },
  );

export const useCosmeticCategories = () =>
  useQuery(['product/cosmeticCategories'], productApi.getCosmeticCategories, {
    select: (data) => data.result,
  });

export const useFunctionalCategories = () =>
  useQuery(
    ['product/functionalCategories'],
    productApi.getFunctionalCategories,
    {
      select: (data) => data.result,
    },
  );

export const useProductCategory = (productId: number) => {
  const {
    data: cosmeticCategories = [],
    isFetching: isLoadingCosmeticCategories,
  } = useQuery(
    ['cosmetic-categories'],
    () => client.get<IAPIResponse<ICosmeticCategory[]>>('/cosmetic-categories'),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  const {
    data: functionalCategories = [],
    isFetching: isLoadingFunctionalCategories,
  } = useQuery(
    ['functional-categories'],
    () =>
      client.get<IAPIResponse<IFunctionalCategory[]>>('/functional-categories'),
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );

  const {
    data: productCategory = null,
    isFetching: isLoadingProductCategory,
  } = useQuery(
    ['product/productCategory', productId],
    () => productApi.getProductCategory(productId),
    {
      select: (res) => res.data.result,
    },
  );

  const [selectedFirstCategoryId, setSelectedFirstCategoryId] = useState<
    number | null
  >(null);
  const [selectedSecondCategoryId, setSelectedSecondCategoryId] = useState<
    number | null
  >(null);

  const firstCategories = useMemo(
    () => cosmeticCategories.filter(({ parentId }) => parentId === null),
    [cosmeticCategories],
  );
  const secondCategories = useMemo(
    () =>
      cosmeticCategories.filter(
        ({ parentId }) =>
          parentId !== null && parentId === selectedFirstCategoryId,
      ),
    [cosmeticCategories, selectedFirstCategoryId],
  );

  const firstCategory = useMemo(
    () =>
      firstCategories.find(
        (category) => category.cosmeticCategoryId === selectedFirstCategoryId,
      )?.name,
    [firstCategories, selectedFirstCategoryId],
  );

  const secondCategory = useMemo(
    () =>
      secondCategories.find(
        (category) => category.cosmeticCategoryId === selectedSecondCategoryId,
      )?.name,
    [firstCategories, selectedSecondCategoryId],
  );

  const thirdCategory = useMemo(() => {
    if (selectedSecondCategoryId && productCategory) {
      if (productCategory.isFunctional) {
        return '기능성 화장품';
      }

      return '일반 화장품';
    }

    return null;
  }, [selectedSecondCategoryId, productCategory]);

  const lastCategory = useMemo(
    () =>
      functionalCategories.find(
        (category) =>
          category.functionalCategoryId ===
          productCategory?.functionalCategoryId,
      )?.name,
    [functionalCategories, productCategory],
  );

  const categoryNames = useMemo(
    () => ({
      firstCategory,
      secondCategory,
      thirdCategory,
      ...(lastCategory ? { lastCategory } : { lastCategory: 'X' }),
    }),
    [firstCategory, secondCategory, thirdCategory, lastCategory],
  );

  useEffect(() => {
    if (productCategory) {
      setSelectedFirstCategoryId(productCategory.cosmeticCategoryIdDepth1);
      setSelectedSecondCategoryId(productCategory.cosmeticCategoryIdDepth2);
    }
  }, [productCategory]);

  return useMemo(
    () => ({
      categoryNames,
      isLoading:
        isLoadingCosmeticCategories ||
        isLoadingFunctionalCategories ||
        isLoadingProductCategory,
    }),
    [
      categoryNames,
      isLoadingCosmeticCategories,
      isLoadingFunctionalCategories,
      isLoadingProductCategory,
    ],
  );
};

export const useProductPhysicalProperty = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/productPhysicalProperty', productId, countryId],
    () => productApi.getProductPhysicalProperty({ productId, countryId }),
    {
      select: (data) => data.result,
    },
  );

export const useProductStabilityTest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/productStabilityTest', productId, countryId],
    () => productApi.getProductStabilityTest({ productId, countryId }),
    {
      select: (data) => data.result,
    },
  );

export const useProductChallengeTest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/productChallengeTest', productId, countryId],
    () => productApi.getProductChallengeTest({ productId, countryId }),
    {
      select: (data) => data.result,
    },
  );

export const useProductPackingAttestation = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/productPackingAttestation', productId, countryId],
    () => productApi.getProductPackingAttestation({ productId, countryId }),
    {
      select: (data) => data.result,
    },
  );

export const useProductInternalResponsibleCompany = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/productPhysicalProperty', productId, countryId],
    () =>
      productApi.getProductInternalResponsibleCompany({ productId, countryId }),
    {
      select: (data) => data.result,
    },
  );

export const useProductShelfLifeDeclaration = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/productShelfLifeDeclaration', productId, countryId],
    () => productApi.getProductShelfLifeDeclaration({ productId, countryId }),
    {
      select: (res) => res.data.result,
    },
  );

export const useProductSaleInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/saleInfo', productId, countryId],
    () => productApi.getProductSaleInfo({ productId, countryId }),
    { select: (res) => res.data.result },
  );

export const useProductVCRPAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/vcrpAccount', productId, countryId],
    () => productApi.getProductVCRPAccount({ productId, countryId }),
    { select: (res) => res.data.result },
  );

export const useProductVCRPAccountRequest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/vcrpAccountRequest', productId, countryId],
    () => productApi.getProductVCRPAccountRequest({ productId, countryId }),
    { select: (res) => res.data.result },
  );

export const useProductNecessaryInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['product/necessaryInfo', productId, countryId],
    () =>
      client.get<IAPIResponse<IProductNecessaryInfo>>(
        `/admin/products/${productId}/countries/${countryId}/product-necessary-info`,
      ),
    { select: (res) => res.data.result },
  );
