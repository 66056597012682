import styled from 'styled-components';

import ClinicalTrialEstimate from 'components/clinicalTrial/estimate/clinicalTrialEstimate/ClinicalTrialEstimate';
import palette from 'lib/styles/palette';

const ClinicalTrialEstimatePageContainer = styled.div`
  min-height: calc(100vh - 48px);
  background-color: ${palette.bgBlue};
`;

const ClinicalTrialEstimatePage = () => {
  return (
    <ClinicalTrialEstimatePageContainer>
      <ClinicalTrialEstimate />
    </ClinicalTrialEstimatePageContainer>
  );
};

export default ClinicalTrialEstimatePage;
