import { Button } from 'antd';
import { BigCloseIcon } from 'components/system/icon/BigCloseIcon';
import palette from 'lib/styles/palette';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const DiscountInfoPopupContainer = styled.div`
  position: relative;
`;

const Popup = styled.div`
  position: absolute;
  top: 24px;
  right: 8px;
  z-index: 10;
  width: 336px;
  color: #fff;
  background-color: ${palette.gray90};
  padding: 16px 48px 16px 24px;
  font-weight: 400;
  text-align: left;
  border-radius: 4px;

  .close-icon {
    position: absolute;
    transform: scale(0.5);
    top: 0;
    right: 0;
  }
`;

const DiscountInfoPopup = ({ discountInfo }: { discountInfo: string }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = () => {
      setVisible(false);
    };
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);
  return (
    <DiscountInfoPopupContainer onClick={(e) => e.stopPropagation()}>
      <Button
        size="small"
        block
        style={{
          width: 58,
          fontSize: 12,
          color: palette.text.primary,
          lineHeight: 1,
          padding: 4,
          backgroundColor: '#e6f0ff',
          border: 'none',
          fontWeight: 500,
        }}
        onClick={() => setVisible(true)}
      >
        할인 정보
      </Button>
      {visible && (
        <Popup>
          <BigCloseIcon color="#fff" onClick={() => setVisible(false)} />
          {discountInfo}
        </Popup>
      )}
    </DiscountInfoPopupContainer>
  );
};

export default DiscountInfoPopup;
