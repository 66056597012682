import styled from 'styled-components';

const FooterBox = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    min-width: 100px;

    & + button {
      margin-left: 16px;
    }
  }
`;
export default FooterBox;
