import { Card, Layout, Row } from 'antd';
import LoginForm from 'components/auth/LoginForm';
import styled from 'styled-components';

const LoginPageBlock = styled(Layout)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const LoginPage = () => {
  return (
    <LoginPageBlock>
      <Card style={{ width: 400, marginTop: 16 }}>
        <Row justify="center">
          <img
            src={'https://cdn.imweb.me/thumbnail/20200907/560fcd3b5005c.png'}
            alt=""
            style={{ width: 90 }}
          />
        </Row>
        <LoginForm />
      </Card>
    </LoginPageBlock>
  );
};

export default LoginPage;
