export enum EClinicalTrialProcessingEstimateStatus {
  CFM = 'CFM',
  COM = 'COM',
  STP = 'STP',
}

export enum EProcessingEstimateSearchType {
  ALL = 'ALL',
  CLIENT_COMPANY_NAME = 'CLIENT_COMPANY_NAME',
  ESTIMATE_NO = 'ESTIMATE_NO',
  CLIENT_NAME = 'CLIENT_NAME',
  ADMIN_USER_NAME = 'ADMIN_USER_NAME',
}

export interface IClinicalTrialProcessingEstimate {
  clinicalTrialEstimateId: number;
  clientCompanyName: string; // 업체명
  estimateNo: string; // 견적서 번호
  processItemRegisterDt: string; // 진행 확정일
  clientName: string; // 의뢰자
  clinicalTrialCount: number; // 시험수
  adminUserName: string; // 담당자
  estimateUrl: string; // 견적서
  paidAmount: number | null;
  totalPrice: number;
  publishDt: string;
  isDepositFinish: boolean;
  isReceiveContractFinish: boolean;
  receiveContractFinishDt: string;
  status: EClinicalTrialProcessingEstimateStatus;
  stopReason: string | null;
  completionDueDDay: number;
  completionDate: string | null;
  testCompletionDueDt: string;
  confirmationDate: string;
}

export interface IProcessingEstimatesSearchParams {
  pageNo: number;
  searchKey?: EProcessingEstimateSearchType;
  searchKeyword?: string;
  status?: string;
  size?: number;
  confirmationDate?: string;
}

export interface IEstimateHistory {
  clinicalTrialEstimateHistoryId: number;
  totalPrice: number;
  estimateUrl: string;
  updateDt: string;
}

export interface IClientInfo {
  clientCompanyName: string;
  clientName: string;
  clientTel: string;
  clientEmail?: string;
  clientCompanyAddress?: string;
  remark?: string;
}

export interface IClientInfoUpdate extends IClientInfo {
  clinicalTrialEstimateId: number;
}

export interface IEstimateDepositHistory {
  totalPrice: number;
  paidAmount: number;
  adminUsername: string;
  depositDate: string;
}

export interface IDepositAdd {
  clinicalTrialEstimateId: number;
  paidAmount: number;
  depositDate: string;
}

export interface IUpdateEstimateStopReason {
  clinicalTrialEstimateId: number;
  stopReason: string;
}
