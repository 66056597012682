import { Card } from 'antd';
import styled from 'styled-components';

const FormCard = styled(Card).attrs({ type: 'inner' })`
  & + & {
    margin-top: 40px;
  }

  & > .ant-card-body {
    padding: 32px 0;
  }
`;

export default FormCard;
