import { Form, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { useUpdateProductName } from 'hooks/clinicalTrial/process/processingEstimateDetail';

import consts from 'lib/consts';
import { requireRule } from 'lib/validate';

const ProductNameModal = ({
  productName,
  clinicalTrialProcessItemId,
  onClose,
}: {
  productName: string | null;
  clinicalTrialProcessItemId: number;
  onClose: () => void;
}) => {
  const [form] = useForm<{ productName: string }>();
  const { updateProductName, updateLoading } = useUpdateProductName();
  const handleSubmit = (formValue: { productName: string }) => {
    if (formValue.productName === productName) {
      message.warning(consts.message.NO_NEED_TO_UPDATE);
      return;
    } else {
      updateProductName(
        {
          clinicalTrialProcessItemId,
          productName: formValue.productName,
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    }
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <Modal
      title="제품명"
      visible
      onOk={handleOk}
      onCancel={onClose}
      bodyStyle={{ paddingBottom: 0 }}
      confirmLoading={updateLoading}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="productName"
          initialValue={productName}
          rules={[requireRule]}
        >
          {/* TODO : 제품명 몇 자까지? */}
          <TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProductNameModal;
