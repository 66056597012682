import { makeAutoObservable, toJS } from 'mobx';
import RootStore from './RootStore';
import { ICertificate } from 'types/certificate';

export default class CertificateStore {
  rootStore;
  private currentCertificate: ICertificate | null;

  constructor(root: RootStore) {
    this.rootStore = root;
    this.currentCertificate = null;

    makeAutoObservable(this);
  }

  getCurrentCertificate() {
    return toJS(this.currentCertificate);
  }

  setCurrentCertificate(certificate: ICertificate | null) {
    this.currentCertificate = certificate;
  }

  get currentCertificateValid() {
    return this.currentCertificate !== null;
  }
}
