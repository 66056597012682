import { Form, Typography } from 'antd';
import { useProductSaleInfo } from 'hooks/product';

const ProductSaleInfoModalContent = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const { data: productSaleInfo } = useProductSaleInfo({
    productId,
    countryId,
  });

  const { isOnlineSale, saleWebUrl } = { ...productSaleInfo };

  return (
    <Form labelCol={{ span: 8 }} size="small" style={{ paddingTop: 20 }}>
      <Form.Item label="온라인 판매 여부">
        <Typography.Text strong>{isOnlineSale ? '유' : '무'}</Typography.Text>
      </Form.Item>
      <Form.Item label="미국내 판매 웹페이지 주소">
        <Typography.Text strong>
          {saleWebUrl ? saleWebUrl : '-'}
        </Typography.Text>
      </Form.Item>
    </Form>
  );
};

export default ProductSaleInfoModalContent;
