import { Button, Form, message, Modal, Input } from 'antd';

import { useChangeCertificateInfoStatus } from 'hooks/certificate';
import FooterBox from 'components/FooterBox';

interface RequestImproveModalProps {
  productDocStatusId: number | null;
  onClose: (isSuccess: boolean) => void;
}

const RequestImproveModal: React.FC<RequestImproveModalProps> = ({
  productDocStatusId,
  onClose,
}) => {
  const changeCertificateInfoStatusMutation = useChangeCertificateInfoStatus({
    onSuccess: () => {
      message.info('보완 요청 완료');
      onClose(true);
    },
  });
  const submitImprove = ({
    correctRequestMessage,
  }: {
    correctRequestMessage: string;
  }) => {
    productDocStatusId &&
      changeCertificateInfoStatusMutation.mutate({
        productDocStatusId,
        correctRequestMessage,
      });
  };
  return (
    <Modal
      visible={productDocStatusId !== null}
      title="보완 요청"
      footer={null}
      closable={false}
    >
      <Form onFinish={submitImprove}>
        <Form.Item
          name="correctRequestMessage"
          rules={[
            { required: true, message: '보완 요청 메세지를 입력해 주세요.' },
          ]}
        >
          <Input.TextArea placeholder="보완 요청 메시지 입력" />
        </Form.Item>
        <FooterBox>
          <Button
            loading={changeCertificateInfoStatusMutation.isLoading}
            type="primary"
            htmlType="submit"
          >
            확인
          </Button>
          <Button onClick={() => onClose(false)}>취소</Button>
        </FooterBox>
      </Form>
    </Modal>
  );
};

export default RequestImproveModal;
