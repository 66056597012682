import { ChartProps, ReactChart } from 'chartjs-react';

import palette from 'lib/styles/palette';
import { IProductRegisterStatWithShare } from 'types/stats';

const DoughnutChart = ({
  dataSource,
}: {
  dataSource: IProductRegisterStatWithShare[];
}) => {
  const data = {
    labels: dataSource.map((v) => v.companyNameKo),
    datasets: [
      {
        data: dataSource.map((v) => v.share),
      },
    ],
  };

  const options: ChartProps['options'] = {
    elements: {
      arc: {
        backgroundColor: [
          palette.primary,
          `${palette.primary}50`,
          `${palette.primary}80`,
        ],
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: 'white',
        textAlign: 'center',
        formatter: function (value: any) {
          return `${value}%`;
        },
      },
      tooltip: {
        cornerRadius: 12,
        displayColors: false,
        xAlign: 'left',
        backgroundColor: '#fff',
        bodyColor: '#222',
        borderColor: palette.primary,
        borderWidth: 1,
        callbacks: {
          title: () => '',
          label: function (context: any) {
            return `${context.label} ${context.raw}%`;
          },
        },
      },
    },
  };

  return <ReactChart type="doughnut" data={data} options={options} />;
};

export default DoughnutChart;
