import { useState } from 'react';
import { notification, Typography } from 'antd';
import axios from 'axios';
import Storage from 'lib/storage';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

export const useForceUpdate = () => {
  const [value, setValue] = useState(0);
  return () => setValue(value + 1);
};

export const useDetectNewVersion = () => {
  const oldModified = useRef(Storage.getItem<string>('last-modified'));
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const fetchIndex = async () => {
      const {
        headers: { 'last-modified': lastModified },
      } = await axios.head('/');
      // cloudfront 환경이 아닐 경우
      if (!lastModified) return;

      if (oldModified.current === null) {
        oldModified.current = lastModified;
        Storage.setItem('last-modified', lastModified);
        return;
      }

      if (new Date(oldModified.current) < new Date(lastModified)) {
        oldModified.current = lastModified;
        Storage.setItem('last-modified', lastModified);
        notification.open({
          message: '새로운 버전이 릴리즈 되었습니다.',
          icon: null,
          description: (
            <Typography.Text>
              원활한 서비스를 위해{' '}
              <Typography.Link underline onClick={() => history.go(0)}>
                페이지 새로고침
              </Typography.Link>
              을 부탁드립니다.
            </Typography.Text>
          ),
          duration: 0,
        });
      }
    };
    if (process.env.NODE_ENV !== 'development') {
      fetchIndex();
    }
  }, [location]);
};
