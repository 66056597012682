import palette from 'lib/styles/palette';
import { useEffect } from 'react';
import styled from 'styled-components';

const MoreActionPopupContainer = styled.div`
  position: absolute;
  top: -12px;
  right: -120px;
  width: 100px;
  z-index: 1;
  border-radius: 8px;
  overflow: hidden;
`;

const MoreActionItemContainer = styled.div`
  position: relative;
  background-color: ${palette.gray90};
  padding: 6px 16px;
  color: #fff;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;

  & + & {
    border-top: 1px solid #7d7d7d;
  }
`;

const MoreActionPopup = ({
  isConfirmed,
  onUpdateEstimate,
  onShowUpdateHistory,
  onConfirmEstimate,
  onInputClientInfo,
  onClose,
}: {
  isConfirmed: boolean;
  onUpdateEstimate: () => void;
  onShowUpdateHistory: () => void;
  onConfirmEstimate: () => void;
  onInputClientInfo: () => void;
  onClose: () => void;
}) => {
  useEffect(() => {
    window.addEventListener('click', onClose);
    return () => {
      window.removeEventListener('click', onClose);
    };
  }, []);
  return (
    <MoreActionPopupContainer>
      {!isConfirmed && (
        <MoreActionItemContainer onClick={onUpdateEstimate}>
          견적 수정
        </MoreActionItemContainer>
      )}
      <MoreActionItemContainer onClick={onShowUpdateHistory}>
        수정 이력
      </MoreActionItemContainer>
      {!isConfirmed && (
        <MoreActionItemContainer onClick={onConfirmEstimate}>
          진행 확정
        </MoreActionItemContainer>
      )}
      <MoreActionItemContainer onClick={onInputClientInfo}>
        의뢰자 정보
      </MoreActionItemContainer>
    </MoreActionPopupContainer>
  );
};

export default MoreActionPopup;
