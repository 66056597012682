import { Table, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { nanoid } from 'nanoid';

import { useEstimateDepositHistories } from 'hooks/clinicalTrial/process/processingEstimate';
import { IEstimateDepositHistory } from 'types/clinicalTrial/process/processingEstimate';

const EstimateDepositHistoryModal = ({
  clinicalTrialEstimateId,
  onClose,
}: {
  clinicalTrialEstimateId: number;
  onClose: () => void;
}) => {
  const columns: ColumnsType<IEstimateDepositHistory> = [
    {
      title: '입금 확인 금액',
      dataIndex: 'paidAmount',
      align: 'center',
      render: (paidAmount: number) =>
        paidAmount ? `${paidAmount.toLocaleString()} 원` : '',
    },
    {
      title: '실 입금 날짜',
      dataIndex: 'depositDate',
      align: 'center',
    },
    {
      title: '관리자 ID',
      dataIndex: 'adminUsername',
      align: 'center',
    },
    {
      title: '일시',
      dataIndex: 'depositDate',
      align: 'center',
      render: (depositDate) => depositDate ?? '',
    },
  ];
  const { estimateDepositHistories, getLoading } = useEstimateDepositHistories(
    clinicalTrialEstimateId,
  );
  return (
    <Modal
      title="입금 확인 이력"
      width={800}
      visible
      footer={null}
      onCancel={onClose}
    >
      <Table
        loading={getLoading}
        columns={columns}
        dataSource={estimateDepositHistories}
        rowKey={() => nanoid()}
      />
    </Modal>
  );
};

export default EstimateDepositHistoryModal;
