import { useMemo, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import SearchInput from 'components/form/SearchInput';
import { Typography } from 'components/system';
import { EClinicalTrialType, IClinicalTrial } from 'types/clinicalTrial';
import useUpdateEffect from 'hooks/useUpdateEffect';
import { clinicalTrialTypeMap } from 'lib/consts';
import palette from 'lib/styles/palette';
import ClinicalTrial from './ClinicalTrial';
import ClinicalTrialProductTable from './ClinicalTrialProductTable';
import { useStores } from 'stores/Context';
import {
  useCategoryData,
  useClinicalTrials,
} from 'hooks/clinicalTrial/clinicalTrial';
import ClinicalTrialRequestDetail from 'components/clinicalTrial/ClinicalTrialRequestDetail';
import { useEstimateRequestDetails } from 'hooks/clinicalTrial/process/estimateRequest';
import { IClinicalTrialEstimateRequestDetail } from 'types/clinicalTrial/process/estimateRequest';

export const CheckboxContainer = styled.div<{ checked: boolean }>`
  background-color: ${({ checked }) =>
    checked ? '#e6f0ff' : palette.disabled};
  border-radius: 4px;
  color: ${({ checked }) =>
    checked ? palette.text.primary : palette.text.disabled};
  font-size: 10px;
  font-weight: 500;
  padding: 3px 4px 2px;
`;

const ClinicalTrialTypeCheckLabel = ({
  children,
  checked,
  onChange,
}: {
  children: string;
  checked: boolean;
  onChange: () => void;
}) => {
  return (
    <CheckboxContainer onClick={onChange} checked={checked}>
      <CheckOutlined style={{ marginRight: 3 }} />
      {children}
    </CheckboxContainer>
  );
};

export const ClinicalTrialProductSelectContainer = styled.div``;

const ClinicalTrialProductTableListContainer = styled.div`
  background-color: ${palette.bgBlue};
  height: calc(100vh - 48px - 112px);
  padding: 40px 20px 72px;
  overflow: auto;
`;

export const SearchWrapper = styled.div`
  position: relative;
  box-shadow: 6px 0 10px 0 rgba(215, 215, 215, 0.42);
  z-index: 1;
`;

const SearchContainer = styled.div`
  position: relative;
  padding-bottom: 56px;
  height: calc(100vh - 48px - 112px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SearchHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  padding: 12px 16px 8px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 1px 4px 0 ${palette.disabled};
`;

const ClinicalTrialListContainer = styled.div``;

const EmptyContainer = styled.div<{ requestMode: boolean }>`
  position: relative;
  height: calc(100vh - 48px - 112px);
  padding-bottom: 72px;
  background-color: ${palette.bgBlue};

  & > p {
    position: absolute;
    top: ${({ requestMode }) => (requestMode ? '25%' : '50%')};
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const RequestItemContainer = styled.div`
  width: 400px;
  background-color: #eff1f8;
  border-radius: 12px;
  padding: 24px 24px 36px 24px;
  margin: 40px 0px 12px 12px; ;
`;

const ClinicalTrialProductSelect = ({
  onClickRequestItem,
}: {
  onClickRequestItem: (categoryDataId: number, onOk: () => void) => void;
}) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [checkedClinicalTrialTypes, setCheckedClinicalTrialTypes] = useState<
    EClinicalTrialType[]
  >([
    EClinicalTrialType.FUNCTIONAL,
    EClinicalTrialType.STABILITY,
    EClinicalTrialType.VALIDITY,
    EClinicalTrialType.IN_VITRO,
  ]);
  const clinicalTrials = useClinicalTrials();
  const { clinicalTrialStore } = useStores();
  const clinicalTrialEstimateRequest =
    clinicalTrialStore.clinicalTrialEstimateRequest;
  const recommendedClinicalTrialIds =
    useCategoryData(clinicalTrialStore.categoryDataId)?.clinicalTrialIds || [];
  const filteredClinicalTrials = useMemo(
    () =>
      clinicalTrials
        .sort((a, b) => {
          const aIsRecommended = recommendedClinicalTrialIds.includes(
            a.clinicalTrialId,
          );
          const bIsRecommended = recommendedClinicalTrialIds.includes(
            b.clinicalTrialId,
          );
          if (
            (aIsRecommended && bIsRecommended) ||
            (!aIsRecommended && !bIsRecommended)
          )
            return a.clinicalTrialName.localeCompare(b.clinicalTrialName);
          if (aIsRecommended) return -1;
          return 1;
        })
        .filter(
          ({ clinicalTrialName, type }) =>
            clinicalTrialName.includes(searchKeyword) &&
            checkedClinicalTrialTypes.includes(type),
        ),
    [
      clinicalTrials,
      recommendedClinicalTrialIds,
      searchKeyword,
      checkedClinicalTrialTypes,
    ],
  );
  const [
    selectedClinicalTrial,
    setSelectedClinicalTrial,
  ] = useState<IClinicalTrial | null>(null);
  const handleToggleClinicalTrialType = (type: EClinicalTrialType) => {
    const isSelected = !checkedClinicalTrialTypes.includes(type);
    if (checkedClinicalTrialTypes.length === 1 && !isSelected) {
      return;
    }
    setCheckedClinicalTrialTypes((draftTypes) =>
      !isSelected
        ? draftTypes.filter((draftType) => draftType !== type)
        : draftTypes.concat(type),
    );
  };
  useUpdateEffect(() => {
    if (
      selectedClinicalTrial &&
      !clinicalTrials.includes(selectedClinicalTrial)
    )
      setSelectedClinicalTrial(null);
  }, [searchKeyword, checkedClinicalTrialTypes]);
  useUpdateEffect(() => {
    setSelectedClinicalTrial(null);
    setSearchKeyword('');
    setCheckedClinicalTrialTypes([
      EClinicalTrialType.FUNCTIONAL,
      EClinicalTrialType.STABILITY,
      EClinicalTrialType.VALIDITY,
      EClinicalTrialType.IN_VITRO,
    ]);
  }, [clinicalTrialStore.categoryDataId]);
  const { estimateRequestDetailMap } = useEstimateRequestDetails(
    clinicalTrialEstimateRequest?.clinicalTrialEstimateRequestId,
  );

  return (
    <ClinicalTrialProductSelectContainer>
      <Row align="stretch" wrap={false}>
        <Col flex="0 0 264px">
          <SearchWrapper>
            <SearchContainer>
              <SearchHeaderContainer>
                <Typography.Text color="darkNavy" bold gutter={{ bottom: 4 }}>
                  시험 항목
                </Typography.Text>
                <SearchInput
                  defaultValue={searchKeyword}
                  onSearch={setSearchKeyword}
                  placeholder="시험 항목 검색"
                  containerStyle={{ width: '100%' }}
                />
                <Row gutter={8} style={{ marginTop: 8 }}>
                  {Object.entries(clinicalTrialTypeMap).map(
                    ([type, typeString]) => (
                      <Col key={type}>
                        <ClinicalTrialTypeCheckLabel
                          checked={checkedClinicalTrialTypes.includes(
                            type as EClinicalTrialType,
                          )}
                          onChange={() =>
                            handleToggleClinicalTrialType(
                              type as EClinicalTrialType,
                            )
                          }
                        >
                          {typeString}
                        </ClinicalTrialTypeCheckLabel>
                      </Col>
                    ),
                  )}
                </Row>
              </SearchHeaderContainer>
              <ClinicalTrialListContainer>
                {filteredClinicalTrials.map((clinicalTrial) => (
                  <ClinicalTrial
                    key={clinicalTrial.clinicalTrialId}
                    clinicalTrial={clinicalTrial}
                    isSelected={clinicalTrial === selectedClinicalTrial}
                    isRecommended={recommendedClinicalTrialIds.includes(
                      clinicalTrial.clinicalTrialId,
                    )}
                    onClick={() => {
                      setSelectedClinicalTrial(clinicalTrial);
                    }}
                  />
                ))}
              </ClinicalTrialListContainer>
            </SearchContainer>
          </SearchWrapper>
        </Col>
        <Col
          flex={clinicalTrialEstimateRequest !== null ? '0 0 760px' : 'auto'}
        >
          {selectedClinicalTrial ? (
            <ClinicalTrialProductTableListContainer>
              {selectedClinicalTrial.methods.map((method) => (
                <ClinicalTrialProductTable
                  key={method.clinicalTrialMethodId}
                  clinicalTrialName={selectedClinicalTrial.clinicalTrialName}
                  clinicalTrialProducts={selectedClinicalTrial.clinicalTrialProducts.filter(
                    (clinicalTrialProduct) =>
                      clinicalTrialProduct.method.clinicalTrialMethodId ===
                      method.clinicalTrialMethodId,
                  )}
                  method={method}
                />
              ))}
            </ClinicalTrialProductTableListContainer>
          ) : (
            <EmptyContainer requestMode={clinicalTrialEstimateRequest !== null}>
              <Typography.Title type="secondary" medium color="darkNavy">
                시험 항목을 선택해 주세요.
              </Typography.Title>
            </EmptyContainer>
          )}
        </Col>
        {clinicalTrialEstimateRequest !== null && (
          <Col flex="auto" style={{ backgroundColor: palette.bgBlue }}>
            <RequestItemContainer>
              {Array.from(estimateRequestDetailMap.entries()).map(
                ([categoryName, items]) => (
                  <ClinicalTrialRequestDetail
                    key={categoryName}
                    categoryName={categoryName}
                    items={items}
                    onClick={(item) =>
                      onClickRequestItem(item.categoryDataId, () =>
                        setSelectedClinicalTrial(
                          clinicalTrials.find(
                            (clinicalTrial) =>
                              item.clinicalTrialId ===
                              clinicalTrial.clinicalTrialId,
                          ) || null,
                        ),
                      )
                    }
                  />
                ),
              )}
            </RequestItemContainer>
          </Col>
        )}
      </Row>
    </ClinicalTrialProductSelectContainer>
  );
};

export default observer(ClinicalTrialProductSelect);
