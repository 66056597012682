import client from 'lib/api/client';
import { IChecklistAnswer } from 'types/checklist';
import { IAPIResponse } from 'types/common';

export const getChecklistAnswers = (certTargetId: number) =>
  client.get<IAPIResponse<IChecklistAnswer[]>>(
    `/admin/cert/checklist-answer/${certTargetId}`,
  );

export const saveMemo = ({
  productCountryChecklistAnswerId,
  memo,
}: {
  productCountryChecklistAnswerId: number;
  memo: string;
}) =>
  client.patch<IAPIResponse<IChecklistAnswer>>(
    `/admin/checklist-answers/${productCountryChecklistAnswerId}/memo`,
    {
      memo,
    },
  );

export const deleteMemo = (productCountryChecklistAnswerId: number) =>
  client.delete<IAPIResponse<IChecklistAnswer>>(
    `/admin/checklist-answers/${productCountryChecklistAnswerId}/memo`,
  );
