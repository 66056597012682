import { makeAutoObservable } from 'mobx';
import { IPreviewedFile } from 'types/etc';
import RootStore from './RootStore';

export default class EtcStore {
  rootStore;

  private _previewedFiles: IPreviewedFile[];

  get previewedFiles() {
    return this._previewedFiles;
  }

  set previewedFiles(files: IPreviewedFile[]) {
    this._previewedFiles = files;
  }

  constructor(root: RootStore) {
    this.rootStore = root;
    this._previewedFiles = [];

    makeAutoObservable(this);
  }
}
