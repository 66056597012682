import { Button, Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { requireRule } from 'lib/validate';
import ClinicalTrialModal from '../ClinicalTrialModal';
import { CircleCheckIcon } from '../style';
import palette from 'lib/styles/palette';
import { useBizNumber } from 'hooks/company';

const Container = styled.div`
  .ant-form-vertical .ant-form-item-label > label {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: ${palette.darkNavy};
  }

  .ant-form-item-label {
    padding-bottom: 6px;
  }

  .ant-input[disabled] {
    color: ${palette.text.disabled};
    background-color: ${palette.disabled};
    border-color: ${palette.inactive};
  }
`;

// TODO : 로그인을 하여 견적 요청을 한 업체의 경우 사업자 등록증을 기본 input값으로 넣어주어야 함.
const EstimateConfirmModal = ({
  onOk,
  onClose,
  userId,
}: {
  onOk: (bizNumber: string) => void;
  onClose: () => void;
  userId: number | null;
}) => {
  const [isReceiveContractFinish, setIsReceiveContractFinish] = useState(false);
  const [form] = Form.useForm<{ bizNumber: string }>();
  const { data: bizNumber } = useBizNumber(userId);
  const confirmEstimate = (formValue: { bizNumber: string }) => {
    const bizNumber = formValue.bizNumber.replaceAll('-', '');
    onOk(bizNumber);
  };

  useEffect(() => {
    if (bizNumber) {
      form.setFieldsValue({
        bizNumber: bizNumber?.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3'),
      });
    }
  }, [bizNumber]);

  return (
    <ClinicalTrialModal
      width={360}
      style={{ paddingLeft: 38, paddingRight: 38 }}
    >
      <Typography.Title
        type="secondary"
        color="darkNavy"
        medium
        align="center"
        gutter={{ top: 34, bottom: 64 }}
      >
        진행 확정하시면 견적 수정이 불가합니다. 진행 확정하시겠습니까?
      </Typography.Title>
      <Container>
        <Form form={form} layout="vertical" onFinish={confirmEstimate}>
          <Form.Item
            required
            label="사업자등록번호"
            name="bizNumber"
            normalize={(value) => {
              if (value.length > 12) return value.substring(0, 12);
              if (/[^0-9-]/.test(value)) return value.replace(/[^0-9-]/, '');
              if (value.slice(-1) === '-') return value;
              return value
                .replace(/[-]/g, '')
                .replace(/^(\d{3})(\d{1,})/, '$1-$2')
                .replace(/^(\d{3}-\d{2})(\d{1,})/, '$1-$2');
            }}
            rules={[
              requireRule,
              {
                pattern: /^\d{3}-\d{2}-\d{5}$/,
                message: '올바르지 않은 사업자등록번호 형식',
              },
            ]}
          >
            <Input
              style={{ height: 44 }}
              disabled={typeof bizNumber !== 'undefined'}
            />
          </Form.Item>
          <Row
            align="middle"
            gutter={8}
            onClick={() => setIsReceiveContractFinish((draft) => !draft)}
          >
            <Col>
              <CircleCheckIcon checked={isReceiveContractFinish} />
            </Col>
            <Col>
              <Typography.Text type="secondary" color="darkNavy" medium>
                계약서 수령을 완료했습니다. (필수)
              </Typography.Text>
            </Col>
          </Row>
          <Button
            type="primary"
            block
            htmlType="submit"
            size="large"
            disabled={!isReceiveContractFinish}
            style={{ marginTop: 16, height: 56 }}
          >
            <Typography.Title type="secondary" medium inline>
              진행 확정
            </Typography.Title>
          </Button>
          <Typography.Title
            type="secondary"
            color="darkNavy"
            align="center"
            medium
            gutter={{ top: 24 }}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          >
            취소
          </Typography.Title>
        </Form>
      </Container>
    </ClinicalTrialModal>
  );
};

export default EstimateConfirmModal;
