import styled from 'styled-components';

import ClinicalTrialEstimateList from 'components/clinicalTrial/estimate/clinicalTrialEstimateList/ClinicalTrialEstimateList';
import palette from 'lib/styles/palette';

const ClinicalTrialEstimateListPageContainer = styled.div`
  min-height: calc(100vh - 48px);
  background-color: ${palette.bgBlue};
`;

const ClinicalTrialEstimateListPage = () => {
  return (
    <ClinicalTrialEstimateListPageContainer>
      <ClinicalTrialEstimateList />
    </ClinicalTrialEstimateListPageContainer>
  );
};

export default ClinicalTrialEstimateListPage;
