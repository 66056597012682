import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { useMemo } from 'react';
import { Typography } from 'components/system';
import { ICountryCertificateStat } from 'types/stats';
import palette from 'lib/styles/palette';
import styled from 'styled-components';

interface IProps {
  visible: boolean;
  onClose: () => void;
  countryCertificateStats: ICountryCertificateStat[];
}

const CountryCertificateTable = styled(Table)`
  .ant-table-row:first-child {
    .ant-table-cell {
      font-weight: 700;
    }
  }
  .ant-table-row:last-child {
    .ant-table-cell {
      color: ${palette.primary};
      font-weight: 500;
    }
  }
`;

const CountryCertificateDetailModal = ({
  visible,
  onClose,
  countryCertificateStats,
}: IProps) => {
  const totalCertFin = useMemo(
    () =>
      countryCertificateStats?.reduce(
        (total, { countCertFin }) => total + countCertFin,
        0,
      ) || 0,
    [countryCertificateStats],
  );
  const countryCertificateStatsWithSum = useMemo(
    () =>
      countryCertificateStats.length
        ? countryCertificateStats.concat({
            countryId: 0,
            countCertFin: totalCertFin,
            countryNameKo: '합계',
            sumSales: 0,
          })
        : [],
    [countryCertificateStats],
  );
  const columns: ColumnsType<any> = [
    {
      title: '순위',
      align: 'center',
      dataIndex: 'countryId',
      render: (countryId, _, index) => (countryId ? index + 1 : '-'),
    },
    {
      title: '국가',
      dataIndex: 'countryNameKo',
      align: 'center',
    },
    {
      title: '인증 완료 수',
      dataIndex: 'countCertFin',
      align: 'center',
    },
    {
      title: '비율 (%)',
      dataIndex: 'countCertFin',
      align: 'center',
      render: (countCertFin) =>
        `${Math.floor((countCertFin / totalCertFin) * 100)}%`,
    },
  ];
  return (
    <Modal visible={visible} footer={null} width={800} onCancel={onClose}>
      <Typography.Title gutter={{ bottom: 24 }}>
        국가별 전체 누적
      </Typography.Title>
      <CountryCertificateTable
        columns={columns}
        dataSource={countryCertificateStatsWithSum}
        pagination={false}
        rowKey={({ countryId }: any) => countryId}
      />
    </Modal>
  );
};

export default CountryCertificateDetailModal;
