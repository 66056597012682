import moment from 'moment';
import { Rule } from 'rc-field-form/lib/interface';
import consts from './consts';

/* REGEX */

export const dateRegex = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/;

/* RULES */
export const requireRule = {
  required: true,
  message: consts.message.REQUIRED_FIELD,
};

export const exceptKoreanRule = {
  pattern: /^[^ㄱ-ㅎㅏ-ㅣ가-힣]*$/,
  message: consts.message.SHOULD_EXCEPT_KOREAN,
};

export const exceptAlphabetRule = {
  pattern: /^[^A-Z]*$/i,
  message: consts.message.SHOULD_EXCEPT_ALPHABET,
};

export const exceptKoreanOrAlphabetRule = {
  pattern: /^[^A-Z]*$/i,
  message: consts.message.SHOULD_EXCEPT_KOREAN_OR_ALPHABET,
};

export const numberRule = {
  type: 'number',
  message: consts.message.SHOULD_NUMBER,
};

export const numbersRule = {
  pattern: /^\d*$/i,
  message: consts.message.SHOULD_NUMBER,
};

export const phoneRule = {
  pattern: /^\d{8,12}$/i,
  message: consts.message.PHONE_FORMAT,
};
export const mobilePhoneRule = {
  pattern: /^\d{10,11}$/i,
  message: consts.message.MOBILE_PHONE_FORMAT,
};

export const emailRule = {
  pattern: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
  message: consts.message.INVALID_EMAIL_FORMAT,
};

export const dateRule: Rule = {
  validator: async (_, value) => {
    if (value && !moment(value, 'YYYY-MM-DD', true).isValid()) {
      throw Error(consts.message.INVALID_DATE_FORMAT);
    }
  },
};

export const pastDateRule: Rule = {
  validator: async (_, value) => {
    if (!value) return;
    if (value !== '' && !moment(value, 'YYYY-MM-DD', true).isValid()) {
      throw Error(consts.message.INVALID_DATE_FORMAT);
    }
    if (new Date(value).getTime() > new Date().getTime()) {
      throw new Error('미래의 날짜는 입력 불가');
    }
  },
};

export const alphabetOrSpaceRule = {
  pattern: /^[A-Z\s]*$/i,
  message: consts.message.SHOULD_ALPHABET_OR_SPACE,
};

export const urlRule = {
  type: 'url',
  message: '올바르지 않은 URL 형식입니다.',
};

/* NORMALIZE */
export const normalizeDate = (input: string) =>
  input
    .replace(/[^\d-]/, '')
    .replace(/^(\d{4})(\d{1,})/, '$1-$2')
    .replace(/^(\d{4}-\d{2})(\d{1,})/, '$1-$2')
    .replace(/^(\d{4}-\d{2}-\d{2}).+/, '$1');
