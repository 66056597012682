import { Form, Typography } from 'antd';

import { useProductCategory } from 'hooks/product';

const ProductCategoryModalContent: React.FC<{
  productId: number;
}> = ({ productId }) => {
  const { categoryNames } = useProductCategory(productId);

  return (
    <Form labelCol={{ span: 8 }} size="small" style={{ paddingTop: 20 }}>
      <Form.Item label="대분류">
        <Typography.Text strong>{categoryNames.firstCategory}</Typography.Text>
      </Form.Item>
      <Form.Item label="중분류">
        <Typography.Text strong>{categoryNames.secondCategory}</Typography.Text>
      </Form.Item>
      <Form.Item label="소분류">
        <Typography.Text strong>{categoryNames.thirdCategory}</Typography.Text>
      </Form.Item>
      <Form.Item label="상세분류">
        <Typography.Text strong>{categoryNames.lastCategory}</Typography.Text>
      </Form.Item>
    </Form>
  );
};

export default ProductCategoryModalContent;
