const path = {
  main: '/',
  login: '/login',
  company: {
    root: '/company',
    approve: '/company/approve',
    buyer: '/company/buyer',
  },
  certificate: {
    root: '/certificate',
    company: '/certificate/company',
    checklist: '/certificate/checklist',
    publish: '/certificate/publish',
    finish: '/certificate/finish',
  },
  estimate: {
    root: '/estimate',
  },
  service: {
    notice: '/service/notice',
    faq: '/service/faq',
    useGuide: '/service/use-guide',
  },
  stats: {
    sales: '/stats/sales',
    certificate: '/stats/certificate',
    category: '/stats/category',
    share: '/stats/share',
    buyer: '/stats/buyer',
  },
  clinicalTrial: {
    root: '/clinical-trial',
    estimate: {
      root: '/clinical-trial/estimate',

      select: '/clinical-trial/estimate/select',
      cart: '/clinical-trial/estimate/cart',
      list: '/clinical-trial/estimate/list',
    },
    process: {
      root: '/clinical-trial/process',
      detail: '/clinical-trial/process/detail',
      request: '/clinical-trial/process/request',
    },
    contracts: '/clinical-trial/contracts',
    list: '/clinical-trial/list',
  },
};

export default path;
