import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'antd';
import styled, { css } from 'styled-components';

import { IClinicalTrial } from 'types/clinicalTrial';
import { useStores } from 'stores/Context';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { clinicalTrialTypeMap } from 'lib/consts';
import { RecommendLabel } from 'components/clinicalTrial/estimate/style';

const ClinicalTrialContainer = styled.div<{ isSelected: boolean }>`
  padding: 16px 8px 16px 20px;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${palette.lightBlue};
    `}

  & + & {
    box-shadow: 0 -1px 0px #f6f6f6;
  }
`;

const CloseButton = styled.div`
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${palette.disabled};

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 1px;
    height: 6px;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: left top;
    background-color: #979797;
  }

  &::before {
    transform: rotate(-45deg) translate(-50%, -50%);
  }
`;

const ClinicalTrial = ({
  clinicalTrial: { clinicalTrialName, type, clinicalTrialProducts },
  isSelected,
  isRecommended,
  onClick,
}: {
  clinicalTrial: IClinicalTrial;
  isSelected: boolean;
  isRecommended: boolean;
  onClick: () => void;
}) => {
  const { clinicalTrialStore } = useStores();

  const selectedClinicalTrialProducts = useMemo(
    () =>
      clinicalTrialProducts.filter(({ clinicalTrialProductId }) =>
        clinicalTrialStore.selectedClinicalTrialProducts.some(
          (product) =>
            product.clinicalTrialProductId === clinicalTrialProductId,
        ),
      ),
    [clinicalTrialProducts, clinicalTrialStore.selectedClinicalTrialProducts],
  );
  const handleDeselect = (e: React.MouseEvent) => {
    e.stopPropagation();
    clinicalTrialStore.deselectClinicalTrialProducts(
      selectedClinicalTrialProducts,
    );
  };
  return (
    <ClinicalTrialContainer isSelected={isSelected} onClick={onClick}>
      <Row align="middle" gutter={4}>
        <Col>
          <Typography.Text type="secondary">
            {clinicalTrialName}
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text
            type="quaternary"
            color="disabled"
            style={{ lineHeight: '21px' }}
          >
            {clinicalTrialTypeMap[type]}
          </Typography.Text>
        </Col>
        {isRecommended && (
          <Col>
            <RecommendLabel>추천</RecommendLabel>
          </Col>
        )}
        {selectedClinicalTrialProducts.length > 0 && (
          <Col flex="auto" style={{ height: 21 }}>
            <Row justify="end" align="middle" gutter={4}>
              <Col>
                <Typography.Text
                  type="quaternary"
                  medium
                  onClick={handleDeselect}
                >
                  <Typography.Text type="quaternary" inline color="primary">
                    {selectedClinicalTrialProducts.length}
                  </Typography.Text>
                  개 선택
                </Typography.Text>
              </Col>
              <Col>
                <CloseButton onClick={handleDeselect} />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </ClinicalTrialContainer>
  );
};

export default observer(ClinicalTrial);
