import { Button, Col, Row, Table } from 'antd';
import { Typography } from 'components/system';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useStores } from 'stores/Context';
import {
  EOrderType,
  IClinicalTrialMethod,
  IClinicalTrialProduct,
} from 'types/clinicalTrial';
import OrderTypeSelect from './OrderSelect';
import ClinicalTrialModal from '../ClinicalTrialModal';
import palette from 'lib/styles/palette';
import InfoIcon from 'components/system/icon/InfoIcon';
import DiscountInfoPopup from './DiscountInfoPopup';

const ClinicalTrialProductTableContainer = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

const RemarkContainer = styled.div`
  .ant-btn + div {
    margin-top: 4px;
  }
`;

const ClinicalTrialProductTable = ({
  clinicalTrialName,
  clinicalTrialProducts,
  method,
}: {
  clinicalTrialName: string;
  clinicalTrialProducts: IClinicalTrialProduct[];
  method: IClinicalTrialMethod;
}) => {
  const { clinicalTrialStore } = useStores();
  const [orderType, setOrderType] = useState(EOrderType.RECOMMEND);
  const orderedClinicalTrialProducts = useMemo(
    () =>
      [...clinicalTrialProducts].sort((a, b) => {
        switch (orderType) {
          case EOrderType.LOWER_PRICE:
            return a.price - b.price;
          case EOrderType.SHORT_DURATION:
            return a.duration - b.duration;
          case EOrderType.AGENCY_NAME:
            return a.agency.agencyName.localeCompare(b.agency.agencyName);
          case EOrderType.COUNTRY_NAME:
            return a.agency.countryName.localeCompare(b.agency.countryName);
          default:
            return 0;
        }
      }),
    [orderType, clinicalTrialProducts],
  );
  const [methodDetailModalVisible, setMethodDetailModalVisible] = useState(
    false,
  );
  const [
    productForShowDetail,
    setProductForShowDetail,
  ] = useState<IClinicalTrialProduct | null>(null);

  return (
    <ClinicalTrialProductTableContainer>
      {productForShowDetail && (
        <ClinicalTrialModal
          onClose={() => setProductForShowDetail(null)}
          style={{ paddingLeft: 24, paddingRight: 76 }}
        >
          <Typography.Text bold color="darkNavy">
            {productForShowDetail.clinicalTrialName} /{' '}
            {productForShowDetail.method.methodName} /{' '}
            {productForShowDetail.agency.agencyName}
          </Typography.Text>
          <Typography.Text
            type="secondary"
            color="darkNavy"
            medium
            gutter={{ top: 16 }}
            style={{ whiteSpace: 'pre-line' }}
          >
            {productForShowDetail.remark}
          </Typography.Text>
        </ClinicalTrialModal>
      )}
      {methodDetailModalVisible && (
        <ClinicalTrialModal
          onClose={() => setMethodDetailModalVisible(false)}
          style={{ paddingLeft: 24, paddingRight: 76 }}
        >
          <Typography.Text bold color="darkNavy">
            {clinicalTrialProducts[0].clinicalTrialName} / {method.methodName}
          </Typography.Text>
          {method.description && (
            <Typography.Text
              type="secondary"
              color="darkNavy"
              medium
              gutter={{ top: 16 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {method.description}
            </Typography.Text>
          )}
          {method.imageUrl && (
            <img
              src={method.imageUrl}
              style={{ width: '100%', marginTop: 16 }}
              alt="method_image"
            />
          )}
        </ClinicalTrialModal>
      )}
      <Row align="bottom" justify="space-between" style={{ marginBottom: 4 }}>
        <Col>
          <Row align="middle" gutter={8}>
            <Col>
              <Typography.Text bold color="darkNavy" inline>
                {clinicalTrialName} / {method.methodName}
              </Typography.Text>
            </Col>
            {(method.description || method.imageUrl) && (
              <Col>
                <InfoIcon
                  width={20}
                  height={20}
                  onClick={() => setMethodDetailModalVisible(true)}
                  style={{ display: 'block' }}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col>
          <OrderTypeSelect
            selectedType={orderType}
            orderTypes={Object.keys(EOrderType) as EOrderType[]}
            onSelect={setOrderType}
          />
        </Col>
      </Row>
      <Table
        className="secondary"
        columns={[
          {
            title: '시험 기관',
            width: '22%',
            dataIndex: ['agency', 'agencyName'],
            align: 'center',
          },
          {
            title: '국가',
            width: 128,
            dataIndex: ['agency', 'countryName'],
            align: 'center',
          },
          {
            title: '소요기간',
            width: 128,
            dataIndex: 'duration',
            align: 'center',
            render: (duration) => (duration !== 1000 ? `${duration}주` : '-'),
          },
          {
            title: '단가 (원)',
            width: 168,
            align: 'center',
            render: (_, { price }) => price.toLocaleString(),
          },
          {
            title: '비고',
            width: 80,
            align: 'center',
            render: (_, product) => (
              <RemarkContainer>
                {product.remark && (
                  <Button
                    size="small"
                    block
                    style={{
                      width: 58,
                      fontSize: 12,
                      color: palette.text.disabled,
                      lineHeight: 1,
                      padding: 4,
                    }}
                    onClick={() => setProductForShowDetail(product)}
                  >
                    상세 보기
                  </Button>
                )}
                {product.discountInfo && (
                  <DiscountInfoPopup discountInfo={product.discountInfo} />
                )}
              </RemarkContainer>
            ),
          },
        ]}
        dataSource={orderedClinicalTrialProducts}
        rowKey="clinicalTrialProductId"
        pagination={false}
        rowSelection={{
          hideSelectAll: true,
          selectedRowKeys: clinicalTrialStore.selectedClinicalTrialProducts.map(
            ({ clinicalTrialProductId }) => clinicalTrialProductId,
          ),
          onSelect: (clinicalTrialItem, selected) => {
            if (selected) {
              clinicalTrialStore.selectClinicalTrialProduct(clinicalTrialItem);
            } else {
              clinicalTrialStore.deselectClinicalTrialProduct(
                clinicalTrialItem,
              );
            }
          },
        }}
      />
    </ClinicalTrialProductTableContainer>
  );
};

export default observer(ClinicalTrialProductTable);
