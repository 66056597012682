export interface IUser {
  userId: number;
  username: string;
  name: string;
  deptName: string;
  positionName: string;
  isManufacturer: boolean;
  mobile: string;
  directTelNumber?: string;
  email: string;
  authority: string;
  token: string;
  refreshToken: string;
  userType: EUserType;
}

export interface ILoginForm {
  username: string;
  password: string;
}

export enum EAuthority {
  SUPER_USER = 'SUPER_USER',
  MA_USER = 'MA_USER',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum EUserType {
  BRAND = 'BRAND',
  MANUFACTURE = 'MANUFACTURE',
  MATERIAL = 'MATERIAL',
}
