import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { message } from 'antd';
import xlsx from 'xlsx';
import moment from 'moment';

import { ECertificateStatus, EDocumentCode } from 'types/certificate';
import { ICompany } from 'types/company';
import * as certificateApi from 'lib/api/certificate';
import * as productApi from 'lib/api/product';

export const useStats = (params: {
  page: number;
  companyNameKo?: string;
  companyNameEn?: string;
  bizNumber?: string;
  ceoNameKo?: string;
}) =>
  useQuery(
    ['certificate/stats', params.page],
    () => certificateApi.getStats(params),
    {
      select: (data) => data.result,
    },
  );
export const useCertificate = (certTargetId: number) => {
  const { data, refetch } = useQuery(
    `certificate/${certTargetId}`,
    () => certificateApi.getCertificate(Number(certTargetId)),
    { select: (res) => res.data.result },
  );
  return { certificate: data, handleRefresh: refetch };
};

export const useCertificates = (params: {
  companyId: number;
  page: number;
  isFinish: boolean;
  productNameEn?: string;
  countryNameKo?: string;
  certRequestNo?: string;
}) =>
  useQuery(
    ['certificate/certificates', params.isFinish],
    () => certificateApi.getCertificates(params),
    {
      select: (data) => data.result,
    },
  );

export const useChangeCertificateStatus = (isFinish: boolean) => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: { certTargetId: number; status: ECertificateStatus }) =>
      certificateApi.changeCertificateStatus(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['certificate/certificates', isFinish]);
      },
    },
  );
};

export const useCertificateInfos = (certTargetId: number) =>
  useQuery(
    ['certificate/certificateInfos', certTargetId],
    () => certificateApi.getCertificateInfos(certTargetId),
    {
      select: (data) => data.result,
    },
  );

export const useCertificateDocuments = (
  certTargetId: number,
  productId: number,
  countryId: number,
) =>
  useQuery(['certificate/certificateDocuments', certTargetId], async () => {
    const { result } = await certificateApi.getCertificateDocuments(
      certTargetId,
    );
    const artwork = result.find(
      ({ documentCode }) => documentCode === EDocumentCode.ART,
    );
    if (artwork) {
      const {
        data: { result },
      } = await productApi.getProductArtworks({
        productId,
        countryId,
      });

      artwork.documentUrls = result
        .filter(({ documentUrl }) => documentUrl !== null)
        .map(({ documentUrl }) => documentUrl);
      artwork.uploadFileUrls = result.map(({ uploadFileUrl }) => uploadFileUrl);
      artwork.filenames = result.map(({ filename }) => filename);
    }

    const thirdPartyTest = result.find(
      ({ documentCode }) => documentCode === EDocumentCode.THIRD,
    );

    if (thirdPartyTest) {
      const {
        data: { result },
      } = await productApi.getProductThirdPartyTest({
        productId,
        countryId,
      });
      thirdPartyTest.documentUrls = result
        .filter(({ documentUrl }) => documentUrl !== null)
        .map(({ documentUrl }) => documentUrl);
      thirdPartyTest.uploadFileUrls = result.map(
        ({ uploadFileUrl }) => uploadFileUrl,
      );
      thirdPartyTest.filenames = result.map(({ filename }) => filename);
    }

    return result;
  });

export const useCertificateDocument = ({
  certTargetId,
  documentCode,
}: {
  certTargetId: number;
  documentCode: EDocumentCode;
}) => {
  const { data: document = null } = useQuery(
    ['certificate/certificateDocument', certTargetId, documentCode],
    () => certificateApi.getCertificateDocument({ certTargetId, documentCode }),
    { select: (res) => res.data.result },
  );
  return document;
};

export const useChangeForm = (certTargetId: number) => {
  const queryClient = useQueryClient();
  return useMutation(certificateApi.changeForm, {
    onSuccess: () => {
      message.info('발행되었습니다.');
      queryClient.invalidateQueries([
        'certificate/certificateDocuments',
        certTargetId,
      ]);
    },
  });
};

export const useFinishCertification = (option: { onSuccess: () => void }) =>
  useMutation(
    (params: {
      certTargetId: number;
      certRegisterNo: string;
      certFinYmd: string;
      isCertDocAutoPublish: boolean;
      docFile?: File;
      cpsrUploadFile?: File;
    }) => certificateApi.finishCertification(params),
    option,
  );

export const useChangeCertificateInfoStatus = (option: {
  onSuccess: () => void;
}) =>
  useMutation(
    (params: { productDocStatusId: number; correctRequestMessage: string }) =>
      certificateApi.changeCertificateInfoStatus(params),
    option,
  );

export const useUploadDocument = (option: { onSuccess: () => void }) =>
  useMutation(
    (params: {
      documentCode: EDocumentCode;
      productId: number;
      countryId: number;
      documentFile: File;
    }) => certificateApi.uploadDocument(params),
    option,
  );

export const useDownloadCertificates = ({
  companyId,
  companyNameKo,
}: ICompany) => {
  const [loading, setLoading] = useState(false);
  const downloadCertificates = useCallback(async () => {
    setLoading(true);
    const {
      result: { content: ongoingCertificates },
    } = await certificateApi.getAllCertificates({
      companyId,
      isFinish: false,
    });
    const {
      result: { content: finishedCertificates },
    } = await certificateApi.getAllCertificates({
      companyId,
      isFinish: true,
    });
    const parsedOngoingCertificates = ongoingCertificates.map(
      ({ certRequestNo, estimateTarget, estimate, registerDt, status }) => ({
        '인증 신청 번호': certRequestNo,
        제품명: estimateTarget.productDetails[0].productNameEn,
        국가: estimateTarget.countryName,
        접수일: registerDt.slice(0, 10),
        '진행 상태':
          status === ECertificateStatus.REG_FIN
            ? '접수 완료'
            : status === ECertificateStatus.CHK_DOC
            ? '서류 검토중'
            : status === ECertificateStatus.CHK_SAM
            ? '샘플 검역중'
            : '',
        입금: estimate.isDepositFinish ? '입금 완료' : '입금 대기중',
        계약서: estimate.isDepositFinish ? '수령 완료' : '수령 대기중',
      }),
    );
    const parsedFinishCertificates = finishedCertificates.map(
      ({
        certRequestNo,
        estimateTarget,
        registerDt,
        lastStatusUpdateDt,
        certRegisterNo,
      }) => ({
        '인증 신청 번호': certRequestNo,
        제품명: estimateTarget.productDetails[0].productNameEn,
        국가: estimateTarget.countryName,
        접수일: registerDt.slice(0, 10),
        '인증 완료일': lastStatusUpdateDt?.slice(0, 10),
        '인증 등록번호': certRegisterNo,
      }),
    );
    const ongoingWorkSheet = xlsx.utils.json_to_sheet(
      parsedOngoingCertificates,
      {
        cellStyles: true,
      },
    );
    ongoingWorkSheet['!cols'] = [
      { wpx: 120 },
      { wpx: 200 },
      { wpx: 80 },
      { wpx: 120 },
      { wpx: 80 },
      { wpx: 80 },
      { wpx: 80 },
    ];
    const finishWorkSheet = xlsx.utils.json_to_sheet(parsedFinishCertificates, {
      cellStyles: true,
    });
    finishWorkSheet['!cols'] = [
      { wpx: 120 },
      { wpx: 200 },
      { wpx: 80 },
      { wpx: 80 },
      { wpx: 80 },
      { wpx: 160 },
    ];

    const workBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workBook, ongoingWorkSheet, '인증 진행 중');
    xlsx.utils.book_append_sheet(workBook, finishWorkSheet, '인증 완료');
    xlsx.writeFile(
      workBook,
      `[인증 현황] ${companyNameKo}_${moment().format('YYYY-MM-DD')}.xlsx`,
    );
    setLoading(false);
  }, []);
  return useMemo(() => ({ loading, downloadCertificates }), [
    loading,
    downloadCertificates,
  ]);
};

export const useIssueCpsr = () => {
  const { mutate: issueCpsr, isLoading: issueCpsrLoading } = useMutation(
    certificateApi.issueCpsr,
  );
  return useMemo(
    () => ({
      issueCpsr,
      issueCpsrLoading,
    }),
    [issueCpsr, issueCpsrLoading],
  );
};

export const useGetSeriesInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    [`products/${productId}/series-info`, countryId],
    () => certificateApi.getSeriesInfo({ productId, countryId }),
    {
      select: (res) => res.data.result.isSeriesProduct,
      enabled: productId !== undefined,
    },
  );

export const useGetCountryVendorInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['partner-vendor', productId, countryId],
    () => certificateApi.getCountryVendorInfo({ productId, countryId }),
    {
      select: (res) => res.data.result,
      enabled: productId !== undefined,
    },
  );

export const useGetProductKeepWarning = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['keep-warning', productId, countryId],
    () =>
      certificateApi.getProductKeepWarning({
        productId,
        countryId,
      }),
    {
      select: (res) => res.data.result,
      enabled: productId !== undefined,
    },
  );

export const useSeparateWasteCategoryIds = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  useQuery(
    ['separate-waste', productId, countryId],
    () =>
      certificateApi.getSeparateWasteCategoryIds({
        productId,
        countryId,
      }),
    {
      select: (res) => res.data.result.categoryDataIds,
      enabled: productId !== undefined,
    },
  );
