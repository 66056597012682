import { Route, Switch } from 'react-router-dom';

import path from 'lib/path';
import ClinicalTrialProcessEstimateDetailPage from 'pages/clinicalTrial/ClinicalTrialProcessEstimateDetailPage';
import ClinicalTrialProcessingEstimatePage from 'pages/clinicalTrial/ClinicalTrialProcessingEstimatePage';
import ClinicalTrialEstimateRequestListPage from 'pages/clinicalTrial/ClinicalTrialEstimateRequestListPage';

const ClinicalTrialProcessRouter = () => {
  return (
    <Switch>
      <Route
        path={path.clinicalTrial.process.root}
        exact
        component={ClinicalTrialProcessingEstimatePage}
      />
      <Route
        path={`${path.clinicalTrial.process.detail}/:clinicalTrialEstimateId`}
        component={ClinicalTrialProcessEstimateDetailPage}
      />
      <Route
        path={path.clinicalTrial.process.request}
        component={ClinicalTrialEstimateRequestListPage}
      />
    </Switch>
  );
};

export default ClinicalTrialProcessRouter;
