import styled from 'styled-components';
import { useEffect } from 'react';

import { BigCloseIcon } from 'components/system/icon/BigCloseIcon';

const ClinicalTrialModalMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: #00000050;
`;

const ClinicalTrialModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
  }
`;

const ClinicalTrialModalContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  padding: 32px 0;
  background-color: #f6f8fb;
  border-radius: 20px;
  overflow: auto;

  .ant-form-item-label {
    padding-bottom: 4px;
  }

  .ant-input-lg {
    height: 44px;
  }
`;

const ClinicalTrialModal = ({
  children,
  width = 560,
  onClose,
  closeOnEsc,
  style,
}: {
  children: React.ReactNode;
  width?: number;
  onClose?: () => void;
  closeOnEsc?: boolean;
  style?: React.CSSProperties;
}) => {
  useEffect(() => {
    if (closeOnEsc && onClose) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.keyCode === 27) {
          onClose();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [onClose, closeOnEsc]);

  return (
    <ClinicalTrialModalMask>
      <ClinicalTrialModalWrapper>
        {onClose && <BigCloseIcon onClick={onClose} />}
        <ClinicalTrialModalContainer width={width} style={style}>
          {children}
        </ClinicalTrialModalContainer>
      </ClinicalTrialModalWrapper>
    </ClinicalTrialModalMask>
  );
};

export default ClinicalTrialModal;
