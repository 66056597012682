import { Col, Row } from 'antd';
import styled from 'styled-components';

import AllManufacturerShareCard from 'components/stats/shareStats/AllManufacturerShareCard';
import CompanyShareCard from 'components/stats/shareStats/CompanyShareCard';
import { Typography } from 'components/system';

const ShareStatsPageBlock = styled.div``;

const ShareStatsPage = () => {
  return (
    <ShareStatsPageBlock>
      <Typography.Title gutter={{ bottom: 16 }}>점유율 분석</Typography.Title>
      <Row gutter={20}>
        <Col span={12}>
          <AllManufacturerShareCard />
        </Col>
        <Col span={12}>
          <CompanyShareCard />
        </Col>
      </Row>
    </ShareStatsPageBlock>
  );
};

export default ShareStatsPage;
