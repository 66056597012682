import { PageHeader } from 'antd';
import { useHistory } from 'react-router';
import styled from 'styled-components';

interface PageTemplateProps {
  title?: string;
  children: React.ReactNode;
  back?: boolean;
}

const PageTemplateBlock = styled.div``;

const PageTemplate: React.FC<PageTemplateProps> = ({
  title,
  children,
  back = false,
}) => {
  const history = useHistory();
  return (
    <PageTemplateBlock>
      {title && (
        <PageHeader title={title} onBack={back ? history.goBack : undefined} />
      )}
      {children}
    </PageTemplateBlock>
  );
};

export default PageTemplate;
