import { RightOutlined, TableOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { Typography } from 'components/system';
import BarChartIcon from 'components/system/icon/BarChartIcon';
import CategoryCertificateModal from 'components/stats/CategoryCertificateModal';
import { useThisYearCategoryCertificateStats } from 'hooks/stats';
import { ChartData, ChartOptions } from 'chart.js';
import palette from 'lib/styles/palette';
import { ReactChart } from 'chartjs-react';

const ThisYearCategoryChartBlock = styled(Card)``;

const ShowAllBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ThisYearCategoryChart = () => {
  const [
    categoryCertificateModalVisible,
    setCategoryCertificateModalVisible,
  ] = useState(false);
  const {
    data: thisYearCategoryCertificateStats = [],
  } = useThisYearCategoryCertificateStats();

  const categoryCertificateChartData: ChartData = useMemo(() => {
    const totalCertFin =
      thisYearCategoryCertificateStats.reduce(
        (total, { countCertFin }) => total + countCertFin,
        0,
      ) || 0;

    const topTenStats = [
      ...thisYearCategoryCertificateStats.slice(
        0,
        thisYearCategoryCertificateStats.length > 11 ? 9 : 10,
      ),
      ...(thisYearCategoryCertificateStats.length > 11
        ? [
            {
              cosmeticCategoryIdDepth2: 0,
              countCertFin: thisYearCategoryCertificateStats
                .slice(10)
                .reduce((total, { countCertFin }) => total + countCertFin, 0),
              categoryName: '기타',
            },
          ]
        : []),
    ];
    return {
      labels: topTenStats.map(({ categoryName }) => categoryName),
      datasets: [
        {
          data: topTenStats.map(({ countCertFin }) => ({
            y: countCertFin,
            x: Math.floor((countCertFin / totalCertFin) * 100),
          })),
          backgroundColor: [
            palette.primary,
            `${palette.primary}70`,
            `${palette.primary}30`,
          ],
          maxBarThickness: 56,
        },
      ],
    };
  }, [thisYearCategoryCertificateStats]);

  const categoryCertificateChartOptions: ChartOptions = {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: 'none',
    aspectRatio: 4,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          borderDash: [5, 5],
          display: true,
        },
        beginAtZero: true,
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        xAlign: 'center' as const,
        yAlign: 'bottom' as const,
        backgroundColor: '#fff',
        bodyColor: '#222',
        borderColor: palette.primary,
        borderWidth: 1,
        caretSize: 0,
        cornerRadius: 16,
        callbacks: {
          title: () => '',
          label: function ({ label, raw: { x, y } }: any) {
            return `${y}건 / ${x}%`;
          },
        },
      },
    },
  };

  return (
    <>
      <CategoryCertificateModal
        categoryCertificateStats={thisYearCategoryCertificateStats}
        visible={categoryCertificateModalVisible}
        onClose={() => setCategoryCertificateModalVisible(false)}
      />
      <ThisYearCategoryChartBlock>
        <Row align="middle" style={{ marginBottom: 48 }}>
          <Col>
            <Typography.Title style={{ fontWeight: 300, fontSize: 32 }}>
              올해 가장 많이 인증된 카테고리는 {'바디제품'}
              입니다.
            </Typography.Title>
          </Col>
          <Col>
            <Typography.Text type="secondary" gutter={{ left: 32 }} bold>
              {moment().format('YYYY')} 기준
            </Typography.Text>
          </Col>
          <Col>
            <BarChartIcon />
          </Col>
          <Col flex="1 1 auto">
            <Typography.Text type="secondary" gutter={{ left: 10 }} bold>
              상위 10개 카테고리 - 중분류
            </Typography.Text>
          </Col>
          <Col>
            <ShowAllBlock
              onClick={() => setCategoryCertificateModalVisible(true)}
            >
              <TableOutlined style={{ fontSize: 14 }} />
              <Typography.Text
                type="secondary"
                gutter={{ left: 4, right: 4 }}
                bold
                inline
              >
                전체 보기
              </Typography.Text>
              <RightOutlined style={{ fontSize: 12 }} />
            </ShowAllBlock>
          </Col>
        </Row>
        <ReactChart
          type="bar"
          data={categoryCertificateChartData}
          options={categoryCertificateChartOptions}
        />
      </ThisYearCategoryChartBlock>
    </>
  );
};

export default ThisYearCategoryChart;
