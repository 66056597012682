import qs from 'qs';

import { IAPIResponse, IAPIPageableResponse } from 'types/common';
import {
  IClientInfo,
  IClientInfoUpdate,
  IClinicalTrialProcessingEstimate,
  IDepositAdd,
  IEstimateDepositHistory,
  IEstimateHistory,
  IProcessingEstimatesSearchParams,
} from 'types/clinicalTrial/process/processingEstimate';
import {
  IClinicalTrialProcessItem,
  IUpdateRemark,
  IUpdateSampleDelivery,
  IUpdateStopReason,
  IUpdateTestCompletion,
} from 'types/clinicalTrial/process/processingEstimateDetail';
import client from 'lib/api/client';
import { createFormData } from 'lib/file';

export const getProcessingEstimates = async ({
  searchKey,
  searchKeyword,
  status,
  pageNo = 1,
  size = 10,
  confirmationDate,
}: IProcessingEstimatesSearchParams) =>
  await client.get<IAPIPageableResponse<IClinicalTrialProcessingEstimate[]>>(
    `/admin/clinical-trial/estimates/progress-list?${qs.stringify({
      searchKey,
      searchKeyword,
      status,
      pageNo,
      size,
      confirmationDate,
    })}`,
  );

export const getClinicalTrialProcessItems = async (
  clinicalTrialEstimateId: number,
) =>
  await client.get<IAPIResponse<IClinicalTrialProcessItem[]>>(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}/process-items`,
  );

export const getEstimateHistories = async (clinicalTrialEstimateId: number) =>
  await client.get<IAPIResponse<IEstimateHistory[]>>(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}/estimate-history`,
  );

export const getClientInfo = async (clinicalTrialEstimateId: number) =>
  await client.get<IAPIResponse<IClientInfo>>(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}/client-info`,
  );

export const updateClientInfos = async (params: IClientInfoUpdate) => {
  const { clinicalTrialEstimateId, ...rest } = params;
  await client.patch(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}/client-info`,
    rest,
  );
};

export const getEstimateDepositHistories = async (
  clinicalTrialEstimateId: number,
) =>
  await client.get<IAPIResponse<IEstimateDepositHistory[]>>(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}/deposit-history`,
  );

export const addDeposit = async (params: IDepositAdd) => {
  const { clinicalTrialEstimateId, ...rest } = params;
  await client.patch(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}/deposit`,
    rest,
  );
};

export const updateProductName = async (params: {
  clinicalTrialProcessItemId: number;
  productName: string;
}) => {
  const { clinicalTrialProcessItemId, productName } = params;
  await client.patch(
    `/admin/clinical-trial/process-items/${clinicalTrialProcessItemId}/product-name`,
    {
      productName,
    },
  );
};

export const updateSampleReceiptDate = async (params: {
  clinicalTrialProcessItemId: number;
  sampleReceiptDate: string;
}) => {
  const { clinicalTrialProcessItemId, sampleReceiptDate } = params;
  await client.patch(
    `/admin/clinical-trial/process-items/${clinicalTrialProcessItemId}/sample-receipt`,
    {
      sampleReceiptDate,
    },
  );
};

export const updateSampleDelivery = async (params: IUpdateSampleDelivery) => {
  const { clinicalTrialProcessItemId, ...rest } = params;
  await client.patch(
    `/admin/clinical-trial/process-items/${clinicalTrialProcessItemId}/sample-delivery`,
    rest,
  );
};

export const updateTestCompletion = async (params: IUpdateTestCompletion) => {
  const { clinicalTrialProcessItemId, files, completionDate } = params;
  await client.post(
    `/admin/clinical-trial/process-items/${clinicalTrialProcessItemId}/completion`,
    createFormData({
      files,
      completionDate,
    }),
  );
};

export const updateRemark = async (params: IUpdateRemark) => {
  const { clinicalTrialProcessItemId, remark } = params;
  await client.patch(
    `/admin/clinical-trial/process-items/${clinicalTrialProcessItemId}/remark`,
    {
      remark,
    },
  );
};

export const updateStopReason = async (params: IUpdateStopReason) => {
  const { clinicalTrialProcessItemId, stopReason } = params;
  await client.patch(
    `/admin/clinical-trial/process-items/${clinicalTrialProcessItemId}/stop`,
    {
      stopReason,
    },
  );
};

export const updateEstimateStopReason = async (params: {
  clinicalTrialEstimateId: number;
  categoryDataId?: number;
}) => {
  await client.patch(
    `/admin/clinical-trial/estimates/${params.clinicalTrialEstimateId}/stop`,
    {
      categoryDataId: params.categoryDataId,
    },
  );
};
