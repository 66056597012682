import { useEffect, useMemo, useState } from 'react';
import { Card, Empty, Row, Tabs, Col, message } from 'antd';
import styled from 'styled-components';

import DoughnutChart from 'components/stats/DoughnutChart';
import PeriodSelect from 'components/stats/PeriodSelect';
import { Typography } from 'components/system';
import {
  ECompanyShareType,
  IProductRegisterStat,
  IProductRegisterStatWithShare,
} from 'types/stats';
import ShareStatsTable from 'components/stats/shareStats/ShareStatsTable';
import CompanySelect from 'components/system/data/CompanySelect';
import { useProductRegisterStatsWithId } from 'hooks/stats';
import { Margin } from 'components/system/general/Margin';

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px;

  .ant-tabs-tab-btn {
    margin-left: 28px;
  }
`;

const CompanyShareCardBlock = styled(Card)`
  .ant-card-body {
    padding: 30px 0px 20px 0px;
  }
`;

const CompanyShareCard: React.FC = () => {
  const [status, setStatus] = useState<ECompanyShareType>(
    ECompanyShareType.MANUFACTURER_SHARE_FOR_BRAND,
  );
  const [userSelectCompanyId, setUserSelectCompanyId] = useState<
    number | undefined
  >();
  const [productRegisterStats, setProductRegisterStats] = useState<
    IProductRegisterStat[]
  >([]);

  const {
    mutate: getProductRegisterStatsWithId,
  } = useProductRegisterStatsWithId(setProductRegisterStats);

  useEffect(() => {
    setUserSelectCompanyId(undefined);
    setProductRegisterStats([]);
  }, [status]);

  const productRegisterStatsWithShare: IProductRegisterStatWithShare[] = useMemo(() => {
    const totalCountProductReg = productRegisterStats.reduce(
      (acc, cur) => acc + cur.countProductReg,
      0,
    );

    return productRegisterStats?.map((v) => ({
      ...v,
      share: Number(
        ((v.countProductReg / totalCountProductReg) * 100).toFixed(1),
      ),
    }));
  }, [productRegisterStats]);

  const doughnutChart = useMemo(() => {
    return productRegisterStatsWithShare ? (
      <div style={{ width: '50%', margin: 'auto' }}>
        <DoughnutChart
          dataSource={productRegisterStatsWithShare.slice(0, 10)}
        />
      </div>
    ) : undefined;
  }, [productRegisterStatsWithShare]);

  return (
    <CompanyShareCardBlock style={{ height: '100%' }}>
      <StyledTabs
        activeKey={status}
        onChange={(status) => setStatus(status as ECompanyShareType)}
      >
        <Tabs.TabPane
          key={ECompanyShareType.MANUFACTURER_SHARE_FOR_BRAND}
          tab="브랜드사별 제조사 점유율"
        />
        <Tabs.TabPane
          key={ECompanyShareType.BRAND_SHARE_FOR_MANUFACTURER}
          tab="제조사별 브랜드사 점유율"
        />
      </StyledTabs>
      <Row style={{ marginLeft: 65 }} align="middle" gutter={[16, 16]}>
        <Col>
          <Typography.Text type="secondary" bold>
            {status === ECompanyShareType.MANUFACTURER_SHARE_FOR_BRAND
              ? '브랜드사명'
              : '제조사명'}
          </Typography.Text>
        </Col>
        <Col>
          <CompanySelect
            companyType={
              status === ECompanyShareType.MANUFACTURER_SHARE_FOR_BRAND
                ? 'brand'
                : 'manufacturer'
            }
            onChange={(value: number) => {
              setUserSelectCompanyId(value);
            }}
          />
        </Col>
        <Col>
          <PeriodSelect
            onSearch={(_, { from, to }) => {
              if (!userSelectCompanyId) {
                return message.warn(
                  `${
                    status !== ECompanyShareType.MANUFACTURER_SHARE_FOR_BRAND
                      ? '제조사'
                      : '브랜드사'
                  }를 선택해 주세요.`,
                );
              }
              getProductRegisterStatsWithId({
                id: userSelectCompanyId,
                from,
                to,
                isManu:
                  status === ECompanyShareType.BRAND_SHARE_FOR_MANUFACTURER,
              });
            }}
          />
        </Col>
      </Row>
      <Margin size={80} />
      {productRegisterStatsWithShare.length !== 0 ? (
        <>
          {doughnutChart}
          <Margin size={20} />
          <Typography.Text style={{ textAlign: 'center' }}>
            {`( 상위 10개 ${
              status === ECompanyShareType.MANUFACTURER_SHARE_FOR_BRAND
                ? '제조사'
                : '브랜드사'
            } )`}
          </Typography.Text>
          <Margin size={36} />
          <ShareStatsTable
            data={productRegisterStatsWithShare}
            type={
              status === ECompanyShareType.MANUFACTURER_SHARE_FOR_BRAND
                ? 'manufacturerId'
                : 'brandCompanyId'
            }
          />
        </>
      ) : (
        <>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="데이터 없음"
          />
          <Typography.Text style={{ textAlign: 'center' }} bold>
            {`${
              status === ECompanyShareType.MANUFACTURER_SHARE_FOR_BRAND
                ? '브랜드사'
                : '제조사'
            } 입력 후 조회 버튼을 눌러주세요.`}
          </Typography.Text>
        </>
      )}
    </CompanyShareCardBlock>
  );
};

export default CompanyShareCard;
