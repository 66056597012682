export enum EClinicalTrialEstimateRequestStatus {
  CANCEL = 'CANCEL',
  REQUEST = 'REQUEST',
  ESTIMATE = 'ESTIMATE',
}

export interface IClinicalTrialEstimateRequestDetail {
  clinicalTrialEstimateRequestDetailId: number;
  clinicalTrialEstimateRequestId: number;
  categoryDataName: string;
  clinicalTrialName: string;
  categoryDataId: number;
  clinicalTrialId: number;
  itemQuantity: number;
}

export interface IClinicalTrialEstimateRequest {
  clinicalTrialEstimateRequestId: number;
  estimateRequestRegisterDt: string;
  bizNumber: string;
  companyNameKo: string;
  clientName: string;
  clientTel: string;
  clientEmail: string;
  status: EClinicalTrialEstimateRequestStatus;
}

export interface IClinicalTrialEstimateClientInfo {
  clientCompanyName: string;
  clientName: string;
  clientEmail: string;
  clientTel: string;
  clientCompanyAddress: string | null;
  remark: string | null;
}
