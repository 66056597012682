import { useMemo } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import { Modal, Table } from 'antd';
import { nanoid } from 'nanoid';

import { useEstimateHistories } from 'hooks/estimate';
import { useStores } from 'stores/Context';

const EstimateHistoriesModal = ({
  estimateId,
  onClose,
}: {
  estimateId: number;
  onClose: () => void;
}) => {
  const { data: estimateHistories } = useEstimateHistories(estimateId);
  const { etcStore } = useStores();
  const columns = useMemo(
    () => [
      { title: 'No.', dataIndex: 'no', align: 'center' as const },
      {
        title: '견적서 번호',
        dataIndex: 'estimateCode',
        align: 'center' as const,
      },
      {
        title: '견적 금액 (부가세 별도)',
        dataIndex: 'totalPrice',
        align: 'center' as const,
        render: (totalPrice: string) => `${totalPrice.toLocaleString()}원`,
      },
      {
        title: '견적서',
        dataIndex: 'fileUrl',
        align: 'center' as const,
        render: (fileUrl: string) => (
          <FilePdfOutlined
            style={{ fontSize: 18, cursor: 'pointer' }}
            onClick={() => {
              etcStore.previewedFiles = [{ url: fileUrl }];
            }}
          />
        ),
      },
    ],
    [],
  );
  return (
    <Modal
      title="견적서 수정 이력"
      visible={true}
      width={768}
      footer={null}
      onCancel={onClose}
    >
      <Table
        columns={columns}
        dataSource={estimateHistories}
        rowKey={() => nanoid()}
        pagination={false}
      />
    </Modal>
  );
};

export default EstimateHistoriesModal;
