import { useState } from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';

import {
  ECertificateSearchTypeOptions,
  ICertificate,
  ICertificateData,
  ICertificatePageState,
} from 'types/certificate';
import CertificateFinishModal from 'components/certificate/CertificateFinishModal';
import EstimateAdditionalAddModal from 'components/estimate/EstimateAdditionalAddModal';
import { EProcessStage } from 'types/common';
import OngoingCertificateTable from './OngoingCertificateTable';
import FinishedCertificateTable from './FinishedCertificateTable';
import { QueryObserverResult } from 'react-query';

const CertificateCompanyDetailCollapseBlock = styled.div``;

const CertificateCompanyDetailCollapse = ({
  page,
  searchType,
  searchValue,
  searchStatus,
  ongoingCertificateData,
  finishedCertificateData,
  ongoingRefetch,
  finishedRefetch,
  onChangeCollapse,
  replaceState,
}: {
  page: number;
  searchType: ECertificateSearchTypeOptions;
  searchValue: string;
  searchStatus: EProcessStage;
  ongoingCertificateData?: ICertificateData;
  finishedCertificateData?: ICertificateData;
  ongoingRefetch: () => Promise<QueryObserverResult<ICertificateData>>;
  finishedRefetch: () => Promise<QueryObserverResult<ICertificateData>>;
  onChangeCollapse: (status?: EProcessStage) => void;
  replaceState: (state: ICertificatePageState) => void;
}) => {
  const [
    certificateBeFinished,
    setCertificateBeFinished,
  ] = useState<ICertificate | null>(null);
  const [
    certificateForEstimateAdd,
    setCertificateForEstimateAdd,
  ] = useState<ICertificate | null>(null);

  const closeCertificateFinishModal = (isSuccess: boolean) => {
    if (isSuccess) {
      ongoingRefetch();
      finishedRefetch();
    }
    setCertificateBeFinished(null);
  };

  return (
    <CertificateCompanyDetailCollapseBlock>
      {certificateBeFinished && (
        <CertificateFinishModal
          certificate={certificateBeFinished}
          onClose={closeCertificateFinishModal}
        />
      )}
      {certificateForEstimateAdd && (
        <EstimateAdditionalAddModal
          certificate={certificateForEstimateAdd}
          onClose={() => setCertificateForEstimateAdd(null)}
        />
      )}
      <Collapse
        activeKey={searchStatus}
        accordion
        onChange={(status) => {
          onChangeCollapse(status as EProcessStage | undefined);
        }}
        style={{ marginTop: 8 }}
        bordered={false}
      >
        <Collapse.Panel key={EProcessStage.ONGOING} header="진행중인 제품 관리">
          <OngoingCertificateTable
            page={page}
            onSetCertificateBeFinished={setCertificateBeFinished}
            onSetCertificateForEstimateAdd={setCertificateForEstimateAdd}
            onChangePage={(pageNum: number) => {
              replaceState({
                page: pageNum,
                searchType,
                searchValue,
                searchStatus,
              });
              setTimeout(ongoingRefetch);
            }}
            certificateData={ongoingCertificateData}
          />
        </Collapse.Panel>
        <Collapse.Panel key={EProcessStage.FINISHED} header="완료 제품 관리">
          <FinishedCertificateTable
            page={page}
            onSetCertificateForEstimateAdd={setCertificateForEstimateAdd}
            onChangePage={(pageNum: number) => {
              replaceState({
                page: pageNum,
                searchType,
                searchValue,
                searchStatus,
              });
              setTimeout(finishedRefetch);
            }}
            certificateData={finishedCertificateData}
          />
        </Collapse.Panel>
      </Collapse>
    </CertificateCompanyDetailCollapseBlock>
  );
};

export default CertificateCompanyDetailCollapse;
