import { Form, Typography } from 'antd';
import { useProductVCRPAccount } from 'hooks/product';

const ProductVCRPAccountModalContent = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const { data: productVCRPAccount } = useProductVCRPAccount({
    productId,
    countryId,
  });

  const { vcrpAccount, vcrpPassword } = { ...productVCRPAccount };

  return (
    <Form labelCol={{ span: 8 }} size="small" style={{ paddingTop: 20 }}>
      <Form.Item label="사용자명 (ID)">
        <Typography.Text strong>{vcrpAccount}</Typography.Text>
      </Form.Item>
      <Form.Item label="비밀번호">
        <Typography.Text strong>{vcrpPassword}</Typography.Text>
      </Form.Item>
    </Form>
  );
};

export default ProductVCRPAccountModalContent;
