import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import * as clinicalTrialProcessApi from 'lib/api/clinicalTrial/clinicalTrialProcess';
import {
  IClientInfoUpdate,
  IDepositAdd,
  IProcessingEstimatesSearchParams,
} from 'types/clinicalTrial/process/processingEstimate';

const processingEstimateKey = 'progress-list';

export const useProcessingEstimates = (
  params: IProcessingEstimatesSearchParams,
) => {
  const {
    data: processingEstimates,
    isLoading: getLoading,
    refetch,
  } = useQuery(
    [
      processingEstimateKey,
      params.pageNo,
      params.searchKey,
      params.searchKeyword,
      params.status,
      params.confirmationDate,
    ],
    () => clinicalTrialProcessApi.getProcessingEstimates(params),
    { select: (res) => res.data.result },
  );

  return useMemo(() => ({ processingEstimates, getLoading, refetch }), [
    processingEstimates,
    getLoading,
  ]);
};

export const useEstimateHistories = (clinicalTrialEstimateId: number) => {
  const {
    data: estimateHistories = [],
    isLoading: getLoading,
  } = useQuery(
    `clinical-trial/estimates/${clinicalTrialEstimateId}/estimate-history`,
    () => clinicalTrialProcessApi.getEstimateHistories(clinicalTrialEstimateId),
    { select: (res) => res.data.result },
  );

  return useMemo(() => ({ estimateHistories, getLoading }), [
    estimateHistories,
    getLoading,
  ]);
};

export const useClientInfo = (clinicalTrialEstimateId: number) => {
  const { data: clientInfos = null, isLoading: getLoading } = useQuery(
    `clinical-trial/estimates/${clinicalTrialEstimateId}/client-info`,
    () => clinicalTrialProcessApi.getClientInfo(clinicalTrialEstimateId),
    {
      select: (res) => res.data.result,
    },
  );

  return useMemo(
    () => ({
      clientInfos,
      getLoading,
    }),
    [clientInfos, getLoading],
  );
};

export const useUpdateClientInfos = () => {
  const queryClient = useQueryClient();
  const { mutate: updateClientInfos, isLoading: updateLoading } = useMutation(
    (clientInfoUpdate: IClientInfoUpdate) =>
      clinicalTrialProcessApi.updateClientInfos(clientInfoUpdate),
    {
      onSuccess: () => {
        queryClient.refetchQueries(processingEstimateKey);
      },
    },
  );

  return useMemo(
    () => ({
      updateClientInfos,
      updateLoading,
    }),
    [updateLoading],
  );
};

export const useAddDeposit = () => {
  const queryClient = useQueryClient();
  const { mutate: addDeposit, isLoading: addLoading } = useMutation(
    (depositAdd: IDepositAdd) => clinicalTrialProcessApi.addDeposit(depositAdd),
    {
      onSuccess: () => {
        queryClient.refetchQueries(processingEstimateKey);
      },
    },
  );

  return useMemo(
    () => ({
      addDeposit,
      addLoading,
    }),
    [addLoading],
  );
};

export const useEstimateDepositHistories = (
  clinicalTrialEstimateId: number,
) => {
  const {
    data: estimateDepositHistories = [],
    isLoading: getLoading,
  } = useQuery(
    `clinical-trial/estimates/${clinicalTrialEstimateId}/estimate-history`,
    () =>
      clinicalTrialProcessApi.getEstimateDepositHistories(
        clinicalTrialEstimateId,
      ),
    { select: (res) => res.data.result },
  );

  return useMemo(() => ({ estimateDepositHistories, getLoading }), [
    estimateDepositHistories,
    getLoading,
  ]);
};
