import { Col, Row } from 'antd';
import ThisYearCategoryChart from 'components/stats/ThisYearCategoryChart';
import { Typography } from 'components/system';
import styled from 'styled-components';

import CountryCategoryCertificateTable from 'components/stats/CountryCategoryCertificateTable';
import BrandCategoryCertificateTable from 'components/stats/BrandCategoryCertificateTable';

const CategoryStatsPageBlock = styled.div``;

const CategoryStatsPage = () => {
  return (
    <CategoryStatsPageBlock>
      <Typography.Title gutter={{ bottom: 8 }}>
        카테고리 분석 - 올해의 카테고리
      </Typography.Title>
      <ThisYearCategoryChart />
      <Row style={{ marginTop: 36 }} gutter={32}>
        <Col
          sm={{ span: 24 }}
          md={{ span: 12 }}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Typography.Title gutter={{ bottom: 8 }}>
            카테고리 분석 - 카테고리별 / 국가별
          </Typography.Title>
          <CountryCategoryCertificateTable />
        </Col>
        <Col
          sm={{ span: 24 }}
          md={{ span: 12 }}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Typography.Title gutter={{ bottom: 8 }}>
            카테고리 분석 - 브랜드사별
          </Typography.Title>
          <BrandCategoryCertificateTable />
        </Col>
      </Row>
    </CategoryStatsPageBlock>
  );
};

export default CategoryStatsPage;
