import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import PageTemplate from 'templates/PageTemplate';
import path from 'lib/path';
import ClinicalTrialProcessingEstimateDetail from 'components/clinicalTrial/process/ClinicalTrialProcessingEstimateDetail';

const ClinicalTrialProcessEstimateDetailPage = () => {
  const { clinicalTrialEstimateId } = useParams<{
    clinicalTrialEstimateId: string;
  }>();
  const history = useHistory();
  const location = useLocation<{
    clientCompanyName: string;
    estimateNo: string;
  }>();

  useEffect(() => {
    if (!location.state) {
      history.push(path.main);
    }
  }, [location]);

  if (!location.state) return null;
  return (
    <PageTemplate
      title={`${location.state.clientCompanyName} : ${location.state.estimateNo}`}
      back
    >
      <ClinicalTrialProcessingEstimateDetail
        clinicalTrialEstimateId={Number(clinicalTrialEstimateId)}
      />
    </PageTemplate>
  );
};

export default ClinicalTrialProcessEstimateDetailPage;
