import { Form, Typography } from 'antd';
import { useProductStabilityTest } from 'hooks/product';

const ProductStabilityTestModal: React.FC<{
  productId: number;
  countryId: number;
}> = ({ productId, countryId }) => {
  const { data: productChallengeTest } = useProductStabilityTest({
    productId,
    countryId,
  });
  const {
    temperatureRange1,
    temperatureRange2,
    temperatureRange3,
    cycleTemperatureRange,
  } = {
    ...productChallengeTest,
  };
  return (
    <Form labelCol={{ span: 8 }} size="small" style={{ paddingTop: 20 }}>
      <Form.Item label="온도범위1">
        <Typography.Text strong>{temperatureRange1}℃</Typography.Text>
      </Form.Item>
      <Form.Item label="온도범위2">
        <Typography.Text strong>
          {temperatureRange2}
          {temperatureRange2 !== 'RT' &&
            temperatureRange2 !== 'ROOM TEMPERATURE' &&
            '℃'}
        </Typography.Text>
      </Form.Item>
      <Form.Item label="온도범위3">
        <Typography.Text strong>{temperatureRange3}℃</Typography.Text>
      </Form.Item>
      <Form.Item label="사이클 온도범위">
        <Typography.Text strong>{cycleTemperatureRange}</Typography.Text>
      </Form.Item>
    </Form>
  );
};

export default ProductStabilityTestModal;
