import { Spin, Typography } from 'antd';
import { useProductShelfLifeDeclaration } from 'hooks/product';
import styled from 'styled-components';

const ShelfLifeWrap = styled.div`
  text-align: center;
  padding-top: 24px;
`;

const ProductShelfLifeModalContent = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const {
    data: shelfLifeDeclaration = null,
    isLoading,
  } = useProductShelfLifeDeclaration({ productId, countryId });

  return (
    <Spin spinning={isLoading}>
      <ShelfLifeWrap>
        Shelf Life Declaration:{' '}
        {shelfLifeDeclaration && (
          <Typography.Text strong>
            {shelfLifeDeclaration.productExpireMonths} months
          </Typography.Text>
        )}
      </ShelfLifeWrap>
    </Spin>
  );
};

export default ProductShelfLifeModalContent;
