import { useQuery, useQueryClient } from 'react-query';
import * as estimateApi from 'lib/api/estimate';
import { useMutation } from 'react-query';
import {
  EEstimateAdditionalStatus,
  IEstimateAdditionalForm,
  IEstimateCountry,
  IEstimateDeposit,
} from 'types/estimate';
import { IAPIResponse } from 'types/common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { message, Modal } from 'antd';
import produce from 'immer';
import { nanoid } from 'nanoid';
import { useStores } from 'stores/Context';

export const useEstimates = ({
  page,
  isDepositFinish,
  isReceiveContractFinish,
  companyNameKo,
  companyNameEn,
  estimateCode,
}: {
  page: number;
  isDepositFinish: string;
  isReceiveContractFinish: string;
  companyNameKo?: string;
  companyNameEn?: string;
  estimateCode?: string;
}) => {
  const {
    data: estimatesData = { content: [], totalElements: 0 },
    refetch,
  } = useQuery(
    ['estimate/estimates', { page, isDepositFinish, isReceiveContractFinish }],
    () =>
      estimateApi.getEstimates({
        page,
        isDepositFinish,
        isReceiveContractFinish,
        companyNameKo,
        companyNameEn,
        estimateCode,
      }),
    {
      select: (data) => {
        data.result.content = data.result.content.map((estimate) => ({
          ...estimate,
          paidAmount: estimate.paidAmount || 0,
        }));
        return data.result;
      },
    },
  );
  const { content: estimates, totalElements } = estimatesData;

  const { mutate: checkEstimateDeposit } = useMutation(
    (estimateDeposit: IEstimateDeposit) =>
      estimateApi.checkEstimateDeposit(estimateDeposit),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
  const { mutate: receiptEstimateContract } = useMutation(
    (estimateId: number) => estimateApi.receiptEstimateContract(estimateId),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
  return useMemo(
    () => ({
      estimates,
      totalElements,
      refetch,
      checkEstimateDeposit,
      receiptEstimateContract,
    }),
    [estimatesData],
  );
};

export const useChangeEstimateAdditionalStatus = (option: {
  onSuccess: () => void;
}) =>
  useMutation(
    (params: {
      estimateAdditionalId: number;
      status: EEstimateAdditionalStatus;
    }) => estimateApi.changeEstimateAdditionalStatus(params),
    option,
  );

export const useAddEstimate = (option: {
  onSuccess: (result: IAPIResponse<string>) => void;
}) =>
  useMutation(
    (params: {
      certTargetId: number;
      estimateAdditionals: IEstimateAdditionalForm[];
      isPreview: boolean;
    }) => estimateApi.addEstimate(params),
    option,
  );

export const useEstimateCountries = (
  estimateId: number,
  { onSuccessSubmit }: { onSuccessSubmit: () => void },
) => {
  const { etcStore } = useStores();
  const queryClient = useQueryClient();
  const { data } = useQuery(
    ['estimate/estimateCountries', estimateId],
    () => estimateApi.getEstimateCountries(estimateId),
    {
      select: (data) => data.result,
    },
  );
  const [estimateCountries, setEstimateCountries] = useState<
    IEstimateCountry[]
  >([]);
  const totalPrice = useMemo(
    () =>
      estimateCountries.reduce(
        (totalPrice, { estimateCountryItems }) =>
          totalPrice +
          estimateCountryItems.reduce(
            (totalPrice, { unitPrice, quantity }) =>
              totalPrice + (unitPrice * quantity || 0),
            0,
          ),
        0,
      ),
    [estimateCountries],
  );
  const { mutate: previewMutate, isLoading: previewLoading } = useMutation(
    (estimateCountries: IEstimateCountry[]) =>
      estimateApi.updateEstimateCountries({
        isPreview: true,
        estimateCountries,
      }),
    {
      onSuccess: (data) => {
        etcStore.previewedFiles = [{ url: data.result }];
      },
    },
  );
  const { mutate: submitMutate, isLoading: submitLoading } = useMutation(
    (estimateCountries: IEstimateCountry[]) =>
      estimateApi.updateEstimateCountries({
        isPreview: false,
        estimateCountries,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['estimate/estimates']);
        onSuccessSubmit();
      },
    },
  );
  const handleChangeField = useCallback(
    (
      countryIndex: number,
      itemIndex: number,
      fieldName: 'itemName' | 'unitPrice' | 'quantity' | 'description',
      value: number | string,
    ) => {
      if (
        (fieldName === 'unitPrice' || fieldName === 'quantity') &&
        isNaN(value as number)
      ) {
        return message.warn('숫자만 입력 가능합니다.');
      }
      setEstimateCountries((draftState) =>
        produce(draftState, (draft) => {
          if (fieldName === 'itemName' || fieldName === 'description') {
            draft[countryIndex].estimateCountryItems[itemIndex][
              fieldName
            ] = value as string;
          } else {
            draft[countryIndex].estimateCountryItems[itemIndex][
              fieldName
            ] = value as number;
          }
        }),
      );
    },
    [],
  );

  const handleAddItem = useCallback((countryIndex: number) => {
    setEstimateCountries((draft) =>
      produce(draft, (draft) => {
        draft[countryIndex].estimateCountryItems.push({
          estimateCountryItemId: nanoid(),
          itemName: '',
          unitPrice: 0,
          quantity: 0,
          description: '',
        });
      }),
    );
  }, []);
  const handleRemoveItem = useCallback(
    (countryIndex: number, itemIndex: number) => {
      Modal.confirm({
        title: '정말로 삭제하시겠습니까?',
        onOk: () => {
          setEstimateCountries((draftState) =>
            produce(draftState, (draft) => {
              draft[countryIndex].estimateCountryItems.splice(itemIndex, 1);
            }),
          );
        },
      });
    },
    [],
  );
  const handlePreview = useCallback(() => {
    previewMutate(estimateCountries);
  }, [estimateCountries]);
  const handleSubmit = useCallback(() => {
    submitMutate(estimateCountries);
  }, [estimateCountries]);
  useEffect(() => {
    if (data) {
      setEstimateCountries(data);
    }
  }, [data]);
  return useMemo(
    () => ({
      estimateCountries,
      totalPrice,
      previewLoading,
      submitLoading,
      handleChangeField,
      handleAddItem,
      handleRemoveItem,
      handlePreview,
      handleSubmit,
    }),
    [
      estimateCountries,
      totalPrice,
      previewLoading,
      submitLoading,
      handleChangeField,
      handleAddItem,
      handleRemoveItem,
      handlePreview,
      handleSubmit,
    ],
  );
};

export const useEstimateHistories = (estimateId: number) => {
  return useQuery(
    ['estimate/estimateHistories', estimateId],
    () => estimateApi.getEstimateHistories(estimateId),
    {
      select: (data) =>
        data.result.map((estimateHistory, index) => ({
          ...estimateHistory,
          no: data.result.length - index,
        })),
    },
  );
};

export const useEstimateDepositHistories = (estimateId: number) => {
  return useQuery(
    ['estimate/estimateHistories', estimateId],
    () => estimateApi.getEstimateDepositHistories(estimateId),
    {
      select: (res) => res.data.result,
    },
  );
};
