const BackIcon = ({
  color = '#222',
  onClick,
}: {
  color?: string;
  onClick: () => void;
}) => {
  return (
    <svg
      className="back-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      viewBox="0 0 21 18"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <path d="M0 0H1920V1436H0z" transform="translate(-501 -174)" />
          <g>
            <path
              d="M0 0H24V24H0z"
              transform="translate(-501 -174) translate(500 171)"
            />
            <g fill={color}>
              <path
                d="M1.189 8.555H20.189V9.555H1.189z"
                transform="translate(-501 -174) translate(500 171) translate(1.81 2.945)"
              />
              <path
                d="M-1.502 4.41L11.166 4.41 11.166 5.255 -1.502 5.255z"
                transform="translate(-501 -174) translate(500 171) translate(1.81 2.945) rotate(135 4.832 4.832)"
              />
              <path
                d="M-1.502 12.855L11.166 12.855 11.166 13.7 -1.502 13.7z"
                transform="translate(-501 -174) translate(500 171) translate(1.81 2.945) scale(-1 1) rotate(-45 0 24.944)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BackIcon;
