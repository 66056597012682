import { Route, Switch } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { observer } from 'mobx-react';

import path from 'lib/path';
import CompanyListPage from 'pages/company/CompanyListPage';
import CompanyApprovePage from 'pages/company/CompanyApprovePage';
import CompanyDetailPage from 'pages/company/CompanyDetailPage';
import BuyerListPage from 'pages/company/BuyerListPage';
import ShareStatsPage from 'pages/stats/ShareStatsPage';
import CertificateCompanyDetailPage from 'pages/certificate/CertificateCompanyDetailPage';
import CertificateDetailPage from 'pages/certificate/CertificateDetailPage';
import PrivateRoute from 'components/PrivateRoute';
import EstimatePage from 'pages/estimate/EstimatePage';
import NoticePage from 'pages/service/NoticePage';
import FaqPage from 'pages/service/FaqPage';
import UseGuidePage from 'pages/service/UseGuidePage';
import CertificateFinishPage from 'pages/certificate/CertificateFinishPage';
import SalesStatsPage from 'pages/stats/SalesStatsPage';
import CertificateCompanyPage from 'pages/certificate/CertificateCompanyPage';
import CertificateStatsPage from 'pages/stats/CertificateStatsPage';
import CategoryStatsPage from 'pages/stats/CategoryStatsPage';
import BuyerStatsPage from 'pages/stats/BuyerStatsPage';
import MainPage from 'pages/MainPage';
import { useStores } from 'stores/Context';
import ClinicalTrialEstimateRouter from './clinicalTrial/ClinicalTrialEstimateRouter';
import ClinicalTrialProcessRouter from './clinicalTrial/ClinicalTrialProcessRouter';

const HomeRouter = () => {
  const { certificateStore, companyStore } = useStores();
  return (
    <Switch>
      <Route path={path.main} component={MainPage} exact />
      <Route
        path={[path.company.approve]}
        render={(props) => <CompanyApprovePage {...props} key={nanoid()} />}
        exact
      />
      <Route path={path.company.root} component={CompanyListPage} exact />

      <Route path={path.company.buyer} component={BuyerListPage} />
      <Route
        path={`${path.company.root}/:companyId`}
        component={CompanyDetailPage}
      />
      <Route
        path={path.certificate.company}
        component={CertificateCompanyPage}
        exact
      />
      <PrivateRoute
        check={companyStore.currentCompanyValid}
        path={`${path.certificate.company}/:companyId`}
        component={CertificateCompanyDetailPage}
        redirect={path.certificate.company}
        exact
      />
      <Route
        path={`${path.certificate.root}/:certTargetId`}
        component={CertificateDetailPage}
        exact
      />
      <PrivateRoute
        check={certificateStore.currentCertificateValid}
        path={`${path.certificate.finish}/:certTargetId`}
        component={CertificateFinishPage}
        redirect={path.certificate.company}
      />
      <Route path={path.estimate.root} component={EstimatePage} exact />
      <Route
        path={path.clinicalTrial.estimate.root}
        component={ClinicalTrialEstimateRouter}
      />
      <Route
        path={path.clinicalTrial.process.root}
        component={ClinicalTrialProcessRouter}
      />
      <Route path={path.service.notice} component={NoticePage} exact />
      <Route path={path.service.faq} component={FaqPage} exact />
      <Route path={path.service.useGuide} component={UseGuidePage} exact />
      <Route path={path.stats.sales} component={SalesStatsPage} exact />
      <Route
        path={path.stats.certificate}
        component={CertificateStatsPage}
        exact
      />
      <Route path={path.stats.category} component={CategoryStatsPage} exact />
      <Route path={path.stats.share} component={ShareStatsPage} exact />
      <Route path={path.stats.buyer} component={BuyerStatsPage} exact />
    </Switch>
  );
};

export default observer(HomeRouter);
