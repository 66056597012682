import { useMutation, useQuery } from 'react-query';
import * as serviceApi from 'lib/api/service';
import { ENoticeType } from 'types/service';

// Notice
export const useNotices = (params: { page: number }) =>
  useQuery('service/notices', () => serviceApi.getNotices(params), {
    select: (data) => data.result,
  });

export const useAddNotice = (options: { onSuccess: () => void }) =>
  useMutation(
    async ({
      noticeType,
      title,
      content,
      isLive,
      files,
    }: {
      noticeType: ENoticeType;
      title: string;
      content: string;
      isLive: boolean;
      files?: File[];
    }) => {
      try {
        const res = await serviceApi.addNotice({
          noticeType,
          title,
          content,
          isLive,
        });
        if (files && files.length) {
          await serviceApi.addNoticeAttaches({
            noticeId: res.data.result,
            files: files,
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    options,
  );

export const useUpdateNotice = (options: { onSuccess: () => void }) =>
  useMutation(
    async ({
      noticeId,
      noticeType,
      title,
      content,
      isLive,
      attachIdsToBeDeleted,
      files,
    }: {
      noticeId: number;
      noticeType: ENoticeType;
      title: string;
      content: string;
      isLive: boolean;
      attachIdsToBeDeleted: number[];
      files?: File[];
    }) => {
      await serviceApi.updateNotice({
        noticeId,
        noticeType,
        title,
        content,
        isLive,
      });
      if (files && files.length) {
        await serviceApi.addNoticeAttaches({
          noticeId,
          files: files,
        });
      }
      if (attachIdsToBeDeleted.length) {
        await serviceApi.deleteNoticeAttaches(attachIdsToBeDeleted);
      }
    },
    options,
  );

export const useDeleteNotices = (options: { onSuccess: () => void }) =>
  useMutation(
    (noticeIds: number[]) => serviceApi.deleteNotices(noticeIds),
    options,
  );

// FAQ
export const useFaqs = (params: { page: number }) =>
  useQuery('service/faqs', () => serviceApi.getFaqs(params), {
    select: (data) => data.result,
  });

export const useAddFaq = (options: { onSuccess: () => void }) =>
  useMutation(
    async ({
      title,
      content,
      isLive,
      files,
    }: {
      title: string;
      content: string;
      isLive: boolean;
      files?: File[];
    }) => {
      try {
        const res = await serviceApi.addFaq({
          title,
          content,
          isLive,
        });
        if (files && files.length) {
          await serviceApi.addFaqAttaches({
            faqId: res.data.result,
            files: files,
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    options,
  );

export const useUpdateFaq = (options: { onSuccess: () => void }) =>
  useMutation(
    async ({
      faqId,
      title,
      content,
      isLive,
      attachIdsToBeDeleted,
      files,
    }: {
      faqId: number;
      title: string;
      content: string;
      isLive: boolean;
      attachIdsToBeDeleted: number[];
      files?: File[];
    }) => {
      await serviceApi.updateFaq({
        faqId,
        title,
        content,
        isLive,
      });
      if (files && files.length) {
        await serviceApi.addFaqAttaches({
          faqId,
          files: files,
        });
      }
      if (attachIdsToBeDeleted.length) {
        await serviceApi.deleteFaqAttaches(attachIdsToBeDeleted);
      }
    },
    options,
  );

export const useDeleteFaqs = (options: { onSuccess: () => void }) =>
  useMutation(
    (noticeIds: number[]) => serviceApi.deleteFaqs(noticeIds),
    options,
  );

// Use Guide
export const useUseGuides = (params: { page: number }) =>
  useQuery('service/useGuides', () => serviceApi.getUseGuides(params), {
    select: (data) => data.result,
  });

export const useAddUseGuide = (options: { onSuccess: () => void }) =>
  useMutation(
    async ({
      title,
      content,
      isLive,
      files,
    }: {
      title: string;
      content: string;
      isLive: boolean;
      files?: File[];
    }) => {
      try {
        const res = await serviceApi.addUseGuide({
          title,
          content,
          isLive,
        });
        if (files && files.length) {
          await serviceApi.addUseGuideAttaches({
            useGuideId: res.data.result,
            files: files,
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    options,
  );

export const useUpdateUseGuide = (options: { onSuccess: () => void }) =>
  useMutation(
    async ({
      useGuideId,
      title,
      content,
      isLive,
      attachIdsToBeDeleted,
      files,
    }: {
      useGuideId: number;
      title: string;
      content: string;
      isLive: boolean;
      attachIdsToBeDeleted: number[];
      files?: File[];
    }) => {
      await serviceApi.updateUseGuide({
        useGuideId,
        title,
        content,
        isLive,
      });
      if (files && files.length) {
        await serviceApi.addUseGuideAttaches({
          useGuideId,
          files: files,
        });
      }
      if (attachIdsToBeDeleted.length) {
        await serviceApi.deleteUseGuideAttaches(attachIdsToBeDeleted);
      }
    },
    options,
  );

export const useDeleteUseGuides = (options: { onSuccess: () => void }) =>
  useMutation(
    (noticeIds: number[]) => serviceApi.deleteUseGuides(noticeIds),
    options,
  );
