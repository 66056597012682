interface IPost {
  title: string;
  content: string;
  registerDt: string;
  updateDt: string;
  isLive: boolean;
}

export enum ENoticeType {
  NOR = 'NOR',
  SYS = 'SYS',
}

interface noticeAttach {
  noticeAttachId: number;
  noticeId: number;
  attachUrl: string;
  filename: string;
}

export interface INotice extends IPost {
  noticeId: number;
  noticeType: ENoticeType;
  noticeAttaches: noticeAttach[];
}

interface useGuideAttaches {
  useGuideAttachId: number;
  useGuideId: number;
  attachUrl: string;
  filename: string;
}

export interface IUseGuide extends IPost {
  useGuideId: number;
  useGuideAttaches: useGuideAttaches[];
}

interface faqAttaches {
  faqAttachId: number;
  faqId: number;
  attachUrl: string;
  filename: string;
}

export interface IFaq extends IPost {
  faqId: number;
  faqAttaches: faqAttaches[];
}

export enum EUserNoticeType {
  WEL = 'WEL',
}

export interface IUserNotice {
  userNoticeId: number;
  userId: number;
  userNoticeType: EUserNoticeType;
  userNoticeTypeString: string;
}
