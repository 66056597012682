import { useState } from 'react';
import { Button, Card, Table, Tabs, message } from 'antd';
import styled from 'styled-components';

import PageSizeSelect, {
  pageSizeOptions,
} from 'components/system/data/PageSizeSelect';
import CompanySelect from 'components/system/data/CompanySelect';
import { ColumnsType } from 'antd/lib/table';
import { ICountryCertificateStat } from 'types/stats';
import { useMutation } from 'react-query';
import * as statsApi from 'lib/api/stats';

const BrandCertificateTableBlock = styled(Card)`
  flex: 1 1 auto;

  .ant-card-body {
    padding: 32px;
  }
`;

const SearchInputBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BrandCertificateTable = () => {
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [countryCertificateStats, setCountryCertificateStats] = useState<
    ICountryCertificateStat[]
  >([]);
  const { mutate: getCountryCertificateStatsByCompany } = useMutation(
    statsApi.getCountryCertificateStatsByCompany,
    {
      onSuccess: (res) => setCountryCertificateStats(res.data.result),
    },
  );
  const [page, setPage] = useState(1);

  const columns: ColumnsType<ICountryCertificateStat> = [
    {
      title: '구분',
      align: 'center',
      render: (_, __, index) => (page - 1) * 10 + index + 1,
    },
    { title: '국가', dataIndex: 'countryNameKo', align: 'center' },
    {
      title: '인증 완료 수',
      dataIndex: 'countCertFin',
      align: 'center',
    },
  ];

  const handleSearch = () => {
    if (!companyId) return message.warn('브랜드사를 선택해 주세요.');
    getCountryCertificateStatsByCompany(companyId);
  };

  return (
    <BrandCertificateTableBlock>
      <SearchInputBlock>
        <CompanySelect onChange={setCompanyId} />
        <Button
          type="primary"
          style={{ marginLeft: 16, padding: '4px 10px' }}
          onClick={handleSearch}
        >
          조회
        </Button>
      </SearchInputBlock>
      <Tabs style={{ padding: '0 4px', marginTop: 24 }}>
        <Tabs.TabPane key="brand" tab="브랜드사별" style={{ marginLeft: 10 }} />
      </Tabs>
      <PageSizeSelect pageSize={pageSize} onChange={setPageSize} />
      <Table
        columns={columns}
        dataSource={countryCertificateStats}
        rowKey={({ countryId }) => countryId}
        pagination={{
          pageSize,
          onChange(cur) {
            setPage(cur);
          },
        }}
      />
    </BrandCertificateTableBlock>
  );
};

export default BrandCertificateTable;
