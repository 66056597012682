// source: https://yeun.github.io/open-color/
const palette = {
  primary: '#3785f7',
  main: ['#3785f7', '#dcf3ff', '#f2fbff'],
  success: '#29bf5f',
  error: ['#ff4d4f'],
  inactive: '#d3d3d3',
  disabled: '#e7e7e7',
  gray: '#f6f7f9',
  gray5: '#f9f9f9',
  gray90: '#535353',
  check: '#03cba4',
  darkNavy: '#4E5968',
  lightBlue: '#dcf3ff',
  bgBlue: '#f5f8fb',
  warmGray: '#a2a2a2',
  text: {
    black: '#222',
    gray: '#7e7e7e',
    primary: '#3785f7',
    success: '#29bf5f',
    disabled: '#a9a6a6',
    error: '#ff5f5f',
    check: '#03cba4',
    darkNavy: '#4E5968',
  },
};

export default palette;
