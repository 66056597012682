import React from 'react';
import { Modal, Spin, Form, Row, Col, Button, message } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import DateInput from 'components/system/form/DateInput';
import { useIssueCpsr } from 'hooks/certificate';

const ModalWrapper = styled.div`
  padding: 0 60px;

  .ant-modal-confirm-btns {
    display: none;
  }
`;

const CpsrIssueModal = ({
  visible,
  certTargetId,
  productName,
  onSetCpsrUrl,
  closeModal,
}: {
  visible: boolean;
  certTargetId: number;
  productName: string;
  onSetCpsrUrl: React.Dispatch<React.SetStateAction<string>>;
  closeModal: () => void;
}) => {
  const [form] = Form.useForm<{
    cpsrIssueDate: string;
  }>();
  const handleSuccess = (response: any) => {
    onSetCpsrUrl(response.data.result);
    message.success('CPSR 발행이 완료되었습니다.');
    form.setFieldsValue({ cpsrIssueDate: '' });
    closeModal();
  };
  const handleClose = () => {
    form.setFieldsValue({ cpsrIssueDate: '' });
    closeModal();
  };
  const { issueCpsr, issueCpsrLoading } = useIssueCpsr();
  const handleSubmit = (formData: { cpsrIssueDate: string }) => {
    const { cpsrIssueDate } = formData;
    const cpsrIssueYmd = cpsrIssueDate.split('-').join('');
    issueCpsr(
      {
        certTargetId: certTargetId,
        cpsrIssueYmd,
      },
      {
        onSuccess: handleSuccess,
      },
    );
  };
  return (
    <Modal
      title="발행하기"
      visible={visible}
      footer={null}
      onCancel={handleClose}
    >
      <ModalWrapper>
        <Spin spinning={issueCpsrLoading}>
          <Form form={form} onFinish={handleSubmit}>
            <Row gutter={8}>
              <Col>
                <Typography.Text type="tertiary">제품명 (용량)</Typography.Text>
              </Col>
              <Col>
                <Typography.Text
                  type="secondary"
                  style={{ width: 276, marginTop: -3 }}
                >
                  {productName}
                </Typography.Text>
              </Col>
            </Row>
            <Row gutter={8} style={{ marginTop: 16, alignItems: 'baseline' }}>
              <Col>
                <Typography.Text type="tertiary">CPSR 발행일</Typography.Text>
              </Col>
              <Col>
                <Form.Item
                  name="cpsrIssueDate"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject('발행일을 입력해주세요.'),
                    },
                  ]}
                >
                  <DateInput style={{ width: 276 }} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center" gutter={8}>
              <Col>
                <Button onClick={handleClose}>취소</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  확인
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </ModalWrapper>
    </Modal>
  );
};

export default CpsrIssueModal;
