import { useState } from 'react';
import { Card, Col, Row, Select } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import BuyerStatsTable from 'components/stats/buyerStats/BuyerStatsTable';
import BuyerDetailModal from 'components/stats/buyerStats/BuyerDetailModal';
import { useCountries } from 'hooks/country';
import { useBuyerCertificateStats, useBuyerDetails } from 'hooks/stats';

const BuyerStatsCardBlock = styled(Card)`
  padding: 12px 336px;
  min-height: 760px;
`;

const BuyerStatsCard: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string | number>(1);
  const [buyerId, setBuyerId] = useState(1);
  const { data: countries = [] } = useCountries();

  const { data: buyerCertificateStats = [] } = useBuyerCertificateStats(
    Number(searchValue),
  );

  const { data: buyerDetails = [] } = useBuyerDetails({
    buyerId,
    countryId: Number(searchValue),
  });

  const handleDetailIconClick = (buyerId: number) => {
    setIsOpen(true);
    setBuyerId(buyerId);
  };

  return (
    <BuyerStatsCardBlock>
      <Row
        justify="center"
        align="middle"
        gutter={16}
        style={{ marginBottom: 24 }}
      >
        <Col>
          <Typography.Text>국가 선택</Typography.Text>
        </Col>
        <Col>
          <Select
            value={searchValue}
            options={countries.map(({ countryId, countryNameKo }) => ({
              value: countryId,
              label: countryNameKo,
            }))}
            style={{ width: 120 }}
            onChange={(value) => setSearchValue(Number(value))}
          />
        </Col>
      </Row>
      <BuyerStatsTable
        data={buyerCertificateStats}
        onDetailIconClick={handleDetailIconClick}
      />
      <BuyerDetailModal
        visible={isOpen}
        data={buyerDetails}
        onClose={() => setIsOpen(false)}
      />
    </BuyerStatsCardBlock>
  );
};

export default BuyerStatsCard;
