import { Card } from 'antd';
import styled from 'styled-components';
import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { useQuery } from 'react-query';
import * as statsApi from 'lib/api/stats';

const TotalSalesBlock = styled(Card)`
  position: relative;
  text-align: center;

  .ant-card-body {
    padding: 58px;
  }
`;

const IncomeTipBlock = styled.div`
  position: absolute;
  display: flex;
  top: 24px;
  right: 056px;
  align-items: center;
`;

const IconBlock = styled.div`
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  background-color: ${palette.primary};
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  border-radius: 50%;
  line-height: 16px;
`;

const TotalSales = () => {
  const { data: totalSales } = useQuery(
    'stats/getTotalSales',
    statsApi.getTotalSales,
    { select: (res) => res.data.result },
  );
  return (
    <TotalSalesBlock>
      <Typography.Headline type="secondary" inline style={{ fontWeight: 300 }}>
        현재까지 총 매출액은
      </Typography.Headline>
      <Typography.Headline
        inline
        gutter={{ left: 20, right: 20 }}
        style={{ fontSize: 56 }}
      >
        {totalSales?.toLocaleString()}
      </Typography.Headline>
      <Typography.Headline type="secondary" inline style={{ fontWeight: 300 }}>
        원 입니다.
      </Typography.Headline>
      <IncomeTipBlock>
        <IconBlock>i</IconBlock>{' '}
        <Typography.Text type="secondary" bold gutter={{ left: 8 }}>
          매출액은 인증 완료 기준으로 산출
        </Typography.Text>
      </IncomeTipBlock>
    </TotalSalesBlock>
  );
};

export default TotalSales;
