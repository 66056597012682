import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CommentOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { IChecklistAnswer } from 'types/checklist';
import palette from 'lib/styles/palette';
import { useChecklistAnswers } from 'hooks/checklist';
import ChecklistMemo from './ChecklistMemo';

const ChecklistAnswersBlock = styled.div``;

const ChecklistAnswers = () => {
  const params = useParams<{ certTargetId: string }>();
  const certTargetId = Number(params.certTargetId);
  const { checklistAnswers, refetch } = useChecklistAnswers(certTargetId);
  const [
    checklistAnswerForMemo,
    setChecklistAnswerForMemo,
  ] = useState<IChecklistAnswer | null>(null);
  const columns: ColumnsType<IChecklistAnswer> = [
    { title: 'No.', align: 'center', render: (_, __, index) => index + 1 },
    {
      title: '체크리스트 항목',
      align: 'center',
      dataIndex: ['countryChecklist', 'questionMsg'],
    },
    {
      title: '작성 값',
      align: 'center',
      render: ({ isYes, subjectAnswer }) =>
        subjectAnswer !== null ? subjectAnswer : isYes ? '네' : '아니오',
    },
    {
      title: '메모',
      align: 'center',
      render: (checklistAnswer) => (
        <CommentOutlined
          style={{
            color: checklistAnswer.memo
              ? palette.text.primary
              : palette.text.disabled,
            cursor: 'pointer',
          }}
          onClick={() => setChecklistAnswerForMemo(checklistAnswer)}
        />
      ),
    },
  ];
  return (
    <ChecklistAnswersBlock>
      <ChecklistMemo
        checklistAnswer={checklistAnswerForMemo}
        onClose={() => setChecklistAnswerForMemo(null)}
      />
      <Row>
        <Col>
          <Button icon={<SyncOutlined />} onClick={() => refetch()}>
            새로고침
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={checklistAnswers}
        style={{ marginTop: 8 }}
        rowKey={({ productCountryChecklistAnswerId }) =>
          productCountryChecklistAnswerId
        }
        pagination={false}
      />
    </ChecklistAnswersBlock>
  );
};

export default ChecklistAnswers;
