import { Form, Typography } from 'antd';
import { useProductPhysicalProperty } from 'hooks/product';

const ProductPhysicalPropertyModalContent: React.FC<{
  productId: number;
  countryId: number;
  netWeight: number;
  netWeightUnit: string;
}> = ({ productId, countryId, netWeight, netWeightUnit }) => {
  const { data: productPhysicalProperty } = useProductPhysicalProperty({
    productId,
    countryId,
  });
  const {
    productType,
    form,
    color,
    odour,
    expiryDate,
    phLimitMin,
    phLimitMax,
    ph,
    specificGravityLimitMin,
    specificGravityLimitMax,
    specificGravity,
    viscosity,
    viscosityLimitMin,
    viscosityLimitMax,
    viscosityUnit,
    hardness,
    hardnessLimitMin,
    hardnessLimitMax,
    hardnessUnit,
    lotNo,
    manufacturingDate,
    isAerosolProduct,
    isEthanolProduct,
  } = {
    ...productPhysicalProperty,
  };
  const parsedProductType = productType
    ? productType.split('_').join(' ')
    : '-';
  return (
    <Form labelCol={{ span: 8 }} size="small" style={{ paddingTop: 20 }}>
      <Form.Item label="Leave On / Rinse Off">
        <Typography.Text strong>{parsedProductType}</Typography.Text>
      </Form.Item>
      <Form.Item label="에어로졸 제품 여부">
        <Typography.Text strong>
          {isAerosolProduct ? '예' : '아니오'}
        </Typography.Text>
      </Form.Item>
      <Form.Item label="에탄올 10% 이상 함유 여부">
        <Typography.Text strong>
          {isEthanolProduct ? '예' : '아니오'}
        </Typography.Text>
      </Form.Item>
      <Form.Item label="Appearance - Form">
        <Typography.Text strong>{form}</Typography.Text>
      </Form.Item>
      <Form.Item label="Appearance - Color">
        <Typography.Text strong>{color}</Typography.Text>
      </Form.Item>
      <Form.Item label="Odour">
        <Typography.Text strong>{odour}</Typography.Text>
      </Form.Item>
      <Form.Item label="Net weight or Net volume">
        <Typography.Text strong>
          {netWeight}
          {netWeightUnit}
        </Typography.Text>
      </Form.Item>
      <Form.Item label="Lot No. (Batch number)">
        <Typography.Text strong>{lotNo}</Typography.Text>
      </Form.Item>
      <Form.Item label="Manufacturing Date">
        <Typography.Text strong>{manufacturingDate}</Typography.Text>
      </Form.Item>
      <Form.Item label="Expiry Date">
        <Typography.Text strong>{expiryDate || '-'}</Typography.Text>
      </Form.Item>
      <Form.Item label="pH">
        정상 범위 : <Typography.Text strong>{phLimitMin}</Typography.Text> ~{' '}
        <Typography.Text strong>{phLimitMax}</Typography.Text> / 수치 :{' '}
        <Typography.Text strong>{ph}</Typography.Text>
      </Form.Item>
      <Form.Item label="Specific gravity (비중)">
        정상 범위 :{' '}
        <Typography.Text strong>{specificGravityLimitMin}</Typography.Text> ~{' '}
        <Typography.Text strong>{specificGravityLimitMax}</Typography.Text> /
        수치 : <Typography.Text strong>{specificGravity}</Typography.Text>
      </Form.Item>
      <Form.Item label="Viscosity">
        정상 범위 :{' '}
        <Typography.Text strong>{viscosityLimitMin || '-'}</Typography.Text> ~{' '}
        <Typography.Text strong>{viscosityLimitMax || '-'}</Typography.Text> /
        수치 :{' '}
        <Typography.Text strong>
          {viscosity ? `${viscosity}${viscosityUnit}` : '-'}
        </Typography.Text>
      </Form.Item>
      <Form.Item label="Hardness">
        정상 범위 :{' '}
        <Typography.Text strong>{hardnessLimitMin || '-'}</Typography.Text> ~{' '}
        <Typography.Text strong>{hardnessLimitMax || '-'}</Typography.Text> /
        수치 :{' '}
        <Typography.Text strong>
          {hardness ? `${hardness}${hardnessUnit}` : '-'}
        </Typography.Text>
      </Form.Item>
    </Form>
  );
};

export default ProductPhysicalPropertyModalContent;
