import { useState, useLayoutEffect } from 'react';
import { Button, Col, Input, Modal, Row } from 'antd';
import styled from 'styled-components';

import { IChecklistAnswer } from 'types/checklist';
import * as checklistApi from 'lib/api/checklist';
import { useMutation, useQueryClient } from 'react-query';
import { IAPIResponse } from 'types/common';
import { AxiosResponse } from 'axios';

const StyledButton = styled(Button)`
  min-width: 60px;
  padding: 0;
  font-size: 12px;
`;

interface IProps {
  checklistAnswer: IChecklistAnswer | null;
  onClose: () => void;
}

const ChecklistMemo = ({ checklistAnswer, onClose }: IProps) => {
  const queryClient = useQueryClient();
  const [memo, setMemo] = useState<string | null>(null);
  const { mutate: saveMemo, isLoading: saveMemoLoading } = useMutation(
    (params: { productCountryChecklistAnswerId: number; memo: string }) =>
      checklistApi.saveMemo(params),
    {
      onSuccess: (res) => {
        const getChecklistAnswersResponse = queryClient.getQueryData<
          AxiosResponse<IAPIResponse<IChecklistAnswer[]>>
        >('checklist/getChecklistAnswers');
        if (getChecklistAnswersResponse) {
          const target = getChecklistAnswersResponse.data.result.find(
            ({ productCountryChecklistAnswerId }) =>
              productCountryChecklistAnswerId ===
              res.data.result.productCountryChecklistAnswerId,
          );
          if (target) {
            target.memo = res.data.result.memo;
          }
        }
        onClose();
      },
    },
  );
  const { mutate: deleteMemo, isLoading: deleteMemoLoading } = useMutation(
    (productCountryChecklistAnswerId: number) =>
      checklistApi.deleteMemo(productCountryChecklistAnswerId),
    {
      onSuccess: (res) => {
        const getChecklistAnswersResponse = queryClient.getQueryData<
          AxiosResponse<IAPIResponse<IChecklistAnswer[]>>
        >('checklist/getChecklistAnswers');
        if (getChecklistAnswersResponse) {
          const targetGetChecklistAnswer = getChecklistAnswersResponse.data.result.find(
            ({ productCountryChecklistAnswerId }) =>
              productCountryChecklistAnswerId ===
              res.data.result.productCountryChecklistAnswerId,
          );
          if (targetGetChecklistAnswer) {
            targetGetChecklistAnswer.memo = null;
          }
        }
        onClose();
      },
    },
  );
  const handleClickSave = () => {
    if (!checklistAnswer || !memo) {
      throw new Error('checklistAnswer or memo could not be falsy');
    }
    const { productCountryChecklistAnswerId } = checklistAnswer;
    saveMemo({ productCountryChecklistAnswerId, memo });
  };
  const handleClickDelete = () => {
    if (!checklistAnswer) {
      throw new Error('checklistAnswer could not be falsy');
    }
    const { productCountryChecklistAnswerId } = checklistAnswer;
    deleteMemo(productCountryChecklistAnswerId);
  };
  useLayoutEffect(() => {
    if (checklistAnswer) {
      setMemo(checklistAnswer.memo);
    }
  }, [checklistAnswer]);
  return (
    <Modal
      visible={checklistAnswer !== null}
      title="메모"
      width={320}
      footer={null}
      bodyStyle={{ padding: '16px' }}
      onCancel={onClose}
    >
      <Input.TextArea
        rows={5}
        maxLength={1000}
        style={{ fontSize: 12 }}
        value={memo || ''}
        onChange={(e) => setMemo(e.target.value)}
      />
      <Row gutter={8} justify="center" style={{ marginTop: 16 }}>
        <Col>
          {checklistAnswer?.memo && (
            <StyledButton
              loading={deleteMemoLoading}
              onClick={handleClickDelete}
            >
              삭제
            </StyledButton>
          )}
        </Col>
        <Col>
          <StyledButton
            type="primary"
            disabled={
              !memo || memo.length === 0 || memo === checklistAnswer?.memo
            }
            loading={saveMemoLoading}
            onClick={handleClickSave}
          >
            메모 저장
          </StyledButton>
        </Col>
      </Row>
    </Modal>
  );
};

export default ChecklistMemo;
