import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Col, InputNumber, Row } from 'antd';

import palette from 'lib/styles/palette';
import {
  IClinicalTrialCartItem,
  IClinicalTrialPackage,
} from 'types/clinicalTrial';
import { useStores } from 'stores/Context';
import { Typography } from 'components/system';
import { CircleCheckIcon } from 'components/clinicalTrial/estimate/style';
import { isPackage } from 'hooks/clinicalTrial/clinicalTrial';

const ClinicalTrialCartItemTableContainer = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

const TableWrapper = styled.div`
  margin-top: 8px;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(234, 234, 234, 0.5);

  table {
    width: 100%;

    tr:not(:first-child) > td {
      border-top: 1px solid #f6f6f6;
    }
    tr:last-child > td {
      border-top: 1px dotted ${palette.darkNavy}40;
    }
    tr.cart-option > td {
      border-top: none;
    }
    th,
    td {
      text-align: center;
      color: ${palette.text.darkNavy};
      font-weight: 500;

      &:nth-child(2) {
        text-align: left;
      }
    }

    th {
      background-color: ${palette.gray5};
      font-size: 10px;
      padding: 4px 8px;
    }

    td {
      padding: 10px 8px;
      letter-spacing: -0.8px;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }

      &.option-name {
        position: relative;
        padding-left: 20px;

        &::before {
          position: absolute;
          top: 12px;
          left: 4px;
          content: '└';
        }
      }

      .ant-input-number {
        width: 48px;
        height: 24px;

        .ant-input-number-input {
          text-align: center;
          height: 22px;
          font-size: 12px;
        }
      }

      .package-items {
        margin-top: 4px;
        padding: 4px;
        background-color: ${palette.gray5};
      }
    }
  }
`;

const ClinicalTrialCartItemTable = ({
  categoryDataName,
  clinicalTrialCartItems,
}: {
  categoryDataName: string;
  clinicalTrialCartItems: IClinicalTrialCartItem[];
}) => {
  const { clinicalTrialStore } = useStores();
  const selectedCartItems = clinicalTrialCartItems.filter(
    ({ isSelected }) => isSelected,
  );
  const selectedPackageCartItems = selectedCartItems.filter(({ item }) =>
    isPackage(item),
  ) as (IClinicalTrialCartItem & { item: IClinicalTrialPackage })[];

  return (
    <ClinicalTrialCartItemTableContainer>
      <Typography.Text color="darkNavy" bold>
        {categoryDataName}
      </Typography.Text>
      <TableWrapper>
        <table>
          <colgroup>
            <col width={48}></col>
            <col width={240}></col>
            <col width={120}></col>
            <col width={84}></col>
            <col width={48}></col>
            <col width={80}></col>
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th align="left">시험항목</th>
              <th>시험기관</th>
              <th>단가 (원)</th>
              <th>품목수</th>
              <th>금액 (원)</th>
            </tr>
          </thead>
          <tbody>
            {clinicalTrialCartItems.reduce(
              (
                acc,
                {
                  clinicalTrialCartItemId,
                  isSelected,
                  item,
                  itemQuantity,
                  options,
                },
              ) => {
                const price = isPackage(item)
                  ? item.clinicalTrialPackageProducts.reduce(
                      (acc, curr) => acc + curr[0].price,
                      0,
                    )
                  : item.price;
                acc.push(
                  <tr key={clinicalTrialCartItemId}>
                    <td>
                      <CircleCheckIcon
                        checked={isSelected}
                        onClick={() => {
                          clinicalTrialStore.changeClinicalTrialCartItem(
                            clinicalTrialCartItemId,
                            {
                              isSelected: !isSelected,
                            },
                          );
                        }}
                      />
                    </td>
                    <td>
                      {isPackage(item) ? (
                        <>
                          {item.packageName}
                          <Typography.Text
                            type="quaternary"
                            medium
                            color="disabled"
                            align="left"
                            className="package-items"
                          >
                            {item.clinicalTrialPackageProducts
                              .map(
                                ([{ clinicalTrialName, method }]) =>
                                  `${clinicalTrialName} - ${method.methodName}`,
                              )
                              .join(' + ')}
                          </Typography.Text>
                        </>
                      ) : (
                        `${item.clinicalTrialName}-${item.method.methodName}`
                      )}
                    </td>
                    <td className="agency-name">{item.agency.agencyName}</td>
                    <td>{price.toLocaleString()}</td>
                    <td>
                      <InputNumber
                        value={itemQuantity}
                        min={1}
                        max={99}
                        inputMode="numeric"
                        controls={false}
                        onChange={(itemQuantity) => {
                          clinicalTrialStore.changeClinicalTrialCartItem(
                            clinicalTrialCartItemId,
                            {
                              itemQuantity: itemQuantity ?? 1,
                            },
                          );
                        }}
                      />
                    </td>
                    <td>{(itemQuantity * price).toLocaleString()}</td>
                  </tr>,
                );
                // HINT : 옵션 목록
                options.forEach(
                  ({
                    clinicalTrialProductOptionId,
                    clinicalTrialName,
                    clinicalTrialPackageName,
                    productOptionName,
                    itemQuantity,
                  }) => {
                    acc.push(
                      <tr
                        className="cart-option"
                        key={`${clinicalTrialCartItemId}_${
                          clinicalTrialPackageName || clinicalTrialName
                        }_${clinicalTrialProductOptionId}`}
                      >
                        <td></td>
                        <td className="option-name">
                          [{productOptionName}] {clinicalTrialName}
                        </td>
                        <td></td>
                        <td>
                          <Typography.Text
                            type="secondary"
                            medium
                            color="darkNavy"
                            style={{ opacity: 0.3 }}
                          >
                            가격 미정
                          </Typography.Text>
                        </td>
                        <td>
                          <InputNumber
                            value={itemQuantity}
                            min={0}
                            max={99}
                            controls={false}
                            inputMode="numeric"
                            onChange={(itemQuantity) => {
                              clinicalTrialStore.changeClinicalTrialCartItemOption(
                                clinicalTrialCartItemId,
                                clinicalTrialProductOptionId,
                                itemQuantity,
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Typography.Text
                            type="secondary"
                            medium
                            color="darkNavy"
                            style={{ opacity: 0.3 }}
                          >
                            가격 미정
                          </Typography.Text>
                        </td>
                      </tr>,
                    );
                  },
                );
                return acc;
              },
              [] as React.ReactNode[],
            )}
            {selectedPackageCartItems.length > 0 && (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td colSpan={4} style={{ textAlign: 'left' }}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Typography.Text type="secondary" medium color="darkNavy">
                        할인
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Title type="secondary" medium color="error">
                        {selectedPackageCartItems
                          .reduce(
                            (acc, currCartItem) =>
                              acc +
                              (currCartItem.item.price -
                                currCartItem.item.clinicalTrialPackageProducts.reduce(
                                  (acc, [{ price }]) => acc + price,
                                  0,
                                )) *
                                currCartItem.itemQuantity,

                            0,
                          )
                          .toLocaleString()}
                      </Typography.Title>
                    </Col>
                  </Row>

                  {selectedPackageCartItems.map(({ item, itemQuantity }) => (
                    <Row
                      key={item.clinicalTrialPackageId}
                      justify="space-between"
                    >
                      <Col>
                        <Typography.Text type="quaternary" color="disabled">
                          {item.packageName}
                        </Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text type="quaternary" color="disabled">
                          {(
                            (item.price -
                              item.clinicalTrialPackageProducts.reduce(
                                (acc, [{ price }]) => acc + price,
                                0,
                              )) *
                            itemQuantity
                          ).toLocaleString()}
                        </Typography.Text>
                      </Col>
                    </Row>
                  ))}
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={3}></td>
              <td colSpan={4} style={{ textAlign: 'left' }}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Typography.Text type="secondary" medium color="darkNavy">
                      합계
                    </Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Title type="secondary" medium color="darkNavy">
                      {selectedCartItems
                        .reduce(
                          (acc, { item, itemQuantity }) =>
                            acc + item.price * itemQuantity,
                          0,
                        )
                        .toLocaleString()}
                    </Typography.Title>
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </ClinicalTrialCartItemTableContainer>
  );
};

export default observer(ClinicalTrialCartItemTable);
