import palette from 'lib/styles/palette';
import styled, { css } from 'styled-components';
interface BlockProps {
  type: 'default' | 'primary' | 'success' | 'error' | 'disabled' | 'check';
  bordered?: boolean;
  onClick?: React.MouseEventHandler;
}
interface Props extends Omit<BlockProps, 'type'> {
  type?: BlockProps['type'];
  children: React.ReactChild;
  style?: React.CSSProperties;
}

const LabelBlock = styled.span<BlockProps>`
  display: inline-block;
  min-width: 56px;
  height: 24px;
  font-size: 12px;
  padding: 4px 4px 3px;
  letter-spacing: -0.4px;
  border-radius: 4px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      padding: 4px 2px 3px 6px;
    `};

  color: ${({ type }) => (type === 'default' ? 'inherit' : palette.text[type])};
  border: ${({ type, bordered }) =>
    bordered && type !== 'default' ? `1px solid ${palette[type]}` : 'none'};

  ${({ type, bordered }) => {
    if (bordered) return null;

    switch (type) {
      case 'primary':
        return css`
          background-color: #e6f0ff;
        `;

      case 'success':
        return css`
          background-color: #e3fbe7;
        `;

      case 'disabled':
        return css`
          background-color: ${palette.disabled};
        `;
    }
  }};

  i.material-icons {
    line-height: 16px;
  }
`;

const Label = ({
  type = 'default',
  bordered,
  style,
  onClick,
  children,
}: Props) => {
  return (
    <LabelBlock type={type} bordered={bordered} style={style} onClick={onClick}>
      {children}
    </LabelBlock>
  );
};

export default Label;
