import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Table, Typography } from 'antd';
import FaqAddModal from 'components/service/FaqAddModal';
import FaqEditModal from 'components/service/FaqEditModal';
import { useDeleteFaqs, useFaqs } from 'hooks/service';
import { useMemo, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { IFaq } from 'types/service';

const FaqPage = () => {
  const [page, setPage] = useState(1);
  const { data, refetch } = useFaqs({ page });
  const { content: faqs, totalElements } = { ...data };
  const [selectedFaqIds, setSelectedFaqIds] = useState<number[]>([]);
  const [faqAddModalVisible, setFaqAddModalVisible] = useState(false);
  const [previewedFaq, setPreviewedFaq] = useState<IFaq | null>(null);
  const columns = useMemo(
    () => [
      { title: '번호', dataIndex: 'faqId', align: 'center' as const },
      {
        title: '제목',
        align: 'center' as const,
        render: (faq: IFaq) => (
          <Typography.Link onClick={() => setPreviewedFaq(faq)}>
            {faq.title}
          </Typography.Link>
        ),
      },
      {
        title: '공개 여부',
        align: 'center' as const,
        render: ({ isLive }) => (isLive ? '공개' : '비공개'),
      },
    ],
    [],
  );
  const { mutate: deleteFaqsMutate, isLoading } = useDeleteFaqs({
    onSuccess: () => {
      refetch();
    },
  });
  const closeFaqEditModal = (isModified: boolean) => {
    if (isModified) {
      refetch();
    }
    setPreviewedFaq(null);
  };
  const closeFaqAddModal = (isSuccess: boolean) => {
    if (isSuccess) {
      refetch();
    }
    setFaqAddModalVisible(false);
  };
  const deleteFaqs = () => {
    if (!selectedFaqIds.length) return message.warn('대상을 선택해 주세요.');
    deleteFaqsMutate(selectedFaqIds);
  };
  return (
    <PageTemplate title="FAQ">
      {faqAddModalVisible && <FaqAddModal onClose={closeFaqAddModal} />}
      {previewedFaq && (
        <FaqEditModal faq={previewedFaq} onClose={closeFaqEditModal} />
      )}

      <Row justify="end" gutter={8} style={{ marginBottom: 8 }}>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setFaqAddModalVisible(true)}
          >
            새로운 FAQ 작성하기
          </Button>
        </Col>
        <Col>
          <Button
            loading={isLoading}
            icon={<DeleteOutlined />}
            onClick={deleteFaqs}
          >
            선택 삭제
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={faqs}
        rowKey={({ faqId }) => faqId}
        pagination={{
          current: page,
          total: totalElements,
          pageSize: 20,
          onChange: setPage,
        }}
        rowSelection={{
          selectedRowKeys: selectedFaqIds,
          onChange: (keys) => {
            setSelectedFaqIds(
              keys.map<number>((key) => Number(key)),
            );
          },
        }}
      />
    </PageTemplate>
  );
};

export default FaqPage;
