import { IBuyer, ICompany } from 'types/company';
import { IEstimate, IEstimateTarget } from 'types/estimate';
import { EProcessStage } from 'types/common';

export interface IStat {
  companyId: number;
  company: ICompany;
  productOnCount: number;
  productRegCount: number;
  certOnCount: number;
  certFinCount: number;
  certFinCountryCount: number;
}

export interface ICertificate {
  certTargetId: number;
  companyId: number;
  company: ICompany;
  buyerId: number;
  buyer: IBuyer;
  certDocUrl: string | null;
  certRegisterNo: string | null;
  estimate: IEstimate;
  estimateTargetId: number;
  estimateTarget: IEstimateTarget;
  certRequestNo: string;
  status: ECertificateStatus;
  statusString: string;
  etcStatus: ECertificateEtcStatus;
  etcStatusString: string;
  registerDt: string;
  lastStatusUpdateDt: string | null;
  isFinish: boolean;
  certFinYmd: string;
  cpsrGenerateFileUrl: string | null;
  cpsrUploadFileUrl: string | null;
  cpsrIssueYmd: string | null;
}

export interface ICertificateInfo {
  no: number;
  productDocStatusId: number | string;
  documentName: string;
  documentCode: EDocumentCode;
  status: ECertificateInfoStatus | null;
  statusString: string | null;
  updateDate: string;
  uploadFileUrl: string | null;
  uploadFileUrls?: string[];
  documentUrl: string | null;
  documentUrls?: string[];
  originalUploadFileName: string;
  filenames?: string[];
  formType: ECertificateInfoFormType;
  isDisplayed: boolean;
  correctRequestMessage: string | null;
}

export interface ICertificateData {
  content: ICertificate[];
  size: number;
  totalElements: number;
}

export interface ICertificatePageState {
  page?: number;
  searchType?: ECertificateSearchTypeOptions;
  searchValue?: string;
  searchStatus?: EProcessStage;
}

export enum ECertificateStatus {
  REG_FIN = 'REG-FIN',
  CHK_DOC = 'CHK-DOC',
  CHK_SAM = 'CHK-SAM',
  CER_FIN = 'CER-FIN',
  CER_CAN = 'CER-CAN',
}

export enum ECertificateSearchTypeOptions {
  PRODUCT_NAME_EN = 'productNameEn',
  COUNTRY_NAME_KO = 'countryNameKo',
  CERT_REQUEST_NO = 'certRequestNo',
}

export enum ECertificateEtcStatus {
  REQ_COR = 'REQ-COR',
}

export enum ECertificateInfoStatus {
  INP = 'INP',
  ONG = 'ONG',
  MOD = 'MOD',
  FIN = 'FIN',
}

export enum ECertificateInfoFormType {
  C = 'C',
  M = 'M',
  B = 'B',
}

export enum EDocumentCode {
  BASIC = 'BASIC01',
  PN = 'PN01',
  CI = 'CI01',
  BI = 'BI01',
  COUNTRY = '  COUNTRY01',
  CAT = 'CAT01',
  PHY = 'PHY01',
  CHE = 'CHE01',
  MIC = 'MIC01',
  FORM = 'FORM01',
  FORMBR = 'FORMBR01',
  FORMBRC = 'FORMBRC01',
  ART = 'ART01',
  COA = 'COA01',
  MSDS = 'MSDS01',
  SPEC = 'SPEC01',
  CGMP = 'CGMP01',
  STAB = 'STAB01',
  CHAL = 'CHAL01',
  PACK = 'PACK01',
  MANU = 'MANU01',
  MANUC = 'MANUC01',
  SAFE = 'SAFE01',
  ALLE = 'ALLE01',
  IFRA = 'IFRA01',
  NONANIM = 'NONANIM01',
  NONHEXA = 'NONHEXA01',
  NONMIC = 'NONMIC01',
  HMDC = 'HMDC01',
  UNDER = 'UNDER01',
  MBIO = 'MBIO01',
  TPCOA = 'TPCOA01',
  TPHM = 'TPHM01',
  RMCOA = 'RMCOA01',
  RMMSDS = 'RMMSDS01',
  THIRD = 'THIRD01',
  CFS = 'CFS01',
  LOGOAI = 'LOGOAI01',
  COBRM = 'COBRM01',
  FOAC = 'FOAC01',
  MD = 'MD01',
  ROPQM = 'ROPQM01',
  CFF = 'CFF01',
  SLD = 'SLD01',
  IRC = 'IRC01',
  PS = 'PS01',
  SPI = 'SPI01',
  CV = 'CV01',
  KW = 'KW01',
  SWI = 'SWI01',
  UPSI = 'UPSI01',
  FCCAD = 'FCCAD01',
  VCRPAR = 'VCRPAR01',
  VCRPAC = 'VCRPAC01',
  PRNIL = 'PRNIL01',
}
export interface ICountryVendor {
  vendorName: string;
  vendorTel?: string;
  businessStartHour?: string;
  businessEndHour?: string;
  isOpenMonday: boolean;
  isOpenTuesday: boolean;
  isOpenWednesday: boolean;
  isOpenThursday: boolean;
  isOpenFriday: boolean;
  isOpenSaturday: boolean;
  isOpenSunday: boolean;
}

export interface ICountryVendorInfo extends ICountryVendor {
  productCountryVendorId: number;
  isRegisterVendor: boolean;
  isUsePartnerVendor: boolean;
}

export interface IProductKeepWarningBasic {
  productLine: 'LID_PRODUCT' | 'MASK_PACK' | 'NOT_APPLICABLE';
}

export interface IProductKeepWarning extends IProductKeepWarningBasic {
  productKeepWarningId: number;
}
