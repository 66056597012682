import React, { useState, useMemo, useEffect } from 'react';
import { Card, Empty, Tabs } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import DoughnutChart from 'components/stats/DoughnutChart';
import PeriodSelect from 'components/stats/PeriodSelect';
import {
  ECompanyShareType,
  IProductRegisterStat,
  IProductRegisterStatWithShare,
} from 'types/stats';
import { Margin } from 'components/system/general/Margin';
import { Typography } from 'components/system';
import ShareStatsTable from 'components/stats/shareStats/ShareStatsTable';
import { useProductRegisterStats } from 'hooks/stats';

const AllManufacturerShareCardBlock = styled(Card)`
  .ant-card-body {
    padding: 30px 0px 20px 0px;
  }
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px;

  .ant-tabs-tab-btn {
    margin-left: 28px;
  }
`;

const AllManufacturerShareCard: React.FC = () => {
  const [productRegisterStats, setProductRegisterStats] = useState<
    IProductRegisterStat[]
  >([]);

  const { mutate: getProductRegisterStats } = useProductRegisterStats(
    setProductRegisterStats,
  );

  useEffect(() => {
    getProductRegisterStats({
      from: '2021-03-23',
      to: moment().format('YYYY-MM-DD'),
    });
  }, []);

  const productRegisterStatsWithShare: IProductRegisterStatWithShare[] = useMemo(() => {
    const totalCountProductReg = productRegisterStats.reduce(
      (acc, cur) => acc + cur.countProductReg,
      0,
    );

    return productRegisterStats.map((v) => ({
      ...v,
      share: Number(
        ((v.countProductReg / totalCountProductReg) * 100).toFixed(1),
      ),
    }));
  }, [productRegisterStats]);

  return (
    <AllManufacturerShareCardBlock style={{ minHeight: 840 }}>
      <StyledTabs>
        <Tabs.TabPane
          key={ECompanyShareType.All_MANUFACTURER_SHARE}
          tab="전체 제조사 점유율"
        />
      </StyledTabs>
      <PeriodSelect
        onSearch={(_, { from, to }) =>
          getProductRegisterStats({
            from,
            to,
          })
        }
      />
      <Margin size={80} />
      {productRegisterStatsWithShare.length !== 0 ? (
        <>
          <div style={{ width: '50%', margin: 'auto' }}>
            <DoughnutChart
              dataSource={productRegisterStatsWithShare.slice(0, 10)}
            />
          </div>
          <Margin size={20} />
          <Typography.Text style={{ textAlign: 'center' }}>
            ( 상위 10개 제조사 )
          </Typography.Text>
          <Margin size={36} />
          <ShareStatsTable
            data={productRegisterStatsWithShare}
            type="manufacturerId"
          />
        </>
      ) : (
        <>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="데이터 없음"
          />
          <Typography.Text style={{ textAlign: 'center' }} bold>
            {`기간을 입력해 주세요.`}
          </Typography.Text>
        </>
      )}
    </AllManufacturerShareCardBlock>
  );
};

export default AllManufacturerShareCard;
