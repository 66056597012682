import { makeAutoObservable } from 'mobx';
import { nanoid } from 'nanoid';

import RootStore from 'stores/RootStore';
import { IClinicalTrialSelectToast } from 'types/clinicalTrial';

export default class AuthStore {
  rootStore;
  private _clinicalTrailSelectToasts: IClinicalTrialSelectToast[];

  constructor(root: RootStore) {
    this.rootStore = root;
    this._clinicalTrailSelectToasts = [];
    makeAutoObservable(this);
  }

  get clinicalTrialToasts() {
    return this._clinicalTrailSelectToasts;
  }

  showClinicalTrialToast(newToast: Omit<IClinicalTrialSelectToast, 'id'>) {
    const id = nanoid();
    this._clinicalTrailSelectToasts.push({ id, ...newToast });
    setTimeout(() => {
      this._clinicalTrailSelectToasts = this._clinicalTrailSelectToasts.filter(
        (toast) => toast.id !== id,
      );
    }, 3000);
  }
}
