import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Table, Typography } from 'antd';
import UseGuideAddModal from 'components/service/UseGuideAddModal';
import UseGuideEditModal from 'components/service/UseGuideEditModal';
import { useDeleteUseGuides, useUseGuides } from 'hooks/service';
import { useMemo, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { IUseGuide } from 'types/service';

const UseGuidePage = () => {
  const [page, setPage] = useState(1);
  const { data, refetch } = useUseGuides({ page });
  const { content: useGuides, totalElements } = { ...data };
  const [selectedUseGuideIds, setSelectedUseGuideIds] = useState<number[]>([]);
  const [useGuideAddModalVisible, setUseGuideAddModalVisible] = useState(false);
  const [previewedUseGuide, setPreviewedUseGuide] = useState<IUseGuide | null>(
    null,
  );
  const columns = useMemo(
    () => [
      { title: '번호', dataIndex: 'useGuideId', align: 'center' as const },
      {
        title: '제목',
        align: 'center' as const,
        render: (useGuide: IUseGuide) => (
          <Typography.Link onClick={() => setPreviewedUseGuide(useGuide)}>
            {useGuide.title}
          </Typography.Link>
        ),
      },
      {
        title: '공개 여부',
        align: 'center' as const,
        render: ({ isLive }) => (isLive ? '공개' : '비공개'),
      },
    ],
    [],
  );
  const { mutate: deleteUseGuidesMutate, isLoading } = useDeleteUseGuides({
    onSuccess: () => {
      refetch();
    },
  });
  const closeUseGuideEditModal = (isModified: boolean) => {
    if (isModified) {
      refetch();
    }
    setPreviewedUseGuide(null);
  };
  const closeUseGuideAddModal = (isSuccess: boolean) => {
    if (isSuccess) {
      refetch();
    }
    setUseGuideAddModalVisible(false);
  };
  const deleteUseGuides = () => {
    if (!selectedUseGuideIds.length)
      return message.warn('대상을 선택해 주세요.');
    deleteUseGuidesMutate(selectedUseGuideIds);
  };
  return (
    <PageTemplate title="이용안내">
      {useGuideAddModalVisible && (
        <UseGuideAddModal onClose={closeUseGuideAddModal} />
      )}
      {previewedUseGuide && (
        <UseGuideEditModal
          useGuide={previewedUseGuide}
          onClose={closeUseGuideEditModal}
        />
      )}

      <Row justify="end" gutter={8} style={{ marginBottom: 8 }}>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setUseGuideAddModalVisible(true)}
          >
            새로운 이용안내 작성하기
          </Button>
        </Col>
        <Col>
          <Button
            loading={isLoading}
            icon={<DeleteOutlined />}
            onClick={deleteUseGuides}
          >
            선택 삭제
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={useGuides}
        rowKey={({ useGuideId }) => useGuideId}
        pagination={{
          current: page,
          total: totalElements,
          pageSize: 20,
          onChange: setPage,
        }}
        rowSelection={{
          selectedRowKeys: selectedUseGuideIds,
          onChange: (keys) => {
            setSelectedUseGuideIds(
              keys.map<number>((key) => Number(key)),
            );
          },
        }}
      />
    </PageTemplate>
  );
};

export default UseGuidePage;
