import AuthStore from 'stores/AuthStore';
import CertificateStore from 'stores/CertificateStore';
import CompanyStore from 'stores/CompanyStore';
import EtcStore from 'stores/EtcStore';
import NotificationStore from 'stores/NotificationStore';
import ClinicalTrialStore from 'stores/ClinicalTrialStore';
import ClinicalTrialToastStore from 'stores/clinicalTrial/ClinicalTrialToastStore';

export default class RootStore {
  authStore;
  certificateStore;
  companyStore;
  etcStore;
  notificationStore;
  clinicalTrialStore;
  clinicalTrialToastStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.certificateStore = new CertificateStore(this);
    this.companyStore = new CompanyStore(this);
    this.etcStore = new EtcStore(this);
    this.notificationStore = new NotificationStore(this);
    this.clinicalTrialStore = new ClinicalTrialStore(this);
    this.clinicalTrialToastStore = new ClinicalTrialToastStore(this);
  }
}
