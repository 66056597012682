import { Form, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import DateInput from 'components/system/form/DateInput';
import consts from 'lib/consts';
import { requireRule } from 'lib/validate';
import { useUpdateSampleReceiptDate } from 'hooks/clinicalTrial/process/processingEstimateDetail';

const SampleReceiptModal = ({
  sampleReceiptDate,
  clinicalTrialProcessItemId,
  onClose,
}: {
  sampleReceiptDate: string | null;
  clinicalTrialProcessItemId: number;
  onClose: () => void;
}) => {
  const [form] = useForm<{ sampleReceiptDate: string }>();
  const {
    updateSampleReceiptDate,
    updateLoading,
  } = useUpdateSampleReceiptDate();
  const handleSubmit = (formValue: { sampleReceiptDate: string }) => {
    if (formValue.sampleReceiptDate === sampleReceiptDate) {
      message.warning(consts.message.NO_NEED_TO_UPDATE);
      return;
    } else {
      updateSampleReceiptDate(
        {
          clinicalTrialProcessItemId,
          sampleReceiptDate: formValue.sampleReceiptDate,
        },
        {
          onSuccess: onClose,
        },
      );
    }
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <Modal
      title="시료 수령일"
      visible
      onOk={handleOk}
      onCancel={onClose}
      confirmLoading={updateLoading}
      bodyStyle={{ padding: '36px 92px 0px', height: 112 }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          label="시료 수령일"
          name="sampleReceiptDate"
          initialValue={sampleReceiptDate}
          rules={[requireRule]}
        >
          <DateInput style={{ width: 240, height: 40 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SampleReceiptModal;
