import qs from 'qs';

import { IAPIResponse } from 'types/common';
import {
  ICosmeticCategory,
  IFunctionalCategory,
  IProductCategory,
  IProductChallengeTest,
  IProductDetail,
  IProductPhysicalProperty,
  IProductStabilityTest,
  IProductPackingAttestation,
  IProductArtwork,
  IProductThirdPartyTest,
  IProductInternalResponsibleCompany,
  IProductShelfLifeDeclaration,
  IProductSaleInfo,
  IProductVCRPAccount,
  IProductVCRPAccountRequest,
} from 'types/product';
import client from './client';

export const getProductDetail = async ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const res = await client.get<IAPIResponse<IProductDetail>>(
    `/product/${productId}/${countryId}`,
  );
  return res.data;
};

export const getCosmeticCategories = async () => {
  const res = await client.get<IAPIResponse<ICosmeticCategory[]>>(
    '/cosmetic-categories',
  );
  return res.data;
};

export const getFunctionalCategories = async () => {
  const res = await client.get<IAPIResponse<IFunctionalCategory[]>>(
    '/functional-categories',
  );
  return res.data;
};

export const getProductCategory = async (productId: number) =>
  await client.get<IAPIResponse<IProductCategory>>(
    `/product-category/${productId}`,
  );

export const getProductPhysicalProperty = async ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const res = await client.get<IAPIResponse<IProductPhysicalProperty>>(
    `/product-physical-property/${productId}/${countryId}`,
  );
  return res.data;
};

export const getProductStabilityTest = async ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const res = await client.get<IAPIResponse<IProductStabilityTest>>(
    `/product-stability-test/${productId}/${countryId}`,
  );
  return res.data;
};

export const getProductChallengeTest = async ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const res = await client.get<IAPIResponse<IProductChallengeTest>>(
    `/product-challenge-test/${productId}/${countryId}`,
  );
  return res.data;
};

export const getProductPackingAttestation = async ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const res = await client.get<IAPIResponse<IProductPackingAttestation>>(
    `/product-packing-attestation/${productId}/${countryId}`,
  );
  return res.data;
};

export const getProductArtworks = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IProductArtwork[]>>(
    `/product-artwork/${productId}${countryId ? `/${countryId}` : ''}`,
  );

export const getProductThirdPartyTest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IProductThirdPartyTest[]>>(
    `/product-third-party-test/${productId}/${countryId}`,
  );

export const getProductInternalResponsibleCompany = async ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const res = await client.get<
    IAPIResponse<IProductInternalResponsibleCompany>
  >(`/internal-responsible-company/${productId}/${countryId}`);
  return res.data;
};

export const getProductShelfLifeDeclaration = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IProductShelfLifeDeclaration>>(
    `/admin/shelf-life?${qs.stringify({ productId, countryId })}`,
  );

export const getProductSaleInfo = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IProductSaleInfo>>(
    `/admin/products/${productId}/countries/${countryId}/sale-info`,
  );

export const getProductVCRPAccount = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IProductVCRPAccount>>(
    `/admin/products/${productId}/countries/${countryId}/vcrp-account`,
  );

export const getProductVCRPAccountRequest = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) =>
  client.get<IAPIResponse<IProductVCRPAccountRequest>>(
    `/admin/products/${productId}/vcrp-account-request`,
    { params: { countryId } },
  );
