import { Modal } from 'antd';
import { useMemo } from 'react';

import { IClinicalTrialEstimateRequestDetail } from 'types/clinicalTrial/process/estimateRequest';
import ClinicalTrialRequestDetail from './ClinicalTrialRequestDetail';

const ClinicalTrialRequestDetailModal = ({
  visible,
  clinicalTrialEstimateRequestItems,
  onClose,
}: {
  visible: boolean;
  clinicalTrialEstimateRequestItems: IClinicalTrialEstimateRequestDetail[];
  onClose: () => void;
}) => {
  const itemMap = useMemo(
    () =>
      clinicalTrialEstimateRequestItems.reduce((map, item) => {
        const items = map.get(item.categoryDataName);
        return map.set(
          item.categoryDataName,
          items ? items.concat(item) : [item],
        );
      }, new Map<string, IClinicalTrialEstimateRequestDetail[]>()),
    [clinicalTrialEstimateRequestItems],
  );

  return (
    <Modal
      visible={visible}
      className="clinicalTrial"
      footer={null}
      width={400}
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#EFF1F8',
        borderRadius: 12,
        padding: '24px 24px 36px 24px',
        maxHeight: 322,
        overflow: 'auto',
      }}
    >
      {Array.from(itemMap.entries()).map(([categoryDataName, items]) => (
        <ClinicalTrialRequestDetail
          categoryName={categoryDataName}
          items={items}
        />
      ))}
    </Modal>
  );
};

export default ClinicalTrialRequestDetailModal;
