import { Card, Col, Row } from 'antd';
import { Typography } from 'components/system';
import styled from 'styled-components';
import { useMainStat } from 'hooks/stats';
import palette from 'lib/styles/palette';

interface IStatProps {
  amount: number;
  opacity: number;
  label: string;
}

const DayCertificateStatusBlock = styled(Card)`
  height: 100%;

  .ant-card-body {
    padding: 32px 40px;
  }
`;

const StatBlock = styled.div<Pick<IStatProps, 'opacity'>>`
  width: 128px;
  cursor: pointer;

  .stat {
    font-size: 56px;
    line-height: 0.8;
    letter-spacing: -1.65px;
    text-align: center;
    font-weight: 500;
  }

  .label-wrap {
    margin-top: 10px;
    text-align: center;

    .label {
      letter-spacing: -0.5px;
    }
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${palette.primary};
    opacity: ${(props) => props.opacity};
    border-radius: 50%;
    margin-right: 8px;
  }
`;

const Stat = ({ amount, opacity, label }: IStatProps) => {
  return (
    <StatBlock opacity={opacity}>
      <div className="stat">{amount}</div>
      <div className="label-wrap">
        <span className="dot" />
        <span>{label}</span>
      </div>
    </StatBlock>
  );
};

const DayCertificateStatus = () => {
  const {
    yesterdayPrdRdyCount,
    yesterdayConReqCount,
    yesterdayDepCfmCount,
    yesterdayCerFinCount,
  } = useMainStat();
  return (
    <DayCertificateStatusBlock>
      <Typography.Headline type="secondary">
        어제 일자 인증 현황입니다.
      </Typography.Headline>
      <Row gutter={[16, 16]} style={{ marginTop: 28 }} justify="space-around">
        <Col>
          <Stat
            amount={yesterdayPrdRdyCount}
            opacity={0.25}
            label="제품 등록 중"
          />
        </Col>
        <Col>
          <Stat amount={yesterdayConReqCount} opacity={0.5} label="인증 신청" />
        </Col>
        <Col>
          <Stat
            amount={yesterdayDepCfmCount}
            opacity={0.75}
            label="인증 진행 중"
          />
        </Col>
        <Col>
          <Stat amount={yesterdayCerFinCount} opacity={1} label="인증 완료" />
        </Col>
      </Row>
    </DayCertificateStatusBlock>
  );
};

export default DayCertificateStatus;
