import Headline from 'components/system/general/Typography/Headline';
import Title from 'components/system/general/Typography/Title';
import Text from 'components/system/general/Typography/Text';
import Label from 'components/system/general/Typography/Label';
import palette from 'lib/styles/palette';

const Typography = { Headline, Title, Text, Label };

export interface ITypographyBlockProps {
  type?: 'primary' | 'secondary';
  gutter?:
    | number
    | { top?: number; bottom?: number; left?: number; right?: number };
  align?: 'left' | 'center' | 'right';
  bold?: boolean;
  medium?: boolean;
  inline?: boolean;
  color?: keyof typeof palette.text;
}

export interface ITypographyProps extends ITypographyBlockProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
}

export default Typography;
