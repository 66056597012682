import styled, { css } from 'styled-components';
import { useState } from 'react';
import { Button, Col, Row, Select, Table } from 'antd';
import { useLocation } from 'react-router-dom';

import { Typography } from 'components/system';
import {
  EClinicalTrialEstimateSearchType,
  EClinicalTrialEstimateStatus,
  IClinicalTrialEstimateSearchParams,
} from 'types/clinicalTrial';
import SearchInput from 'components/form/SearchInput';
import TuneIcon from 'components/system/icon/TuneIcon';
import palette from 'lib/styles/palette';
import history from 'lib/history';
import path from 'lib/path';
import PostAddIcon from 'components/system/icon/PostAddIcon';
import FilterDrawer from './FilterDrawer';
import DescriptionIcon from 'components/system/icon/DescriptionIcon';
import MoreIcon from 'components/system/icon/MoreIcon';
import { useClinicalTrialEstimates } from 'hooks/clinicalTrial/clinicalTrial';
import useUpdateEffect from 'hooks/useUpdateEffect';
import MoreActionPopup from './MoreActionPopup';
import EstimateConfirmModal from './EstimateConfirmModal';
import ClientInfoModal from 'components/clinicalTrial/estimate/clinicalTrialEstimateList/ClientInfoModal';
import EstimateHistoriesModal from './EstimateHistoriesModal';
import { useStores } from 'stores/Context';

const ClinicalTrialEstimateListContainer = styled.div`
  width: 720px;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 50px;

  table {
    border-radius: 0;
    box-shadow: 0 0 10px 0 rgb(215 215 215 / 42%);

    th,
    td {
      color: ${palette.text.darkNavy};
      font-weight: 500;

      &:first-child {
        padding-left: 8px;
      }

      &:last-child {
        padding-right: 8px;
      }
    }
    th {
      padding: 5px 4px 4px;
      font-size: 10px;
    }
    td {
      padding: 12px 4px;
      letter-spacing: -0.8px;
    }
  }
`;

const TuneIconContainer = styled.div<{ filtering: boolean }>`
  ${({ filtering }) =>
    filtering &&
    css`
      &::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 4px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${palette.primary};
      }
    `}
`;

const MoreActionContainer = styled.div`
  position: relative;
`;

const ClinicalTrialEstimateList = () => {
  const { pathname, state: savedSearchParams = {} } = useLocation<
    Partial<IClinicalTrialEstimateSearchParams>
  >();
  const { clinicalTrialToastStore } = useStores();
  const [page, setPage] = useState(savedSearchParams.page ?? 1);
  const [searchType, setSearchType] = useState(
    (savedSearchParams.searchType as EClinicalTrialEstimateSearchType) ??
      EClinicalTrialEstimateSearchType.ALL,
  );
  const [searchKeyword, setSearchKeyword] = useState(
    savedSearchParams.searchKeyword ?? '',
  );
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean | null>(
    savedSearchParams.isConfirmed ?? null,
  );
  const [publishDuration, setPublishingDuration] = useState<{
    from: string;
    to: string;
  } | null>(savedSearchParams.publishDuration ?? null);
  const {
    clinicalTrialEstimates,
    totalElements,
    refetchClinicalTrialEstimates,
    confirmClinicalTrialEstimate,
  } = useClinicalTrialEstimates({
    page,
    searchType,
    searchKeyword,
    isConfirmed,
    publishDuration,
  });
  const handleInitializeFilter = () => {
    setIsConfirmed(null);
    setPublishingDuration(null);
    if (filterDrawerVisible) {
      setFilterDrawerVisible(false);
    }
  };
  const [
    clinicalTrialEstimateIdForMoreAction,
    setClinicalTrialEstimateIdForMoreAction,
  ] = useState<number | null>(null);
  const handleClickMore = (clinicalTrialEstimateId: number) => {
    setClinicalTrialEstimateIdForMoreAction(clinicalTrialEstimateId);
  };
  const handleUpdateEstimate = (clinicalTrialEstimateId: number) => {
    history.push(
      `${path.clinicalTrial.estimate.root}/${clinicalTrialEstimateId}`,
    );
  };
  const [
    clinicalTrialEstimateIdForEstimateHistory,
    setClinicalTrialEstimateIdForEstimateHistory,
  ] = useState<number | null>(null);
  const handleShowUpdateHistoryModal = (clinicalTrialEstimateId: number) => {
    setClinicalTrialEstimateIdForEstimateHistory(clinicalTrialEstimateId);
  };
  const [estimateConfirmModalProps, setEstimateConfirmModalProps] = useState<{
    clinicalTrialEstimateId: number;
    userId: number | null;
  } | null>(null);
  const handleShowConfirmEstimateModal = (
    hasClientInfo: boolean,
    clinicalTrialEstimateId: number,
    userId: number | null,
  ) => {
    if (hasClientInfo) {
      setEstimateConfirmModalProps({
        clinicalTrialEstimateId,
        userId,
      });
      return;
    }
    clinicalTrialToastStore.showClinicalTrialToast({
      message: '의뢰자 정보를 먼저 입력해주세요.',
      buttonProps: {
        text: '입력하기',
        onClick: () =>
          setClinicalTrialEstimateIdForUserInfo(clinicalTrialEstimateId),
      },
    });
  };
  const handleConfirmEstimate = (bizNumber: string) => {
    if (estimateConfirmModalProps === null) {
      throw new Error('Invalid clinicalTrialEstimateIdToBeConfirmed');
    }
    confirmClinicalTrialEstimate(
      {
        clinicalTrialEstimateId:
          estimateConfirmModalProps.clinicalTrialEstimateId,
        bizNumber,
      },
      {
        onSuccess: () => {
          setEstimateConfirmModalProps(null);
        },
      },
    );
  };
  const [
    clinicalTrialEstimateIdForUserInfo,
    setClinicalTrialEstimateIdForUserInfo,
  ] = useState<number | null>(null);
  const handleShowClientInfoModal = (clinicalTrialEstimateId: number) => {
    setClinicalTrialEstimateIdForUserInfo(clinicalTrialEstimateId);
  };
  const handleSearch = (keyword: string) => {
    if (keyword !== searchKeyword) {
      setSearchKeyword(keyword);
    } else {
      refetchClinicalTrialEstimates();
    }
  };
  useUpdateEffect(() => {
    history.replace(pathname, {
      page,
      searchType,
      searchKeyword,
      isConfirmed,
      publishDuration,
    });
  }, [searchType, searchKeyword, page, isConfirmed, publishDuration]);
  return (
    <ClinicalTrialEstimateListContainer>
      <FilterDrawer
        visible={filterDrawerVisible}
        isConfirmed={isConfirmed}
        onChangeIsConfirmed={setIsConfirmed}
        publishDuration={publishDuration}
        onChangePublishingDuration={setPublishingDuration}
        onClose={() => setFilterDrawerVisible(false)}
        onInitialize={handleInitializeFilter}
      />
      {estimateConfirmModalProps !== null && (
        <EstimateConfirmModal
          onOk={handleConfirmEstimate}
          onClose={() => setEstimateConfirmModalProps(null)}
          userId={estimateConfirmModalProps.userId}
        />
      )}
      {clinicalTrialEstimateIdForUserInfo !== null && (
        <ClientInfoModal
          clinicalTrialEstimateId={clinicalTrialEstimateIdForUserInfo}
          onSuccess={() => {
            refetchClinicalTrialEstimates();
          }}
          onClose={() => setClinicalTrialEstimateIdForUserInfo(null)}
        />
      )}
      {clinicalTrialEstimateIdForEstimateHistory && (
        <EstimateHistoriesModal
          clinicalTrialEstimateId={clinicalTrialEstimateIdForEstimateHistory}
          onClose={() => setClinicalTrialEstimateIdForEstimateHistory(null)}
        />
      )}
      <Typography.Title
        medium
        color="darkNavy"
        align="center"
        gutter={{ bottom: 24 }}
      >
        견적 목록
      </Typography.Title>
      <Row justify="space-between" align="bottom">
        <Col>
          <Row gutter={8} align="middle">
            <Col>
              <Select
                value={searchType}
                onChange={setSearchType}
                style={{ width: 82 }}
              >
                <Select.Option value={EClinicalTrialEstimateSearchType.ALL}>
                  전체
                </Select.Option>
                <Select.Option
                  value={EClinicalTrialEstimateSearchType.CLIENT_COMPANY_NAME}
                >
                  업체명
                </Select.Option>
                <Select.Option
                  value={EClinicalTrialEstimateSearchType.CLIENT_NAME}
                >
                  의뢰자
                </Select.Option>
                <Select.Option
                  value={EClinicalTrialEstimateSearchType.ADMIN_USER_NAME}
                >
                  담당자
                </Select.Option>
              </Select>
            </Col>
            <Col>
              <SearchInput
                defaultValue={searchKeyword}
                placeholder="검색어"
                onSearch={handleSearch}
              />
            </Col>
            <Col flex="auto">
              <TuneIconContainer
                filtering={isConfirmed !== null || publishDuration !== null}
                onClick={() => setFilterDrawerVisible(true)}
              >
                <TuneIcon
                  color={palette.text.darkNavy}
                  style={{
                    cursor: 'pointer',
                    display: 'block',
                    transform: 'rotate(90deg)',
                    width: 20,
                    height: 20,
                  }}
                />
              </TuneIconContainer>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            size="small"
            icon={
              <PostAddIcon
                color="#fff"
                style={{
                  width: 14,
                  height: 14,
                  position: 'relative',
                  top: 2,
                  marginRight: 2,
                }}
              />
            }
            style={{
              border: 'none',
              backgroundColor: palette.darkNavy,
              color: '#fff',
              fontSize: 12,
              fontWeight: 500,
            }}
            onClick={() => {
              history.push(path.clinicalTrial.estimate.select);
            }}
          >
            새 견적 발행
          </Button>
        </Col>
      </Row>
      <Table
        style={{ marginTop: 8 }}
        columns={[
          {
            title: 'No.',
            align: 'center',
            width: 40,
            render: (_, __, index) => totalElements - (10 * (page - 1) + index),
          },
          {
            title: '업체명',
            align: 'center',
            dataIndex: 'clientCompanyName',
            width: 124,
          },
          {
            title: '의뢰자',
            align: 'center',
            dataIndex: 'clientName',
            width: 108,
          },
          {
            title: '시험수',
            align: 'center',
            dataIndex: 'clinicalTrialCount',
            width: 40,
          },
          {
            title: '견적 금액 (원)',
            align: 'center',
            width: 88,
            render: (_, { totalPrice }) => totalPrice.toLocaleString(),
          },
          {
            title: '담당자',
            align: 'center',
            dataIndex: 'adminUserName',
            width: 48,
          },
          {
            title: '견적 발행일',
            align: 'center',
            dataIndex: 'publishDt',
            width: 76,
          },
          {
            title: '견적서',
            align: 'center',
            width: 40,
            render: (_, { estimateUrl }) => (
              <DescriptionIcon
                color={palette.text.darkNavy}
                width={20}
                height={20}
                style={{
                  position: 'relative',
                  top: 4,
                }}
                onClick={() => {
                  window.open(estimateUrl);
                }}
              />
            ),
          },
          {
            align: 'center',
            width: 32,
            render: (_, { status }) =>
              status === EClinicalTrialEstimateStatus.CFM && (
                <Typography.Label
                  type="primary"
                  style={{
                    backgroundColor: palette.primary,
                    color: '#fff',
                    fontSize: 10,
                    minWidth: 24,
                    height: 18,
                    lineHeight: 1.2,
                  }}
                >
                  확정
                </Typography.Label>
              ),
          },
          {
            align: 'center',
            width: 28,
            render: (
              _,
              {
                clinicalTrialEstimateId,
                clientTel,
                status,
                userId,
                clientEmail,
              },
            ) => (
              <MoreActionContainer>
                <MoreIcon
                  color={palette.text.darkNavy}
                  width={20}
                  height={20}
                  style={{
                    display: 'block',
                  }}
                  onClick={() => handleClickMore(clinicalTrialEstimateId)}
                />
                {clinicalTrialEstimateId ===
                  clinicalTrialEstimateIdForMoreAction && (
                  <MoreActionPopup
                    isConfirmed={status === EClinicalTrialEstimateStatus.CFM}
                    onUpdateEstimate={() => {
                      handleUpdateEstimate(clinicalTrialEstimateId);
                    }}
                    onShowUpdateHistory={() => {
                      handleShowUpdateHistoryModal(clinicalTrialEstimateId);
                    }}
                    onConfirmEstimate={() => {
                      handleShowConfirmEstimateModal(
                        clientTel !== null && clientEmail !== null,
                        clinicalTrialEstimateId,
                        userId,
                      );
                    }}
                    onInputClientInfo={() => {
                      handleShowClientInfoModal(clinicalTrialEstimateId);
                    }}
                    onClose={() =>
                      setClinicalTrialEstimateIdForMoreAction(null)
                    }
                  />
                )}
              </MoreActionContainer>
            ),
          },
        ]}
        dataSource={clinicalTrialEstimates}
        pagination={{
          current: page,
          pageSize: 10,
          total: totalElements,
          onChange: (page: number) => setPage(page),
        }}
        rowKey="clinicalTrialEstimateId"
      />
    </ClinicalTrialEstimateListContainer>
  );
};

export default ClinicalTrialEstimateList;
