import { Button, Col, message, Modal, Row, Upload } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Add } from 'assets/icon/add.svg';
import { Typography } from 'components/system';
import FileViewer from 'components/file/FileViewer';
import palette from 'lib/styles/palette';
import FileList from 'components/file/FileList';
import { useUpdateTestCompletion } from 'hooks/clinicalTrial/process/processingEstimateDetail';

const Container = styled.div``;

const StyledUploadDragger = styled(Upload.Dragger)`
  border: 1px solid ${palette.inactive} !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  height: 406px !important;

  .ant-btn {
    width: 104px;
    height: 104px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0;
    color: #727272;

    .material-icons {
      margin-right: 0;
      color: ${palette.warmGray};
    }
  }

  .ant-upload p {
    padding-top: 16px;
    font-size: 10px;
    color: #7e7e7e;
  }
`;

const CompleteTestModal = ({
  visible,
  clinicalTrialProcessItemId,
  onClose,
}: {
  visible: boolean;
  clinicalTrialProcessItemId: number;
  onClose: () => void;
}) => {
  const [previewedFile, setPreviewedFile] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);
  const { updateTestCompletion } = useUpdateTestCompletion();

  const complete = () => {
    if (files.length === 0) {
      return message.warn('시험 레포트를 업로드해 주세요.');
    }

    updateTestCompletion(
      {
        clinicalTrialProcessItemId,
        completionDate: new Date().toISOString().slice(0, 10),
        files,
      },
      {
        onSuccess: () => onClose(),
      },
    );
  };

  return (
    <Modal
      title="시험 완료 처리 - 시험 레포트를 업로드해 주세요. (필수)"
      footer={null}
      width={864}
      visible={visible}
      onCancel={onClose}
    >
      <Container>
        <Row gutter={16}>
          <Col flex={'1 1 50%'}>
            <FileList
              header={
                <Typography.Text type="secondary" gutter={{ bottom: 8 }}>
                  파일 목록
                </Typography.Text>
              }
              upload={
                <StyledUploadDragger
                  multiple
                  beforeUpload={(file) => {
                    setFiles(files.concat(file));
                    return false;
                  }}
                  itemRender={() => null}
                >
                  <Button>
                    <Add />
                    <br />
                    파일 선택
                  </Button>
                  <Typography.Text type="secondary" gutter={{ top: 4 }}>
                    버튼 클릭 혹은 드래그하여
                    <br />
                    파일을 첨부하세요.
                  </Typography.Text>
                </StyledUploadDragger>
              }
              onFileClick={(file) => setPreviewedFile(file)}
              files={files}
              selectedFile={previewedFile}
              style={{ height: 556 }}
              onDelete={(deletedFile) =>
                setFiles(files.filter((file) => file.name !== deletedFile.name))
              }
            />
          </Col>
          <Col flex={'1 1 50%'}>
            <FileViewer bodyStyle={{ height: 556 }} file={previewedFile} />
          </Col>
        </Row>
        <Row
          gutter={8}
          justify="center"
          style={{ marginTop: 44, paddingBottom: 40 }}
        >
          <Col>
            <Button onClick={onClose}>취소</Button>
          </Col>
          {/* TODO : 완료 처리 */}
          <Col>
            <Button type="primary" onClick={complete}>
              확인
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default CompleteTestModal;
