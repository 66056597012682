import moment from 'moment';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  EProgressPeriodType,
  ICompanySalesStat,
  ICountrySalesStat,
  IPeriod,
  IProductRegisterStat,
} from 'types/stats';
import useUpdateEffect from './useUpdateEffect';
import { DATE_FORMAT } from 'lib/consts';
import * as statsApi from 'lib/api/stats';
import { IAPIResponse } from 'types/common';
import { AxiosResponse } from 'axios';

const getDates = (periodType: EProgressPeriodType, isPast: boolean) => {
  const result = [];
  const date = moment()
    .subtract(isPast ? 1 : 0, periodType)
    .startOf(periodType);
  const expireDate = moment(date)
    .endOf(periodType)
    .startOf('day')
    .add(1, 'day');
  while (date.isBefore(expireDate)) {
    result.push(
      date.format(
        periodType === EProgressPeriodType.YEAR ? 'YYYY-MM' : DATE_FORMAT,
      ),
    );
    date.add(1, periodType === EProgressPeriodType.YEAR ? 'month' : 'day');
  }
  return result;
};

export const useSalesStatsProgress = (periodType: EProgressPeriodType) => {
  const [currentPeriod, setCurrentPeriod] = useState<IPeriod>({
    from: moment().startOf('year').format(DATE_FORMAT),
    to: moment().endOf('year').format(DATE_FORMAT),
  });
  const [pastPeriod, setPastPeriod] = useState<IPeriod>({
    from: moment().subtract(1, 'year').startOf('year').format(DATE_FORMAT),
    to: moment().subtract(1, 'year').endOf('year').format(DATE_FORMAT),
  });
  const { data: currentSalesStats = [] } = useQuery(
    ['stats/getCurrentSalesStats', currentPeriod],
    () =>
      statsApi.getPeriodSalesStats(
        currentPeriod,
        periodType === EProgressPeriodType.YEAR ? 'monthly' : 'daily',
      ),
    { select: (res) => res.data.result },
  );
  const { data: pastSalesStats = [] } = useQuery(
    ['stats/getPastSalesStats', pastPeriod],
    () =>
      statsApi.getPeriodSalesStats(
        pastPeriod,
        periodType === EProgressPeriodType.YEAR ? 'monthly' : 'daily',
      ),
    { select: (res) => res.data.result },
  );
  const currentDates = useMemo(() => getDates(periodType, false), [periodType]);
  const pastDates = useMemo(() => getDates(periodType, true), [periodType]);

  useUpdateEffect(() => {
    const currentFrom = moment().startOf(periodType);
    const currentTo = moment().endOf(periodType);
    setCurrentPeriod({
      from: currentFrom.format(DATE_FORMAT),
      to: currentTo.format(DATE_FORMAT),
    });
    setPastPeriod({
      from: currentFrom.subtract(1, periodType).format(DATE_FORMAT),
      to: currentTo.subtract(1, periodType).format(DATE_FORMAT),
    });
  }, [periodType]);

  return useMemo(
    () => ({
      current: {
        dates: currentDates,
        salesStats: currentDates.map(
          (date) =>
            currentSalesStats.find(
              ({ targetDate, targetMonth }) =>
                date === targetDate || date === targetMonth,
            ) || {
              targetDate: '',
              targetMonth: '',
              sumSales: 0,
              countCertFin: 0,
            },
        ),
        period: `${currentPeriod.from} ~ ${currentPeriod.to}`,
        totalSales: currentSalesStats.reduce(
          (total, { sumSales }) => total + sumSales,
          0,
        ),
      },
      past: {
        dates: pastDates,
        salesStats: pastDates.map(
          (date) =>
            pastSalesStats.find(
              ({ targetDate, targetMonth }) =>
                date === targetDate || date === targetMonth,
            ) || {
              targetDate: '',
              targetMonth: '',
              sumSales: 0,
              countCertFin: 0,
            },
        ),
        period: `${pastPeriod.from} ~ ${pastPeriod.to}`,
        totalSales: pastSalesStats.reduce(
          (total, { sumSales }) => total + sumSales,
          0,
        ),
      },
    }),
    [periodType, currentDates, pastDates, pastSalesStats, currentSalesStats],
  );
};

export const useCountryOrCompanySalesStats = (
  type: 'country' | 'company',
  period: IPeriod,
) => {
  const { data: salesStatsResponse } = useQuery<
    | AxiosResponse<IAPIResponse<ICountrySalesStat[]>>
    | AxiosResponse<IAPIResponse<ICompanySalesStat[]>>
  >(['stats/countryOrCompanySalesStats', type, period], () =>
    type === 'country'
      ? statsApi.getCountrySalesStats(period)
      : statsApi.getCompanySalesStats(period),
  );

  return salesStatsResponse?.data.result || [];
};

export const useProductRegisterStats = (
  onSuccessCallback: React.Dispatch<IProductRegisterStat[]>,
) =>
  useMutation(
    (params: { from: string; to: string }) =>
      statsApi.getProductRegisterStats(params),
    {
      onSuccess: (response) => {
        onSuccessCallback(response.data.result);
      },
    },
  );

export const useProductRegisterStatsWithId = (
  onSuccessCallback: React.Dispatch<IProductRegisterStat[]>,
) =>
  useMutation(
    (params: { id?: number; from: string; to: string; isManu: boolean }) =>
      statsApi.getProductRegisterStatsWithId(params),
    {
      onSuccess: (response) => {
        onSuccessCallback(response.data.result);
      },
    },
  );

export const useThisYearCategoryCertificateStats = () =>
  useQuery(
    'stats/getThisYearCategoryCertificateStats',
    statsApi.getThisYearCategoryCertificateStats,
    { select: (res) => res.data.result },
  );

export const useBuyerCertificateStats = (countryId: number) =>
  useQuery(
    ['stats/getBuyerCertificateStats', countryId],
    () => statsApi.getBuyerCertificateStats(countryId),
    { select: (res) => res.data.result },
  );

export const useBuyerDetails = (param: {
  countryId: number;
  buyerId: number;
}) =>
  useQuery(
    ['stats/getBuyerDetails', param],
    () => statsApi.getBuyerDetails(param),
    { select: (res) => res.data.result },
  );

export const useMainStat = () => {
  const {
    data: mainStat = {
      prdRegCount: 0,
      cerFinCount: 0,
      brandCompanyCount: 0,
      manufacturerCount: 0,
      yesterdaySales: 0,
      theDayBeforeYesterday: 0,
      confirmCount: 0,
      depRdyCount: 0,
      conRdyCount: 0,
      yesterdayPrdRdyCount: 0,
      yesterdayConReqCount: 0,
      yesterdayDepCfmCount: 0,
      yesterdayCerFinCount: 0,
    },
  } = useQuery('stats/getMainStat', () => statsApi.getMainStats(), {
    select: (res) => res.data.result,
  });
  return mainStat;
};
