import styled from 'styled-components';

const BarChartIcon = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 22px;

  &::before,
  &::after {
    display: block;
    content: '';
    width: 5px;
    height: 14px;
    background-color: #3785f7;
  }

  &::after {
    height: 9px;
    opacity: 0.7;
    margin-left: 3px;
  }
`;

export default BarChartIcon;
