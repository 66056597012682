import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import produce from 'immer';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import {
  IClinicalTrialProduct,
  IClinicalTrialPackage,
} from 'types/clinicalTrial';
import palette from 'lib/styles/palette';
import { useStores } from 'stores/Context';
import { CircleCheckIcon, RecommendLabel } from '../style';
import { clinicalTrialTypeMap } from 'lib/consts';

const ClinicalTrialPackageContainer = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

const ClinicalTrialPackageDetailContainer = styled.div<{
  selected: boolean;
}>`
  margin-top: 8px;
  background-color: #fff;

  ${({ selected }) =>
    selected
      ? css`
          box-shadow: 0 0 6px 1px rgba(55, 133, 247, 0.5),
            0 0 0 1px ${palette.primary};
        `
      : css`
          box-shadow: 0 0 6px 0 rgba(234, 234, 234, 0.5);
        `}
`;

const ClinicalTrialPackageItemListContainer = styled.div`
  border-right: 2px solid ${palette.gray5};
`;

const ClinicalTrialPackagePriceContainer = styled.div<{
  selected: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  span.anticon-check {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1;
  }
`;

const ClinicalTrialPackageItemContainer = styled.div`
  & + & {
    border-top: 1px solid ${palette.gray5};
  }
`;

const ClinicalTrialPackageItemSelectContainer = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  box-shadow: 0 2px 9px 0 rgba(220, 220, 220, 0.5);
  z-index: 1;
`;

const ClinicalTrialPackageItemSelectOptionWrapper = styled.div`
  position: relative;
`;

const ClinicalTrialPackageItemSelectOptionContainer = styled.div<{
  active?: boolean;
  selected?: boolean;
  multiple?: boolean;
  disabled?: boolean;
}>`
  padding: 12px 24px;
  background-color: #fff;

  ${({ multiple }) =>
    multiple &&
    css`
      cursor: pointer;
    `}

  ${({ active }) =>
    active &&
    css`
      box-shadow: 0 0 0px 1px ${palette.primary} inset;
    `}
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${palette.lightBlue};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${palette.disabled};
    `}
`;

const ClinicalTrialPackageItemChoiceDescription = styled(Typography.Text)`
  padding: 4px 24px 4px 16px;
  background-color: ${palette.gray5};
`;

const ClinicalTrialPackageItem = ({
  clinicalTrialProducts,
  selectedClinicalTrialProduct,
  selectedClinicalTrialProductIds,
  onSelectClinicalTrialProduct,
}: {
  clinicalTrialProducts: IClinicalTrialProduct[];
  selectedClinicalTrialProduct: IClinicalTrialProduct;
  selectedClinicalTrialProductIds: number[];
  onSelectClinicalTrialProduct: (
    clinicalTrialItem: IClinicalTrialProduct,
  ) => void;
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const handleSelectOption = (clinicalTrialItem: IClinicalTrialProduct) => {
    setIsSelectOpen(false);
    onSelectClinicalTrialProduct(clinicalTrialItem);
  };
  const multiple = clinicalTrialProducts.length > 1;
  useEffect(() => {
    if (isSelectOpen) {
      const handleClickOutside = () => {
        setIsSelectOpen(false);
      };
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }
  }, [isSelectOpen]);
  return (
    <ClinicalTrialPackageItemContainer>
      <ClinicalTrialPackageItemSelectOptionWrapper>
        <ClinicalTrialPackageItemSelectOptionContainer
          active={isSelectOpen}
          multiple={multiple}
          onClick={() => {
            if (multiple) {
              setIsSelectOpen((draft) => !draft);
            }
          }}
        >
          <Row justify="space-between" gutter={16}>
            <Col span={12}>
              <Typography.Text type="secondary" color="darkNavy" medium>
                {selectedClinicalTrialProduct.clinicalTrialName}(
                {clinicalTrialTypeMap[selectedClinicalTrialProduct.type]}) -{' '}
                {selectedClinicalTrialProduct.method.methodName}
              </Typography.Text>
            </Col>
            <Col flex="0 0 60px">
              <Typography.Text
                type="secondary"
                color="darkNavy"
                medium
                align="center"
              >
                {selectedClinicalTrialProduct.duration}주
              </Typography.Text>
            </Col>
            <Col flex="0 0 120px">
              <Typography.Text
                type="secondary"
                color="darkNavy"
                medium
                align="right"
              >
                {selectedClinicalTrialProduct.price.toLocaleString()} 원
              </Typography.Text>
            </Col>
            <Col flex="0 0 30px">
              {multiple && (
                <CaretDownOutlined style={{ color: palette.text.darkNavy }} />
              )}
            </Col>
          </Row>
        </ClinicalTrialPackageItemSelectOptionContainer>
        {isSelectOpen && (
          <ClinicalTrialPackageItemSelectContainer>
            {clinicalTrialProducts.map((clinicalTrialProduct) => {
              const selected =
                clinicalTrialProduct === selectedClinicalTrialProduct;
              const disabled =
                !selected &&
                selectedClinicalTrialProductIds.includes(
                  clinicalTrialProduct.clinicalTrialProductId,
                );
              return (
                <ClinicalTrialPackageItemSelectOptionContainer
                  key={clinicalTrialProduct.clinicalTrialProductId}
                  selected={selected}
                  disabled={disabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selected && !disabled) {
                      handleSelectOption(clinicalTrialProduct);
                    }
                  }}
                >
                  <Row gutter={16} justify="space-between">
                    <Col span={12}>
                      <Typography.Text type="secondary" color="darkNavy" medium>
                        {clinicalTrialProduct.clinicalTrialName}(
                        {clinicalTrialTypeMap[clinicalTrialProduct.type]}) -{' '}
                        {clinicalTrialProduct.method.methodName}
                      </Typography.Text>
                    </Col>
                    <Col flex="0 0 60px">
                      <Typography.Text
                        type="secondary"
                        color="darkNavy"
                        medium
                        align="center"
                      >
                        {clinicalTrialProduct.duration}주
                      </Typography.Text>
                    </Col>
                    <Col flex="0 0 120px">
                      <Typography.Text
                        type="secondary"
                        color="darkNavy"
                        medium
                        align="right"
                      >
                        {clinicalTrialProduct.price.toLocaleString()} 원
                      </Typography.Text>
                    </Col>
                    <Col flex="0 0 30px"></Col>
                  </Row>
                </ClinicalTrialPackageItemSelectOptionContainer>
              );
            })}
          </ClinicalTrialPackageItemSelectContainer>
        )}
      </ClinicalTrialPackageItemSelectOptionWrapper>
      {multiple && (
        <ClinicalTrialPackageItemChoiceDescription
          type="quaternary"
          color="disabled"
        >
          {clinicalTrialProducts
            .map(
              ({ clinicalTrialName, type, method }) =>
                `${clinicalTrialName}(${clinicalTrialTypeMap[type]}) - ${method.methodName}`,
            )
            .join(', ')}{' '}
          중 택1
        </ClinicalTrialPackageItemChoiceDescription>
      )}
    </ClinicalTrialPackageItemContainer>
  );
};

const ClinicalTrialPackage = ({
  clinicalTrialPackage,
  isRecommended,
}: {
  clinicalTrialPackage: IClinicalTrialPackage;
  isRecommended?: boolean;
}) => {
  const {
    clinicalTrialPackageId,
    packageName,
    agency: { agencyName, countryName },
    price,
    clinicalTrialPackageProducts,
  } = clinicalTrialPackage;
  const { clinicalTrialStore } = useStores();
  const [
    selectedClinicalTrialProducts,
    setSelectedClinicalTrialProducts,
  ] = useState(
    clinicalTrialPackageProducts.map(
      (clinicalTrialPackageProduct) => clinicalTrialPackageProduct[0],
    ),
  );
  const originalTotalPrice = selectedClinicalTrialProducts
    .map(({ price }) => price)
    .reduce((acc, curr) => acc + curr);

  const isSelected = clinicalTrialStore.getIsSelectedClinicalTrialPackage(
    clinicalTrialPackageId,
  );

  const handleSelectClinicalTrialProduct = (
    clinicalTrialPackageItemIndex: number,
    clinicalTrialProduct: IClinicalTrialProduct,
  ) => {
    setSelectedClinicalTrialProducts((draftItems) =>
      produce(draftItems, (proxyItems) => {
        proxyItems[clinicalTrialPackageItemIndex] = clinicalTrialProduct;
      }),
    );
    if (isSelected) {
      clinicalTrialStore.changeClinicalTrialProduct({
        clinicalTrialPackageId,
        clinicalTrialPackageItemIndex,
        clinicalTrialProduct,
      });
    }
  };

  const handleSelectClinicalTrialPackage = () => {
    if (!isSelected) {
      clinicalTrialStore.selectClinicalTrialPackage({
        clinicalTrialPackage,
        clinicalTrialProducts: selectedClinicalTrialProducts,
      });
    } else {
      clinicalTrialStore.deselectClinicalTrialPackage(clinicalTrialPackage);
    }
  };

  return (
    <ClinicalTrialPackageContainer>
      <Row align="middle" gutter={4}>
        <Col>
          <Typography.Text color="darkNavy" bold>
            {packageName} - {agencyName} ({countryName})
          </Typography.Text>
        </Col>
        {isRecommended && (
          <Col>
            <RecommendLabel>추천</RecommendLabel>
          </Col>
        )}
      </Row>

      <ClinicalTrialPackageDetailContainer selected={isSelected}>
        <Row align="stretch">
          <Col span={18}>
            <ClinicalTrialPackageItemListContainer>
              {clinicalTrialPackageProducts.map(
                (clinicalTrialProducts, index) => (
                  <ClinicalTrialPackageItem
                    key={nanoid()}
                    clinicalTrialProducts={clinicalTrialProducts}
                    selectedClinicalTrialProductIds={selectedClinicalTrialProducts.map(
                      ({ clinicalTrialProductId }) => clinicalTrialProductId,
                    )}
                    selectedClinicalTrialProduct={
                      selectedClinicalTrialProducts[index]
                    }
                    onSelectClinicalTrialProduct={(clinicalTrialProduct) => {
                      handleSelectClinicalTrialProduct(
                        index,
                        clinicalTrialProduct,
                      );
                    }}
                  />
                ),
              )}
            </ClinicalTrialPackageItemListContainer>
          </Col>
          <Col span={6}>
            <ClinicalTrialPackagePriceContainer
              selected={isSelected}
              onClick={() => {
                handleSelectClinicalTrialPackage();
              }}
            >
              <CircleCheckIcon checked={isSelected} />
              <Typography.Text
                type="secondary"
                color="darkNavy"
                medium
                style={{ textDecoration: 'line-through' }}
              >
                {originalTotalPrice.toLocaleString()} 원
              </Typography.Text>
              <Typography.Title type="secondary" color="primary" bold>
                {price.toLocaleString()}원
              </Typography.Title>
            </ClinicalTrialPackagePriceContainer>
          </Col>
        </Row>
      </ClinicalTrialPackageDetailContainer>
    </ClinicalTrialPackageContainer>
  );
};

export default observer(ClinicalTrialPackage);
