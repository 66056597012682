import { useState } from 'react';
import {
  FilePdfOutlined,
  FileSearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Table,
  Typography as Typo,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { Moment } from 'moment';

import { useStores } from 'stores/Context';
import SearchInput from 'components/form/SearchInput';
import { Typography } from 'components/system';
import path from 'lib/path';
import {
  EClinicalTrialProcessingEstimateStatus,
  EProcessingEstimateSearchType,
  IClinicalTrialProcessingEstimate,
} from 'types/clinicalTrial/process/processingEstimate';
import { useProcessingEstimates } from 'hooks/clinicalTrial/process/processingEstimate';
import {
  ClientInfoModal,
  EstimateHistoriesModal,
  EstimateDepositCheckModal,
  EstimateDepositHistoryModal,
} from 'components/clinicalTrial/process/modal/index';

const searchTypeOptions = [
  { label: '전체', value: EProcessingEstimateSearchType.ALL },
  {
    label: '업체명',
    value: EProcessingEstimateSearchType.CLIENT_COMPANY_NAME,
  },
  {
    label: '견적서 번호',
    value: EProcessingEstimateSearchType.ESTIMATE_NO,
  },
  { label: '의뢰자', value: EProcessingEstimateSearchType.CLIENT_NAME },
  { label: '담당자', value: EProcessingEstimateSearchType.ADMIN_USER_NAME },
];

const statusSearchTypeOptions = [
  { value: '', label: '전체' },
  {
    value: EClinicalTrialProcessingEstimateStatus.CFM,
    label: '진행중',
  },
  { value: EClinicalTrialProcessingEstimateStatus.COM, label: '완료' },
  { value: EClinicalTrialProcessingEstimateStatus.STP, label: '중단' },
];

const ClinicalTrialProcessingEstimate = () => {
  const [searchType, setSearchType] = useState<EProcessingEstimateSearchType>(
    EProcessingEstimateSearchType.ALL,
  );
  const [statusSearchType, setStatusSearchType] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [idForClientInfoModal, setIdForClientInfoModal] = useState<
    number | null
  >(null);
  const [
    idForEstimateHistoriesModal,
    setIdForEstimateHistoriesModal,
  ] = useState<number | null>(null);
  const [
    idForEstimateDepositHistory,
    setIdForEstimateDepositHistory,
  ] = useState<number | null>(null);
  const [
    estimate,
    setEstimate,
  ] = useState<IClinicalTrialProcessingEstimate | null>(null);
  const [confirmationDate, setConfirmationDate] = useState<Moment | null>(null);
  const [pageNo, setPageNo] = useState(1);
  const { processingEstimates, getLoading, refetch } = useProcessingEstimates({
    ...((searchType === EProcessingEstimateSearchType.ALL || searchKeyword) &&
      searchType && { searchKey: searchType }),
    ...(searchKeyword && { searchKeyword }),
    ...(statusSearchType && { status: statusSearchType }),
    ...(confirmationDate && {
      confirmationDate: confirmationDate.format('YYYY-MM-DD'),
    }),
    pageNo,
  });

  const handleRefresh = () => {
    setPageNo(1);
    setSearchType(EProcessingEstimateSearchType.ALL);
    setSearchKeyword('');
    setStatusSearchType('');
    setConfirmationDate(null);
    setTimeout(refetch);
  };

  const getLabelType = (status: EClinicalTrialProcessingEstimateStatus) => {
    switch (status) {
      case EClinicalTrialProcessingEstimateStatus.CFM:
        return 'primary';
      case EClinicalTrialProcessingEstimateStatus.COM:
        return 'success';
      case EClinicalTrialProcessingEstimateStatus.STP:
        return 'disabled';
    }
  };

  const history = useHistory();
  const { etcStore } = useStores();
  const columns: ColumnsType<IClinicalTrialProcessingEstimate> = [
    {
      title: '업체명',
      align: 'center',
      dataIndex: 'clientCompanyName',
    },
    {
      title: '견적서 번호',
      dataIndex: 'estimateNo',
      align: 'center',
      render: (
        _,
        { clientCompanyName, estimateNo, clinicalTrialEstimateId },
      ) => (
        <Typo.Link
          underline
          onClick={() => {
            history.push(
              `${path.clinicalTrial.process.detail}/${clinicalTrialEstimateId}`,
              {
                clientCompanyName,
                estimateNo,
              },
            );
          }}
        >
          {estimateNo}
        </Typo.Link>
      ),
    },

    {
      title: '진행 확정일',
      align: 'center',
      render: (_, { confirmationDate }) => confirmationDate.slice(0, 10),
    },
    {
      title: '의뢰자',
      align: 'center',
      render: (_, { clientName, clinicalTrialEstimateId }) => (
        <Typo.Link
          underline
          onClick={() => setIdForClientInfoModal(clinicalTrialEstimateId)}
        >
          {clientName}
        </Typo.Link>
      ),
    },
    {
      title: '시험수',
      align: 'center',
      dataIndex: 'clinicalTrialCount',
    },
    {
      title: '담당자',
      align: 'center',
      dataIndex: 'adminUserName',
    },
    {
      title: '견적서',
      align: 'center',
      render: (_, { estimateUrl }) => (
        <FilePdfOutlined
          style={{ fontSize: 16, cursor: 'pointer' }}
          onClick={() => {
            etcStore.previewedFiles = [{ url: estimateUrl }];
          }}
        />
      ),
    },
    {
      title: '견적 수정 이력',
      align: 'center',
      render: (_, { clinicalTrialEstimateId }) => (
        <FileSearchOutlined
          style={{ cursor: 'pointer', fontSize: 16 }}
          onClick={() =>
            setIdForEstimateHistoriesModal(clinicalTrialEstimateId)
          }
        />
      ),
    },
    {
      title: '입금 처리 / 견적 금액',
      align: 'center',
      render: (_, { paidAmount, totalPrice }) => (
        <>
          <Typography.Text type="secondary" inline bold>
            {paidAmount ? paidAmount.toLocaleString() : 0}원
          </Typography.Text>{' '}
          /{' '}
          <Typography.Text
            type="secondary"
            inline
            bold={paidAmount === totalPrice}
          >
            {totalPrice.toLocaleString()} 원
          </Typography.Text>
        </>
      ),
    },
    {
      title: '입금 상태',
      align: 'center',
      render: (_, { paidAmount, totalPrice }) => (
        <Typography.Text type="secondary" bold>
          {paidAmount === null || paidAmount === 0
            ? '미납'
            : totalPrice === paidAmount
            ? '완납'
            : '분납'}
        </Typography.Text>
      ),
    },
    {
      title: '입금 확인',
      align: 'center',
      render: (_, estimate) => (
        <Row align="middle" justify="center" gutter={8}>
          <Col>
            {!estimate.isDepositFinish ? (
              <Button
                onClick={() => setEstimate(estimate)}
                disabled={
                  estimate.status === EClinicalTrialProcessingEstimateStatus.STP
                }
              >
                입금 확인
              </Button>
            ) : (
              <Typography.Text type="secondary" inline>
                입금 완료
              </Typography.Text>
            )}
          </Col>
          <FileSearchOutlined
            style={{ cursor: 'pointer', fontSize: 16 }}
            onClick={() =>
              setIdForEstimateDepositHistory(estimate.clinicalTrialEstimateId)
            }
          />
        </Row>
      ),
    },
    {
      title: '진행 현황',
      align: 'center',
      render: (_, { status }) => (
        <Typography.Label type={getLabelType(status)}>
          {statusSearchTypeOptions.find(({ value }) => value === status)
            ?.label || '-'}
        </Typography.Label>
      ),
    },
    {
      title: '시험 (예정)완료일',
      align: 'center',
      dataIndex: 'completionDDay',
      render: (_, { status, completionDueDDay, completionDate }) => {
        if (status === EClinicalTrialProcessingEstimateStatus.STP) {
          return (
            <Typography.Text type="secondary" color="disabled">
              중단
            </Typography.Text>
          );
        }
        if (status === EClinicalTrialProcessingEstimateStatus.COM) {
          return completionDate;
        }
        return typeof completionDueDDay === 'number' ? (
          <Typography.Text
            type="secondary"
            color={completionDueDDay > 7 ? 'black' : 'error'}
          >
            {completionDueDDay === 0
              ? 'D-DAY'
              : `D${
                  completionDueDDay > 0
                    ? `-${completionDueDDay}`
                    : `+${-completionDueDDay}`
                }`}
          </Typography.Text>
        ) : (
          '-'
        );
      },
    },
  ];

  const handleSelectSearchType = (type: EProcessingEstimateSearchType) => {
    setSearchType(type);
    if (type === EProcessingEstimateSearchType.ALL) {
      setSearchKeyword('');
    }
  };

  return (
    <>
      {idForClientInfoModal && (
        <ClientInfoModal
          clinicalTrialEstimateId={idForClientInfoModal}
          onClose={() => setIdForClientInfoModal(null)}
        />
      )}
      {idForEstimateHistoriesModal && (
        <EstimateHistoriesModal
          clinicalTrialEstimateId={idForEstimateHistoriesModal}
          onClose={() => setIdForEstimateHistoriesModal(null)}
        />
      )}
      {estimate && (
        <EstimateDepositCheckModal
          estimate={estimate}
          onClose={() => setEstimate(null)}
        />
      )}
      {idForEstimateDepositHistory && (
        <EstimateDepositHistoryModal
          clinicalTrialEstimateId={idForEstimateDepositHistory}
          onClose={() => setIdForEstimateDepositHistory(null)}
        />
      )}
      <Row justify="end" style={{ marginBottom: 8 }} gutter={8}>
        <Col flex="auto">
          <Button onClick={handleRefresh} icon={<SyncOutlined />}>
            새로 고침
          </Button>
        </Col>
        <Col>
          <Select
            value={searchType}
            onSelect={handleSelectSearchType}
            options={searchTypeOptions}
            style={{ width: 120, marginRight: 4 }}
          />
          <SearchInput
            defaultValue={searchKeyword}
            onSearch={setSearchKeyword}
            disabled={searchType === EProcessingEstimateSearchType.ALL}
          />
        </Col>
        <Col>
          <Form.Item label="진행현황" colon={false} style={{ marginBottom: 0 }}>
            <Select
              value={statusSearchType}
              onSelect={setStatusSearchType}
              style={{ width: 120 }}
              options={statusSearchTypeOptions}
            ></Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label="진행 확정일"
            colon={false}
            style={{ marginBottom: 0 }}
          >
            <DatePicker
              value={confirmationDate}
              onChange={(date) => setConfirmationDate(date)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={processingEstimates?.content}
        loading={getLoading}
        rowKey="clinicalTrialEstimateId"
        pagination={{
          current: pageNo,
          pageSize: processingEstimates?.size,
          total: processingEstimates?.totalElements,
          onChange: setPageNo,
        }}
      />
    </>
  );
};

export default ClinicalTrialProcessingEstimate;
