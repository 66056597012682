import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Table, Typography, Select, Modal, Button } from 'antd';

import { useChangeCertificateStatus } from 'hooks/certificate';
import path from 'lib/path';
import { useStores } from 'stores/Context';
import {
  ECertificateStatus,
  ICertificate,
  ICertificateData,
} from 'types/certificate';
import { ColumnsType } from 'antd/lib/table';

const OngoingCertificateTable = ({
  page,
  onSetCertificateBeFinished,
  onSetCertificateForEstimateAdd,
  onChangePage,
  certificateData,
}: {
  page: number;
  onSetCertificateBeFinished: React.Dispatch<
    React.SetStateAction<ICertificate | null>
  >;
  onSetCertificateForEstimateAdd: React.Dispatch<
    React.SetStateAction<ICertificate | null>
  >;
  onChangePage: (page: number) => void;
  certificateData?: ICertificateData;
}) => {
  const history = useHistory();
  const { certificateStore } = useStores();
  const {
    content: ongoingCertificates,
    totalElements: ongoingTotal,
    size: ongoingSize,
  } = {
    ...certificateData,
  };
  const changeCertificateStatusMutation = useChangeCertificateStatus(false);
  const certificateStatusOptions = useMemo(
    () => [
      { value: ECertificateStatus.REG_FIN, label: '접수 완료' },
      { value: ECertificateStatus.CHK_DOC, label: '서류 검토중' },
      { value: ECertificateStatus.CHK_SAM, label: '샘플 검역중' },
      { value: ECertificateStatus.CER_FIN, label: '인증 완료' },
      {
        value: ECertificateStatus.CER_CAN,
        label: <Typography.Text type="danger">진행 취소</Typography.Text>,
      },
    ],
    [],
  );
  const changeStatus = useCallback(
    (certTargetId: number, status: ECertificateStatus): void => {
      if (status === ECertificateStatus.CER_FIN) {
        onSetCertificateBeFinished(
          ongoingCertificates?.find(
            (certificate) => certificate.certTargetId === certTargetId,
          ) || null,
        );
        return;
      }
      Modal.confirm({
        content:
          status === ECertificateStatus.CER_CAN
            ? '정말로 진행을 취소하시겠습니까?'
            : '정말로 변경하시겠습니까?',
        onOk: () => {
          changeCertificateStatusMutation.mutate({
            certTargetId,
            status,
          });
        },
      });
    },
    [changeCertificateStatusMutation, ongoingCertificates],
  );

  const ongoingColumns: ColumnsType<ICertificate> = useMemo(
    () => [
      {
        title: '인증 신청 번호',
        dataIndex: 'certRequestNo',
        align: 'center',
      },
      {
        title: '제품명',
        align: 'center',
        render: (_, certificate) => (
          <Typography.Link
            underline
            onClick={() => {
              certificateStore.setCurrentCertificate(certificate);
              history.push(
                `${path.certificate.root}/${certificate.certTargetId}`,
              );
            }}
          >
            {`${certificate.estimateTarget.productDetails[0].productNameEn} ${
              certificate.estimateTarget.netWeight
                ? `(${certificate.estimateTarget.netWeight} ${certificate.estimateTarget.netWeightUnit})`
                : '( - )'
            }`}
          </Typography.Link>
        ),
      },
      {
        title: '국가',
        dataIndex: ['estimateTarget', 'countryName'],
        align: 'center',
      },
      {
        title: '접수일',
        align: 'center',
        render: (_, { registerDt }) => registerDt.slice(0, 10),
      },
      {
        title: '진행 상태',
        align: 'center',
        render: (
          _,
          {
            certTargetId,
            status,
            estimate: { isDepositFinish, isReceiveContractFinish },
            etcStatus,
          },
        ) => (
          <Select
            options={
              !isDepositFinish || !isReceiveContractFinish || etcStatus
                ? certificateStatusOptions.filter(
                    ({ value }) => value !== ECertificateStatus.CER_FIN,
                  )
                : certificateStatusOptions
            }
            value={status}
            onChange={(status) => changeStatus(certTargetId, status)}
          />
        ),
      },
      {
        title: '입금',
        align: 'center',
        render: (_, { estimate: { isDepositFinish } }) =>
          isDepositFinish ? (
            <Typography.Text>입금 완료</Typography.Text>
          ) : (
            <Typography.Text type="danger">입금 대기중</Typography.Text>
          ),
      },
      {
        title: '계약서',
        align: 'center',
        render: (_, { estimate: { isReceiveContractFinish } }) =>
          isReceiveContractFinish ? (
            <Typography.Text>수령 완료</Typography.Text>
          ) : (
            <Typography.Text type="danger">수령 대기중</Typography.Text>
          ),
      },
      {
        title: '비고',
        align: 'center',
        render: (_, { etcStatus, etcStatusString }) =>
          etcStatus && (
            <Typography.Text type="danger">{etcStatusString}</Typography.Text>
          ),
      },
      {
        title: '',
        align: 'center',
        render: (_, certificate) => (
          <Button onClick={() => onSetCertificateForEstimateAdd(certificate)}>
            추가 견적
          </Button>
        ),
      },
    ],
    [certificateStatusOptions, certificateStore, history, changeStatus],
  );

  return (
    <Table
      columns={ongoingColumns}
      dataSource={ongoingCertificates}
      rowKey={({ certTargetId }) => certTargetId}
      pagination={{
        current: page,
        pageSize: ongoingSize,
        total: ongoingTotal,
        onChange: onChangePage,
      }}
    />
  );
};

export default OngoingCertificateTable;
