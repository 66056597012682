import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Table,
  Typography,
} from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import Modal from 'antd/lib/modal/Modal';
import FooterBox from 'components/FooterBox';
import { useAddEstimate } from 'hooks/estimate';
import { useState } from 'react';
import { useStores } from 'stores/Context';
import { ICertificate } from 'types/certificate';
import { IEstimateAdditionalForm } from 'types/estimate';

interface IEstimateAddModalProps {
  certificate: ICertificate;
  onClose: () => void;
}
const EstimateAddModal: React.FC<IEstimateAddModalProps> = ({
  certificate,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { certTargetId, certRequestNo, estimateTarget } = { ...certificate };
  const { productDetails } = estimateTarget;
  const [isPreview, setIsPreview] = useState(false);
  const { etcStore } = useStores();
  const { mutate: addEstimateMutate, isLoading } = useAddEstimate({
    onSuccess: ({ result: estimateUrl }) => {
      if (isPreview) {
        etcStore.previewedFiles = [{ url: estimateUrl }];
      } else {
        message.info('추가 견적이 발행되었습니다.');
        onClose();
      }
    },
  });
  const addEstimate = ({
    estimateAdditionals,
  }: {
    estimateAdditionals: IEstimateAdditionalForm[];
  }) => {
    if (!estimateAdditionals.length)
      return message.warn('1개 이상의 항목을 입력해 주세요.');
    addEstimateMutate({
      certTargetId,
      estimateAdditionals: estimateAdditionals.map((estimateAdditional) => ({
        ...estimateAdditional,
        unitPrice: Number(
          estimateAdditional.unitPrice.toString().replace(/,/, ''),
        ),
      })),
      isPreview,
    });
  };
  const previewEstimate = () => {
    setIsPreview(true);
    form.submit();
  };
  const publishEstimate = () => {
    setIsPreview(false);
    form.submit();
  };

  return (
    <Modal
      width={800}
      visible={true}
      title="추가견적 발행"
      footer={null}
      maskClosable={false}
      onCancel={() => onClose()}
    >
      <Typography.Paragraph strong>
        {certRequestNo} / {productDetails[0].productNameEn}
      </Typography.Paragraph>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        onFinish={addEstimate}
        colon={false}
      >
        <Form.List name="estimateAdditionals" initialValue={[{}]}>
          {(fields, { add, remove }) => {
            const columns = [
              {
                title: '품목명',
                align: 'center' as const,
                render: (field: FormListFieldData) => (
                  <Form.Item
                    {...field}
                    name={[field.name, 'itemName']}
                    rules={[{ required: true, message: '필수 입력 항목' }]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input />
                  </Form.Item>
                ),
              },
              {
                title: '단가',
                align: 'center' as const,
                render: (field: FormListFieldData) => (
                  <Form.Item
                    {...field}
                    name={[field.name, 'unitPrice']}
                    rules={[{ required: true, message: '필수 입력 항목' }]}
                    style={{ marginBottom: 0 }}
                    normalize={(value: string) =>
                      Number(value.replace(/\D/g, '')).toLocaleString()
                    }
                  >
                    <Input maxLength={8} style={{ width: 100 }} step={1000} />
                  </Form.Item>
                ),
              },
              {
                title: '수량',
                align: 'center' as const,
                render: (field: FormListFieldData) => (
                  <Form.Item
                    {...field}
                    name={[field.name, 'quantity']}
                    rules={[{ required: true, message: '필수 입력 항목' }]}
                    style={{ marginBottom: 0 }}
                  >
                    <InputNumber min={0} maxLength={2} />
                  </Form.Item>
                ),
              },
              {
                title: '비고',
                align: 'center' as const,
                render: (field: FormListFieldData) => (
                  <Form.Item
                    {...field}
                    name={[field.name, 'description']}
                    style={{ marginBottom: 0 }}
                  >
                    <Input />
                  </Form.Item>
                ),
              },
              {
                render: (field: FormListFieldData) => (
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                ),
              },
            ];
            return (
              <>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={fields}
                  pagination={false}
                  bordered
                />
                <Form.Item style={{ marginTop: 8 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    항목 추가
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <FooterBox>
          <Button loading={isPreview && isLoading} onClick={previewEstimate}>
            미리보기
          </Button>
          <Button
            type="primary"
            loading={!isPreview && isLoading}
            onClick={publishEstimate}
          >
            발행 하기
          </Button>
        </FooterBox>
      </Form>
    </Modal>
  );
};

export default EstimateAddModal;
