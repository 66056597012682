import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import palette from 'lib/styles/palette';
import styled from 'styled-components';
import InfoIcon from '../icon/InfoIcon';

interface IProps {
  children: React.ReactChild;
  icon?: React.ReactChild;
  trigger?: 'hover' | 'click';
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
}

const TipBlock = styled.div`
  position: relative;
  line-height: 22px;
`;

const IconWrap = styled.div``;

const TipBodyBlock = styled.div<{
  $visible: boolean;
  $trigger: 'hover' | 'click';
}>`
  width: auto;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transform-origin: left top;
  transform: scale(${({ $visible }) => ($visible ? 1 : 0.6)});
  position: absolute;
  top: 0;
  left: calc(100% + 8px);
  transition: opacity 0.2s ease, transform 0.1s ease;
  color: ${palette.text.black};
  min-width: 50px;
  z-index: 1;
  white-space: nowrap;
  padding: 12px 16px;
  border: 1px solid ${palette.primary};
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(162, 162, 162, 0.23);
  font-size: 12px;

  .anticon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    cursor: pointer;
    font-size: 10px;
    color: ${palette.primary};
  }
`;

const Tip = ({
  children,
  icon,
  trigger = 'hover',
  style,
  bodyStyle,
}: IProps) => {
  const [visible, setVisible] = useState(false);
  return (
    <TipBlock style={style}>
      <IconWrap
        onClick={() => {
          trigger === 'click' && setVisible((draft) => !draft);
        }}
        onMouseEnter={() => trigger === 'hover' && setVisible(true)}
        onMouseLeave={() => trigger === 'hover' && setVisible(false)}
      >
        {icon ? icon : <InfoIcon />}
      </IconWrap>
      <TipBodyBlock $visible={visible} $trigger={trigger} style={bodyStyle}>
        {children}
        {trigger === 'click' && (
          <CloseOutlined
            onClick={(e) => {
              e.stopPropagation();
              trigger === 'click' && setVisible(false);
            }}
          />
        )}
      </TipBodyBlock>
    </TipBlock>
  );
};

export default Tip;
