import { EClinicalTrialType } from 'types/clinicalTrial';
import { ECompanySearchType } from 'types/company';

const consts = {
  message: {
    REQUIRED_FIELD: '필수 항목',
    REQUIRED_FILE: '파일을 업로드해 주세요.',
    REQUIRED_FILES: '하나 이상의 파일을 업로드해 주세요.',
    REQUIRE_ONE_OR_MORE_FILES: '최소 한 개 이상의 파일이 필요합니다.',
    SHOULD_DUP_CHECK: '중복확인 버튼을 눌러주세요.',
    DUPLICATE_FILE_NAME: '같은 이름의 파일은 업로드 할 수 없습니다.',
    INVALID_DATE_FORMAT: '올바르지 않은 날짜 형식',
    INVALID_EMAIL_FORMAT: '올바르지 않은 이메일 형식',
    SHOULD_EXCEPT_KOREAN_OR_ALPHABET: '한글 또는 알파벳 입력 불가',
    SHOULD_EXCEPT_KOREAN: '한글 입력 불가',
    SHOULD_EXCEPT_ALPHABET: '알파벳 입력 불가',
    SHOULD_NUMBER: '숫자만 입력 가능',
    SHOULD_ALPHABET_OR_SPACE: '알파벳 또는 공백만 입력 가능',
    PHONE_FORMAT: '숫자, 최소 8글자 ~ 최대 12글자',
    NO_NEED_TO_UPDATE: '수정할 내역이 없습니다.',
    MOBILE_PHONE_FORMAT: '숫자, 최소 10글자 ~ 최대 11글자',
  },
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const searchTypeOptions = [
  { label: '회사명(국문)', value: ECompanySearchType.COMPANY_NAME_KO },
  { label: '회사명(영문)', value: ECompanySearchType.COMPANY_NAME_EN },
  { label: '사업자번호', value: ECompanySearchType.BIZ_NUMBER },
  { label: '대표자명', value: ECompanySearchType.CEO_NAME },
];

export const clinicalTrialTypeMap: {
  [key in keyof typeof EClinicalTrialType]: string;
} = {
  [EClinicalTrialType.FUNCTIONAL]: '기능성',
  [EClinicalTrialType.STABILITY]: '안전성',
  [EClinicalTrialType.VALIDITY]: '유효성',
  [EClinicalTrialType.IN_VITRO]: 'In-vitro',
};

export default consts;
