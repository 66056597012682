import client from 'lib/api/client';
import { createFormData } from 'lib/file';
import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import {
  ECompanyStatus,
  ICompany,
  IBuyer,
  ICompanyStatus,
  IProductBuyer,
  ICompanyBasic,
  IFactory,
  ECompanyType,
  ICompanyUpdate,
} from 'types/company';

export const getCompanies = async ({
  page = 1,
  companyId,
  companyType,
  companyNameKo,
  companyNameEn,
  bizNumber,
  ceoNameKo,
  statuses,
}: {
  page?: number;
  companyId?: number;
  companyType?: ECompanyType | 'ALL';
  companyNameKo?: string;
  companyNameEn?: string;
  bizNumber?: string;
  ceoNameKo?: string;
  statuses?: ECompanyStatus[];
}) => {
  const res = await client.post<IAPIPageableResponse<ICompany[]>>(
    `/admin/company/all/${page}/10/registerDt/D`,
    {
      ...(statuses && { statuses }),
      ...(companyId && { companyId }),
      ...(companyType && companyType !== 'ALL' && { companyType }),
      ...(companyNameKo && { companyNameKo }),
      ...(companyNameEn && { companyNameEn }),
      ...(bizNumber && { bizNumber }),
      ...(ceoNameKo && { ceoNameKo }),
    },
  );
  return res.data;
};

export const getCompany = (companyId: number) =>
  client.get<IAPIResponse<ICompany>>(`/admin/companies/${companyId}`);

export const updateCompany = ({ companyId, ...rest }: ICompanyUpdate) =>
  client.post(`/admin/company/patch/${companyId}`, createFormData(rest));

export const getCompanyBasics = ({
  companyNameKo,
  isManufacturer,
}: {
  companyNameKo: string;
  isManufacturer: boolean;
}) =>
  client.post<IAPIResponse<ICompanyBasic[]>>(
    '/company/name/auto',
    `companyNameKo=${companyNameKo}&isManufacturer=${isManufacturer}`,
  );

export const updateCompanyStatus = (params: ICompanyStatus) =>
  client.patch('/admin/company/status', params);

export const getBuyers = async ({
  page,
  companyNameKo,
  companyNameEn,
  countryId,
  companyName,
}: {
  page: number;
  companyNameKo?: string;
  companyNameEn?: string;
  countryId?: number;
  companyName?: string;
}) => {
  const res = await client.post<IAPIPageableResponse<IBuyer[]>>(
    `/admin/buyer/all/${page}/10/registerDt/D`,
    {
      ...(companyNameKo && { companyNameKo }),
      ...(companyNameEn && { companyNameEn }),
      ...(countryId && { countryId }),
      ...(companyName && { companyName }),
    },
  );
  return res.data;
};

export const getProductBuyers = async ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const res = await client.get<IAPIResponse<IProductBuyer[]>>(
    `/product-buyers/${productId}/${countryId}`,
  );
  return res.data;
};

export const getAllBuyers = () => {
  return client.get<IAPIResponse<IBuyer[]>>('/admin/buyer/all');
};

export const getFactories = (companyId: number) =>
  client.get<IAPIResponse<IFactory[]>>(`/company/factories/${companyId}`);

export const getMaterialFactories = (companyId: number) =>
  client.get<IAPIResponse<IFactory[]>>(`/v2/company/factories/${companyId}`);
