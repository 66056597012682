import { Spin } from 'antd';
import styled from 'styled-components';

import { useSeparateWasteCategoryIds } from 'hooks/certificate';
import { Typography } from 'components/system';

const Wrapper = styled.div`
  text-align: center;
  padding-top: 24px;
`;

const plasticOptions = [
  { label: '용기', id: 2 },
  { label: '튜브', id: 3 },
  { label: '캡', id: 4 },
  { label: '마스크 시트류', id: 5 },
  { label: '펌프', id: 6 },
];

const paperOptions = [
  { label: '라벨', id: 8 },
  { label: '단상자', id: 9 },
  { label: '봉인씰', id: 10 },
];

const SeparateWasteModalContent = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const {
    data: separateWasteCategoryIds = [],
    isLoading,
  } = useSeparateWasteCategoryIds({
    productId,
    countryId,
  });
  const plastics = plasticOptions.filter(({ id }) =>
    separateWasteCategoryIds.includes(id),
  );

  const papers = paperOptions.filter(({ id }) =>
    separateWasteCategoryIds.includes(id),
  );
  return (
    <Spin spinning={isLoading}>
      {plastics.length > 0 && (
        <Wrapper>
          플라스틱:{' '}
          <Typography.Text type="secondary" medium inline>
            {plastics.map(({ label }) => label).join(', ')}
          </Typography.Text>
        </Wrapper>
      )}
      {papers.length > 0 && (
        <Wrapper>
          종이:{' '}
          <Typography.Text type="secondary" medium inline>
            {papers.map(({ label }) => label).join(', ')}
          </Typography.Text>
        </Wrapper>
      )}
    </Spin>
  );
};

export default SeparateWasteModalContent;
