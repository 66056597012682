import styled from 'styled-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Card, Empty, Typography } from 'antd';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';

const PreviewCard = styled(Card)`
  border: none;

  .ant-card-head {
    margin: 0;
    min-height: 28px;
    height: 28px;
    border-bottom: none;

    .ant-card-head-title {
      font-size: 14px;
      padding: 0px;
    }
  }

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d3d3d3;
    padding: 16px;
    height: 556px;
    overflow: hidden;
  }

  .ant-empty {
    position: absolute;
    top: 45%;
    left: 40%;
  }
`;

const PreviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Navigator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ViewSectionContainer = styled.div`
  padding: 16px;
  overflow-y: auto;
`;

const ViewSection = ({
  file,
  pageNumber,
  onLoadSuccess,
}: {
  file: any;
  pageNumber: number;
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
}) => {
  if (file) {
    if (
      (typeof file === 'string' && file.endsWith('.pdf')) ||
      file.type === 'application/pdf'
    ) {
      return (
        <Document
          file={file}
          onLoadSuccess={onLoadSuccess}
          onLoadError={console.error}
        >
          <Page pageNumber={pageNumber} scale={2} />
        </Document>
      );
    }
    if (typeof file === 'string') {
      return <img src={file} alt="" style={{ width: '100%' }} />;
    }
    return (
      <Document
        file={file.url}
        onLoadSuccess={onLoadSuccess}
        onLoadError={console.error}
      >
        <Page pageNumber={pageNumber} scale={2} />
      </Document>
    );
  } else {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
};

const FileViewer = ({
  title = '미리보기',
  file,
  style,
  bodyStyle,
}: {
  title?: React.ReactNode;
  file?: File | string;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onLoadSuccess: ({ numPages }: { numPages: number }) => void = ({
    numPages,
  }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  return (
    <PreviewCard
      title={
        <PreviewHeader>
          <Typography.Text>{title}</Typography.Text>
          {file &&
            (typeof file === 'string' || file.type === 'application/pdf') &&
            numPages !== null &&
            numPages > 1 && (
              <Navigator>
                <LeftOutlined
                  onClick={() =>
                    pageNumber > 1 && setPageNumber(pageNumber - 1)
                  }
                />
                <span style={{ margin: '0 8px', paddingBottom: '2px' }}>
                  {pageNumber} / {numPages} 페이지
                </span>
                <RightOutlined
                  onClick={() =>
                    pageNumber < numPages && setPageNumber(pageNumber + 1)
                  }
                />
              </Navigator>
            )}
        </PreviewHeader>
      }
      style={style}
    >
      <ViewSectionContainer style={bodyStyle}>
        <ViewSection
          file={file}
          pageNumber={pageNumber}
          onLoadSuccess={onLoadSuccess}
        />
      </ViewSectionContainer>
    </PreviewCard>
  );
};

export default FileViewer;
