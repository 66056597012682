import { Card, Col, Row } from 'antd';
import styled from 'styled-components';
import { ReactChart } from 'chartjs-react';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { RightOutlined, TableOutlined } from '@ant-design/icons';
import { ChartOptions } from 'chart.js';
import { ChartData } from 'chart.js';
import { useQuery } from 'react-query';
import * as statsApi from 'lib/api/stats';
import { useMemo } from 'react';
import { useState } from 'react';
import CountryCertificateDetailModal from './CountryCertificateDetailModal';
import BarChartIcon from 'components/system/icon/BarChartIcon';

const CountryCertificateChartBlock = styled(Card)`
  align-items: center;

  .ant-card-body {
    padding: 36px 56px;
  }
`;

const ShowAllBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CountryCertificateChart = () => {
  const [
    countryCertificateDetailModalVisible,
    setCountryCertificateDetailModalVisible,
  ] = useState(false);

  const {
    data: totalCountryCertificateStats = [],
  } = useQuery(
    'stats/totalCountryCertificateStats',
    statsApi.getTotalCountryCertificateStats,
    { select: (res) => res.data.result },
  );

  const countryCertificateChartData: ChartData = useMemo(() => {
    const totalCertFin =
      totalCountryCertificateStats?.reduce(
        (total, { countCertFin }) => total + countCertFin,
        0,
      ) || 0;

    const topTenStats = [
      ...totalCountryCertificateStats.slice(
        0,
        totalCountryCertificateStats.length > 11 ? 9 : 10,
      ),
      ...(totalCountryCertificateStats.length > 11
        ? [
            {
              countryId: 0,
              countCertFin: totalCountryCertificateStats
                .slice(10)
                .reduce((total, { countCertFin }) => total + countCertFin, 0),
              countryNameKo: '기타',
            },
          ]
        : []),
    ];
    return {
      labels: topTenStats?.map(({ countryNameKo }) => countryNameKo) || [],
      datasets: [
        {
          data:
            topTenStats?.map(({ countCertFin }) => ({
              y: countCertFin,
              x: Math.floor((countCertFin / totalCertFin) * 100),
            })) || [],
          backgroundColor: [
            palette.primary,
            `${palette.primary}70`,
            `${palette.primary}30`,
          ],
          maxBarThickness: 56,
        },
      ],
    };
  }, [totalCountryCertificateStats]);

  const countryCertificateChartOptions: ChartOptions = {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: 'none',
    aspectRatio: 4,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          borderDash: [5, 5],
          display: true,
        },
        beginAtZero: true,
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        xAlign: 'center' as const,
        yAlign: 'bottom' as const,
        backgroundColor: '#fff',
        bodyColor: '#222',
        borderColor: palette.primary,
        borderWidth: 1,
        caretSize: 0,
        cornerRadius: 16,
        callbacks: {
          title: () => '',
          label: function ({ label, raw: { x, y } }: any) {
            return `${label} : ${y}건 / ${x}%`;
          },
        },
      },
    },
  };

  return (
    <>
      <CountryCertificateDetailModal
        visible={countryCertificateDetailModalVisible}
        onClose={() => setCountryCertificateDetailModalVisible(false)}
        countryCertificateStats={totalCountryCertificateStats}
      />
      <CountryCertificateChartBlock>
        <Row align="middle" style={{ marginBottom: 48 }}>
          <Col>
            <Typography.Title style={{ fontWeight: 300, fontSize: 32 }}>
              가장 많이 인증 완료된 국가는{' '}
              {totalCountryCertificateStats.length
                ? totalCountryCertificateStats[0].countryNameKo
                : '미정'}{' '}
              입니다.
            </Typography.Title>
          </Col>
          <Col>
            <Typography.Text type="secondary" gutter={{ left: 32 }} bold>
              총 기간 기준
            </Typography.Text>
          </Col>
          <Col>
            <BarChartIcon />
          </Col>
          <Col flex="1 1 auto">
            <Typography.Text type="secondary" gutter={{ left: 10 }} bold>
              상위 10개국
            </Typography.Text>
          </Col>
          <Col>
            <ShowAllBlock
              onClick={() => setCountryCertificateDetailModalVisible(true)}
            >
              <TableOutlined style={{ fontSize: 14 }} />
              <Typography.Text
                type="secondary"
                gutter={{ left: 4, right: 4 }}
                bold
                inline
              >
                전체 보기
              </Typography.Text>
              <RightOutlined style={{ fontSize: 12 }} />
            </ShowAllBlock>
          </Col>
        </Row>
        <ReactChart
          type="bar"
          data={countryCertificateChartData}
          options={countryCertificateChartOptions}
        />
      </CountryCertificateChartBlock>
    </>
  );
};

export default CountryCertificateChart;
