import {
  FileExcelOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Table } from 'antd';
import { useBuyers, useDownloadBuyers } from 'hooks/company';
import { useCountries } from 'hooks/country';
import { useMemo, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { IBuyer } from 'types/company';

const BuyerListPage = () => {
  const [page, setPage] = useState(1);
  const [searchOption, setSearchOption] = useState('companyNameKo');
  const [searchValue, setSearchValue] = useState<string | number>('');
  const { data: buyersData, refetch } = useBuyers({
    page,
    [searchOption]: searchValue,
  });
  const { data: countries } = useCountries();
  const { content, size, totalElements } = { ...buyersData };
  const { loading: downloadLoading, downloadBuyers } = useDownloadBuyers();

  const columns = useMemo(
    () => [
      { title: '번호', dataIndex: 'buyerId', align: 'center' as const },
      {
        title: '회사명(국문)',
        align: 'center' as const,
        render: ({ brandCompany }: IBuyer) => brandCompany.companyNameKo,
      },
      {
        title: '회사명(영문)',
        align: 'center' as const,
        render: ({ brandCompany }: IBuyer) => brandCompany.companyNameEn,
      },
      {
        title: '국가',
        align: 'center' as const,
        render: ({ countryId }: IBuyer) =>
          countries?.find((country) => country.countryId === countryId)
            ?.countryNameKo,
      },
      { title: '바이어명', align: 'center' as const, dataIndex: 'companyName' },
      { title: '주소', align: 'center' as const, dataIndex: 'companyAddress' },
      {
        title: '담당자',
        align: 'center' as const,
        dataIndex: 'representativeName',
      },
      { title: '연락처', align: 'center' as const, dataIndex: 'companyTel' },
      { title: '이메일', align: 'center' as const, dataIndex: 'companyEmail' },
    ],
    [countries],
  );

  const searchBuyers = () => {
    setPage(1);
    setTimeout(refetch);
  };

  return (
    <PageTemplate title="바이어 정보 관리">
      <Row justify="end" gutter={4}>
        <Col>
          <Button
            icon={<SyncOutlined />}
            onClick={() => {
              setPage(1);
              setSearchOption('companyNameKo');
              setSearchValue('');
              setTimeout(refetch);
            }}
          >
            새로고침
          </Button>
        </Col>
        <Col flex="auto">
          <Button
            loading={downloadLoading}
            icon={<FileExcelOutlined />}
            onClick={() => downloadBuyers(countries || [])}
          >
            엑셀로 저장하기
          </Button>
        </Col>
        <Col>
          <Select
            value={searchOption}
            style={{ width: 140 }}
            onChange={(value) => {
              setSearchOption(value);
              if (value === 'countryId') {
                setSearchValue(1);
              } else {
                setSearchValue('');
              }
            }}
          >
            <Select.Option value="companyNameKo">회사명 (국문)</Select.Option>
            <Select.Option value="companyNameEn">회사명 (영문)</Select.Option>
            <Select.Option value="countryId">국가</Select.Option>
            <Select.Option value="companyName">바이어명</Select.Option>
          </Select>
        </Col>
        <Col>
          {searchOption !== 'countryId' ? (
            <Input
              style={{ width: 120 }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyUp={(e) => e.key === 'Enter' && searchBuyers()}
            />
          ) : (
            <Select
              value={searchValue}
              options={countries?.map(({ countryId, countryNameKo }) => ({
                value: countryId,
                label: countryNameKo,
              }))}
              style={{ width: 120 }}
              onChange={(value) => setSearchValue(Number(value))}
            />
          )}
        </Col>
        <Col>
          <Button icon={<SearchOutlined />} onClick={searchBuyers}>
            검색
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={content}
        rowKey={({ buyerId }) => buyerId}
        pagination={{
          current: page,
          pageSize: size,
          total: totalElements,
          onChange: (page) => {
            setPage(page);
            setTimeout(refetch);
          },
        }}
        style={{ marginTop: 8 }}
      />
    </PageTemplate>
  );
};

export default BuyerListPage;
