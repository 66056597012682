import { Button, Col, Row, Select, Table, Typography } from 'antd';
import { ChangeEvent, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';

import { useStats } from 'hooks/certificate';
import PageTemplate from 'templates/PageTemplate';
import { IStat } from 'types/certificate';
import path from 'lib/path';
import { useStores } from 'stores/Context';
import { ECompanySearchType, ICompanyPageState } from 'types/company';
import SearchInput from 'components/form/SearchInput';
import { searchTypeOptions } from 'lib/consts';

const CertificateCompanyPage = () => {
  const history = useHistory();
  const { state = {} } = useLocation<ICompanyPageState>();
  const {
    page = 1,
    searchType = ECompanySearchType.COMPANY_NAME_KO,
    searchValue: initialSearchValue = '',
  } = state;
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const { data, refetch } = useStats({ page, [searchType]: searchValue });
  const { content: stats, totalElements } = { ...data };
  const { companyStore } = useStores();

  const replaceState = (state?: ICompanyPageState) => {
    history.replace(path.certificate.company, state);
  };

  const columns = useMemo(
    () => [
      {
        title: '번호',
        dataIndex: 'companyId',
        align: 'center' as const,
      },
      {
        title: '사업자 등록번호',
        align: 'center' as const,
        render: ({ company: { bizNumber } }: IStat) => {
          return bizNumber;
        },
      },
      {
        title: '회사명 (국문)',
        align: 'center' as const,
        render: ({ companyId, company }: IStat) => (
          <Typography.Link
            underline
            onClick={() => {
              history.push(`${path.certificate.company}/${companyId}`);
              companyStore.setCurrentCompany(company);
            }}
          >
            {company.companyNameKo}
          </Typography.Link>
        ),
      },
      {
        title: '회사명 (영문)',
        align: 'center' as const,
        render: ({ company: { companyNameEn } }: IStat) => companyNameEn,
      },
      {
        title: '대표자명',
        align: 'center' as const,
        render: ({ company: { ceoNameKo } }: IStat) => ceoNameKo,
      },
      {
        title: (
          <Typography.Paragraph>
            인증 건수
            <br />(<Typography.Text type="danger">인증 진행</Typography.Text> /
            인증 완료)
          </Typography.Paragraph>
        ),
        align: 'center' as const,
        render: ({ certOnCount, certFinCount }: IStat) => (
          <Typography.Text>
            <Typography.Text type="danger">{certOnCount || 0}</Typography.Text>{' '}
            / {certFinCount || 0}
          </Typography.Text>
        ),
      },
    ],
    [history, companyStore],
  );

  const handleChangeSearchType = (type: ECompanySearchType) => {
    replaceState({
      page,
      searchType: type,
    });
    setSearchValue('');
  };
  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleSearch = (keyword: string) => {
    replaceState({
      searchType,
      searchValue: keyword,
    });
    setTimeout(refetch);
  };
  const handleRefresh = () => {
    replaceState();
    setSearchValue('');
    setTimeout(refetch);
  };

  return (
    <PageTemplate title="회사별 제품 관리">
      <Row gutter={[8, 8]}>
        <Col flex="auto">
          <Button icon={<SyncOutlined />} onClick={handleRefresh}>
            새로 고침
          </Button>
        </Col>
        <Col>
          <Select
            value={searchType}
            options={searchTypeOptions}
            style={{ width: 120, marginRight: 4 }}
            onChange={handleChangeSearchType}
          />
          <SearchInput
            value={searchValue}
            onChange={handleChangeSearchValue}
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      {stats && (
        <Table
          columns={columns}
          dataSource={stats}
          rowKey={({ companyId }) => companyId}
          pagination={{
            current: page,
            total: totalElements,
            onChange: (page) =>
              replaceState({
                page,
                searchType,
                searchValue,
              }),
          }}
          style={{ marginTop: 8 }}
        />
      )}
    </PageTemplate>
  );
};

export default CertificateCompanyPage;
