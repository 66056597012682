import { Spin } from 'antd';
import { useIsFetching } from 'react-query';
import styled from 'styled-components';

const LoadingIndicatorBlock = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 11000;
  background-color: rgba(255, 255, 255, 0.8);
`;

const excludedFetchingQueryKeys = [
  'notification/newEstimateExists',
  'notification/newUserExists',
  'notification/getNotifications',
];

const LoadingIndicator = () => {
  const isFetching = useIsFetching({
    predicate: ({ queryKey }) => {
      return !excludedFetchingQueryKeys.includes(queryKey as string);
    },
  });
  if (isFetching === 0) {
    return null;
  }
  return (
    <LoadingIndicatorBlock>
      <Spin />
    </LoadingIndicatorBlock>
  );
};

export default LoadingIndicator;
