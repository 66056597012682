import { Button, Col, DatePicker, Input, message, Row, Select } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { useState } from 'react';
import useUpdateEffect from 'hooks/useUpdateEffect';
import moment from 'moment';
import { EPeriodType, IPeriod } from 'types/stats';
import { DATE_FORMAT } from 'lib/consts';

interface IProps {
  label?: React.ReactNode | null;
  style?: React.CSSProperties;
  onSearch: (periodType: EPeriodType, { from, to }: IPeriod) => void;
}

const PeriodSelectBlock = styled(Row)``;

const periodTypeOptions: { label: string; value: EPeriodType }[] = [
  { label: '전체 기간', value: EPeriodType.ALL },
  { label: '월 별', value: EPeriodType.MONTH },
  { label: '분기 별', value: EPeriodType.QUARTER },
  { label: '반기 별', value: EPeriodType.HALF },
  { label: '년도 별', value: EPeriodType.YEAR },
];

const halfYearOptions = [
  { label: '상반기', value: 'first' },
  { label: '하반기', value: 'second' },
];

const PeriodSelect = ({ label = '기간별', style, onSearch }: IProps) => {
  const [periodType, setPeriodType] = useState(periodTypeOptions[0].value);
  const [halfYear, setHalfYear] = useState(halfYearOptions[0].value);
  const [period, setPeriod] = useState(moment());
  const handleClickSearch = () => {
    switch (periodType) {
      case EPeriodType.ALL: {
        return onSearch(periodType, {
          from: '2021-03-23',
          to: moment().format(DATE_FORMAT),
        });
      }
      case EPeriodType.HALF: {
        if (halfYear === 'first') {
          return onSearch(periodType, {
            from: period.startOf('year').format(DATE_FORMAT),
            to: `${period.format('YYYY')}-06-30`,
          });
        } else {
          return onSearch(periodType, {
            from: `${period.format('YYYY')}-07-01`,
            to: period.endOf('year').format('YYYY-MM-DD'),
          });
        }
      }
      default: {
        return onSearch(periodType, {
          from: period.startOf(periodType).format(DATE_FORMAT),
          to: period.endOf(periodType).format(DATE_FORMAT),
        });
      }
    }
  };

  useUpdateEffect(() => {
    setPeriod(moment());
    if (periodType === EPeriodType.HALF) {
      if (moment().isBefore('07-01 00:00:00')) {
        setHalfYear(halfYearOptions[0].value);
      } else {
        setHalfYear(halfYearOptions[1].value);
      }
    }
  }, [periodType]);
  return (
    <PeriodSelectBlock
      align="middle"
      justify="center"
      gutter={[8, 8]}
      style={style}
    >
      <Col>
        {label && (
          <Typography.Text type="secondary" bold>
            {label}
          </Typography.Text>
        )}
      </Col>
      <Col>
        <Select
          options={periodTypeOptions}
          value={periodType}
          style={{ width: 140 }}
          onChange={setPeriodType}
        />
      </Col>
      <Col>
        {periodType === EPeriodType.ALL && (
          <Input
            value="전체 기간"
            disabled
            style={{ width: 140, color: '#888' }}
          />
        )}
        {periodType === 'half' && (
          <Row gutter={8}>
            <Col>
              <DatePicker
                picker="year"
                value={period}
                style={{ width: 140 }}
                onChange={(date) => {
                  if (!date) return;
                  if (date.isBefore('2021-07-31'))
                    return message.warn('2021년 7월 31일부터 조회 가능합니다.');
                  setPeriod(date);
                }}
              />
            </Col>
            <Col>
              <Select
                options={halfYearOptions}
                value={halfYear}
                onChange={setHalfYear}
              />
            </Col>
          </Row>
        )}
        {periodType !== EPeriodType.ALL &&
          periodType !== 'half' &&
          typeof period !== 'string' && (
            <DatePicker
              picker={periodType}
              value={period}
              style={{ width: 140 }}
              onChange={(date) => {
                if (!date) return;
                if (date.isBefore('2021-07-31'))
                  return message.warn('2021년 7월 31일부터 조회 가능합니다.');
                setPeriod(date);
              }}
            />
          )}
      </Col>
      <Col>
        <Button type="primary" onClick={handleClickSearch}>
          조회
        </Button>
      </Col>
    </PeriodSelectBlock>
  );
};

export default PeriodSelect;
