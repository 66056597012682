import { Form, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import DateInput from 'components/system/form/DateInput';
import { requireRule } from 'lib/validate';
import { useUpdateSampleDelivery } from 'hooks/clinicalTrial/process/processingEstimateDetail';

const SampleDeliveryModal = ({
  sampleDeliveryDate,
  completionDueDate,
  clinicalTrialProcessItemId,
  onClose,
}: {
  sampleDeliveryDate: string | null;
  completionDueDate: string | null;
  clinicalTrialProcessItemId: number;
  onClose: () => void;
}) => {
  const [form] = useForm<{
    sampleDeliveryDate: string;
    completionDueDate: string;
  }>();
  const { updateSampleDelivery, updateLoading } = useUpdateSampleDelivery();
  const handleSubmit = (formValue: {
    sampleDeliveryDate: string;
    completionDueDate: string;
  }) => {
    const { sampleDeliveryDate, completionDueDate } = formValue;
    if (new Date(sampleDeliveryDate) >= new Date(completionDueDate)) {
      message.warning('시험 완료 예정일은 시료 발송일 이후 날짜만 가능합니다.');
      return;
    }
    updateSampleDelivery(
      {
        clinicalTrialProcessItemId,
        sampleDeliveryDate,
        completionDueDate,
      },
      {
        onSuccess: onClose,
      },
    );
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <Modal
      title="시료 발송일"
      visible
      onOk={handleOk}
      onCancel={onClose}
      bodyStyle={{ padding: '36px 82px 0px' }}
      confirmLoading={updateLoading}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          justifyContent: 'center',
        }}
      >
        <Form.Item
          label="시료 발송일"
          initialValue={sampleDeliveryDate}
          name="sampleDeliveryDate"
          rules={[requireRule]}
        >
          <DateInput style={{ width: 240, height: 40 }} />
        </Form.Item>
        <Form.Item
          label="시험 완료 예정일"
          initialValue={completionDueDate}
          name="completionDueDate"
          rules={[requireRule]}
        >
          <DateInput style={{ width: 240, height: 40 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SampleDeliveryModal;
