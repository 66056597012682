import { Form, Typography } from 'antd';
import FileViewer from 'components/file/FileViewer';
import { useProductPackingAttestation } from 'hooks/product';
import { useMemo } from 'react';
import { EAttachType } from 'types/product';

const ProductPackingAttestationModal: React.FC<{
  productId: number;
  countryId: number;
}> = ({ productId, countryId }) => {
  const { data: productPackingAttestation } = useProductPackingAttestation({
    productId,
    countryId,
  });
  const {
    packingContainer1,
    packingContainer2,
    productPackingAttestationAttaches,
    isUseRecyclingContainer,
    isUseColorContainer,
  } = {
    ...productPackingAttestation,
  };
  const fisrtAttaches = useMemo(
    () =>
      productPackingAttestationAttaches?.filter(
        ({ attachType }) => attachType === EAttachType.FIRST,
      ),
    [productPackingAttestationAttaches],
  );
  const secondAttaches = useMemo(
    () =>
      productPackingAttestationAttaches?.filter(
        ({ attachType }) => attachType === EAttachType.SECOND,
      ),
    [productPackingAttestationAttaches],
  );

  return (
    <Form labelCol={{ span: 6 }} size="small" style={{ paddingTop: 20 }}>
      <Form.Item label="1차 포장용기 재질">
        <Typography.Text strong>{packingContainer1}</Typography.Text>
      </Form.Item>
      <Form.Item label="1차 포장용기 파일목록">
        {fisrtAttaches?.map(
          ({ productPackingAttestationAttachId, attachUrl, filename }) => (
            <FileViewer
              key={productPackingAttestationAttachId}
              title={filename}
              file={attachUrl}
            />
          ),
        )}
      </Form.Item>
      <Form.Item label="2차 포장용기 재질">
        <Typography.Text strong>{packingContainer2}</Typography.Text>
      </Form.Item>
      <Form.Item label="2차 포장용기 파일목록">
        {secondAttaches?.map(
          ({ productPackingAttestationAttachId, attachUrl, filename }) => (
            <FileViewer
              key={productPackingAttestationAttachId}
              title={filename}
              file={attachUrl}
            />
          ),
        )}
      </Form.Item>
      <Form.Item label="재활용 용기 사용 여부">
        <Typography.Text strong>
          {isUseRecyclingContainer ? '사용' : '미사용'}
        </Typography.Text>
      </Form.Item>
      {isUseRecyclingContainer && (
        <Form.Item label="재활용 용기에 색깔 사용 여부">
          <Typography.Text strong>
            {isUseColorContainer ? '사용' : '미사용'}
          </Typography.Text>
        </Form.Item>
      )}
    </Form>
  );
};

export default ProductPackingAttestationModal;
