import { Link, useLocation } from 'react-router-dom';
import { Menu, Badge } from 'antd';
import {
  CustomerServiceOutlined,
  DollarCircleOutlined,
  UserOutlined,
  ContainerOutlined,
  BarChartOutlined,
  FilterOutlined,
} from '@ant-design/icons';

import path from 'lib/path';
import { useNewExists } from 'hooks/notification';
import { useStores } from 'stores/Context';

const Navigator = () => {
  const { pathname } = useLocation();
  const selectedKeys = [
    pathname,
    ...(/^\/company\/\d+/.test(pathname) ? [path.company.root] : []),
    ...(/^\/certificate\/company\/\d+/.test(pathname)
      ? [path.certificate.company]
      : []),
    ...(/^\/certificate\/\d+/.test(pathname) ? [path.certificate.company] : []),
  ];
  const {
    isNewUserExists,
    isNewEstimateExists,
    readNewEstimate,
  } = useNewExists();
  const { clinicalTrialStore } = useStores();

  return (
    <Menu
      mode="inline"
      theme="dark"
      selectedKeys={selectedKeys}
      defaultOpenKeys={[
        'company',
        'certificate',
        'payment',
        'customer',
        'stats',
        'clinicalTrial',
      ]}
      style={{ paddingTop: 16 }}
    >
      <Menu.SubMenu key="company" icon={<UserOutlined />} title="회원 관리">
        <Menu.Item key={path.company.approve}>
          <Link to={path.company.approve}>
            <Badge dot={isNewUserExists} style={{ right: -5 }}>
              가입 승인
            </Badge>
          </Link>
        </Menu.Item>
        <Menu.Item key={path.company.root}>
          <Link to={path.company.root}>회원 정보 관리</Link>
        </Menu.Item>
        <Menu.Item key={path.company.buyer}>
          <Link to={path.company.buyer}>바이어 정보 관리</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="certificate"
        icon={<ContainerOutlined />}
        title="인증 통합 관리"
      >
        <Menu.Item key={path.certificate.company}>
          <Link to={path.certificate.company}>회사별 제품 관리</Link>
        </Menu.Item>
        {/* 고도화 개발 부분
        <Menu.Item key="체크리스트 관리">체크리스트 관리</Menu.Item>
        <Menu.Item key="타사 양식 서류 발행">타사 양식 서류 발행</Menu.Item> */}
      </Menu.SubMenu>
      <Menu.SubMenu
        key="payment"
        icon={<DollarCircleOutlined />}
        title="결제 관리"
      >
        <Menu.Item key={path.estimate.root}>
          <Link to={path.estimate.root} onClick={readNewEstimate}>
            <Badge dot={isNewEstimateExists} style={{ right: -5 }}>
              견적서 및 입금 확인
            </Badge>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="clinicalTrial"
        title="시험대행 관리"
        icon={<FilterOutlined />}
      >
        <Menu.Item key={path.clinicalTrial.process.request}>
          <Link to={path.clinicalTrial.process.request}>견적요청 관리</Link>
        </Menu.Item>
        <Menu.Item key={path.clinicalTrial.estimate.select}>
          <Link
            to={path.clinicalTrial.estimate.select}
            onClick={() => {
              clinicalTrialStore.clinicalTrialEstimateRequest = null;
            }}
          >
            시험대행 견적 관리
          </Link>
        </Menu.Item>
        <Menu.Item key={path.clinicalTrial.process.root}>
          <Link to={path.clinicalTrial.process.root}>시험대행 진행 관리</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="customer"
        icon={<CustomerServiceOutlined />}
        title="고객서비스 관리"
      >
        <Menu.Item key={path.service.notice}>
          <Link to={path.service.notice}>공지사항</Link>
        </Menu.Item>
        <Menu.Item key={path.service.faq}>
          <Link to={path.service.faq}>FAQ</Link>
        </Menu.Item>
        <Menu.Item key={path.service.useGuide}>
          <Link to={path.service.useGuide}>이용안내</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key="stats" icon={<BarChartOutlined />} title="통계">
        <Menu.Item key={path.stats.sales}>
          <Link to={path.stats.sales}>매출 분석</Link>
        </Menu.Item>
        <Menu.Item key={path.stats.certificate}>
          <Link to={path.stats.certificate}>인증 분석</Link>
        </Menu.Item>
        <Menu.Item key={path.stats.category}>
          <Link to={path.stats.category}>카테고리 분석</Link>
        </Menu.Item>
        <Menu.Item key={path.stats.share}>
          <Link to={path.stats.share}>점유율 분석</Link>
        </Menu.Item>
        <Menu.Item key={path.stats.buyer}>
          <Link to={path.stats.buyer}>바이어 분석</Link>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

export default Navigator;
