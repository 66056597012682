import qs from 'qs';

import {
  EClinicalTrialEstimateStatus,
  ICategoryData,
  IClinicalTrial,
  IClinicalTrialEstimate,
  IClinicalTrialEstimateAdd,
  IClinicalTrialEstimateBasic,
  IClinicalTrialEstimateClientInfo,
  IClinicalTrialEstimateItemOfCategory,
  IClinicalTrialEstimateSearchParams,
  IClinicalTrialEstimateUpdate,
  IClinicalTrialPackage,
} from 'types/clinicalTrial';
import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import client from './client';

export const getCategoryDatas = async () => {
  const res = await client.get<IAPIResponse<ICategoryData[]>>(
    '/admin/clinical-trial/clinical-trials/recommendations',
  );

  const resOfPackage = await client.get<
    IAPIResponse<
      {
        categoryDataId: number;
        clinicalTrialPackageIds: number[];
      }[]
    >
  >('/admin/clinical-trial/clinical-trial-packages/recommendations');
  res.data.result = res.data.result.map((categoryData) => ({
    ...categoryData,
    clinicalTrialPackageIds:
      resOfPackage.data.result.find(
        ({ categoryDataId }) => categoryData.categoryDataId === categoryDataId,
      )?.clinicalTrialPackageIds || [],
  }));
  return res;
};

export const getClinicalTrials = () =>
  client.get<IAPIResponse<IClinicalTrial[]>>(
    '/admin/clinical-trial/clinical-trials',
  );

export const getClinicalTrialPackages = () =>
  client.get<IAPIResponse<IClinicalTrialPackage[]>>(
    '/admin/clinical-trial/clinical-trial-packages',
  );

export const addClinicalTrialEstimate = (estimate: IClinicalTrialEstimateAdd) =>
  client.post('/admin/clinical-trial/estimate', estimate);

export const previewClinicalTrialEstimate = (
  estimate: IClinicalTrialEstimateItemOfCategory[],
) =>
  client.post<IAPIResponse<string>>('/admin/clinical-trial/estimate/preview', {
    itemsOfCategory: estimate,
  });

export const getClinicalTrialEstimates = ({
  page,
  searchType,
  searchKeyword,
  isConfirmed,
  publishDuration,
}: IClinicalTrialEstimateSearchParams) =>
  client.get<IAPIPageableResponse<IClinicalTrialEstimateBasic[]>>(
    `/admin/clinical-trial/estimates?${qs.stringify({
      pageNo: page,
      size: 10,
      searchKey: searchType,
      searchKeyword,
      ...(isConfirmed !== null && {
        status: isConfirmed
          ? EClinicalTrialEstimateStatus.CFM
          : EClinicalTrialEstimateStatus.RDY,
      }),
      ...(publishDuration !== null && {
        publishFromDate: publishDuration.from,
        publishToDate: publishDuration.to,
      }),
    })}`,
  );

export const confirmClinicalTrialEstimate = ({
  clinicalTrialEstimateId,
  bizNumber,
}: {
  clinicalTrialEstimateId: number;
  bizNumber: string;
}) =>
  client.patch(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}/confirmation`,
    {
      bizNumber,
    },
  );

export const getClinicalTrialEstimateClientInfo = (
  clinicalTrialEstimateId: number,
) =>
  client.get<IAPIResponse<IClinicalTrialEstimateClientInfo>>(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}/client-info`,
  );

export const updateClinicalTrialEstimateClientInfo = (
  clinicalTrialEstimateId: number,
  clientInfo: IClinicalTrialEstimateClientInfo,
) =>
  client.patch<IAPIResponse<IClinicalTrialEstimateClientInfo>>(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}/client-info`,
    clientInfo,
  );

export const getClinicalTrialEstimate = (clinicalTrialEstimateId: number) =>
  client.get<IAPIResponse<IClinicalTrialEstimate>>(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}`,
  );

export const updateClinicalTrialEstimate = ({
  clinicalTrialEstimateId,
  ...estimate
}: IClinicalTrialEstimateUpdate) =>
  client.patch(
    `/admin/clinical-trial/estimates/${clinicalTrialEstimateId}`,
    estimate,
  );
