/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Button,
  Card,
  Col,
  DatePicker,
  message,
  Row,
  Select,
  Table,
  Tabs,
  Switch,
} from 'antd';
import { useEffect } from 'react';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { useCosmeticCategories, useFunctionalCategories } from 'hooks/product';
import * as statsApi from 'lib/api/stats';
import moment from 'moment';
import {
  ECategoryCertificateSearchType,
  ICategoryCertificateStat,
  ICountryCategoryCertificateStat,
  IPeriod,
} from 'types/stats';
import { DATE_FORMAT } from 'lib/consts';
import { ColumnsType } from 'antd/lib/table';
import { nanoid } from 'nanoid';
import { Typography } from 'components/system';

const CountryCategoryCertificateTableBlock = styled(Card)`
  height: 100%;
`;

const CountryCategoryCertificateTable = () => {
  const [tab, setTab] = useState<'category' | 'country'>('category');
  const { data: cosmeticCategories = [] } = useCosmeticCategories();
  const { data: functionalCategories = [] } = useFunctionalCategories();
  const [selectedFirstCategoryId, setSelectedFirstCategoryId] = useState<
    number | null
  >(null);
  const firstCategories = useMemo(
    () => cosmeticCategories.filter(({ parentId }) => parentId === null),
    [cosmeticCategories],
  );
  const [selectedSecondCategoryId, setSelectedSecondCategoryId] = useState<
    number | null
  >(null);
  const secondCategories = useMemo(
    () =>
      cosmeticCategories.filter(
        ({ parentId }) => parentId === selectedFirstCategoryId,
      ),
    [cosmeticCategories, selectedFirstCategoryId],
  );
  const [isFunctional, setIsFunctional] = useState<boolean | null>(null);
  const [
    selectedFunctionalCategoryId,
    setSelectedFunctionalCategoryId,
  ] = useState<number | null>(null);
  const [period, setPeriod] = useState(moment());
  const [categoryCertificateStats, setCategoryCertificateStats] = useState<
    ICategoryCertificateStat[]
  >([]);
  const { mutate: getCategoryCertificateStats } = useMutation(
    (params: {
      type: ECategoryCertificateSearchType;
      id: number;
      period: IPeriod;
    }) => statsApi.getCategoryCertificateStats(params),
    { onSuccess: (res) => setCategoryCertificateStats(res.data.result) },
  );
  const [
    countryCategoryCertificateStats,
    setCountryCategoryCertificateStats,
  ] = useState<ICountryCategoryCertificateStat[]>([]);
  const { mutate: getCountryCategoryCertificateStats } = useMutation(
    (params: {
      type: ECategoryCertificateSearchType;
      id: number[];
      period: IPeriod;
    }) => statsApi.getCountryCategoryCertificateStats(params),
    { onSuccess: (res) => setCountryCategoryCertificateStats(res.data.result) },
  );
  const categoryCertificateColumns: ColumnsType<ICategoryCertificateStat> = [
    { title: '구분', align: 'center', render: (_, __, index) => index + 1 },
    {
      title: '카테고리',
      dataIndex: 'categoryName',
      align: 'center',
    },
    {
      title: '인증 완료 수',
      dataIndex: 'countCertFin',
      align: 'center',
    },
  ];
  const countryCategoryCertificateColumns: ColumnsType<ICountryCategoryCertificateStat> = [
    { title: '구분', align: 'center', render: (_, __, index) => index + 1 },
    {
      title: '국가',
      dataIndex: 'countryNameKo',
      align: 'center',
    },
    {
      title: '인증 완료 수',
      dataIndex: 'countCertFin',
      align: 'center',
    },
  ];
  const handleSearch = () => {
    if (tab === 'category') {
      if (selectedFirstCategoryId === null) {
        return message.warn('대분류를 선택해 주세요');
      }
      const {
        type,
        id,
      }: { type: ECategoryCertificateSearchType; id?: number } = isFunctional
        ? {
            type: ECategoryCertificateSearchType.FUNCTIONAL,
            id: selectedSecondCategoryId!,
          }
        : selectedSecondCategoryId
        ? {
            type: ECategoryCertificateSearchType.SECOND,
            id: selectedSecondCategoryId,
          }
        : {
            type: ECategoryCertificateSearchType.FIRST,
            id: selectedFirstCategoryId,
          };
      getCategoryCertificateStats({
        type,
        id,
        period: {
          from: period.startOf('year').format(DATE_FORMAT),
          to: period.endOf('year').format(DATE_FORMAT),
        },
      });
    } else {
      if (selectedFirstCategoryId === null) {
        return message.warn('대분류를 선택해 주세요');
      }
      const {
        type,
        id,
      }: { type: ECategoryCertificateSearchType; id?: number[] } =
        selectedFunctionalCategoryId !== null
          ? {
              type: ECategoryCertificateSearchType.FUNCTIONAL,
              id: [selectedSecondCategoryId!, selectedFunctionalCategoryId],
            }
          : isFunctional !== null
          ? {
              type: ECategoryCertificateSearchType.THIRD,
              id: [isFunctional ? 1 : 0],
            }
          : selectedSecondCategoryId
          ? {
              type: ECategoryCertificateSearchType.SECOND,
              id: [selectedSecondCategoryId],
            }
          : {
              type: ECategoryCertificateSearchType.FIRST,
              id: [selectedFirstCategoryId],
            };
      getCountryCategoryCertificateStats({
        type,
        id,
        period: {
          from: period.startOf('year').format(DATE_FORMAT),
          to: period.endOf('year').format(DATE_FORMAT),
        },
      });
    }
  };

  useEffect(() => {
    if (tab === 'country') {
      setSelectedFirstCategoryId(null);
    }
    setPeriod(moment());
  }, [tab]);
  useEffect(() => {
    setSelectedSecondCategoryId(null);
  }, [selectedFirstCategoryId]);
  useEffect(() => {
    setIsFunctional(null);
  }, [selectedSecondCategoryId]);
  return (
    <CountryCategoryCertificateTableBlock>
      <Tabs
        style={{ padding: '0 4px' }}
        activeKey={tab}
        onChange={(tab) => setTab(tab as 'category' | 'country')}
      >
        <Tabs.TabPane key="category" tab="카테고리별" />
        <Tabs.TabPane key="country" tab="국가별" />
      </Tabs>
      <Row
        gutter={[8, 8]}
        align="middle"
        style={{ marginTop: 20, marginBottom: 36 }}
      >
        <Col>
          <Select
            value={selectedFirstCategoryId || undefined}
            onChange={setSelectedFirstCategoryId}
            placeholder="대분류"
            style={{ width: 180 }}
          >
            {firstCategories.map(({ cosmeticCategoryId, name }) => (
              <Select.Option
                key={cosmeticCategoryId}
                value={cosmeticCategoryId}
              >
                {name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            value={selectedSecondCategoryId || undefined}
            onChange={setSelectedSecondCategoryId}
            placeholder="중분류"
            disabled={selectedFirstCategoryId === null}
            style={{ width: 200 }}
          >
            {secondCategories.map(({ cosmeticCategoryId, name }) => (
              <Select.Option
                key={cosmeticCategoryId}
                value={cosmeticCategoryId}
              >
                {name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          {tab === 'category' ? (
            <Row align="middle" gutter={4}>
              <Col>
                <Switch
                  size="small"
                  checked={isFunctional || false}
                  disabled={selectedSecondCategoryId === null}
                  onChange={setIsFunctional}
                />
              </Col>
              <Col>
                <Typography.Text
                  type="secondary"
                  color={selectedSecondCategoryId !== null ? 'black' : 'gray'}
                  style={{ paddingTop: 2 }}
                >
                  기능성 화장품
                </Typography.Text>
              </Col>
            </Row>
          ) : (
            <Select
              value={
                isFunctional !== null
                  ? isFunctional
                    ? 'true'
                    : 'false'
                  : undefined
              }
              onChange={(value) => setIsFunctional(value === 'true')}
              placeholder="소분류"
              disabled={selectedSecondCategoryId === null}
              style={{ width: 180 }}
            >
              <Select.Option value="false">일반 화장품</Select.Option>
              <Select.Option value="true">기능성 화장품</Select.Option>
            </Select>
          )}
        </Col>
        {tab === 'country' && (
          <Col>
            <Select
              value={selectedFunctionalCategoryId || undefined}
              style={{ width: 180 }}
              placeholder="상세분류"
              disabled={isFunctional !== true}
              onChange={setSelectedFunctionalCategoryId}
            >
              {functionalCategories.map(({ functionalCategoryId, name }) => (
                <Select.Option
                  key={functionalCategoryId}
                  value={functionalCategoryId}
                >
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        )}
        <Col>
          <DatePicker
            picker="year"
            value={period}
            onChange={(date) => date && setPeriod(date)}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={handleSearch}>
            조회
          </Button>
        </Col>
      </Row>
      {tab === 'category' ? (
        <Table
          columns={categoryCertificateColumns}
          dataSource={categoryCertificateStats}
          pagination={false}
          rowKey={() => nanoid()}
        />
      ) : (
        <Table
          columns={countryCategoryCertificateColumns}
          dataSource={countryCategoryCertificateStats}
          pagination={false}
          rowKey={() => nanoid()}
        />
      )}
    </CountryCategoryCertificateTableBlock>
  );
};

export default CountryCategoryCertificateTable;
