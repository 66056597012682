import { SyncOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';

import SearchInput from 'components/form/SearchInput';
import { useCompanies, useUpdateCompanyStatus } from 'hooks/company';
import path from 'lib/path';
import PageTemplate from 'templates/PageTemplate';
import {
  ECompanyStatus,
  ECompanySearchType,
  ICompany,
  ICompanyStatus,
  ECompanyType,
  ICompanyListPageState,
} from 'types/company';
import { searchTypeOptions } from 'lib/consts';

const CompanyListPage = () => {
  const history = useHistory();
  const { state = {} } = useLocation<ICompanyListPageState>();
  const {
    page = 1,
    companyType = ECompanyType.ALL,
    searchType = ECompanySearchType.COMPANY_NAME_KO,
    searchValue: initialSearchValue = '',
  } = state;
  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const { data, refetch } = useCompanies({
    page,
    companyType,
    [searchType]: searchValue,
    statuses: [ECompanyStatus.CFM, ECompanyStatus.WIH, ECompanyStatus.STP],
  });
  const { content, totalElements } = { ...data };

  const replaceState = (state: ICompanyListPageState) => {
    history.replace(path.company.root, state);
  };

  const changeCompanyMutation = useUpdateCompanyStatus({ page });
  const changeCompanyStatus = useCallback(
    async (companyStatus: ICompanyStatus) => {
      Modal.confirm({
        icon: false,
        content: '정말로 수정하시겠습니까?',
        onOk: () => {
          changeCompanyMutation.mutate(companyStatus);
        },
      });
    },
    [changeCompanyMutation],
  );

  const columns: ColumnsType<ICompany> = [
    { title: 'No.', dataIndex: 'companyId', align: 'center' },
    {
      title: '회사명 (국문)',
      align: 'center',
      render: (_, { companyId, companyNameKo }) => (
        <Typography.Link
          underline
          onClick={() => history.push(`${path.company.root}/${companyId}`)}
        >
          {companyNameKo}
        </Typography.Link>
      ),
    },
    {
      title: '회사명 (영문)',
      dataIndex: 'companyNameEn',
      align: 'center',
    },
    {
      title: '사업자 등록 번호',
      dataIndex: 'bizNumber',
      align: 'center',
    },
    { title: '대표자명', dataIndex: 'ceoNameKo', align: 'center' },
    {
      title: '구분',
      align: 'center',
      render: (_, { companyType }) =>
        companyType === 'BRAND'
          ? '책임판매업자'
          : companyType === 'MANUFACTURE'
          ? '제조사'
          : '원료사',
    },
    {
      title: '상태',
      align: 'center',
      render: (_, { companyId, status }) => (
        <Select
          value={status}
          options={[
            { value: ECompanyStatus.CFM, label: '승인' },
            { value: ECompanyStatus.STP, label: '정지' },
            { value: ECompanyStatus.WIH, label: '탈퇴' },
          ]}
          onChange={(status) => changeCompanyStatus({ companyId, status })}
        />
      ),
    },
  ];
  const typeOptions = useMemo(
    () => [
      { label: '전체', value: ECompanyType.ALL },
      { label: '책임판매업자', value: ECompanyType.BRAND },
      { label: '제조사', value: ECompanyType.MANUFACTURE },
      { label: '원료사', value: ECompanyType.MATERIAL },
    ],
    [],
  );
  const handleChangeCompanyType = (e: RadioChangeEvent) => {
    replaceState({ page: 1, companyType: e.target.value });
    setTimeout(refetch);
    setSearchValue('');
  };
  const handleChangeSearchType = (type: ECompanySearchType) => {
    replaceState({
      page,
      companyType,
      searchType: type,
    });
    setSearchValue('');
  };
  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleSearch = (keyword: string) => {
    replaceState({
      page: 1,
      companyType,
      searchType,
      searchValue: keyword,
    });
    setTimeout(refetch);
  };
  const handleRefresh = () => {
    replaceState({ page: 1 });
    setSearchValue('');
    setTimeout(refetch);
  };
  return (
    <PageTemplate title="회원 정보 관리">
      <Row gutter={[8, 8]}>
        <Col flex="auto">
          <Button icon={<SyncOutlined />} onClick={handleRefresh}>
            새로 고침
          </Button>
        </Col>
        <Col>
          <Radio.Group
            optionType="button"
            options={typeOptions}
            buttonStyle="solid"
            value={companyType}
            onChange={handleChangeCompanyType}
          />
        </Col>
        <Col>
          <Select
            value={searchType}
            options={searchTypeOptions}
            style={{ width: 120, marginRight: 4 }}
            onChange={handleChangeSearchType}
          />
          <SearchInput
            value={searchValue}
            onChange={handleChangeSearchValue}
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      <Table
        dataSource={content}
        columns={columns}
        pagination={{
          current: page,
          total: totalElements,
          pageSize: 10,
          onChange: (page) =>
            replaceState({
              page,
              companyType,
              searchType,
              searchValue,
            }),
        }}
        rowKey={({ companyId }) => companyId}
        style={{ marginTop: 8 }}
      />
    </PageTemplate>
  );
};

export default CompanyListPage;
