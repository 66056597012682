import { useMemo, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Radio, Typography, Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';

import FileViewer from 'components/file/FileViewer';
import FooterBox from 'components/FooterBox';
import DateInput from 'components/system/form/DateInput';
import { useFinishCertification } from 'hooks/certificate';
import consts from 'lib/consts';
import { normalizeDate, dateRule, requireRule } from 'lib/validate';
import { ICertificate } from 'types/certificate';

const CertificateFinishModal = ({
  certificate,
  onClose,
}: {
  certificate: ICertificate;
  onClose: (isSuccess: boolean) => void;
}) => {
  const { certTargetId, estimateTarget, cpsrGenerateFileUrl } = {
    ...certificate,
  };
  const { countryName, productDetails } = { ...estimateTarget };
  const [form] = Form.useForm();
  const attachOptions = useMemo(
    () => [
      { value: true, label: '자동발행' },
      { value: false, label: '첨부하기' },
    ],
    [],
  );
  const isNecessaryCPSR = countryName === '유럽연합' || countryName === '영국';
  const [isCertDocAutoPublish, setIsCertDocAutoPublish] = useState(true);
  const [docFile, setDocFile] = useState<File | null>(null);
  const [cpsrFile, setCpsrFile] = useState<File | null>(null);
  const finishCertificateMutation = useFinishCertification({
    onSuccess: () => {
      onClose(true);
      message.info('인증이 완료처리 되었습니다.');
    },
  });
  const cpsr = cpsrFile || cpsrGenerateFileUrl;
  const finishCertificate = ({
    certRegisterNo,
    registerDt,
  }: {
    registerDt: string;
    certRegisterNo: string;
  }) => {
    if (!isCertDocAutoPublish && docFile === null)
      return message.warn('첨부파일을 업로드해주세요.');
    if (isNecessaryCPSR && !cpsrFile)
      return message.warn('CPSR을 업로드해주세요.');
    finishCertificateMutation.mutate({
      certTargetId,
      certRegisterNo,
      certFinYmd: registerDt.replace(/-/g, ''),
      isCertDocAutoPublish,
      ...(!isCertDocAutoPublish && docFile && { docFile }),
      ...(isNecessaryCPSR && cpsrFile && { cpsrUploadFile: cpsrFile }),
    });
  };
  return (
    <Modal
      visible={true}
      title="인증 완료 처리"
      footer={null}
      maskClosable={false}
      onCancel={() => onClose(false)}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        onFinish={finishCertificate}
        colon={false}
      >
        <Form.Item label="국가">
          <Typography.Text strong>{countryName}</Typography.Text>
        </Form.Item>
        <Form.Item label="제품명">
          <Typography.Text strong>
            {productDetails[0].productNameEn}
          </Typography.Text>
        </Form.Item>
        <Form.Item
          label="인증 등록 번호"
          name="certRegisterNo"
          rules={[
            {
              required: true,
              message: consts.message.REQUIRED_FIELD,
            },
          ]}
        >
          <Input maxLength={40} />
        </Form.Item>
        <Form.Item label="등록증">
          <Radio.Group
            value={isCertDocAutoPublish}
            options={attachOptions}
            optionType="button"
            buttonStyle="solid"
            onChange={(e) => setIsCertDocAutoPublish(e.target.value)}
          />
        </Form.Item>
        {!isCertDocAutoPublish && (
          <>
            <Form.Item label={' '}>
              <Upload
                accept=".pdf"
                itemRender={() => null}
                multiple
                beforeUpload={(file) => {
                  setDocFile(file);
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>파일 업로드</Button>
                {docFile && (
                  <Typography.Text style={{ marginLeft: 16 }}>
                    {docFile.name}
                  </Typography.Text>
                )}
              </Upload>
            </Form.Item>
            {docFile && (
              <FileViewer file={docFile} style={{ marginBottom: 16 }} />
            )}
          </>
        )}
        <Form.Item
          label="등록 완료일"
          name="registerDt"
          initialValue={moment().format('YYYY-MM-DD')}
          normalize={normalizeDate}
          rules={[requireRule, dateRule]}
        >
          <DateInput
            style={{ width: 120 }}
            checkDisabledDate={(date) => date.isAfter(moment())}
          />
        </Form.Item>
        {isNecessaryCPSR && (
          <>
            <Form.Item required label="CPSR">
              <Upload
                accept=".pdf"
                itemRender={() => null}
                beforeUpload={(file) => {
                  setCpsrFile(file);
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>파일 업로드</Button>
                {cpsrFile && (
                  <Typography.Text style={{ marginLeft: 16 }}>
                    {cpsrFile.name}
                  </Typography.Text>
                )}
              </Upload>
            </Form.Item>
            {cpsr && <FileViewer file={cpsr} style={{ marginTop: 8 }} />}
          </>
        )}
        <FooterBox>
          <Button
            type="primary"
            htmlType="submit"
            loading={finishCertificateMutation.isLoading}
          >
            인증 완료
          </Button>
          <Button onClick={() => onClose(false)}>취소</Button>
        </FooterBox>
      </Form>
    </Modal>
  );
};

export default CertificateFinishModal;
