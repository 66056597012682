import { useState } from 'react';
import { Button, Form, Modal, Typography } from 'antd';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';

import { useProductNecessaryInfo } from 'hooks/product';
import palette from 'lib/styles/palette';

const ProductNecessaryInfoModalContainer = styled.div`
  .ant-row.ant-form-item {
    margin: 4px 0 0;
  }
`;

const Section = styled.section`
  margin-top: 24px;
`;

const ZipCodeSearchContainer = styled.div`
  display: flex;
  margin: 8px 0 24px;

  button {
    height: 36px;
    width: 58px;
    border-radius: 0 4px 4px 0;
  }
`;

const ZipCodeAddress = styled.div`
  height: 36px;
  width: 440px;
  border: 1px solid #d9d9d9;
  border-right-width: 0;
  border-radius: 4px 0 0 4px;
  padding: 8px;
  line-height: 20px;
`;

const Ingredients = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 12px;
  margin-top: 4px;
  font-size: 12px;
  line-height: 20px;
`;

const ProductNecessaryInfoModal = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const { data: productNecessaryInfo } = useProductNecessaryInfo({
    productId,
    countryId,
  });
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');

  const {
    brandNameEn,
    productNameEn,
    companyNameEn,
    companyAddressEn,
    companyAddressKo,
    manufacturerNameEn,
    manufacturerAddressEn,
    manufacturerAddressKo,
    cosmeticCategories,
    functionalCategory,
    productSaleWebUrl,
    inciNames,
  } = { ...productNecessaryInfo };

  const handleZipCodeSearch = () => {
    const modal = Modal.info({
      centered: true,
      icon: null,
      content: (
        <DaumPostcode
          onComplete={(address) => {
            setZipCode(`(${address.zonecode})`);
            setAddress(address.address);
            modal.destroy();
          }}
        />
      ),
      closable: true,
      maskClosable: true,
    });
  };

  return (
    <ProductNecessaryInfoModalContainer>
      <Form size="small" style={{ paddingTop: 20 }}>
        <Form.Item label="PRODUCT NAME">
          <Typography.Text strong>
            {productNecessaryInfo && `${brandNameEn} / ${productNameEn}`}
          </Typography.Text>
        </Form.Item>
        <Section>
          <Typography.Text>1. LABELER</Typography.Text>
          <Form.Item label="LABELER NAME">
            <Typography.Text strong>{companyNameEn}</Typography.Text>
          </Form.Item>
          <Form.Item label="ADDRESS">
            <Typography.Text strong>{companyAddressEn}</Typography.Text>
          </Form.Item>
          <Form.Item label="사업장 소재지 (국문)">
            <Typography.Text strong>{companyAddressKo}</Typography.Text>
          </Form.Item>
        </Section>
        <Section>
          <Typography.Text>2. MANUFACTURER</Typography.Text>
          <Form.Item label="ESTABLISHMENT NAME">
            <Typography.Text strong>{manufacturerNameEn}</Typography.Text>
          </Form.Item>
          <Form.Item label="ADDRESS">
            <Typography.Text strong>{manufacturerAddressEn}</Typography.Text>
          </Form.Item>
          <Form.Item label="사업장 소재지 (국문)">
            <Typography.Text strong>{manufacturerAddressKo}</Typography.Text>
          </Form.Item>
        </Section>
        <Section>
          <Typography.Text>* 우편 번호 검색 (ZIP/POSTAL CODE)</Typography.Text>
          <ZipCodeSearchContainer>
            <ZipCodeAddress
              onClick={
                zipCode || address ? undefined : () => handleZipCodeSearch()
              }
            >
              <Typography.Text style={{ color: palette.primary }}>
                {zipCode}
              </Typography.Text>{' '}
              <Typography.Text>{address}</Typography.Text>
            </ZipCodeAddress>
            <Button type="primary" onClick={handleZipCodeSearch}>
              검색
            </Button>
          </ZipCodeSearchContainer>
        </Section>
        <Section>
          <Typography.Text>3. BRAND</Typography.Text>
          <Form.Item label="PRODUCT CATEGORY">
            <Typography.Text strong>
              {productNecessaryInfo &&
                (functionalCategory !== null
                  ? `${cosmeticCategories?.[0].name} - ${cosmeticCategories?.[1].name} - 기능성 화장품 - ${functionalCategory?.name}`
                  : `${cosmeticCategories?.[0].name} - ${cosmeticCategories?.[1].name} - 일반 화장품`)}
            </Typography.Text>
          </Form.Item>
          <Form.Item label="BRAND / SPECIFIC PRODUCT NAME">
            <Typography.Text strong>
              {productNecessaryInfo && `${brandNameEn} / ${productNameEn}`}
            </Typography.Text>
          </Form.Item>
          <Form.Item label="PRODUCT WEBSITE">
            <Typography.Text strong>{productSaleWebUrl ?? '-'}</Typography.Text>
          </Form.Item>
        </Section>
        <Section>
          <Typography.Text>4. INGREDIENTS</Typography.Text>
          <Ingredients>
            {inciNames?.map((inciName) => (
              <div key={inciName}>{inciName}</div>
            ))}
          </Ingredients>
        </Section>
      </Form>
    </ProductNecessaryInfoModalContainer>
  );
};

export default ProductNecessaryInfoModal;
