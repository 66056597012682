import { useState, useEffect } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { useMutation } from 'react-query';

import { ICompanyBasic } from 'types/company';
import * as companyApi from 'lib/api/company';

interface IProps {
  companyType?: 'brand' | 'manufacturer';
  onChange: (companyId: number) => void;
}

const CompanySelect = ({ companyType = 'brand', onChange }: IProps) => {
  const [companies, setCompanies] = useState<ICompanyBasic[]>([]);
  const [selected, setSelected] = useState<string>();

  const handleSelect = (value: string) => {
    onChange(Number(value));
    setSelected(value);
  };

  useEffect(() => {
    setCompanies([]);
    setSelected(undefined);
  }, [companyType]);

  const {
    mutate: getCompanyBasics,
    isLoading: getCompanyBasicsLoading,
  } = useMutation(
    ({
      companyNameKo,
      isManufacturer,
    }: {
      companyNameKo: string;
      isManufacturer: boolean;
    }) => companyApi.getCompanyBasics({ companyNameKo, isManufacturer }),
  );

  const handleSearch = _.debounce((searchValue: string) => {
    getCompanyBasics(
      {
        companyNameKo: searchValue,
        isManufacturer: companyType === 'manufacturer',
      },
      { onSuccess: (res) => setCompanies(res.data.result) },
    );
  }, 200);

  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder={`${companyType === 'brand' ? '브랜드사' : '제조사'}명 입력`}
      optionFilterProp="children"
      loading={getCompanyBasicsLoading}
      onSearch={handleSearch}
      onChange={handleSelect}
      value={selected}
    >
      {companies.map(({ companyId, companyNameKo }) => (
        <Select.Option value={companyId}>{companyNameKo}</Select.Option>
      ))}
    </Select>
  );
};

export default CompanySelect;
