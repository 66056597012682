import palette from 'lib/styles/palette';
import React from 'react';
import styled, { css } from 'styled-components';
import { ITypographyBlockProps, ITypographyProps } from '.';

const TitleBlock = styled.p<ITypographyBlockProps>`
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -1px;
  margin: 0;

  ${({ type }) =>
    type === 'primary' &&
    css`
      font-size: 24px;
    `}
  ${({ type }) =>
    type === 'secondary' &&
    css`
      font-size: 18px;
    `}

  ${({ gutter }) => {
    if (typeof gutter === 'number') {
      return css`
        margin: ${gutter}px;
      `;
    }
    if (typeof gutter === 'object') {
      return css`
        margin-top: ${gutter.top ? `${gutter.top}px` : 0};
        margin-bottom: ${gutter.bottom ? `${gutter.bottom}px` : 0};
        margin-left: ${gutter.left ? `${gutter.left}px` : 0};
        margin-right: ${gutter.right ? `${gutter.right}px` : 0};
      `;
    }
  }}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${palette.text[color]};
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  ${({ medium }) =>
    medium &&
    css`
      font-weight: 500;
    `}
    
  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}
`;

const Title = ({
  children,
  type = 'primary',
  gutter,
  align,
  color,
  bold,
  medium,
  inline,
  className,
  style,
  onClick,
}: ITypographyProps) => {
  return (
    <TitleBlock
      type={type}
      gutter={gutter}
      align={align}
      color={color}
      bold={bold}
      medium={medium}
      inline={inline}
      className={className}
      style={style}
      onClick={onClick}
    >
      {children}
    </TitleBlock>
  );
};

export default Title;
