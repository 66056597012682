import { makeAutoObservable, toJS } from 'mobx';
import RootStore from './RootStore';
import { ICompany } from 'types/company';

export default class CompanyStore {
  rootStore;
  private currentCompany: ICompany | null;

  constructor(root: RootStore) {
    this.rootStore = root;
    this.currentCompany = null;

    makeAutoObservable(this);
  }

  getCurrentCompany() {
    if (this.currentCompany === null)
      throw new Error("currentCompany can't be null");
    return toJS(this.currentCompany);
  }

  setCurrentCompany(company: ICompany | null) {
    this.currentCompany = company;
  }

  get currentCompanyValid() {
    return this.currentCompany !== null;
  }
}
