import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import FileViewer from 'components/file/FileViewer';
import { downloadFile } from 'lib/file';
import { observer } from 'mobx-react';
import printJS from 'print-js';
import { useStores } from 'stores/Context';
import styled from 'styled-components';

const FileViewerListBlock = styled.div`
  padding-top: 28px;
`;

const FileViewWrap = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

const FilePreviewModal = () => {
  const { etcStore } = useStores();
  const handleClose = () => {
    etcStore.previewedFiles = [];
  };

  if (etcStore.previewedFiles.length === 0) return null;
  return (
    <Modal
      visible={etcStore.previewedFiles.length > 0}
      onCancel={handleClose}
      footer={null}
      width={992}
      zIndex={10000}
      destroyOnClose
    >
      <FileViewerListBlock>
        {etcStore.previewedFiles.map((file) => (
          <FileViewWrap key={file.url}>
            <FileViewer key={file.url} file={file.url} />
            <Row gutter={8} justify="end" style={{ marginTop: 8 }}>
              <Col>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => downloadFile(file.url, file.filename)}
                >
                  다운로드
                </Button>
              </Col>
              {file.url.endsWith('.pdf') && (
                <Col>
                  <Button
                    type="primary"
                    icon={<PrinterOutlined />}
                    onClick={() => printJS(file.url)}
                  >
                    인쇄하기
                  </Button>
                </Col>
              )}
            </Row>
          </FileViewWrap>
        ))}
      </FileViewerListBlock>
    </Modal>
  );
};

export default observer(FilePreviewModal);
