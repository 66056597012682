import client from 'lib/api/client';
import { IAPIPageableResponse } from 'types/common';
import { ENoticeType, IFaq, INotice, IUseGuide } from 'types/service';
import qs from 'qs';

// Notice
export const getNotices = async ({ page }: { page: number }) => {
  const res = await client.post<IAPIPageableResponse<INotice[]>>(
    `/admin/notice/all/${page}/20/registerDt/D`,
    {},
  );
  return res.data;
};

export const addNotice = ({
  noticeType,
  title,
  content,
  isLive,
}: {
  noticeType: ENoticeType;
  title: string;
  content: string;
  isLive: boolean;
}) =>
  client.post('/admin/notice', {
    noticeType,
    title,
    content,
    isLive,
  });

export const updateNotice = ({
  noticeId,
  noticeType,
  title,
  content,
  isLive,
}: {
  noticeId: number;
  noticeType: ENoticeType;
  title: string;
  content: string;
  isLive: boolean;
}) =>
  client.patch(`/admin/notice/${noticeId}`, {
    noticeType,
    title,
    content,
    isLive,
  });

export const deleteNotices = (noticeIds: number[]) =>
  client.delete(
    `/admin/notices?${qs.stringify({ noticeIds }, { indices: false })}`,
  );

export const addNoticeAttaches = ({
  noticeId,
  files,
}: {
  noticeId: number;
  files: File[];
}) =>
  client.post(`/admin/notice/attach/${noticeId}`, {
    files,
  });

export const deleteNoticeAttaches = (attachIdsToBeDeleted: number[]) =>
  client.delete(
    `/admin/notice/attaches?${qs.stringify(
      { noticeAttachIds: attachIdsToBeDeleted },
      { indices: false },
    )}`,
  );

// Faq
export const getFaqs = async ({ page }: { page: number }) => {
  const res = await client.post<IAPIPageableResponse<IFaq[]>>(
    `/admin/faq/all/${page}/20/registerDt/D`,
    {},
  );
  return res.data;
};

export const addFaq = ({
  title,
  content,
  isLive,
}: {
  title: string;
  content: string;
  isLive: boolean;
}) =>
  client.post('/admin/faq', {
    title,
    content,
    isLive,
  });

export const updateFaq = ({
  faqId,
  title,
  content,
  isLive,
}: {
  faqId: number;
  title: string;
  content: string;
  isLive: boolean;
}) =>
  client.patch(`/admin/faq/${faqId}`, {
    title,
    content,
    isLive,
  });

export const deleteFaqs = (faqIds: number[]) =>
  client.delete(`/admin/faq?${qs.stringify({ faqIds }, { indices: false })}`);

export const addFaqAttaches = ({
  faqId,
  files,
}: {
  faqId: number;
  files: File[];
}) =>
  client.post(`/admin/faq/attach/${faqId}`, {
    files,
  });

export const deleteFaqAttaches = (attachIdsToBeDeleted: number[]) =>
  client.delete(
    `/admin/faq/attaches?${qs.stringify(
      { faqAttachIds: attachIdsToBeDeleted },
      { indices: false },
    )}`,
  );

// Use Guide
export const getUseGuides = async ({ page }: { page: number }) => {
  const res = await client.post<IAPIPageableResponse<IUseGuide[]>>(
    `/admin/use-guide/all/${page}/20/registerDt/D`,
    {},
  );
  return res.data;
};

export const addUseGuide = ({
  title,
  content,
  isLive,
}: {
  title: string;
  content: string;
  isLive: boolean;
}) =>
  client.post('/admin/use-guide', {
    title,
    content,
    isLive,
  });

export const updateUseGuide = ({
  useGuideId,
  title,
  content,
  isLive,
}: {
  useGuideId: number;
  title: string;
  content: string;
  isLive: boolean;
}) =>
  client.patch(`/admin/use-guide/${useGuideId}`, {
    title,
    content,
    isLive,
  });

export const deleteUseGuides = (useGuideIds: number[]) =>
  client.delete(
    `/admin/use-guide?${qs.stringify({ useGuideIds }, { indices: false })}`,
  );

export const addUseGuideAttaches = ({
  useGuideId,
  files,
}: {
  useGuideId: number;
  files: File[];
}) =>
  client.post(`/admin/use-guide/attach/${useGuideId}`, {
    files,
  });

export const deleteUseGuideAttaches = (attachIdsToBeDeleted: number[]) =>
  client.delete(
    `/admin/use-guide/attaches?${qs.stringify(
      { useGuideAttachIds: attachIdsToBeDeleted },
      { indices: false },
    )}`,
  );
