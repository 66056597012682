import { useQueryClient, useMutation, useQuery } from 'react-query';
import * as companyApi from 'lib/api/company';
import {
  EBuyerStatus,
  ECompanyStatus,
  ECompanyType,
  ICompanyStatus,
  ICompanyUpdate,
} from 'types/company';
import { useCallback, useMemo, useState } from 'react';
import xlsx from 'xlsx';
import { ICountry } from 'types/country';
import moment from 'moment';
import { message } from 'antd';

import client from 'lib/api/client';
import { IAPIResponse } from 'types/common';

export const useCompanies = (params: {
  page: number;
  companyType: ECompanyType | 'ALL';
  companyNameKo?: string;
  companyNameEn?: string;
  bizNumber?: string;
  ceoNameKo?: string;
  statuses: ECompanyStatus[];
}) => {
  return useQuery(
    [`company/companies`, params.page],
    () => companyApi.getCompanies(params),
    {
      select: (data) => data.result,
    },
  );
};

export const useUpdateCompanyStatus = ({ page }: { page: number }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (companyStatus: ICompanyStatus) =>
      companyApi.updateCompanyStatus(companyStatus),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['company/companies', page]);
        queryClient.invalidateQueries('notification/newUserExists');
      },
    },
  );
};

export const useCompany = (companyId: number) => {
  const { data: company, refetch } = useQuery(
    [`company/company`, companyId],
    () => companyApi.getCompany(companyId),
    {
      select: (res) => res.data.result,
    },
  );
  const { mutate: updateCompany, isLoading: updateLoading } = useMutation(
    (company: Omit<ICompanyUpdate, 'companyId'>) =>
      companyApi.updateCompany({ companyId, ...company }),
    {
      onSuccess: () => {
        refetch();
        message.info('수정되었습니다.');
      },
    },
  );
  return useMemo(() => ({ company, updateCompany, updateLoading }), [
    company,
    updateCompany,
    updateLoading,
  ]);
};

export const useBuyers = (params: {
  page: number;
  companyNameKo?: string;
  companyNameEn?: string;
  countryId?: number;
  companyName?: string;
}) => {
  return useQuery(['company/buyers'], () => companyApi.getBuyers(params), {
    select: (data) => data.result,
  });
};

export const useProductBuyers = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  return useQuery(
    [`company/product-buyers`, productId, countryId],
    () => companyApi.getProductBuyers({ productId, countryId }),
    {
      select: (data) => data.result,
    },
  );
};

export const useDownloadBuyers = () => {
  const [loading, setLoading] = useState(false);
  const downloadBuyers = useCallback(async (countries: ICountry[]) => {
    setLoading(true);
    const {
      data: { result: buyers },
    } = await companyApi.getAllBuyers();
    const parsedBuyers = buyers.map(
      ({
        buyerId,
        brandCompany: { companyNameKo, companyNameEn },
        countryId,
        companyName,
        companyAddress,
        representativeName,
        companyTel,
        companyEmail,
        status,
      }) => ({
        번호: buyerId,
        '회사명(국문)': companyNameKo,
        '회사명(영문)': companyNameEn,
        국가: countries.find((country) => country.countryId === countryId)
          ?.countryNameKo,
        바이어명: companyName,
        주소: companyAddress,
        담당자: representativeName,
        연락처: companyTel,
        이메일: companyEmail,
        상태:
          status === EBuyerStatus.NOR
            ? '정상'
            : status === EBuyerStatus.DEL
            ? '삭제'
            : '',
      }),
    );
    const workSheet = xlsx.utils.json_to_sheet(parsedBuyers, {
      cellStyles: true,
    });
    workSheet['!cols'] = [
      { wpx: 40 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 80 },
      { wpx: 120 },
      { wpx: 200 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 160 },
      { wpx: 40 },
    ];
    const workBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workBook, workSheet, '바이어 목록');
    xlsx.writeFile(
      workBook,
      `바이어목록_${moment().format('YYYY-MM-DD')}.xlsx`,
    );
    setLoading(false);
  }, []);
  return useMemo(() => ({ loading, downloadBuyers }), [
    loading,
    downloadBuyers,
  ]);
};

export const useFactories = (
  companyId: number,
  companyType: ECompanyType | undefined,
) => {
  const { data: factories = [] } = useQuery(
    'company/getFactories',
    () =>
      companyType === ECompanyType.MANUFACTURE
        ? companyApi.getFactories(companyId)
        : companyApi.getMaterialFactories(companyId),
    {
      select: (res) => res.data.result,
      enabled: companyType !== ECompanyType.BRAND,
    },
  );

  return factories;
};

export const useBizNumber = (userId: number | null) =>
  useQuery(
    ['company/getFactories', userId],
    () =>
      client.get<IAPIResponse<string>>(
        `/v1/admin/users/${userId}/company/biz-number`,
      ),
    {
      select: (res) => res.data.result,
      enabled: userId !== null,
    },
  );
