import { Link } from 'react-router-dom';
import { Col } from 'antd';

import ReceiptLongIcon from 'components/system/icon/ReceiptLongIcon';
import path from 'lib/path';

import * as S from './style';

const ClinicalTrialEstimateHeader = () => {
  return (
    <S.ClinicalTrialEstimateHeaderContainer>
      <S.ClinicalTrialEstimateHeaderRow justify="space-between">
        <Col>
          <Link to="/">
            <S.LogoImage
              src="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/logo.png"
              alt="30COS logo"
            />
          </Link>
        </Col>
        <Col>
          <Link to={path.clinicalTrial.estimate.list}>
            <ReceiptLongIcon style={{ cursor: 'pointer' }} color="#435968" />
          </Link>
        </Col>
      </S.ClinicalTrialEstimateHeaderRow>
    </S.ClinicalTrialEstimateHeaderContainer>
  );
};
export default ClinicalTrialEstimateHeader;
