import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';

import PageSizeSelect, {
  pageSizeOptions,
} from 'components/system/data/PageSizeSelect';
import { IBuyerCertificateStat } from 'types/stats';
import { ColumnsType } from 'antd/lib/table';

interface IProps {
  data?: IBuyerCertificateStat[];
  onDetailIconClick: (buyerId: number) => void;
}

const BuyerStatsTable = ({ data, onDetailIconClick }: IProps) => {
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [page, setPage] = useState(1);

  const columns: ColumnsType<IBuyerCertificateStat> = useMemo(
    () => [
      {
        title: '구분',
        dataIndex: 'buyerId',
        align: 'center',
        render: (_, __, index) => (page - 1) * 10 + index + 1,
      },
      {
        title: '바이어',
        dataIndex: 'companyNameKo',
        align: 'center' as const,
      },
      {
        title: '인증 완료 수',
        dataIndex: 'countCertFin',
        align: 'center',
      },
      {
        title: '진행 브랜드사',
        dataIndex: 'buyerId',
        align: 'center',
        render: (value: number) => (
          <FileTextOutlined onClick={() => onDetailIconClick(value)} />
        ),
      },
    ],
    [page],
  );

  return (
    <>
      <PageSizeSelect pageSize={pageSize} onChange={setPageSize} />
      <Table
        columns={columns}
        dataSource={data}
        rowKey={() => nanoid()}
        pagination={{
          pageSize,
          onChange(cur) {
            setPage(cur);
          },
        }}
      />
    </>
  );
};

export default BuyerStatsTable;
