import { Card } from 'antd';
import { Typography } from 'components/system';
import styled from 'styled-components';
import { useMainStat } from 'hooks/stats';
import history from 'lib/history';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { Margin } from 'components/system/general/Margin';

const NeedToCheckBlock = styled(Card)`
  height: 100%;

  .ant-card-body {
    padding: 32px 40px;
  }
`;

const BadgeBlock = styled.span`
  display: inline-block;
  margin-left: 2px;
  padding: 1px 6px 0 5px;
  height: 18px;
  border-radius: 9px;
  background-color: ${palette.primary};
  color: #fff;
  font-size: 12px;
  line-height: 18px;
`;

const NeedToCheck = () => {
  const { confirmCount, depRdyCount, conRdyCount } = useMainStat();
  return (
    <NeedToCheckBlock>
      <Typography.Headline type="secondary">처리할 항목</Typography.Headline>
      <Margin size={16} />
      <Typography.Text
        type="secondary"
        gutter={{ top: 16 }}
        inline
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(path.company.approve)}
      >
        가입 승인 필요 <BadgeBlock>{confirmCount}</BadgeBlock>
      </Typography.Text>
      <Margin size={20} />
      <Typography.Text
        type="secondary"
        gutter={{ top: 20 }}
        inline
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(path.estimate.root)}
      >
        입금 확인 필요 <BadgeBlock>{depRdyCount}</BadgeBlock>
      </Typography.Text>
      <Margin size={20} />
      <Typography.Text
        type="secondary"
        gutter={{ top: 20 }}
        inline
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(path.estimate.root)}
      >
        계약서 수령 확인 필요 <BadgeBlock>{conRdyCount}</BadgeBlock>
      </Typography.Text>
    </NeedToCheckBlock>
  );
};

export default NeedToCheck;
