import { Table } from 'antd';

import { Typography } from 'components/system';
import ClinicalTrialModal from '../ClinicalTrialModal';
import { useEstimateHistories } from 'hooks/clinicalTrial/process/processingEstimate';
import DescriptionIcon from 'components/system/icon/DescriptionIcon';
import palette from 'lib/styles/palette';

const EstimateHistoriesModal = ({
  clinicalTrialEstimateId,
  onClose,
}: {
  clinicalTrialEstimateId: number;
  onClose: () => void;
}) => {
  const { estimateHistories } = useEstimateHistories(clinicalTrialEstimateId);
  return (
    <ClinicalTrialModal
      onClose={onClose}
      style={{ paddingLeft: 40, paddingRight: 40 }}
    >
      <Typography.Title
        align="center"
        color="darkNavy"
        medium
        gutter={{ bottom: 24 }}
      >
        수정 이력
      </Typography.Title>
      <Table
        columns={[
          {
            title: 'No.',
            align: 'center',
            render: (_, __, index) => estimateHistories.length - index,
          },
          {
            title: '수정 일자',
            dataIndex: 'updateDt',
            align: 'center',
            render: (updateDt) => updateDt.slice(0, 10),
          },
          {
            title: '견적 금액 (원)',
            dataIndex: 'totalPrice',
            align: 'center',
            render: (totalPrice: string) => `${totalPrice.toLocaleString()}원`,
          },
          {
            title: '견적서',
            dataIndex: 'estimateUrl',
            align: 'center',
            render: (estimateUrl: string) => (
              <DescriptionIcon
                onClick={() => window.open(estimateUrl)}
                color={palette.text.darkNavy}
              />
            ),
          },
        ]}
        dataSource={estimateHistories.reverse()}
        rowKey="clinicalTrialEstimateHistoryId"
        pagination={false}
      />
    </ClinicalTrialModal>
  );
};

export default EstimateHistoriesModal;
