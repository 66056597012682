import { useState } from 'react';
import { Card, Col, Row, Table, Tabs } from 'antd';
import styled from 'styled-components';

import Tip from 'components/system/data/Tip';
import PeriodSelect from './PeriodSelect';
import { ColumnsType } from 'antd/es/table/interface';
import {
  ECertificateStatus,
  ICountryCertificateStat,
  IPeriod,
} from 'types/stats';
import { useQuery } from 'react-query';
import * as statsApi from 'lib/api/stats';
import PageSizeSelect, {
  pageSizeOptions,
} from 'components/system/data/PageSizeSelect';
import moment from 'moment';
import { DATE_FORMAT } from 'lib/consts';
import useUpdateEffect from 'hooks/useUpdateEffect';

const CountryCertificateTableBlock = styled(Card)`
  flex: 1 1 auto;

  .ant-card-body {
    padding: 32px;
  }
`;

const CountryCertificateTable = () => {
  const [status, setStatus] = useState(ECertificateStatus.REQ);

  const [period, setPeriod] = useState<IPeriod>({
    from: '2021-03-23',
    to: moment().format(DATE_FORMAT),
  });

  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [page, setPage] = useState(1);

  const { data: countryCertificateStats } = useQuery(
    ['stats/countryCertificateStats', status, period],
    () => statsApi.getCountryCertificateStats(status, period),
    { select: (res) => res.data.result },
  );

  const columns: ColumnsType<ICountryCertificateStat> = [
    {
      title: '구분',
      align: 'center',
      render: (_, __, index) => (page - 1) * 10 + index + 1,
    },
    { title: '국가', dataIndex: 'countryNameKo', align: 'center' },
    {
      title:
        status === ECertificateStatus.FIN
          ? '인증 완료 수'
          : status === ECertificateStatus.ON
          ? '인증 진행 수'
          : '인증 신청 수',
      dataIndex:
        status === ECertificateStatus.FIN
          ? 'countCertFin'
          : status === ECertificateStatus.ON
          ? 'countCertOn'
          : 'countCertReq',
      align: 'center',
    },
    ...(status === ECertificateStatus.FIN
      ? [
          {
            title: (
              <Row justify="center" align="middle" gutter={4}>
                <Col>평균 소요일</Col>
                <Col>
                  <Tip
                    trigger="click"
                    bodyStyle={{ padding: '12px 24px 11px' }}
                  >
                    인증 신청일부터 인증 완료일까지 걸린 기간 입니다.
                  </Tip>
                </Col>
              </Row>
            ),
            dataIndex: 'avgCertTerm',
            align: 'center' as const,
            render: (requiredDateAverage: number) => `${requiredDateAverage}일`,
          },
        ]
      : []),
  ];

  useUpdateEffect(() => {
    setPage(1);
  }, [status]);
  return (
    <CountryCertificateTableBlock>
      <PeriodSelect onSearch={(_, period) => setPeriod(period)} />
      <Tabs
        style={{ padding: '0 4px', marginTop: 24 }}
        activeKey={status}
        onChange={(status) => setStatus(status as ECertificateStatus)}
      >
        <Tabs.TabPane
          key={ECertificateStatus.REQ}
          tab="인증 신청"
          style={{ marginLeft: 10 }}
        />
        <Tabs.TabPane key={ECertificateStatus.ON} tab="인증 진행" />
        <Tabs.TabPane key={ECertificateStatus.FIN} tab="인증 완료" />
      </Tabs>
      <PageSizeSelect pageSize={pageSize} onChange={setPageSize} />
      <Table
        columns={columns}
        dataSource={countryCertificateStats}
        rowKey={({ countryId }) => countryId}
        pagination={{
          pageSize,
          current: page,
          onChange: setPage,
        }}
      />
    </CountryCertificateTableBlock>
  );
};

export default CountryCertificateTable;
