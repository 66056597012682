import { Card, Col, Row, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ChartData, ChartOptions } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { Typography } from 'components/system';
import PageSizeSelect, {
  pageSizeOptions,
} from 'components/system/data/PageSizeSelect';
import { useCountryOrCompanySalesStats } from 'hooks/stats';
import useUpdateEffect from 'hooks/useUpdateEffect';
import { DATE_FORMAT } from 'lib/consts';
import palette from 'lib/styles/palette';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ICompanySalesStat, ICountrySalesStat } from 'types/stats';
import PeriodRangeSelect from './PeriodRangeSelect';

const CountryAndCompanySalesStatsBlock = styled(Row)``;

const TableBlock = styled(Card)`
  .ant-card-body {
    padding: 36px 40px;
  }
`;
const CountryAndCompanySalesStats = () => {
  const [type, setType] = useState<'country' | 'company'>('country');
  const [period, setPeriod] = useState({
    from: '2021-03-23',
    to: moment().format(DATE_FORMAT),
  });
  const salesStats = useCountryOrCompanySalesStats(type, period);
  const firstSalesStats = salesStats[0] || {};
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [page, setPage] = useState(1);
  const columns: ColumnsType<ICountrySalesStat | ICompanySalesStat> = [
    {
      title: '구분',
      align: 'center',
      render: (_, __, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: type === 'country' ? '국가' : '브랜드사',
      dataIndex: type === 'country' ? 'countryNameKo' : 'companyNameKo',
      align: 'center',
    },
    {
      title: '매출액',
      dataIndex: 'sumSales',
      align: 'center',
      render: (sumSales) => `${sumSales.toLocaleString()}원`,
    },
    {
      title: '인증 완료 수 (SKU)',
      dataIndex: 'countCertFin',
      align: 'center',
    },
  ];

  const salesChartData: ChartData = useMemo(() => {
    const totalSales = (salesStats as ICountrySalesStat[]).reduce(
      (total: number, salesStat) => total + salesStat.sumSales,
      0,
    );
    return {
      labels: salesStats
        .slice(0, 10)
        .map((salesStat: ICountrySalesStat | ICompanySalesStat) =>
          'companyNameKo' in salesStat
            ? salesStat.companyNameKo
            : salesStat.countryNameKo,
        ),
      datasets: [
        {
          data: salesStats
            .slice(0, 10)
            .map(({ sumSales }: ICountrySalesStat | ICompanySalesStat) =>
              Math.floor((sumSales / totalSales) * 100),
            ),
          backgroundColor: salesStats
            .slice(0, 10)
            .map(
              (_: ICountrySalesStat | ICompanySalesStat, index: number) =>
                `${palette.primary}${(100 + (130 - index * 13)).toString(16)}`,
            ),
        },
      ],
    };
  }, [salesStats]);
  const salesChartOptions: ChartOptions = useMemo(
    () => ({
      plugins: {
        datalabels: {
          textAlign: 'center',
          color: '#fff',
          formatter: function (value: any) {
            return `${value}%`;
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          cornerRadius: 16,
          caretSize: 0,
          displayColors: false,
          xAlign: 'left',
          backgroundColor: '#fff',
          bodyColor: '#222',
          borderColor: palette.primary,
          borderWidth: 1,
          callbacks: {
            title: () => '',
            label: function (context: any) {
              return `${context.label} ${context.raw}%`;
            },
          },
        },
      },
    }),
    [],
  );

  useUpdateEffect(() => {
    setPage(1);
  }, [type]);

  return (
    <CountryAndCompanySalesStatsBlock gutter={36}>
      <Col span={16}>
        <TableBlock>
          <PeriodRangeSelect
            label="기간"
            onSearch={(_, period) => setPeriod(period)}
          />
          <Tabs
            style={{ padding: '0 4px', marginTop: 24 }}
            activeKey={type}
            onChange={(type) =>
              (type === 'country' || type === 'company') && setType(type)
            }
          >
            <Tabs.TabPane
              key="country"
              tab="국가별"
              style={{ marginLeft: 10 }}
            />
            <Tabs.TabPane key="company" tab="브랜드사별" />
          </Tabs>
          <PageSizeSelect pageSize={pageSize} onChange={setPageSize} />
          <Table
            columns={columns}
            dataSource={salesStats}
            pagination={{
              pageSize,
              current: page,
              onChange: setPage,
            }}
            rowKey={() => nanoid()}
          />
        </TableBlock>
      </Col>
      <Col span={8}>
        <Card style={{ height: '100%' }}>
          <Typography.Headline type="secondary" gutter={{ bottom: 60 }}>
            매출이 가장 높은 {type === 'country' ? '국가' : '브랜드사'}는
            <br />
            {'companyNameKo' in firstSalesStats
              ? firstSalesStats.companyNameKo
              : firstSalesStats.countryNameKo}{' '}
            입니다.
          </Typography.Headline>
          <ReactChart
            type="doughnut"
            data={salesChartData}
            options={salesChartOptions}
          />
          <Typography.Text
            type="secondary"
            bold
            style={{ textAlign: 'center' }}
            gutter={{ top: 30 }}
          >
            (상위 10개국)
          </Typography.Text>
        </Card>
      </Col>
    </CountryAndCompanySalesStatsBlock>
  );
};

export default CountryAndCompanySalesStats;
