export interface IPeriod {
  from: string;
  to: string;
}

export interface ISalesStat {
  countCertFin: number;
  sumSales: number;
}

export interface IPeriodSalesStat extends ISalesStat {
  targetDate: string;
  targetMonth: string;
}

export interface ICountrySalesStat extends ISalesStat {
  countryId: number;
  countryNameKo: string;
  avgCertTerm: number;
}
export interface ICompanySalesStat extends ISalesStat {
  companyNameKo: string;
}
export interface IProductRegisterStat {
  manufacturerId: number | null;
  brandCompanyId: number | null;
  countProductReg: number;
  companyNameKo: string;
}

export interface IProductRegisterStatWithShare {
  manufacturerId: number | null;
  brandCompanyId: number | null;
  countProductReg: number;
  companyNameKo: string;
  share: number;
}
export interface ICountryCertificateStat {
  countryId: number;
  countCertFin: number;
  countryNameKo: string;
  sumSales: number;
}

export interface ICompanyCertificateStat {
  countryId: number;
}

export interface ICategoryCertificateStat {
  cosmeticCategoryIdDepth2: number;
  categoryName: string;
  countCertFin: number;
}
export interface ICountryCategoryCertificateStat {
  countryId: number;
  countryNameKo: string;
  countCertFin: number;
}

export interface IBrandCategoryCertificateStat {
  categoryName: string;
  countCertFin: number;
}

export interface IBuyerCertificateStat {
  countCertFin: number;
  buyerId: number | null;
  companyNameKo: string;
  brandCompanyId: number | null;
}

export interface IMainStat {
  prdRegCount: number;
  cerFinCount: number;
  brandCompanyCount: number;
  manufacturerCount: number;
  yesterdaySales: number;
  theDayBeforeYesterday: number;
  confirmCount: number;
  depRdyCount: number;
  conRdyCount: number;
  yesterdayPrdRdyCount: number;
  yesterdayConReqCount: number;
  yesterdayDepCfmCount: number;
  yesterdayCerFinCount: number;
}

export enum EPeriodType {
  ALL = 'all',
  MONTH = 'month',
  QUARTER = 'quarter',
  HALF = 'half',
  YEAR = 'year',
}

export enum EPeriodRangeType {
  ALL = 'all',
  MONTH = 'month',
  DATE = 'date',
}

export enum EProgressPeriodType {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
}

export enum ECertificateStatus {
  REQ = 'req',
  ON = 'on',
  FIN = 'fin',
}

export enum ECategoryCertificateSearchType {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FUNCTIONAL = 'functional',
}

export enum ECompanyShareType {
  All_MANUFACTURER_SHARE = 'allManufacturerShare',
  BRAND_SHARE_FOR_MANUFACTURER = 'brandShareForManufacturer',
  MANUFACTURER_SHARE_FOR_BRAND = 'manufacturerShareForBrand',
}
