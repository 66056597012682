import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import * as etcApi from 'lib/api/etc';

interface IEditorProps {
  value: string;
  setValue: (value: string) => void;
}

const editorOptions = {
  width: 'auto',
  height: 'auto',
  minHeight: '400px',

  templates: [
    {
      name: 'Template-1',
      html: '<p>HTML source1</p>',
    },
    {
      name: 'Template-2',
      html: '<p>HTML source2</p>',
    },
  ],
  buttonList: [
    [
      'formatBlock',
      'fontColor',
      'hiliteColor',
      'bold',
      'underline',
      'strike',
      'superscript',
      'removeFormat',
    ],
    ['outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
    ['link', 'image', 'video', 'fullScreen', 'codeView', 'preview'],
  ],
};

const Editor: React.FC<IEditorProps> = ({ value, setValue }) => {
  return (
    <SunEditor
      lang="ko"
      setOptions={editorOptions}
      defaultValue={value}
      onChange={setValue}
      onImageUploadBefore={(files, info, uploadHandler) => {
        etcApi
          .attachFiles(files[0])
          .then(({ data: { result } }) => {
            uploadHandler({
              result: [
                {
                  url: result[0],
                  name: 'test',
                },
              ],
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }}
    />
  );
};

export default Editor;
