import palette from 'lib/styles/palette';
import styled from 'styled-components';

export const BigCloseIcon = styled.div.attrs({ className: 'close-icon' })<{
  color?: string;
}>`
  position: relative;
  width: 44px;
  height: 44px;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    display: block;
    width: 30px;
    height: 2px;
    background-color: ${({ color }) => color || palette.text.darkNavy};
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
