import { Spin, Form } from 'antd';
import { Typography } from 'components/system';

import { useGetCountryVendorInfo } from 'hooks/certificate';
import { ICountryVendorInfo } from 'types/certificate';

const CountryVendorModalContent = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const { data: countryVendorInfo = null, isLoading } = useGetCountryVendorInfo(
    {
      productId,
      countryId,
    },
  );

  const getHoliday = (countryVendorInfo: ICountryVendorInfo | null) => {
    if (countryVendorInfo) {
      let word = '';
      if (!countryVendorInfo.isOpenMonday) {
        word += '월 ';
      }
      if (!countryVendorInfo.isOpenTuesday) {
        word += '화 ';
      }
      if (!countryVendorInfo.isOpenWednesday) {
        word += '수 ';
      }
      if (!countryVendorInfo.isOpenThursday) {
        word += '목 ';
      }
      if (!countryVendorInfo.isOpenFriday) {
        word += '금 ';
      }
      if (!countryVendorInfo.isOpenSaturday) {
        word += '토 ';
      }
      if (!countryVendorInfo.isOpenSunday) {
        word += '일';
      }
      if (!word) {
        word = '연중무휴';
      }
      return word;
    }

    return '';
  };

  return (
    <Spin spinning={isLoading}>
      {countryVendorInfo !== null && (
        <Form size="small" style={{ paddingTop: 20 }}>
          <Form.Item label="판매처 정보 입력 여부">
            <Typography.Text type="secondary" medium inline>
              {countryVendorInfo.isRegisterVendor ? '예' : '아니오'}
            </Typography.Text>
          </Form.Item>
          {countryVendorInfo.isRegisterVendor && (
            <>
              <Form.Item label="현지 판매처 (스킨가든) 정보를 사용 여부">
                <Typography.Text type="secondary" medium inline>
                  {countryVendorInfo.isUsePartnerVendor ? '예' : '아니오'}
                </Typography.Text>
              </Form.Item>
              <Form.Item label="판매처">
                <Typography.Text type="secondary" medium inline>
                  {countryVendorInfo.vendorName}
                </Typography.Text>
              </Form.Item>
              {countryVendorInfo.vendorTel && (
                <Form.Item label="전화번호">
                  <Typography.Text type="secondary" medium inline>
                    {countryVendorInfo.vendorTel}
                  </Typography.Text>
                </Form.Item>
              )}
              {countryVendorInfo.businessStartHour && (
                <Form.Item label="회사 운영 시간">
                  <Typography.Text type="secondary" medium inline>
                    {`시작: ${countryVendorInfo.businessStartHour}, 종료: ${countryVendorInfo.businessEndHour}`}
                  </Typography.Text>
                </Form.Item>
              )}
              <Form.Item label="휴일">
                <Typography.Text type="secondary" medium inline>
                  {getHoliday(countryVendorInfo)}
                </Typography.Text>
              </Form.Item>
            </>
          )}
        </Form>
      )}
    </Spin>
  );
};

export default CountryVendorModalContent;
