const MoreIcon = ({
  width = 24,
  height = 24,
  color = '#222',
  style,
  onClick,
}: {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
  onClick: () => void;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    width={`${width}px`}
    height={`${height}px`}
    fill={color}
    style={{ cursor: 'pointer', ...style }}
    onClick={onClick}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
  </svg>
);

export default MoreIcon;
