import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import {
  FileExcelOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Col, Input, Radio, Row, Select } from 'antd';

import { useCertificates, useDownloadCertificates } from 'hooks/certificate';
import path from 'lib/path';
import { useStores } from 'stores/Context';
import CertificateCompanyDetailCollapse from './CertificateCompanyDetailCollapse';
import { EProcessStage } from 'types/common';
import {
  ECertificateSearchTypeOptions,
  ICertificatePageState,
} from 'types/certificate';

const CertificateCompanyDetailBlock = styled.div``;

const CertificateCompanyDetail = ({
  companyId,
  page,
  initialSearchType,
  initialSearchValue,
  initialSearchStatus,
}: {
  companyId: number;
  page: number;
  initialSearchType: ECertificateSearchTypeOptions;
  initialSearchValue: string;
  initialSearchStatus: EProcessStage;
}) => {
  const history = useHistory();
  const [searchType, setSearchType] = useState<ECertificateSearchTypeOptions>(
    initialSearchType,
  );
  const [searchStatus, setSearchStatus] = useState<EProcessStage>(
    initialSearchStatus,
  );
  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const { companyStore } = useStores();
  const currentCompany = companyStore.getCurrentCompany();
  const {
    loading: downloadLoading,
    downloadCertificates,
  } = useDownloadCertificates(currentCompany);

  const searchGroupOptions = useMemo(
    () => [
      { value: EProcessStage.ONGOING, label: '진행중인 제품' },
      { value: EProcessStage.FINISHED, label: '인증 완료' },
    ],
    [],
  );
  const searchTypeOptions = useMemo(
    () => [
      {
        value: ECertificateSearchTypeOptions.PRODUCT_NAME_EN,
        label: '제품명 (영문)',
      },
      { value: ECertificateSearchTypeOptions.COUNTRY_NAME_KO, label: '국가' },
      {
        value: ECertificateSearchTypeOptions.CERT_REQUEST_NO,
        label: '인증신청번호',
      },
    ],
    [],
  );

  const {
    data: ongoingCertificateData,
    refetch: ongoingRefetch,
  } = useCertificates({
    companyId: Number(companyId),
    isFinish: false,
    page,
    ...(searchValue && { [searchType]: searchValue }),
  });
  const {
    data: finishedCertificateData,
    refetch: finishedRefetch,
  } = useCertificates({
    companyId: Number(companyId),
    isFinish: true,
    page,
    ...(searchValue && { [searchType]: searchValue }),
  });

  const replaceState = (state?: ICertificatePageState) => {
    history.replace(`${path.certificate.company}/${companyId}`, state);
  };

  const refetchByStatus = (status: EProcessStage) => {
    if (status === EProcessStage.ONGOING) {
      setTimeout(ongoingRefetch);
    } else {
      setTimeout(finishedRefetch);
    }
  };

  const handleClickRefresh = () => {
    replaceState();
    setSearchValue('');
    refetchByStatus(searchStatus);
  };

  useEffect(() => {
    if (initialSearchValue) {
      refetchByStatus(searchStatus);
    }
  }, [initialSearchValue]);

  const searchCertificates = () => {
    replaceState({
      searchType,
      searchValue,
      searchStatus,
    });
    refetchByStatus(searchStatus);
  };

  const handleChangeCollapse = (status?: EProcessStage) => {
    const newStatus = status
      ? status
      : searchStatus === EProcessStage.ONGOING
      ? EProcessStage.FINISHED
      : EProcessStage.ONGOING;
    replaceState({
      searchStatus: newStatus,
    });
    setSearchStatus(newStatus);
    setSearchType(ECertificateSearchTypeOptions.PRODUCT_NAME_EN);
    setSearchValue('');
  };

  const handleChangeRadio = (status: EProcessStage) => {
    replaceState({
      searchStatus: status,
    });
    setSearchStatus(status);
    setSearchType(ECertificateSearchTypeOptions.PRODUCT_NAME_EN);
    setSearchValue('');
    refetchByStatus(searchStatus);
  };

  return (
    <CertificateCompanyDetailBlock>
      <Row justify="end" gutter={8}>
        <Col>
          <Button icon={<SyncOutlined />} onClick={handleClickRefresh}>
            새로고침
          </Button>
        </Col>
        <Col flex="auto">
          <Button
            loading={downloadLoading}
            icon={<FileExcelOutlined />}
            onClick={() => downloadCertificates()}
          >
            엑셀로 저장하기
          </Button>
        </Col>
        <Col>
          <Radio.Group
            options={searchGroupOptions}
            value={searchStatus}
            onChange={(e) => {
              handleChangeRadio(e.target.value);
            }}
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
        <Col>
          <Select
            value={searchType}
            style={{ width: 140 }}
            options={searchTypeOptions}
            onChange={(searchType) => {
              replaceState({
                page,
                searchType,
                searchStatus,
              });
              setSearchValue('');
              setSearchType(searchType);
            }}
          />
        </Col>
        <Col>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyUp={(e) => {
              e.key === 'Enter' && searchCertificates();
            }}
          />
        </Col>
        <Col>
          <Button icon={<SearchOutlined />} onClick={searchCertificates}>
            검색
          </Button>
        </Col>
      </Row>
      <CertificateCompanyDetailCollapse
        page={page}
        searchType={searchType}
        searchValue={searchValue}
        searchStatus={searchStatus}
        ongoingCertificateData={ongoingCertificateData}
        finishedCertificateData={finishedCertificateData}
        ongoingRefetch={ongoingRefetch}
        finishedRefetch={finishedRefetch}
        onChangeCollapse={handleChangeCollapse}
        replaceState={replaceState}
      />
    </CertificateCompanyDetailBlock>
  );
};

export default CertificateCompanyDetail;
