import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Typography } from 'antd';
import { useChangeEstimateAdditionalStatus } from 'hooks/estimate';
import { useMemo } from 'react';
import { useStores } from 'stores/Context';
import {
  EEstimateAdditionalStatus,
  IEstimate,
  IEstimateAdditional,
} from 'types/estimate';

interface EstimateAdditionalListModalProps {
  estimate: IEstimate;
  onClose: () => void;
  onChangeStatusSuccess: () => void;
}

const EstimateAdditionalListModal: React.FC<EstimateAdditionalListModalProps> = ({
  estimate,
  onClose,
  onChangeStatusSuccess,
}) => {
  const { estimateCode, company, estimateAdditionals } = estimate;
  const { companyNameKo } = company;
  const {
    mutate: changeEstimateAdditionalState,
  } = useChangeEstimateAdditionalStatus({ onSuccess: onChangeStatusSuccess });
  const { etcStore } = useStores();
  const columns = useMemo(
    () => [
      {
        title: '견적서 번호',
        dataIndex: 'estimateAdditionalCode',
        align: 'center' as const,
      },
      {
        title: '인증신청번호',
        dataIndex: 'certRequestNo',
        align: 'center' as const,
      },
      {
        title: '제품명 (영문)',
        dataIndex: 'productNameEn',
        align: 'center' as const,
      },
      {
        title: '국가',
        dataIndex: 'countryNameKo',
        align: 'center' as const,
      },
      {
        title: '추가견적 발행일',
        dataIndex: 'registerDt',
        align: 'center' as const,
        render: (registerDt: string) => registerDt.slice(0, 10),
      },
      {
        title: '견적서',
        dataIndex: 'fileUrl',
        align: 'center' as const,
        render: (fileUrl: string) => (
          <FilePdfOutlined
            style={{ fontSize: 16, cursor: 'pointer' }}
            onClick={() => (etcStore.previewedFiles = [{ url: fileUrl }])}
          />
        ),
      },
      {
        title: '금액',
        dataIndex: 'totalPrice',
        align: 'center' as const,
        render: (totalPrice: number) => `${totalPrice.toLocaleString()}원`,
      },
      {
        title: '처리 현황',
        align: 'center' as const,
        render: ({ estimateAdditionalId, status }: IEstimateAdditional) =>
          status === EEstimateAdditionalStatus.DEP_RDY ? (
            <Button
              onClick={() =>
                Modal.confirm({
                  content: '정말로 입금 완료 처리를 하시겠습니까?',
                  onOk: () =>
                    changeEstimateAdditionalState({
                      estimateAdditionalId,
                      status: EEstimateAdditionalStatus.DEP_FIN,
                    }),
                })
              }
            >
              입금 확인
            </Button>
          ) : (
            <Typography.Text>입금 확인 완료</Typography.Text>
          ),
      },
    ],
    [],
  );
  if (estimateAdditionals === null)
    throw new Error("estimateAdditionals can't be null");
  return (
    <Modal
      title={`추가견적 목록 [${estimateCode} / ${companyNameKo}]`}
      width={1000}
      visible={true}
      footer={false}
      onCancel={onClose}
    >
      <Table
        columns={columns}
        dataSource={estimateAdditionals}
        bordered
        size="small"
        rowKey={({ estimateAdditionalId }) => estimateAdditionalId}
        pagination={false}
      />
    </Modal>
  );
};

export default EstimateAdditionalListModal;
