import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import * as clinicalTrialProcessApi from 'lib/api/clinicalTrial/clinicalTrialProcess';
import client from 'lib/api/client';
import { IAPIResponse } from 'types/common';
import { IClinicalTrialProcessReport } from 'types/clinicalTrial/process/processingEstimateDetail';

const processingEstimateDetailKey = 'process-items';

export const useProcessingEstimateDetail = (
  clinicalTrialEstimateId: number,
) => {
  const {
    data: clinicalTrialProcessItems = [],
    isLoading: getLoading,
    refetch: clinicalTrialProcessItemsRefetch,
  } = useQuery(
    [processingEstimateDetailKey, clinicalTrialEstimateId],
    () =>
      clinicalTrialProcessApi.getClinicalTrialProcessItems(
        clinicalTrialEstimateId,
      ),
    { select: (res) => res.data.result },
  );

  return useMemo(
    () => ({
      clinicalTrialProcessItems,
      getLoading,
      clinicalTrialProcessItemsRefetch,
    }),
    [clinicalTrialProcessItems, getLoading],
  );
};

export const useUpdateProductName = () => {
  const queryClient = useQueryClient();
  const { mutate: updateProductName, isLoading: updateLoading } = useMutation(
    clinicalTrialProcessApi.updateProductName,
    {
      onSuccess: () => {
        queryClient.refetchQueries(processingEstimateDetailKey);
      },
    },
  );

  return useMemo(
    () => ({
      updateProductName,
      updateLoading,
    }),
    [updateLoading],
  );
};

export const useUpdateSampleReceiptDate = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateSampleReceiptDate,
    isLoading: updateLoading,
  } = useMutation(clinicalTrialProcessApi.updateSampleReceiptDate, {
    onSuccess: () => {
      queryClient.refetchQueries(processingEstimateDetailKey);
    },
  });

  return useMemo(
    () => ({
      updateSampleReceiptDate,
      updateLoading,
    }),
    [updateLoading],
  );
};

export const useUpdateSampleDelivery = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateSampleDelivery,
    isLoading: updateLoading,
  } = useMutation(clinicalTrialProcessApi.updateSampleDelivery, {
    onSuccess: () => {
      queryClient.refetchQueries(processingEstimateDetailKey);
    },
  });

  return useMemo(
    () => ({
      updateSampleDelivery,
      updateLoading,
    }),
    [updateLoading],
  );
};

export const useUpdateStopReason = () => {
  const queryClient = useQueryClient();
  const { mutate: updateStopReason, isLoading: updateLoading } = useMutation(
    clinicalTrialProcessApi.updateStopReason,
    {
      onSuccess: () => {
        queryClient.refetchQueries(processingEstimateDetailKey);
      },
    },
  );

  return useMemo(
    () => ({
      updateStopReason,
      updateLoading,
    }),
    [updateLoading],
  );
};

export const useUpdateRemark = () => {
  const queryClient = useQueryClient();
  const { mutate: updateRemark, isLoading: updateLoading } = useMutation(
    clinicalTrialProcessApi.updateRemark,
    {
      onSuccess: () => {
        queryClient.refetchQueries(processingEstimateDetailKey);
      },
    },
  );

  return useMemo(
    () => ({
      updateRemark,
      updateLoading,
    }),
    [updateLoading],
  );
};

export const useUpdateTestCompletion = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateTestCompletion,
    isLoading: updateLoading,
  } = useMutation(clinicalTrialProcessApi.updateTestCompletion, {
    onSuccess: () => {
      queryClient.refetchQueries(processingEstimateDetailKey);
    },
  });

  return useMemo(
    () => ({
      updateTestCompletion,
      updateLoading,
    }),
    [updateLoading],
  );
};

export const useUpdateEstimateStopReason = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateEstimateStopReason,
    isLoading: updateLoading,
  } = useMutation(clinicalTrialProcessApi.updateEstimateStopReason, {
    onSuccess: () => {
      queryClient.refetchQueries(processingEstimateDetailKey);
    },
  });

  return useMemo(
    () => ({
      updateEstimateStopReason,
      updateLoading,
    }),
    [updateLoading],
  );
};

export const useClinicalTrialProcessReports = () => {
  const {
    mutate: getClinicalTrialProcessReports,
    isLoading,
  } = useMutation((clinicalTrialProcessItemId: number) =>
    client.get<IAPIResponse<IClinicalTrialProcessReport[]>>(
      `/v1/admin/clinical-trial/process-items/${clinicalTrialProcessItemId}/reports`,
    ),
  );

  return useMemo(
    () => ({
      getClinicalTrialProcessReports,
      isLoading,
    }),
    [getClinicalTrialProcessReports, isLoading],
  );
};
