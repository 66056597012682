import { ISavedFile } from 'types/common';

export interface IOptionItem {
  clinicalTrialItemOptionName: string; // HINT : 영문 성적서 or 중문 성적서
}

// RDY(진행) | REV(시료 수령) | DLV(시료 발송) | COM(완료) | STP(중단)
export enum EClinicalTrialProcessItemStatus {
  RDY = 'RDY',
  REV = 'REV',
  COM = 'COM',
  STP = 'STP',
  DLV = 'DLV',
}
export interface IClinicalTrialProcessItem {
  clinicalTrialProcessItemId: number;
  clinicalTrialItemName: string; // 시험항목명
  agencyName: string; // 시험기관
  productName: string | null; // 제품명
  sampleReceiptDate: string | null; // 시료 수령일
  sampleDeliveryDate: string | null; // 시료 발송일
  completionDueDate: string | null;
  completionDueDDay: number | null;
  completionDate: string;
  remark: string;
  stopDate: string;
  stopReason: string;
  status: EClinicalTrialProcessItemStatus;
  optionItems: IOptionItem[];
  categoryDataId: number;
  categoryName: string;
}

export interface IUpdateProductName {
  productName: string;
  clinicalTrialProcessItemId: number;
}

export interface IUpdateSampleReceiptDate {
  sampleReceiptDate: string;
  clinicalTrialProcessItemId: number;
}

export interface IUpdateSampleDelivery {
  sampleDeliveryDate: string;
  completionDueDate: string;
  clinicalTrialProcessItemId: number;
}

export interface IUpdateStopReason {
  stopReason: string;
  clinicalTrialProcessItemId: number;
}

export interface IUpdateTestCompletion {
  clinicalTrialProcessItemId: number;
  files: File[];
  completionDate: string;
}

export interface IUpdateRemark {
  clinicalTrialProcessItemId: number;
  remark: string;
}

export interface IClinicalTrialProcessReport extends ISavedFile {
  clinicalTrialProcessReportId: number;
  clinicalTrialProcessItemId: number;
  registerDt: string;
}
