import { FilePdfOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import prompt from 'antd-prompt';
import SearchInput from 'components/form/SearchInput';
import { useCompanies, useUpdateCompanyStatus } from 'hooks/company';
import { useEffect } from 'react';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStores } from 'stores/Context';
import PageTemplate from 'templates/PageTemplate';
import {
  ECompanyStatus,
  ECompanySearchType,
  ICompany,
  ICompanyStatus,
  ECompanyType,
} from 'types/company';

const CompanyApprovePage = () => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  // const [isManufacturer, setIsManufacturer] = useState<undefined | boolean>();
  const [companyType, setCompanyType] = useState<ECompanyType | 'ALL'>('ALL');
  const [searchType, setSearchType] = useState<ECompanySearchType>(
    ECompanySearchType.COMPANY_NAME_KO,
  );
  const [searchValue, setSearchValue] = useState('');
  const { data, refetch } = useCompanies({
    page,
    companyType,
    [searchType]: searchValue,
    statuses: [ECompanyStatus.RDY],
  });
  const { content, size, totalElements } = { ...data };
  const changeCompanyMutation = useUpdateCompanyStatus({ page });
  const { etcStore } = useStores();
  const changeCompanyStatus = useCallback(
    async (companyStatus: ICompanyStatus) => {
      if (companyStatus.status === ECompanyStatus.REJ) {
        try {
          const rejectReason = await prompt({
            title: '반려 사유',
            rules: [
              {
                required: true,
                message: '반려 사유를 입력해 주세요.',
              },
            ],
          });
          if (typeof rejectReason === 'string') {
            changeCompanyMutation.mutate({ ...companyStatus, rejectReason });
          }
        } catch (error) {
          message.warn('반려 사유를 입력해 주세요.');
        }
      } else {
        Modal.confirm({
          icon: false,
          content: '정말로 수정하시겠습니까?',
          onOk: () => {
            changeCompanyMutation.mutate(companyStatus);
          },
        });
      }
    },
    [changeCompanyMutation],
  );
  const columns = useMemo(
    () => [
      { title: 'No.', dataIndex: 'companyId', align: 'center' as const },
      {
        title: (
          <Typography.Text>
            아이디
            <br />
            (사업자등록번호)
          </Typography.Text>
        ),
        align: 'center' as const,
        render: ({ bizNumber }: ICompany) => bizNumber,
      },
      {
        title: '회사명 (국문)',
        dataIndex: 'companyNameKo',
        align: 'center' as const,
      },
      {
        title: '회사명 (영문)',
        dataIndex: 'companyNameEn',
        align: 'center' as const,
      },
      { title: '대표자명', dataIndex: 'ceoNameKo', align: 'center' as const },
      {
        title: '구분',
        align: 'center' as const,
        render: ({ companyType }: ICompany) =>
          companyType === 'BRAND'
            ? '책임판매업자'
            : companyType === 'MANUFACTURE'
            ? '제조사'
            : '원료사',
      },
      {
        title: '사업자 등록증',
        align: 'center' as const,
        render: ({ bizLicenseUrl }: ICompany) => (
          <FilePdfOutlined
            style={{ fontSize: 16, cursor: 'pointer' }}
            onClick={() => {
              etcStore.previewedFiles = [{ url: bizLicenseUrl }];
            }}
          />
        ),
      },
      {
        title: '직접 입력 여부',
        align: 'center' as const,
        render: ({ isCompanyNameUserInserted }: ICompany) => {
          return isCompanyNameUserInserted ? 'Y' : 'N';
        },
      },
      {
        title: '가입 상태',
        align: 'center' as const,
        render: ({ companyId, status }: ICompany) => (
          <Select
            value={status}
            options={[
              { value: ECompanyStatus.RDY, label: '가입 대기' },
              { value: ECompanyStatus.CFM, label: '가입 승인' },
              { value: ECompanyStatus.REJ, label: '가입 반려' },
            ]}
            onChange={(status) => changeCompanyStatus({ companyId, status })}
          />
        ),
      },
    ],
    [changeCompanyStatus],
  );
  const typeOptions = useMemo(
    () => [
      { label: '전체', value: 'ALL' },
      { label: '책임판매업자', value: ECompanyType.BRAND },
      { label: '제조사', value: ECompanyType.MANUFACTURE },
      { label: '원료사', value: ECompanyType.MATERIAL },
    ],
    [],
  );
  const searchTypeOptions = useMemo(
    () => [
      { label: '회사명(국문)', value: ECompanySearchType.COMPANY_NAME_KO },
      { label: '회사명(영문)', value: ECompanySearchType.COMPANY_NAME_EN },
      { label: '사업자번호', value: ECompanySearchType.BIZ_NUMBER },
      { label: '대표자명', value: ECompanySearchType.CEO_NAME },
    ],
    [],
  );
  const handleChangeType = (e: RadioChangeEvent) => {
    setCompanyType(e.target.value);
    setPage(1);
    setTimeout(refetch);
  };
  const handleChangeSearchType = (type: ECompanySearchType) => {
    setSearchType(type);
    setSearchValue('');
  };
  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleSearch = (keyword: string) => {
    setSearchValue(keyword);
    setPage(1);
    setTimeout(refetch);
  };
  const handleRefresh = () => {
    setPage(1);
    setCompanyType('ALL');
    setSearchType(ECompanySearchType.COMPANY_NAME_KO);
    setSearchValue('');
    setTimeout(refetch);
  };
  useEffect(() => {
    handleRefresh();
  }, [location]);
  return (
    <PageTemplate title="가입 승인">
      <Row gutter={[8, 8]}>
        <Col flex="auto">
          <Button icon={<SyncOutlined />} onClick={handleRefresh}>
            새로 고침
          </Button>
        </Col>
        <Col>
          <Radio.Group
            optionType="button"
            options={typeOptions}
            buttonStyle="solid"
            value={companyType}
            onChange={handleChangeType}
          />
        </Col>
        <Col>
          <Select
            value={searchType}
            options={searchTypeOptions}
            style={{ width: 120, marginRight: 4 }}
            onChange={handleChangeSearchType}
          />
          <SearchInput
            value={searchValue}
            onChange={handleChangeSearchValue}
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      <Table
        dataSource={content}
        columns={columns}
        pagination={{
          current: page,
          total: totalElements,
          pageSize: size,
          onChange: setPage,
        }}
        rowKey={({ companyId }) => companyId}
        style={{ marginTop: 8 }}
      />
    </PageTemplate>
  );
};

export default CompanyApprovePage;
