import { Button, Col, Form, Input, message, Row } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

import { Typography } from 'components/system';
import PostAddIcon from 'components/system/icon/PostAddIcon';
import palette from 'lib/styles/palette';
import {
  EClinicalTrialEstimateItemType,
  IClinicalTrialCartItem,
  IClinicalTrialEstimate,
  IClinicalTrialEstimateItem,
} from 'types/clinicalTrial';
import ClinicalTrialEstimateItemTable from './ClinicalTrialEstimateItemTable';
import ClinicalTrialModal from '../ClinicalTrialModal';
import ClinicalTrialSelect from '../clinicalTrialSelect/ClinicalTrialSelect';
import ClinicalTrialCart from '../clinicalTrialCart/ClinicalTrialCart';
import { BigCloseIcon } from 'components/system/icon/BigCloseIcon';
import {
  isPackage,
  useCategoryDatas,
  useClinicalTrialEstimate,
} from 'hooks/clinicalTrial/clinicalTrial';
import produce from 'immer';
import history from 'lib/history';
import consts from 'lib/consts';
import BackIcon from 'components/system/icon/BackIcon';
import { useStores } from 'stores/Context';

const ClinicalTrialEstimateContainer = styled.div`
  width: 720px;
  margin: 0 auto;
  padding: 24px 0 50px;

  .ant-form-item {
    margin-bottom: 12px;
  }
`;

const AdditionalPageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding-top: 48px;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  z-index: 110;

  & > .close-icon {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    border-radius: 8px;
  }
`;

const AdditionalPageContainer = styled.div`
  background-color: #fff;
  overflow: auto;
`;

const ClinicalTrialEstimateHeader = styled.div`
  margin-bottom: 12px;
  position: relative;

  .back-icon {
    position: absolute;
    top: -50px;
  }
`;

const ItemAddButton = styled(Button)`
  border: none;
  background-color: ${palette.darkNavy};
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;

  &:focus,
  &:hover {
    border: none;
    box-shadow: none;
    background-color: ${palette.darkNavy};
    color: #fff;
  }

  &::after {
    display: none;
  }
`;

const FooterContainer = styled.div`
  background-color: #fff;
  padding: 28px 80px 36px;
  box-shadow: 0 0 6px 0 rgba(7, 7, 7, 0.1);
`;

const TotalPriceWrapper = styled.div`
  margin-top: 8px;
  padding-top: 8px;

  background-image: linear-gradient(
    to right,
    ${palette.darkNavy}40 33%,
    ${palette.darkNavy}00 0%
  );
  background-position: top;
  background-size: 4px 1px;
  background-repeat: repeat-x;
`;

const AppendixContainer = styled.div`
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 0 6px 0 rgba(7, 7, 7, 0.1);
`;

const sortEstimateItems = (
  a: IClinicalTrialEstimateItem,
  b: IClinicalTrialEstimateItem,
) => {
  if (a.itemType !== b.itemType) {
    if (a.itemType === EClinicalTrialEstimateItemType.ETC) {
      return 1;
    }
    if (b.itemType === EClinicalTrialEstimateItemType.ETC) {
      return -1;
    }
    if (a.itemType === EClinicalTrialEstimateItemType.DISCOUNT) {
      return 1;
    }
    if (b.itemType === EClinicalTrialEstimateItemType.DISCOUNT) {
      return -1;
    }
  }
  if (typeof a.clinicalTrialEstimateItemId === 'string') {
    if (typeof b.clinicalTrialEstimateItemId === 'number') {
      return 1;
    }
    return 0;
  }
  if (typeof b.clinicalTrialEstimateItemId === 'string') {
    if (typeof a.clinicalTrialEstimateItemId === 'number') {
      return -1;
    }
    return 0;
  }
  return a.clinicalTrialEstimateItemId - b.clinicalTrialEstimateItemId;
};

const ClinicalTrialEstimate = () => {
  const params = useParams<{ clinicalTrialEstimateId: string }>();
  const clinicalTrialEstimateId = Number(params.clinicalTrialEstimateId);
  const { clinicalTrialToastStore } = useStores();
  const {
    clinicalTrialEstimate,
    updateClinicalTrialEstimate,
    updateLoading,
  } = useClinicalTrialEstimate(clinicalTrialEstimateId);
  const categoryDatas = useCategoryDatas();
  const [form] = Form.useForm<IClinicalTrialEstimate>();
  const [additionalPage, setAdditionalPage] = useState<
    'SELECT' | 'CART' | null
  >(null);
  const [updateEstimateModalVisible, setUpdateEstimateModalVisible] = useState(
    false,
  );
  const [
    clinicalTrialEstimateItemIdToBeDeleted,
    setClinicalTrialEstimateItemIdToBeDeleted,
  ] = useState<number | string | null>(null);
  const handleAddEstimateItem = (cartItems: IClinicalTrialCartItem[]) => {
    const estimateItems = form.getFieldValue(
      'estimateItems',
    ) as IClinicalTrialEstimateItem[];
    form.setFieldsValue({
      estimateItems: produce(estimateItems, (proxy) => {
        cartItems.forEach(({ categoryDataId, item, itemQuantity, options }) => {
          if (isPackage(item)) {
            const sameEstimateItem = proxy.find(
              (estimateItem) =>
                estimateItem.categoryDataId === categoryDataId &&
                estimateItem.itemType ===
                  EClinicalTrialEstimateItemType.PACKAGE &&
                estimateItem.clinicalTrialPackageId ===
                  item.clinicalTrialPackageId,
            );
            if (sameEstimateItem) {
              const discountItem = proxy.find(
                (estimateItem) =>
                  estimateItem.categoryDataId === categoryDataId &&
                  estimateItem.itemType ===
                    EClinicalTrialEstimateItemType.DISCOUNT &&
                  estimateItem.clinicalTrialPackageId ===
                    item.clinicalTrialPackageId,
              );
              if (!discountItem) throw new Error('Invalid discountItem');
              sameEstimateItem.itemQuantity += itemQuantity;
              discountItem.itemQuantity += itemQuantity;
              sameEstimateItem.estimateItemOptions.forEach(
                (estimateItemOption) => {
                  estimateItemOption.itemQuantity +=
                    options.find(
                      ({ clinicalTrialProductOptionId }) =>
                        clinicalTrialProductOptionId ===
                        estimateItemOption.clinicalTrialProductOptionId,
                    )?.itemQuantity || 0;
                },
              );
            } else {
              // HINT 견적 목록에 추가
              proxy.push({
                clinicalTrialEstimateItemId: nanoid(),
                clinicalTrialPackageId: item.clinicalTrialPackageId,
                categoryDataId,
                itemName: item.packageName,
                itemQuantity,
                itemUnitPrice: item.clinicalTrialPackageProducts.reduce(
                  (acc, [{ price }]) => acc + price,
                  0,
                ),
                itemType: EClinicalTrialEstimateItemType.PACKAGE,
                agencyName: item.agency.agencyName,
                remark: '',
                estimateItemOptions: options.map(
                  ({
                    clinicalTrialProductOptionId,
                    clinicalTrialProductId,
                    productOptionName,
                    clinicalTrialName,
                    itemQuantity,
                  }) => ({
                    clinicalTrialEstimateItemOptionId: nanoid(),
                    clinicalTrialProductOptionId,
                    clinicalTrialProductId,
                    agencyName: item.agency.agencyName,
                    itemName: `[${productOptionName}] ${clinicalTrialName}`,
                    itemQuantity,
                    itemUnitPrice: 0,
                    remark: '',
                  }),
                ),
              });
              proxy.push({
                clinicalTrialEstimateItemId: nanoid(),
                categoryDataId,
                itemName: `${item.packageName} 할인가`,
                itemQuantity,
                itemUnitPrice:
                  item.clinicalTrialPackageProducts.reduce(
                    (acc, [{ price }]) => acc - price,
                    0,
                  ) + item.price,
                agencyName: item.agency.agencyName,
                itemType: EClinicalTrialEstimateItemType.DISCOUNT,
                clinicalTrialPackageId: item.clinicalTrialPackageId,
                remark: '',
                estimateItemOptions: [],
              });
            }
          } else {
            // HINT : 단일 시험
            const sameEstimateItem = proxy.find(
              (estimateItem) =>
                estimateItem.categoryDataId === categoryDataId &&
                estimateItem.itemType ===
                  EClinicalTrialEstimateItemType.PRODUCT &&
                estimateItem.clinicalTrialProductId ===
                  item.clinicalTrialProductId,
            );
            if (sameEstimateItem) {
              sameEstimateItem.itemQuantity += itemQuantity;
              sameEstimateItem.estimateItemOptions.forEach(
                (estimateItemOption) => {
                  estimateItemOption.itemQuantity +=
                    options.find(
                      ({ clinicalTrialProductOptionId }) =>
                        clinicalTrialProductOptionId ===
                        estimateItemOption.clinicalTrialProductOptionId,
                    )?.itemQuantity || 0;
                },
              );
            } else {
              // HINT : 항목 추가
              proxy.push({
                clinicalTrialEstimateItemId: nanoid(),
                clinicalTrialProductId: item.clinicalTrialProductId,
                categoryDataId,
                itemName: item.clinicalTrialName,
                itemQuantity,
                itemUnitPrice: item.price,
                itemType: EClinicalTrialEstimateItemType.PRODUCT,
                agencyName: item.agency.agencyName,
                remark: '',
                estimateItemOptions: options.map(
                  ({
                    clinicalTrialProductOptionId,
                    productOptionName,
                    clinicalTrialName,
                    itemQuantity,
                  }) => ({
                    clinicalTrialEstimateItemOptionId: nanoid(),
                    clinicalTrialProductOptionId,
                    agencyName: item.agency.agencyName,
                    itemName: `[${productOptionName}] ${clinicalTrialName}`,
                    itemQuantity,
                    itemUnitPrice: 0,
                    remark: '',
                  }),
                ),
              });
            }
          }
        });
      }),
    });

    setAdditionalPage(null);
  };
  const handleAddEtcEstimateItem = (categoryDataId: number) => {
    const estimateItems = form.getFieldValue(
      'estimateItems',
    ) as IClinicalTrialEstimateItem[];
    form.setFieldsValue({
      estimateItems: estimateItems.concat({
        clinicalTrialEstimateItemId: nanoid(),
        categoryDataId,
        itemName: '',
        agencyName: '',
        itemUnitPrice: 0,
        itemQuantity: 1,
        estimateItemOptions: [],
        remark: '',
        itemType: EClinicalTrialEstimateItemType.ETC,
      }),
    });
  };
  const handleDeleteEstimateItem = () => {
    const estimateItems = form.getFieldValue(
      'estimateItems',
    ) as IClinicalTrialEstimateItem[];
    const estimateItem = estimateItems.find(
      ({ clinicalTrialEstimateItemId }) =>
        clinicalTrialEstimateItemId === clinicalTrialEstimateItemIdToBeDeleted,
    );
    if (!estimateItem) throw new Error('Invalid estimateItem');
    const deletingItemIds = [clinicalTrialEstimateItemIdToBeDeleted];
    if (estimateItem.itemType === EClinicalTrialEstimateItemType.PACKAGE) {
      const discountItem = estimateItems.find(
        ({ categoryDataId, clinicalTrialPackageId, itemType }) =>
          categoryDataId === estimateItem.categoryDataId &&
          clinicalTrialPackageId === estimateItem.clinicalTrialPackageId &&
          itemType === EClinicalTrialEstimateItemType.DISCOUNT,
      );
      if (!discountItem) throw new Error('Invalid discountItem');
      deletingItemIds.push(discountItem.clinicalTrialEstimateItemId);
    }

    form.setFieldsValue({
      estimateItems: estimateItems.filter(
        (item) => !deletingItemIds.includes(item.clinicalTrialEstimateItemId),
      ),
    });
    setClinicalTrialEstimateItemIdToBeDeleted(null);
  };
  const handleClickUpdateEstimate = () => {
    form.validateFields().then(() => {
      setUpdateEstimateModalVisible(true);
    });
  };
  const handleUpdateEstimate = () => {
    updateClinicalTrialEstimate(form.getFieldsValue(true), {
      onSuccess: () => {
        clinicalTrialToastStore.showClinicalTrialToast({
          message: '수정되었습니다.',
        });
        history.goBack();
      },
      onError: (error: any) => {
        if (error.message === consts.message.NO_NEED_TO_UPDATE) {
          clinicalTrialToastStore.showClinicalTrialToast({
            message: consts.message.NO_NEED_TO_UPDATE,
          });
          setUpdateEstimateModalVisible(false);
        } else {
          message.error(error);
          console.error(error);
        }
      },
    });
  };

  useEffect(() => {
    if (clinicalTrialEstimate) {
      form.setFieldsValue(clinicalTrialEstimate);
    }
  }, [clinicalTrialEstimate]);
  return (
    <ClinicalTrialEstimateContainer>
      {additionalPage !== null && (
        <AdditionalPageWrapper onClick={() => setAdditionalPage(null)}>
          <BigCloseIcon color="#fff" />
          <AdditionalPageContainer onClick={(e) => e.stopPropagation()}>
            {additionalPage === 'SELECT' ? (
              <>
                <ClinicalTrialSelect
                  onMoveToCart={() => setAdditionalPage('CART')}
                />
              </>
            ) : (
              <ClinicalTrialCart
                onAddEstimateItem={handleAddEstimateItem}
                onMoveToSelect={() => setAdditionalPage('SELECT')}
              />
            )}
          </AdditionalPageContainer>
        </AdditionalPageWrapper>
      )}
      {clinicalTrialEstimateItemIdToBeDeleted !== null && (
        <ClinicalTrialModal width={360} style={{ padding: '125px 40px 30px' }}>
          <Typography.Title
            type="secondary"
            color="darkNavy"
            align="center"
            medium
            gutter={{ bottom: 68 }}
          >
            해당 항목을 삭제하시겠습니까?
          </Typography.Title>
          <Button
            type="primary"
            block
            size="large"
            onClick={handleDeleteEstimateItem}
          >
            <Typography.Title type="secondary" medium inline>
              삭제
            </Typography.Title>
          </Button>
          <Typography.Title
            type="secondary"
            color="darkNavy"
            align="center"
            medium
            gutter={{ top: 24 }}
            style={{ cursor: 'pointer' }}
            onClick={() => setClinicalTrialEstimateItemIdToBeDeleted(null)}
          >
            취소
          </Typography.Title>
        </ClinicalTrialModal>
      )}
      {updateEstimateModalVisible && (
        <ClinicalTrialModal width={360} style={{ padding: '125px 40px 30px' }}>
          <Typography.Title
            type="secondary"
            color="darkNavy"
            align="center"
            medium
            gutter={{ bottom: 68 }}
          >
            견적을 수정하시겠습니까?
          </Typography.Title>
          <Button
            type="primary"
            block
            size="large"
            loading={updateLoading}
            onClick={handleUpdateEstimate}
          >
            <Typography.Title type="secondary" medium inline>
              수정
            </Typography.Title>
          </Button>
          <Typography.Title
            type="secondary"
            color="darkNavy"
            align="center"
            medium
            gutter={{ top: 24 }}
            style={{ cursor: 'pointer' }}
            onClick={() => setUpdateEstimateModalVisible(false)}
          >
            취소
          </Typography.Title>
        </ClinicalTrialModal>
      )}
      <Typography.Title
        medium
        color="darkNavy"
        align="center"
        gutter={{ bottom: 24 }}
      >
        견적 수정
      </Typography.Title>
      <Form form={form} onFinish={handleClickUpdateEstimate}>
        <ClinicalTrialEstimateHeader>
          <BackIcon
            color={palette.text.darkNavy}
            onClick={() => history.goBack()}
          />
          <Row gutter={12}>
            <Col>
              <Row gutter={4}>
                <Col>
                  <Typography.Text
                    type="secondary"
                    color="darkNavy"
                    medium
                    gutter={{ top: 6 }}
                  >
                    업체명
                  </Typography.Text>
                </Col>
                <Col>
                  <Form.Item name="clientCompanyName">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col flex="auto">
              <Row justify="space-between">
                <Col>
                  <Typography.Text
                    type="secondary"
                    color="darkNavy"
                    medium
                    gutter={{ top: 6 }}
                  >
                    견적 발행일 {clinicalTrialEstimate?.publishDt || '-'}
                  </Typography.Text>
                </Col>
                <Col>
                  <ItemAddButton
                    size="small"
                    icon={
                      <PostAddIcon
                        color="#fff"
                        style={{
                          width: 14,
                          height: 14,
                          position: 'relative',
                          top: 2,
                          marginRight: 2,
                        }}
                      />
                    }
                    onClick={() => {
                      setAdditionalPage('SELECT');
                    }}
                  >
                    시험 항목 추가
                  </ItemAddButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </ClinicalTrialEstimateHeader>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const estimateItems = getFieldValue(
              'estimateItems',
            ) as IClinicalTrialEstimateItem[];
            if (!estimateItems) return null;
            return categoryDatas.map(({ categoryDataId, categoryDataName }) => {
              const estimateItemsPerCategory = [
                ...estimateItems
                  .filter(
                    (estimateItem) =>
                      estimateItem.categoryDataId === categoryDataId,
                  )
                  .sort(sortEstimateItems),
              ];
              return estimateItemsPerCategory.length > 0 ? (
                <ClinicalTrialEstimateItemTable
                  key={categoryDataId}
                  categoryDataId={categoryDataId}
                  categoryDataName={categoryDataName}
                  itemIndexes={estimateItemsPerCategory.map((item) =>
                    estimateItems.indexOf(item),
                  )}
                  estimateItems={estimateItemsPerCategory}
                  onDeleteItem={setClinicalTrialEstimateItemIdToBeDeleted}
                  onAddEtcEstimateItem={handleAddEtcEstimateItem}
                />
              ) : null;
            });
          }}
        </Form.Item>
        <Typography.Text color="darkNavy" bold gutter={{ top: 24, bottom: 8 }}>
          Appendix
        </Typography.Text>
        <AppendixContainer>
          <Form.Item name="appendix" style={{ marginBottom: 0 }}>
            <Input.TextArea autoSize={{ minRows: 4 }} maxLength={1500} />
          </Form.Item>
        </AppendixContainer>
        <Typography.Text color="darkNavy" bold gutter={{ top: 24 }}>
          총 견적금액
        </Typography.Text>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const items = getFieldValue(
              'estimateItems',
            ) as IClinicalTrialEstimateItem[];
            let totalPrice = 0;
            if (items) {
              totalPrice = items.reduce(
                (acc, { itemUnitPrice, itemQuantity, estimateItemOptions }) =>
                  acc +
                  itemUnitPrice * itemQuantity +
                  estimateItemOptions.reduce(
                    (acc, { itemUnitPrice, itemQuantity }) =>
                      acc + itemUnitPrice * itemQuantity,
                    0,
                  ),
                0,
              );
            }
            return (
              <FooterContainer>
                <Row gutter={64} align="middle">
                  <Col span={14}>
                    <Row align="middle" justify="space-between">
                      <Col>
                        <Typography.Text
                          type="secondary"
                          color="darkNavy"
                          medium
                        >
                          금액
                        </Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text type="secondary" color="darkNavy">
                          {totalPrice.toLocaleString()} 원
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row
                      align="middle"
                      justify="space-between"
                      style={{ marginTop: 8 }}
                    >
                      <Col>
                        <Typography.Text
                          type="secondary"
                          color="darkNavy"
                          medium
                        >
                          부가세
                        </Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text type="secondary" color="darkNavy">
                          {(totalPrice * 0.1).toLocaleString()} 원
                        </Typography.Text>
                      </Col>
                    </Row>
                    <TotalPriceWrapper>
                      <Row align="middle" justify="space-between">
                        <Col>
                          <Typography.Text
                            type="secondary"
                            color="darkNavy"
                            medium
                          >
                            총 합계 (VAT 포함)
                          </Typography.Text>
                        </Col>
                        <Col>
                          <Typography.Title color="primary" bold>
                            {(totalPrice * 1.1).toLocaleString()} 원
                          </Typography.Title>
                        </Col>
                      </Row>
                    </TotalPriceWrapper>
                  </Col>
                  <Col span={10}>
                    <Button
                      type="primary"
                      block
                      style={{ height: 40, fontSize: 18, fontWeight: 500 }}
                      onClick={handleClickUpdateEstimate}
                    >
                      견적 수정
                    </Button>
                  </Col>
                </Row>
              </FooterContainer>
            );
          }}
        </Form.Item>
      </Form>
    </ClinicalTrialEstimateContainer>
  );
};

export default ClinicalTrialEstimate;
