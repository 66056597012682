import { makeAutoObservable } from 'mobx';
import RootStore from 'stores/RootStore';

export default class NotificationStore {
  rootStore;
  notificationDrawerVisible: boolean;
  hasNewNotification: boolean;

  setNotificationDrawerVisible(visible: boolean) {
    this.notificationDrawerVisible = visible;
  }
  setHasNewNotification(hasNewNotification: boolean) {
    this.hasNewNotification = hasNewNotification;
  }

  constructor(root: RootStore) {
    this.rootStore = root;
    this.notificationDrawerVisible = false;
    this.hasNewNotification = false;

    makeAutoObservable(this);
  }
}
