import { Form, Typography } from 'antd';
import { useProductVCRPAccountRequest } from 'hooks/product';

const ProductVCRPAccountRequestModalContent = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const { data: productVCRPAccountRequest } = useProductVCRPAccountRequest({
    productId,
    countryId,
  });

  const {
    companyNameEn,
    managerFirstName,
    managerLastName,
    managerPositionName,
    managerMobile,
    vcrpAccount,
    managerEmail,
  } = { ...productVCRPAccountRequest };

  return (
    <Form labelCol={{ span: 8 }} size="small" style={{ paddingTop: 20 }}>
      <Form.Item label="회사명 (영문)">
        <Typography.Text strong>{companyNameEn}</Typography.Text>
      </Form.Item>
      <Form.Item label="담당자 이름 (영문)">
        <Typography.Text strong>{managerFirstName}</Typography.Text>
      </Form.Item>
      <Form.Item label="담당자 성 (영문)">
        <Typography.Text strong>{managerLastName}</Typography.Text>
      </Form.Item>
      <Form.Item label="담당자 직급 (영문)">
        <Typography.Text strong>{managerPositionName}</Typography.Text>
      </Form.Item>
      <Form.Item label="담당자 전화번호">
        <Typography.Text strong>{managerMobile}</Typography.Text>
      </Form.Item>
      <Form.Item label="사용자명 (ID)">
        <Typography.Text strong>{vcrpAccount}</Typography.Text>
      </Form.Item>
      <Form.Item label="담당자 이메일">
        <Typography.Text strong>{managerEmail}</Typography.Text>
      </Form.Item>
    </Form>
  );
};

export default ProductVCRPAccountRequestModalContent;
