import { useMemo } from 'react';
import styled from 'styled-components';
import { Card, Table } from 'antd';
import { useQuery } from 'react-query';

import { ColumnsType } from 'antd/lib/table';
import * as statsApi from 'lib/api/stats';
import { ICountryCertificateStat } from 'types/stats';
import { Typography } from 'components/system';

const MainStatsTableBlock = styled(Card)`
  height: 100%;

  .ant-card-body {
    padding: 32px 40px;
  }
`;

const MainStatsTable = () => {
  const {
    data: totalCountryCertificateStats = [],
  } = useQuery(
    'stats/totalCountryCertificateStats',
    statsApi.getTotalCountryCertificateStats,
    { select: (res) => res.data.result },
  );

  const columns: ColumnsType<ICountryCertificateStat> = useMemo(
    () => [
      {
        title: '구분',
        dataIndex: `no`,
        align: 'center',
        render: (_, __, index) => index + 1,
      },
      {
        title: '국가',
        dataIndex: 'countryNameKo',
        align: 'center',
      },
      {
        title: '인증 완료 수(SKU)',
        dataIndex: 'countCertFin',
        align: 'center',
        render: (productOnCount: number) => `${productOnCount}%`,
      },
      {
        title: '매출액',
        dataIndex: 'sumSales',
        align: 'center',
      },
    ],
    [],
  );

  return (
    <MainStatsTableBlock>
      <Typography.Headline type="secondary">{`인증이 가장 많이 완료된 국가는 ${
        totalCountryCertificateStats[0]?.countryNameKo || '미정'
      }입니다.`}</Typography.Headline>
      <Table
        style={{ height: 550, overflow: 'auto', marginTop: 28 }}
        columns={columns}
        dataSource={totalCountryCertificateStats}
        rowKey={({ countryId }) => countryId}
        pagination={false}
      />
    </MainStatsTableBlock>
  );
};

export default MainStatsTable;
