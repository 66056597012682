import ClinicalTrialEstimateRequestList from 'components/clinicalTrial/process/ClinicalTrialEstimateRequestList';
import PageTemplate from 'templates/PageTemplate';

const ClinicalTrialEstimateRequestListPage = () => {
  return (
    <PageTemplate title="견적 요청 관리">
      <ClinicalTrialEstimateRequestList />
    </PageTemplate>
  );
};

export default ClinicalTrialEstimateRequestListPage;
