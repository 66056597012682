import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Table, Typography, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

import path from 'lib/path';
import { useStores } from 'stores/Context';
import { ICertificate, ICertificateData } from 'types/certificate';
import { ColumnsType } from 'antd/lib/table';

const FinishedCertificateTable = ({
  page,
  onSetCertificateForEstimateAdd,
  onChangePage,
  certificateData,
}: {
  page: number;
  onSetCertificateForEstimateAdd: React.Dispatch<
    React.SetStateAction<ICertificate | null>
  >;
  onChangePage: (page: number) => void;
  certificateData?: ICertificateData;
}) => {
  const history = useHistory();
  const { certificateStore, etcStore } = useStores();
  const {
    content: finishedCertificates,
    totalElements: finishTotal,
    size: finishedSize,
  } = {
    ...certificateData,
  };

  const finishedColumns: ColumnsType<ICertificate> = useMemo(
    () => [
      {
        title: '인증 신청 번호',
        dataIndex: 'certRequestNo',
        align: 'center',
      },
      {
        title: '제품명',
        align: 'center',
        render: (_, certificate) => (
          <Typography.Link
            underline
            onClick={() => {
              certificateStore.setCurrentCertificate(certificate);
              history.push(
                `${path.certificate.finish}/${certificate.certTargetId}`,
              );
            }}
          >
            {`${certificate.estimateTarget.productDetails[0].productNameEn} ${
              certificate.estimateTarget.netWeight
                ? `(${certificate.estimateTarget.netWeight} ${certificate.estimateTarget.netWeightUnit})`
                : '( - )'
            }`}
          </Typography.Link>
        ),
      },
      {
        title: '국가',
        align: 'center',
        dataIndex: ['estimateTarget', 'countryName'],
      },
      {
        title: '접수일',
        align: 'center',
        render: (_, { registerDt }) => registerDt.slice(0, 10),
      },
      {
        title: '인증 완료일',
        align: 'center',
        dataIndex: 'certFinYmd',
      },
      {
        title: '인증 등록번호',
        dataIndex: 'certRegisterNo',
        align: 'center',
      },
      {
        title: '등록증',
        align: 'center',
        render: (_, { certDocUrl }) =>
          certDocUrl && (
            <FilePdfOutlined
              style={{ fontSize: 16, cursor: 'pointer' }}
              onClick={() => {
                etcStore.previewedFiles = [{ url: certDocUrl }];
              }}
            />
          ),
      },
      {
        title: '',
        align: 'center',
        render: (_, certificate) => (
          <Button onClick={() => onSetCertificateForEstimateAdd(certificate)}>
            추가 견적
          </Button>
        ),
      },
    ],
    [],
  );

  return (
    <Table
      columns={finishedColumns}
      dataSource={finishedCertificates}
      rowKey={({ certTargetId }) => certTargetId}
      pagination={{
        current: page,
        pageSize: finishedSize,
        total: finishTotal,
        onChange: onChangePage,
      }}
    />
  );
};

export default FinishedCertificateTable;
