import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DownloadOutlined,
  FilePdfOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Statistic, Table } from 'antd';

import {
  EDocumentCode,
  ICertificate,
  ICertificateInfo,
} from 'types/certificate';
import { useStores } from 'stores/Context';
import { downloadFile, downloadFiles, getFilenameFromUrl } from 'lib/file';
import { useCertificate, useCertificateDocuments } from 'hooks/certificate';
import PageTemplate from 'templates/PageTemplate';
import { ColumnsType } from 'antd/lib/table';

const CertificateDocumentList = ({
  certificate,
}: {
  certificate: ICertificate;
}) => {
  const { certTargetId } = certificate;
  const { etcStore } = useStores();
  const {
    certRequestNo,
    certFinYmd,
    estimateTarget: { productId, countryId, productDetails, countryName },
    cpsrGenerateFileUrl,
    cpsrUploadFileUrl,
  } = certificate;
  const { data: certificateDocuments } = useCertificateDocuments(
    Number(certTargetId),
    productId,
    countryId,
  );

  const columns = useMemo(
    () => [
      {
        title: 'No.',
        align: 'center' as const,
        render: ({ documentCode, no }: ICertificateInfo) => {
          if (documentCode !== EDocumentCode.THIRD) return no;
          if (countryId === 14 || countryId === 13) return no;
          return '★';
        },
      },
      { title: '서류명', dataIndex: 'documentName', align: 'center' as const },
      {
        title: '유저 업로드 파일',
        align: 'center' as const,
        render: ({
          documentCode,
          uploadFileUrl,
          uploadFileUrls,
          originalUploadFileName,
          filenames,
        }: ICertificateInfo) => {
          if (
            (documentCode === EDocumentCode.ART ||
              documentCode === EDocumentCode.THIRD) &&
            uploadFileUrls &&
            uploadFileUrls.length !== 0
          ) {
            return (
              <FilePdfOutlined
                style={{ cursor: 'pointer', fontSize: 16 }}
                onClick={async () => {
                  etcStore.previewedFiles = uploadFileUrls.map(
                    (url, index) => ({
                      url,
                      filename: filenames?.[index],
                    }),
                  );
                }}
              />
            );
          }
          if (uploadFileUrl !== null) {
            if (
              documentCode === EDocumentCode.RMCOA ||
              documentCode === EDocumentCode.RMMSDS ||
              documentCode === EDocumentCode.LOGOAI ||
              documentCode === EDocumentCode.FORM ||
              documentCode === EDocumentCode.FORMBR ||
              documentCode === EDocumentCode.FORMBRC
            ) {
              return (
                <PaperClipOutlined
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  onClick={() => {
                    downloadFile(uploadFileUrl, originalUploadFileName);
                  }}
                />
              );
            } else {
              return (
                <FilePdfOutlined
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  onClick={() => {
                    etcStore.previewedFiles = [{ url: uploadFileUrl }];
                  }}
                />
              );
            }
          }
          return null;
        },
      },
      {
        title: '인증 진행 서류',
        align: 'center' as const,
        render: ({
          documentCode,
          documentUrl,
          documentUrls,
        }: ICertificateInfo) => {
          if (
            documentCode === EDocumentCode.ART &&
            documentUrls &&
            documentUrls.length !== 0
          ) {
            return (
              <FilePdfOutlined
                style={{ cursor: 'pointer', fontSize: 16 }}
                onClick={async () => {
                  etcStore.previewedFiles = documentUrls.map((url) => ({
                    url,
                  }));
                }}
              />
            );
          }

          return (
            documentUrl !== null && (
              <FilePdfOutlined
                style={{ cursor: 'pointer', fontSize: 16 }}
                onClick={() => {
                  etcStore.previewedFiles = [{ url: documentUrl }];
                }}
              />
            )
          );
        },
      },
      {
        title: '비고',
        align: 'center' as const,
      },
    ],
    [],
  );

  const [downloadAllFilesLoading, setDownloadAllFilesLoading] = useState(false);

  const handleClickDownloadAllFiles = async () => {
    if (!certificateDocuments) return;
    const documents = certificateDocuments.reduce<
      {
        name: string;
        url: string;
      }[]
    >((acc, { documentName, documentUrl, documentUrls }) => {
      if (documentUrl) {
        return acc.concat({ name: documentName, url: documentUrl });
      }
      if (documentUrls) {
        return acc.concat(
          documentUrls.map((documentUrl, index) => ({
            name: `${documentName}_${index + 1}`,
            url: documentUrl,
          })),
        );
      }
      return acc;
    }, []);
    const uploadFiles = certificateDocuments.reduce<
      {
        name: string;
        url: string;
      }[]
    >(
      (
        acc,
        { uploadFileUrl, uploadFileUrls, originalUploadFileName, filenames },
      ) => {
        if (uploadFileUrl) {
          return acc.concat({
            name: originalUploadFileName || getFilenameFromUrl(uploadFileUrl),
            url: uploadFileUrl,
          });
        }
        if (uploadFileUrls) {
          return acc.concat(
            uploadFileUrls.map((uploadFileUrl, index) => ({
              name: `${
                filenames?.[index] || getFilenameFromUrl(uploadFileUrl)
              }`.replace('.', `_${index + 1}.`),
              url: uploadFileUrl,
            })),
          );
        }
        return acc;
      },
      [],
    );
    setDownloadAllFilesLoading(true);
    await Promise.all([
      downloadFiles(
        documents,
        `[인증서류]${certRequestNo}_${productDetails[0].productNameEn}_${countryName}_${certFinYmd}.zip`,
      ),
      downloadFiles(
        uploadFiles,
        `[업로드파일]${certRequestNo}_${productDetails[0].productNameEn}_${countryName}_${certFinYmd}.zip`,
      ),
    ]);
    setDownloadAllFilesLoading(false);
  };

  const cpsrColumns: ColumnsType<{
    documentName: string;
    cpsrUrl: string | null;
  }> = useMemo(
    () => [
      {
        title: 'No.',
        dataIndex: 'no',
        align: 'center',
        render: (_, __, index) => index + 1,
      },
      { title: '서류명', dataIndex: 'documentName', align: 'center' },
      {
        title: '인증 진행 서류',
        align: 'center',
        render: (record: any) =>
          record.cpsrUrl ? (
            <FilePdfOutlined
              style={{ cursor: 'pointer', fontSize: 16 }}
              onClick={() => {
                etcStore.previewedFiles = [{ url: record.cpsrUrl }];
              }}
            />
          ) : null,
      },
    ],
    [],
  );
  const cpsrDatas = useMemo(() => {
    return [
      {
        documentName: 'CPSR',
        cpsrUrl: cpsrUploadFileUrl || cpsrGenerateFileUrl,
      },
    ];
  }, [cpsrGenerateFileUrl, cpsrUploadFileUrl]);
  return (
    <>
      <Row style={{ marginTop: 32 }}>
        <Col>
          <Button
            loading={downloadAllFilesLoading}
            icon={<DownloadOutlined />}
            onClick={handleClickDownloadAllFiles}
          >
            모든 서류 다운로드
          </Button>
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        dataSource={certificateDocuments}
        rowKey={({ no, productDocStatusId }) => productDocStatusId || no}
        pagination={false}
        size="small"
        style={{ marginTop: 8 }}
      />
      {(countryName === '유럽연합' || countryName === '영국') && (
        <Table
          bordered
          columns={cpsrColumns}
          dataSource={cpsrDatas}
          rowKey="documentName"
          pagination={false}
          style={{ marginTop: 36 }}
          size="small"
        />
      )}
    </>
  );
};

const CertificateFinishPage = () => {
  const { etcStore } = useStores();
  const { certTargetId } = useParams<{ certTargetId: string }>();

  const { certificate } = useCertificate(Number(certTargetId));
  if (!certificate) return null;
  const {
    certRequestNo,
    certDocUrl,
    certRegisterNo,
    certFinYmd,
    estimateTarget: { productDetails, countryName, netWeight, netWeightUnit },
  } = certificate;

  return (
    <PageTemplate title="인증완료 제품 관리" back>
      <Card>
        <Row>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Statistic title="인증 신청 번호" value={certRequestNo} />
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Statistic
              title="제품명"
              value={`${productDetails[0].productNameEn} ${
                netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
              }`}
            />
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Statistic title="국가" value={countryName} />
          </Col>
        </Row>
        <Divider />
        <Row justify="space-around">
          <Col span={8} style={{ textAlign: 'center' }}>
            <Statistic
              title="인증 등록 번호"
              valueRender={() => certRegisterNo || '-'}
            />
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Statistic title="인증 등록 완료일" value={certFinYmd} />
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Statistic
              title="등록증"
              valueRender={() => (
                <FilePdfOutlined
                  style={{ cursor: 'pointer', fontSize: 18 }}
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    etcStore.previewedFiles = [{ url: certDocUrl! }];
                  }}
                />
              )}
            />
          </Col>
        </Row>
      </Card>
      <CertificateDocumentList certificate={certificate} />
    </PageTemplate>
  );
};

export default CertificateFinishPage;
