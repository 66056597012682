import { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Input,
  InputNumber,
  Table,
  Divider,
  Row,
  Col,
  Empty,
} from 'antd';
import FooterBox from 'components/FooterBox';
import { useEstimateCountries } from 'hooks/estimate';
import palette from 'lib/styles/palette';
import { IEstimateCountry, IEstimateCountryItem } from 'types/estimate';
import { Typography } from 'components/system';

interface IEstimateCountryProps {
  index: number;
  estimateCountry: IEstimateCountry;
  onChangeField: (
    countryIndex: number,
    itemIdex: number,
    fieldName: 'itemName' | 'unitPrice' | 'quantity' | 'description',
    value: number | string,
  ) => void;
  onAddItem: (countryIndex: number) => void;
  onRemoveItem: (countryIndex: number, itemIdex: number) => void;
}

interface IProps {
  estimateId: number;
  onClose: () => void;
}

const EstimateCountryBlock = styled.div`
  & + & {
    margin-top: 16px;
  }

  .ant-typography {
    margin-bottom: 8px;
    font-size: 18px;
  }

  .ant-input-number input {
    text-align: right;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-table {
    background-color: #f6f7f9;
    border-radius: 4px;
    overflow: hidden;
    padding: 4px;
    th,
    td {
      background-color: #f6f7f9 !important;
      border-bottom: none;
    }

    th {
      font-size: 12px;
      border-bottom: 0;
      padding-bottom: 0 !important;
    }
  }
`;

const EstimateCountry = memo(
  ({
    index,
    estimateCountry,
    onChangeField,
    onAddItem,
    onRemoveItem,
  }: IEstimateCountryProps) => {
    const { countryNameKo, estimateCountryItems } = estimateCountry;
    const handleChangeField = useCallback(
      (
        itemIndex: number,
        fieldName: 'itemName' | 'unitPrice' | 'quantity' | 'description',
        value: number | string,
      ) => {
        onChangeField(index, itemIndex, fieldName, value);
      },
      [onChangeField],
    );
    const handleClickAdd = useCallback(() => {
      onAddItem(index);
    }, []);
    const handleRemoveItem = useCallback((itemIndex: number) => {
      onRemoveItem(index, itemIndex);
    }, []);

    const columns = useMemo(
      () => [
        {
          title: '품목명',
          width: 'auto',
          dataIndex: 'itemName',
          render: (name: string, _: any, index: number) => (
            <Input
              value={name}
              onChange={(e) =>
                handleChangeField(index, 'itemName', e.target.value)
              }
            />
          ),
        },
        {
          title: '단가',
          width: '118px',
          dataIndex: 'unitPrice',
          render: (unitPrice: number, _: any, index: number) => (
            <InputNumber
              value={unitPrice}
              formatter={(value) => Number(value)?.toLocaleString() || ''}
              parser={(value) => Number(value?.replace(/,/g, '')) || 0}
              style={{ width: 120, textAlign: 'right' }}
              onChange={(value) => handleChangeField(index, 'unitPrice', value)}
            />
          ),
        },
        {
          title: '수량',
          width: '58px',
          dataIndex: 'quantity',
          render: (quantity: number, _: any, index: number) => (
            <InputNumber
              value={quantity}
              formatter={(value) => value?.toString() || '0'}
              parser={(value) => Number(value)}
              style={{ width: 60, textAlign: 'right' }}
              onChange={(value) => handleChangeField(index, 'quantity', value)}
              inputMode="text"
            />
          ),
        },
        {
          title: '금액',
          width: '120px',
          render: ({ unitPrice, quantity }: IEstimateCountryItem) => (
            <Input
              value={(unitPrice * quantity).toLocaleString()}
              disabled
              style={{
                width: 120,
                textAlign: 'right',
                backgroundColor: '#fff',
                color: '#222',
              }}
            />
          ),
        },
        {
          title: '비고',
          width: '15%',
          dataIndex: 'description',
          render: (description: string, _: any, index: number) => (
            <Input
              value={description}
              onChange={(e) =>
                handleChangeField(index, 'description', e.target.value)
              }
            />
          ),
        },
        {
          render: (_1: any, _2: any, index: number) =>
            index !== 0 && (
              <MinusCircleOutlined onClick={() => handleRemoveItem(index)} />
            ),
        },
      ],
      [handleChangeField],
    );
    return (
      <EstimateCountryBlock>
        <Typography.Text>
          {index + 1}. {countryNameKo}
        </Typography.Text>
        <Table
          size="small"
          columns={columns}
          dataSource={estimateCountryItems}
          rowKey={(estimateItem) => String(estimateItem.estimateCountryItemId)}
          pagination={false}
          footer={() => (
            <Button
              icon={<PlusOutlined style={{ color: palette.main[0] }} />}
              type="dashed"
              block
              style={{ borderColor: palette.main[0] }}
              onClick={handleClickAdd}
            >
              항목 추가하기
            </Button>
          )}
        />
      </EstimateCountryBlock>
    );
  },
);

const PriceTableBlock = styled.div``;

const PriceTable = ({ unitPrice }: { unitPrice: number }) => {
  const cutOff = unitPrice % 1000;
  const cutOffUnitPrice = unitPrice - cutOff;
  const vat = cutOffUnitPrice * 0.1;
  const total = cutOffUnitPrice + vat;
  return (
    <PriceTableBlock>
      <Row justify="end">
        <Col>
          <Typography.Text
            type="tertiary"
            color="gray"
            style={{ display: 'block', marginBottom: 2 }}
          >
            금액
          </Typography.Text>
        </Col>
        <Col flex="0 0 160px">
          <Typography.Text
            type="tertiary"
            color="gray"
            style={{ textAlign: 'right', marginBottom: 2 }}
          >
            {unitPrice.toLocaleString()} 원
          </Typography.Text>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Typography.Text
            type="tertiary"
            color="gray"
            style={{ marginBottom: 2 }}
          >
            절사
          </Typography.Text>
        </Col>
        <Col flex="0 0 160px">
          <Typography.Text
            type="tertiary"
            color="gray"
            style={{ textAlign: 'right', marginBottom: 2 }}
          >
            -{cutOff.toLocaleString()} 원
          </Typography.Text>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Typography.Text
            type="tertiary"
            color="gray"
            style={{ marginBottom: 2 }}
          >
            부가세
          </Typography.Text>
        </Col>
        <Col flex="0 0 160px">
          <Typography.Text
            type="tertiary"
            color="gray"
            style={{ textAlign: 'right', marginBottom: 2 }}
          >
            {vat.toLocaleString()} 원
          </Typography.Text>
        </Col>
      </Row>
      <Row justify="end" align="middle">
        <Col>
          <Typography.Text type="tertiary" style={{ marginBottom: 0 }}>
            총 합계
          </Typography.Text>
        </Col>
        <Col flex="0 0 160px">
          <Typography.Title
            style={{ marginBottom: 0, textAlign: 'right', letterSpacing: -0.5 }}
            bold
          >
            {total.toLocaleString()} 원
          </Typography.Title>
        </Col>
      </Row>
      <Typography.Text
        type="quaternary"
        style={{ fontSize: '10px', marginBottom: 0, textAlign: 'right' }}
      >
        (VAT 포함)
      </Typography.Text>
    </PriceTableBlock>
  );
};

const EstimateUpdateModal = ({ estimateId, onClose }: IProps) => {
  const {
    estimateCountries,
    totalPrice,
    previewLoading,
    submitLoading,
    handleChangeField,
    handleRemoveItem,
    handleAddItem,
    handlePreview,
    handleSubmit,
  } = useEstimateCountries(estimateId, { onSuccessSubmit: onClose });

  return (
    <Modal
      width={800}
      visible={true}
      title="견적서 수정"
      footer={null}
      maskClosable={false}
      onCancel={onClose}
    >
      {estimateCountries.length > 0 ? (
        estimateCountries.map((estimateCountry, index) => (
          <EstimateCountry
            key={estimateCountry.estimateCountryId}
            index={index}
            estimateCountry={estimateCountry}
            onChangeField={handleChangeField}
            onRemoveItem={handleRemoveItem}
            onAddItem={handleAddItem}
          />
        ))
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="견적 데이터 없음"
        />
      )}
      {estimateCountries.length > 0 && (
        <>
          <Divider />
          <PriceTable unitPrice={totalPrice} />
          <FooterBox>
            <Button loading={previewLoading} onClick={handlePreview}>
              미리보기
            </Button>
            <Button
              loading={submitLoading}
              onClick={handleSubmit}
              type="primary"
            >
              발행하기
            </Button>
          </FooterBox>
        </>
      )}
    </Modal>
  );
};

export default EstimateUpdateModal;
