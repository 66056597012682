import { Button, Input, Modal } from 'antd';

import FooterBox from 'components/FooterBox';

interface IProps {
  correctRequestMessage: string | null;
  onClose: () => void;
}

const CorrectMessageModal = ({ correctRequestMessage, onClose }: IProps) => {
  return (
    <Modal
      visible={correctRequestMessage !== null}
      title="보완 문구 보기"
      footer={null}
      closable={false}
    >
      <Input.TextArea value={correctRequestMessage || ''} readOnly />
      <FooterBox>
        <Button type="primary" onClick={onClose}>
          닫기
        </Button>
      </FooterBox>
    </Modal>
  );
};

export default CorrectMessageModal;
