import { useState } from 'react';
import { useMutation } from 'react-query';
import { Card, Col, message, Row, Table, Tabs } from 'antd';
import styled from 'styled-components';

import { IBrandCategoryCertificateStat, IPeriod } from 'types/stats';
import * as statsApi from 'lib/api/stats';
import { ColumnsType } from 'antd/es/table/interface';
import PeriodSelect from 'components/stats/PeriodSelect';
import CompanySelect from 'components/system/data/CompanySelect';
import { nanoid } from 'nanoid';

const BrandCategoryCertificateTableBlock = styled(Card)`
  height: 100%;
`;

const BrandCategoryCertificateTable = () => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<null | number>(
    null,
  );

  const [
    brandCategoryCertificateStats,
    setBrandCategoryCertificateStats,
  ] = useState<IBrandCategoryCertificateStat[]>([]);
  const { mutate: getBrandCategoryCertificateStats } = useMutation(
    (params: { companyId: number; period: IPeriod }) =>
      statsApi.getBrandCategoryCertificateStats(params),
    { onSuccess: (res) => setBrandCategoryCertificateStats(res.data.result) },
  );
  const columns: ColumnsType<IBrandCategoryCertificateStat> = [
    { title: '구분', align: 'center', render: (_, __, index) => index + 1 },
    {
      title: '국가',
      dataIndex: 'countryNameKo',
      align: 'center',
    },
    {
      title: '인증 완료 수',
      dataIndex: 'countCertFin',
      align: 'center',
    },
  ];
  const handleSearch = (period: IPeriod) => {
    if (!selectedCompanyId) {
      return message.warn('브랜드사를 선택해 주세요.');
    }
    getBrandCategoryCertificateStats({
      companyId: selectedCompanyId,
      period,
    });
  };
  return (
    <BrandCategoryCertificateTableBlock>
      <Tabs style={{ padding: '0 4px' }}>
        <Tabs.TabPane key="category" tab="브랜드사별" />
      </Tabs>
      <Row justify="center" style={{ marginTop: 20, marginBottom: 36 }}>
        <Col>
          <CompanySelect companyType="brand" onChange={setSelectedCompanyId} />
        </Col>
        <Col>
          <PeriodSelect
            label={null}
            onSearch={(_, period) => {
              handleSearch(period);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={brandCategoryCertificateStats}
        pagination={false}
        rowKey={() => nanoid()}
      />
    </BrandCategoryCertificateTableBlock>
  );
};
export default BrandCategoryCertificateTable;
