import { ImportOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { ILoginForm } from 'types/auth';
import { useLogin } from 'hooks/auth';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const LoginForm = () => {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const loginMutation = useLogin();
  const [form] = useForm();
  const handleLogin = (loginForm: ILoginForm) => {
    loginMutation.mutate(loginForm);
  };
  useEffect(() => {
    if (query.reason === 'expire') {
      message.warn('로그인 세션이 만료되었습니다.');
      window.history.pushState(null, '', '/login');
    }
  }, [query.reason]);
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      form.setFieldsValue({ username: '1111111110', password: '1111' });
    }
  }, []);
  return (
    <Form style={{ marginTop: 16 }} onFinish={handleLogin}>
      <Form.Item
        name="username"
        rules={[{ required: true, message: '아이디를 입력해 주세요.' }]}
      >
        <Input placeholder="아이디" autoComplete="off" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: '패스워드를 입력해 주세요.' }]}
      >
        <Input.Password placeholder="패스워드" autoComplete="off" />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          type="primary"
          icon={<ImportOutlined />}
          loading={loginMutation.isLoading}
          block
          htmlType="submit"
        >
          로그인
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
