import { observer } from 'mobx-react';
import { useLocation, useRouteMatch } from 'react-router';

import { useStores } from 'stores/Context';
import PageTemplate from 'templates/PageTemplate';
import CertificateCompanyDetail from 'components/certificate/certificateCompanyDetail/CertificateCompanyDetail';
import { EProcessStage } from 'types/common';
import {
  ECertificateSearchTypeOptions,
  ICertificatePageState,
} from 'types/certificate';

const CertificateCompanyDetailPage = () => {
  const {
    params: { companyId },
  } = useRouteMatch<{ companyId: string }>();
  const { state = {} } = useLocation<ICertificatePageState>();
  const {
    page = 1,
    searchType = ECertificateSearchTypeOptions.PRODUCT_NAME_EN,
    searchValue = '',
    searchStatus = EProcessStage.ONGOING,
  } = state;
  const { companyStore } = useStores();
  const currentCompany = companyStore.getCurrentCompany();

  return (
    <PageTemplate
      title={`${currentCompany.companyNameKo} | ${currentCompany.bizNumber}`}
      back
    >
      <CertificateCompanyDetail
        companyId={Number(companyId)}
        page={page}
        initialSearchType={searchType}
        initialSearchValue={searchValue}
        initialSearchStatus={searchStatus}
      />
    </PageTemplate>
  );
};

export default observer(CertificateCompanyDetailPage);
