import { Divider, Form, Typography } from 'antd';
import { useProductChallengeTest } from 'hooks/product';

const ProductChallengeTestModalContent: React.FC<{
  productId: number;
  countryId: number;
}> = ({ productId, countryId }) => {
  const { data: productChallengeTest } = useProductChallengeTest({
    productId,
    countryId,
  });
  const {
    isReportExist,
    yeastName,
    moldName,
    isBacteriaTemperatureNone,
    isYeastTemperatureNone,
    isMoldTemperatureNone,
    bacteriaTemperature,
    yeastTemperature,
    moldTemperature,
    isMergeBacteria,
    isMergeYeast,
    isMergeMold,
    isMergeBacteriaTntc,
    isMergeYeastTntc,
    isMergeMoldTntc,
    mergeBacteria,
    mergeYeast,
    mergeMold,
    mergeBacteriaExponent,
    mergeYeastExponent,
    mergeMoldExponent,
    isSaureus,
    isEcoli,
    isPaeruginosa,
    isBacillus,
    isEnterobacter,
    isSaureusTntc,
    isEcoliTntc,
    isPaeruginosaTntc,
    isBacillusTntc,
    isEnterobacterTntc,
    isYeastTntc,
    isMoldTntc,
    saureus,
    ecoli,
    paeruginosa,
    bacillus,
    enterobacter,
    yeast,
    mold,
    saureusExponent,
    ecoliExponent,
    paeruginosaExponent,
    bacillusExponent,
    enterobacterExponent,
    yeastExponent,
    moldExponent,
  } = {
    ...productChallengeTest,
  };
  return (
    <Form labelCol={{ span: 12 }} size="small" style={{ paddingTop: 20 }}>
      {!isReportExist ? (
        <Typography.Title style={{ textAlign: 'center', fontSize: 16 }}>
          성적서 없음
        </Typography.Title>
      ) : (
        <>
          <Divider />
          <Typography.Paragraph
            style={{ textAlign: 'center', fontSize: 16 }}
            strong
          >
            - 선택 항목 -
          </Typography.Paragraph>
          <Form.Item label="Bacteria" colon={false}>
            <ul style={{ paddingLeft: 16 }}>
              {isSaureus && (
                <li>
                  <Typography.Text strong>
                    Staphylococcus aureus subsp
                  </Typography.Text>
                </li>
              )}
              {isEcoli && (
                <li>
                  <Typography.Text strong>Escherichia coli</Typography.Text>
                </li>
              )}
              {isPaeruginosa && (
                <li>
                  <Typography.Text strong>Bacillus subtilis</Typography.Text>
                </li>
              )}
              {isBacillus && (
                <li>
                  <Typography.Text strong>
                    Pseudomanas aeruginosa
                  </Typography.Text>
                </li>
              )}
              {isEnterobacter && (
                <li>
                  <Typography.Text strong>
                    Enterobacter spp (On-site detection of bacteria)
                  </Typography.Text>
                </li>
              )}
            </ul>
          </Form.Item>
          <Form.Item label="Yeast" colon={false}>
            <ul style={{ paddingLeft: 16 }}>
              <li>
                <Typography.Text strong>{yeastName}</Typography.Text>
              </li>
            </ul>
          </Form.Item>
          <Form.Item label="Mold" colon={false}>
            <ul style={{ paddingLeft: 16 }}>
              <li>
                <Typography.Text strong>{moldName}</Typography.Text>
              </li>
            </ul>
          </Form.Item>
          <Divider />
          <Typography.Paragraph
            style={{ textAlign: 'center', fontSize: 16 }}
            strong
          >
            - 온도 -
          </Typography.Paragraph>
          <Form.Item label="Bacteria">
            <Typography.Text strong>
              {isBacteriaTemperatureNone ? 'None' : `${bacteriaTemperature}℃`}
            </Typography.Text>
          </Form.Item>
          <Form.Item label="Yeast" style={{ marginTop: 44 }}>
            <Typography.Text strong>
              {isYeastTemperatureNone ? 'None' : `${yeastTemperature}℃`}
            </Typography.Text>
          </Form.Item>
          <Form.Item label="Mold" style={{ marginTop: 44 }}>
            <Typography.Text strong>
              {isMoldTemperatureNone ? 'None' : `${moldTemperature}℃`}
            </Typography.Text>
          </Form.Item>
          <Divider />
          <Typography.Paragraph
            style={{ textAlign: 'center', fontSize: 16 }}
            strong
          >
            - 미생물 수 -
          </Typography.Paragraph>
          {isMergeBacteria ? (
            <Form.Item label="Bacteria">
              <Typography.Text strong>
                {isMergeBacteriaTntc ? (
                  'TNTC'
                ) : (
                  <>
                    {mergeBacteria} x 10<sup>{mergeBacteriaExponent}</sup>
                  </>
                )}
              </Typography.Text>
            </Form.Item>
          ) : (
            <>
              <Form.Item label="Staphylococcus aureus subsp">
                <Typography.Text strong>
                  {isSaureusTntc ? (
                    'TNTC'
                  ) : (
                    <>
                      {saureus} x 10<sup>{saureusExponent}</sup>
                    </>
                  )}
                </Typography.Text>
              </Form.Item>
              <Form.Item label="Escherichia coli">
                <Typography.Text strong>
                  {isEcoliTntc ? (
                    'TNTC'
                  ) : (
                    <>
                      {ecoli} x 10<sup>{ecoliExponent}</sup>
                    </>
                  )}
                </Typography.Text>
              </Form.Item>
              <Form.Item label="Pseudomanas aeruginosa">
                <Typography.Text strong>
                  {isPaeruginosaTntc ? (
                    'TNTC'
                  ) : (
                    <>
                      {paeruginosa} x 10<sup>{paeruginosaExponent}</sup>
                    </>
                  )}
                </Typography.Text>
              </Form.Item>
              <Form.Item label="Bacillus subtilis">
                <Typography.Text strong>
                  {isBacillusTntc ? (
                    'TNTC'
                  ) : (
                    <>
                      {bacillus} x 10<sup>{bacillusExponent}</sup>
                    </>
                  )}
                </Typography.Text>
              </Form.Item>
              <Form.Item
                label={
                  <>
                    Enterobacter spp
                    <br />
                    (On-site detection of bacteria)
                  </>
                }
              >
                <Typography.Text strong>
                  {isEnterobacterTntc ? (
                    'TNTC'
                  ) : (
                    <>
                      {enterobacter} x 10<sup>{enterobacterExponent}</sup>
                    </>
                  )}
                </Typography.Text>
              </Form.Item>
            </>
          )}
          {isMergeYeast ? (
            <Form.Item label="Yeast" style={{ marginTop: 44 }}>
              <Typography.Text strong>
                {isMergeYeastTntc ? (
                  'TNTC'
                ) : (
                  <>
                    {mergeYeast} x 10<sup>{mergeYeastExponent}</sup>
                  </>
                )}
              </Typography.Text>
            </Form.Item>
          ) : (
            <Form.Item label={yeastName} style={{ marginTop: 44 }}>
              <Typography.Text strong>
                {isYeastTntc ? (
                  'TNTC'
                ) : (
                  <>
                    {yeast} x 10<sup>{yeastExponent}</sup>
                  </>
                )}
              </Typography.Text>
            </Form.Item>
          )}
          {isMergeMold ? (
            <Form.Item label="Mold" style={{ marginTop: 44 }}>
              <Typography.Text strong>
                {isMergeMoldTntc ? (
                  'TNTC'
                ) : (
                  <>
                    {mergeMold} x 10<sup>{mergeMoldExponent}</sup>
                  </>
                )}
              </Typography.Text>
            </Form.Item>
          ) : (
            <Form.Item label={moldName} style={{ marginTop: 44 }}>
              <Typography.Text strong>
                {isMoldTntc ? (
                  'TNTC'
                ) : (
                  <>
                    {mold} x 10<sup>{moldExponent}</sup>
                  </>
                )}
              </Typography.Text>
            </Form.Item>
          )}
          <Divider />
        </>
      )}
    </Form>
  );
};

export default ProductChallengeTestModalContent;
