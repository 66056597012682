import styled from 'styled-components';
import { Card, Col, Row, Select } from 'antd';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  LineChartOutlined,
  RiseOutlined,
} from '@ant-design/icons';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { useMemo, useState } from 'react';
import { EProgressPeriodType } from 'types/stats';
import { useSalesStatsProgress } from 'hooks/stats';
import { ChartData, ChartOptions } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import Table from 'components/system/data/Table';

const SalesProgressChartBlock = styled(Card)`
  .ant-card-body {
    padding: 56px;
  }
`;
const Line = styled.div<{ borderStyle: string }>`
  display: inline-block;
  width: 36px;
  height: 8px;
  border-top: 3px ${(props) => props.borderStyle} ${palette.primary};
`;

const periodTypeOptions = [
  {
    label: '올해 / 작년',
    value: EProgressPeriodType.YEAR,
  },
  {
    label: '이번 달 / 지난 달',
    value: EProgressPeriodType.MONTH,
  },
  {
    label: '이번 주 / 지난 주',
    value: EProgressPeriodType.WEEK,
  },
];

const SalesProgressChart = () => {
  const [periodType, setPeriodType] = useState<EProgressPeriodType>(
    periodTypeOptions[0].value,
  );
  const [currentPeriodLabel, pastPeriodLabel] =
    periodTypeOptions
      .find(({ value }) => value === periodType)
      ?.label.split(' / ') || [];

  const { current, past } = useSalesStatsProgress(periodType);

  const salesProgressChartData: ChartData = useMemo(() => {
    const labels = (past.dates.length > current.dates.length
      ? past.dates
      : current.dates
    ).map((_, index) => {
      const dateString = `${past.dates[index] || ''} ~ ${
        current.dates[index] || ''
      }`;
      return periodType === EProgressPeriodType.YEAR
        ? dateString
        : dateString.replace(/\d{4}-/g, '');
    });
    const currentData = current.salesStats.map(
      ({ sumSales, countCertFin }) => ({
        y: sumSales,
        x: countCertFin,
        index: 1,
      }),
    );
    const pastData = past.salesStats.map(({ sumSales, countCertFin }) => ({
      y: sumSales,
      x: countCertFin,
      index: 0,
    }));
    return {
      labels,
      datasets: [
        {
          borderColor: 'rgba(50, 115, 220)',
          borderWidth: 2,
          backgroundColor: 'rgba(26, 115, 232, 0.1)',
          fill: true,
          pointBorderWidth: 0,
          pointBackgroundColor: 'rgba(0,0,0,0)',
          data: currentData,
        },
        {
          borderColor: 'rgba(50, 115, 220, 0.5)',
          borderWidth: 2,
          backgroundColor: 'rgba(26, 115, 232, 0.1)',
          borderDash: [5, 5],
          fill: true,
          pointBorderWidth: 0,
          pointBackgroundColor: 'rgba(0,0,0,0)',
          data: pastData,
        },
      ],
    };
  }, [periodType, past.salesStats, current.salesStats]);

  const salesChartOptions: ChartOptions = useMemo(
    () => ({
      layout: {
        padding: 30,
      },
      aspectRatio: 4,
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          type: 'linear',
          grid: {
            borderDash: [5, 5],
            display: true,
          },
          beginAtZero: true,
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          backgroundColor: '#fff',
          bodyColor: '#222',
          borderColor: palette.primary,
          borderWidth: 1,
          callbacks: {
            title: () => '',
            label: function ({ label, raw: { x, y, index } }: any) {
              return `${label.split(' ~ ')[index]} : ${y}원 / ${x}건`;
            },
          },
        },
      },
    }),
    [],
  );

  return (
    <SalesProgressChartBlock>
      <Row justify="space-between">
        <Col>
          <LineChartOutlined style={{ color: palette.primary }} />
          <Typography.Text inline bold gutter={{ left: 8 }}>
            비교 그래프
          </Typography.Text>
        </Col>
        <Col>
          <Line borderStyle="solid" />
          <Typography.Text
            type="secondary"
            inline
            bold
            gutter={{ left: 8, right: 16 }}
            style={{ fontSize: 12 }}
          >
            {currentPeriodLabel}
          </Typography.Text>
          <Line borderStyle="dashed" />
          <Typography.Text
            type="secondary"
            inline
            bold
            gutter={{ left: 8 }}
            style={{ fontSize: 12 }}
          >
            {pastPeriodLabel}
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text type="secondary" bold inline gutter={{ right: 8 }}>
            기간 선택
          </Typography.Text>
          <Select
            options={periodTypeOptions}
            value={periodType}
            onChange={setPeriodType}
            style={{ width: 140, fontWeight: 700 }}
          />
        </Col>
      </Row>
      <ReactChart
        type="line"
        data={salesProgressChartData}
        options={salesChartOptions}
      />
      <Row style={{ borderBottom: '1px solid #ebeded', marginTop: 20 }}>
        <Col>
          <RiseOutlined style={{ color: palette.primary }} />
        </Col>
        <Col>
          <Typography.Text inline bold gutter={{ left: 8 }}>
            {currentPeriodLabel} / {pastPeriodLabel} 증감 추이
          </Typography.Text>
        </Col>
      </Row>
      <Table style={{ marginTop: 56 }}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{ textAlign: 'center' }}>기간</Table.Th>
            <Table.Th style={{ textAlign: 'center' }}>매출액</Table.Th>
            <Table.Th style={{ textAlign: 'center' }}>증감</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'center' }}>
              {currentPeriodLabel} ({current.period})
            </Table.Td>
            <Table.Td style={{ textAlign: 'center' }}>
              {current.totalSales.toLocaleString()}원
            </Table.Td>
            <Table.Td
              rowSpan={2}
              style={{
                textAlign: 'center',
                borderRight: '1px solid #eee',
              }}
            >
              <Typography.Title
                bold
                style={{
                  color:
                    current.totalSales - past.totalSales > 0
                      ? palette.text.primary
                      : palette.text.error,
                }}
              >
                {current.totalSales - past.totalSales > 0 ? (
                  <CaretUpOutlined style={{ marginRight: 8 }} />
                ) : (
                  <CaretDownOutlined style={{ marginRight: 8 }} />
                )}
                {Math.abs(
                  current.totalSales - past.totalSales,
                ).toLocaleString()}
                원
              </Typography.Title>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'center' }}>
              {pastPeriodLabel} ({past.period})
            </Table.Td>
            <Table.Td style={{ textAlign: 'center' }}>
              {past.totalSales.toLocaleString()}원
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </SalesProgressChartBlock>
  );
};

export default SalesProgressChart;
