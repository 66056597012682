import React from 'react';
import { Button, Form, Input, Modal, Radio } from 'antd';
import Editor from 'components/Editor';
import FooterBox from 'components/FooterBox';
import { useState } from 'react';
import { ENoticeType } from 'types/service';
import { useAddNotice } from 'hooks/service';
import consts from 'lib/consts';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface INoticeAddModalProps {
  onClose: (isModified: boolean) => void;
}

const NoticeAddModal: React.FC<INoticeAddModalProps> = ({ onClose }) => {
  const [form] = Form.useForm();
  const [content, setContent] = useState('');
  const [contentValidMap, setContentValidMap] = useState<{
    validateStatus:
      | ''
      | 'error'
      | 'success'
      | 'warning'
      | 'validating'
      | undefined;
    help: string;
  }>({
    validateStatus: '',
    help: '',
  });
  const { mutate: addNoticeMutate, isLoading } = useAddNotice({
    onSuccess: () => {
      onClose(true);
    },
  });
  const addNotice = (noticeForm: {
    noticeType: ENoticeType;
    title: string;
    isLive: boolean;
    files: FileList[];
  }) => {
    if (
      content.replace(/<[^>]*>/g, '') === '' &&
      contentValidMap.validateStatus === ''
    ) {
      setContentValidMap({
        validateStatus: 'error' as const,
        help: consts.message.REQUIRED_FIELD,
      });
      return;
    }
    if (contentValidMap.validateStatus !== '') return;
    debugger;
    addNoticeMutate({
      noticeType: noticeForm.noticeType,
      title: noticeForm.title,
      content,
      isLive: noticeForm.isLive,
      ...(noticeForm.files &&
        noticeForm.files.length && {
          files: noticeForm.files
            .filter((fileList) => fileList instanceof FileList)
            .map((fileList) => fileList[0]),
        }),
    });
  };
  const changeContent = (content: string) => {
    setContent(content);
    setContentValidMap((draft) =>
      content.replace(/<[^>]*>/g, '') === ''
        ? {
            validateStatus: 'error' as const,
            help: consts.message.REQUIRED_FIELD,
          }
        : draft.validateStatus !== ''
        ? {
            validateStatus: '',
            help: '',
          }
        : draft,
    );
    if (content.replace(/<[^>]*>/g, '') === '') {
      setContentValidMap({
        validateStatus: 'error' as const,
        help: consts.message.REQUIRED_FIELD,
      });
    } else if (contentValidMap.help !== '') {
      setContentValidMap({
        validateStatus: '',
        help: '',
      });
    }
  };

  return (
    <Modal
      title="공지사항 등록"
      visible={true}
      width={800}
      footer={null}
      maskClosable={false}
      onCancel={() => onClose(false)}
    >
      <Form
        form={form}
        onFinish={addNotice}
        labelCol={{ span: 4 }}
        colon={false}
      >
        <Form.Item
          name="noticeType"
          label="공지 유형"
          initialValue={ENoticeType.NOR}
        >
          <Radio.Group
            options={[
              { value: ENoticeType.NOR, label: '일반 공지' },
              { value: ENoticeType.SYS, label: '시스템 공지' },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="제목"
          name="title"
          required
          rules={[{ required: true, message: consts.message.REQUIRED_FIELD }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="내용"
          {...contentValidMap}
          style={{ marginBottom: 0 }}
          required
        >
          <Editor value={content} setValue={changeContent} />
        </Form.Item>
        <Form.Item label="공개 여부" name="isLive" initialValue={true}>
          <Radio.Group
            options={[
              { value: true, label: '공개' },
              { value: false, label: '비공개' },
            ]}
          />
        </Form.Item>
        <Form.Item label="첨부파일">
          <Form.List name="files" initialValue={[undefined]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item key={field.key}>
                    <Form.Item
                      noStyle
                      {...field}
                      name={[field.name]}
                      style={{ marginBottom: 8 }}
                      valuePropName="files"
                    >
                      <Input
                        type="file"
                        style={{
                          display: 'inline-block',
                          width: 'auto',
                          border: 'none',
                          padding: 0,
                        }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Form.Item>
                ))}
                <Button
                  type="dashed"
                  onClick={add}
                  style={{ width: 320 }}
                  icon={<PlusOutlined />}
                >
                  추가 업로드
                </Button>
              </>
            )}
          </Form.List>
        </Form.Item>
        <FooterBox>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            추가하기
          </Button>
        </FooterBox>
      </Form>
    </Modal>
  );
};

export default NoticeAddModal;
