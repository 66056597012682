import styled from 'styled-components';

import { Typography } from 'components/system';
import BuyerStatsCard from 'components/stats/buyerStats/BuyerStatsCard';

const BuyerStatsPageBlock = styled.div``;

const BuyerStatsPage = () => {
  return (
    <BuyerStatsPageBlock>
      <Typography.Title gutter={{ bottom: 8 }}>바이어 분석</Typography.Title>
      <BuyerStatsCard />
    </BuyerStatsPageBlock>
  );
};

export default BuyerStatsPage;
