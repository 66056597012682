export interface IProductDetail {
  productNameKo: string;
  productNameEn: string;
  countryProductName: string | null;
  brandNameEn: string;
  countryBrandName: string | null;
  manufacturerNameKo: '한국콜마';
  manufacturerNameEn: 'Kolmar Korea';
  manufacturerAddressEn: '12-11 Doekgogae-gil, Jeonui-myeon, Sejong Metropolitan Autonomous City';
  manufacturerTel: '02-1111-2222';
  manufacturerFax: '02-1111-2220';
}

export interface ICosmeticCategory {
  cosmeticCategoryId: number;
  depth: number;
  name: string;
  parentId: number;
  example: string;
}

export interface IFunctionalCategory {
  functionalCategoryId: number;
  name: string;
}

export interface IProductCategory {
  productCategoryId: number;
  productId: number;
  cosmeticCategoryIdDepth1: number;
  cosmeticCategoryIdDepth2: number;
  isFunctional: boolean;
  functionalCategoryId: number;
}

export interface IProductPhysicalProperty {
  color: string;
  countryId: number;
  expiryDate?: string;
  form: string;
  hardness?: number;
  hardnessLimitMax?: number;
  hardnessLimitMin?: number;
  hardnessUnit?: string;
  isAerosolProduct: boolean;
  isEthanolProduct: boolean;
  isExistedHardness?: boolean;
  isExistedPh?: boolean;
  isExistedSpecificGravity?: boolean;
  isExistedViscosity?: boolean;
  lotNo: string;
  manufacturingDate?: string;
  odour: string;
  ph: number;
  phLimitMax: number;
  phLimitMin: number;
  productId: number;
  productPhysicalPropertyId: number;
  productType: string;
  specificGravity: number;
  specificGravityLimitMax: number;
  specificGravityLimitMin: number;
  viscosity?: number;
  viscosityLimitMax?: number;
  viscosityLimitMin?: number;
  viscosityUnit?: string;
}

export interface IProductStabilityTest {
  temperatureRange1: string;
  temperatureRange2: string;
  temperatureRange3: string;
  cycleTemperatureRange: string;
}

export interface IProductChallengeTest {
  isReportExist: boolean;
  bacteriaTemperature: number;
  yeastTemperature: number;
  moldTemperature: number;
  isSaureus: boolean;
  isEcoli: boolean;
  isPaeruginosa: boolean;
  isBacillus: boolean;
  isEnterobacter: boolean;
  isBacteriaTemperatureNone: boolean;
  isYeastTemperatureNone: boolean;
  isMoldTemperatureNone: boolean;
  isMergeBacteria: boolean;
  isMergeYeast: boolean;
  isMergeMold: boolean;
  saureus: number;
  saureusExponent: number;
  isSaureusTntc: boolean;
  ecoli: number;
  ecoliExponent: number;
  isEcoliTntc: boolean;
  paeruginosa: number;
  paeruginosaExponent: number;
  isPaeruginosaTntc: boolean;
  bacillus: number;
  bacillusExponent: number;
  isBacillusTntc: boolean;
  enterobacter: number;
  enterobacterExponent: number;
  isEnterobacterTntc: boolean;
  yeastName: string;
  yeast: number;
  yeastExponent: number;
  isYeastTntc: boolean;
  moldName: string;
  mold: number;
  moldExponent: number;
  isMoldTntc: boolean;
  mergeBacteria: number;
  mergeBacteriaExponent: number;
  isMergeBacteriaTntc: boolean;
  mergeYeast: number;
  mergeYeastExponent: number;
  isMergeYeastTntc: boolean;
  mergeMold: number;
  mergeMoldExponent: number;
  isMergeMoldTntc: boolean;
}

export enum EAttachType {
  FIRST = '1ST',
  SECOND = '2ST',
}

interface IProductPackingAttestationAttache {
  productPackingAttestationAttachId: number;
  attachType: EAttachType;
  attachUrl: 'string';
  filename: 'string';
}

export interface IProductPackingAttestation {
  packingContainer1: string;
  packingContainer2: string;
  isUseRecyclingContainer: boolean;
  isUseColorContainer: boolean;
  productPackingAttestationAttaches: IProductPackingAttestationAttache[];
}

export interface IProductArtwork {
  uploadFileUrl: string;
  documentUrl: string;
  filename: string;
}

export interface IProductThirdPartyTest {
  uploadFileUrl: string;
  documentUrl: string;
  filename: string;
}
export interface IProductInternalResponsibleCompany {
  internalResponsibleCompanyId: number;
  isOwnSubsidiary: boolean;
  productId: number;
  countryId: number;
  isCreateNewNMPAAccount: boolean;
  newNMPAAccountId: string;
  newNMPAAccountPassword: string;
  fileName: string;
  documentFile: string;
  companyTel: string;
  companyEmail: string;
}

export interface IProductShelfLifeDeclaration {
  productExpireMonths: number;
}

export interface IProductSaleInfoAttach {
  attachUrl?: string;
  filename?: string;
  productSaleInfoAttachId?: number;
}
export interface IProductSaleInfo {
  productSaleInfoId: number;
  isOnlineSale: boolean;
  saleWebUrl: string | null;
  attaches: IProductSaleInfoAttach[];
}

export enum EProductVCRPManagerPositionName {
  OWNER = 'OWNER',
  PRESIDENT = 'PRESIDENT',
  MANAGER = 'MANAGER (담당자)',
}
export interface IProductVCRPAccountRequest {
  productVcrpAccountRequestId: number;
  productId: number;
  countryId: number;
  companyNameEn: string;
  managerLastName: string;
  managerFirstName: string;
  vcrpAccount: string;
  managerPositionName: EProductVCRPManagerPositionName;
  managerMobile: string;
  managerEmail: string;
}

export interface IProductVCRPAccount {
  vcrpAccountId: number;
  vcrpAccount: string;
  vcrpPassword: string;
}

export interface IProductNecessaryInfoCosmeticCategory {
  cosmeticCategoryId: number;
  depth: number;
  name: string;
  indiaCategory1: string;
  indiaCategory2: string;
  indiaCategory3: string;
  europeCategory: string;
  parentId: number;
  example: string;
}

export interface IProductNecessaryInfoCosmeticCategoryCountry {
  cosmeticCategoryCountryId: number;
  countryId: number;
  name: string;
  depth: number;
  priority: number;
}

export interface IProductNecessaryInfo {
  brandNameEn: string;
  productNameEn: string;
  companyNameEn: string;
  companyAddressEn: string;
  companyAddressKo: string;
  manufacturerNameEn: string;
  manufacturerAddressEn: string;
  manufacturerAddressKo: string;
  cosmeticCategories: IProductNecessaryInfoCosmeticCategory[];
  functionalCategory: IFunctionalCategory;
  cosmeticCategoryCountries: IProductNecessaryInfoCosmeticCategoryCountry[];
  productSaleWebUrl: string | null;
  inciNames: string[];
}
