import { useEffect, useState } from 'react';
import { Row } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { EClinicalTrialProcessItemStatus } from 'types/clinicalTrial/process/processingEstimateDetail';
import {
  CompleteTestModal,
  RemarkModal,
  StopReasonModal,
} from 'components/clinicalTrial/process/modal/index';
import palette from 'lib/styles/palette';

const OrderOptionListContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 16px;
  width: 130px;
  z-index: 1;
  border-radius: 8px;
  overflow: hidden;

  cursor: pointer;
`;

const OrderOptionContainer = styled.div`
  position: relative;
  background-color: ${palette.gray90};
  padding: 6px 24px;
  color: #fff;

  & + & {
    border-top: 1px solid #7d7d7d;
  }

  .anticon {
    position: absolute;
    top: 9px;
    left: 8px;
  }
`;

const EtcModal = ({
  clinicalTrialProcessItemId,
  status,
  stopReason,
  remark,
}: {
  clinicalTrialProcessItemId: number;
  status: EClinicalTrialProcessItemStatus | null;
  stopReason: string | null;
  remark: string | null;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fileListModalVisible, setFileListModalVisible] = useState(false);
  const [stopReasonModalProps, setStopReasonModalProps] = useState<{
    stopReason: string | null;
    clinicalTrialProcessItemId: number;
  } | null>(null);
  const [remarkModalProps, setRemarkModalProps] = useState<{
    remark: string | null;
    clinicalTrialProcessItemId: number;
  } | null>(null);
  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = () => {
        setIsOpen(false);
      };
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }
  }, [isOpen]);

  return (
    <>
      <CompleteTestModal
        visible={fileListModalVisible}
        clinicalTrialProcessItemId={clinicalTrialProcessItemId}
        onClose={() => setFileListModalVisible(false)}
      />
      {stopReasonModalProps && (
        <StopReasonModal
          {...stopReasonModalProps}
          onClose={() => setStopReasonModalProps(null)}
        />
      )}
      {remarkModalProps && (
        <RemarkModal
          {...remarkModalProps}
          onClose={() => setRemarkModalProps(null)}
        />
      )}
      <Row justify="center" onClick={() => setIsOpen(!isOpen)}>
        {[...Array(3).keys()].map((val) => (
          <Typography.Text
            key={val}
            type="secondary"
            bold
            style={{ fontSize: 20 }}
          >
            .
          </Typography.Text>
        ))}
      </Row>
      {isOpen && (
        <OrderOptionListContainer>
          {status === EClinicalTrialProcessItemStatus.DLV && (
            <OrderOptionContainer onClick={() => setFileListModalVisible(true)}>
              <Typography.Text type="tertiary">시험 완료 처리</Typography.Text>
            </OrderOptionContainer>
          )}
          {status !== EClinicalTrialProcessItemStatus.STP &&
            status !== EClinicalTrialProcessItemStatus.COM && (
              <OrderOptionContainer
                onClick={() =>
                  setStopReasonModalProps({
                    clinicalTrialProcessItemId,
                    stopReason,
                  })
                }
              >
                <Typography.Text type="tertiary">
                  시험 중단 (사유)
                </Typography.Text>
              </OrderOptionContainer>
            )}
          <OrderOptionContainer
            onClick={() =>
              setRemarkModalProps({
                remark,
                clinicalTrialProcessItemId,
              })
            }
          >
            <Typography.Text type="tertiary">비고</Typography.Text>
          </OrderOptionContainer>
        </OrderOptionListContainer>
      )}
    </>
  );
};

export default EtcModal;
