import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Button, Col, Row } from 'antd';

import { Typography } from 'components/system';
import { useStores } from 'stores/Context';
import { CircleCheckIcon } from 'components/clinicalTrial/estimate/style';
import palette from 'lib/styles/palette';
import history from 'lib/history';
import path from 'lib/path';
import ClinicalTrialCartItemTable from './ClinicalTrialCartItemTable';
import ClinicalTrialEstimatePublishModal from './ClinicalTrialEstimatePublishModal';
import {
  useAddClinicalTrialEstimate,
  useCategoryDatas,
  usePreviewClinicalTrialEstimate,
} from 'hooks/clinicalTrial/clinicalTrial';
import {
  EClinicalTrialEstimateSearchType,
  IClinicalTrialCartItem,
} from 'types/clinicalTrial';
import ClinicalTrialRequestDetail from 'components/clinicalTrial/ClinicalTrialRequestDetail';
import { useEstimateRequestDetails } from 'hooks/clinicalTrial/process/estimateRequest';

const ClinicalTrialCartContainer = styled.div`
  max-width: 720px;
  padding: 24px 0px 40px 16px;
  margin: 0 auto;
`;

const ClinicalTrialCartItemTotalPriceContainer = styled.div`
  margin-top: 8px;
  background-color: #fff;
  padding: 36px 80px;
  box-shadow: 0 0 6px 0 rgba(234, 234, 234, 0.5);
`;

const ClinicalTrialCartBody = styled.div`
  display: flex;
  gap: 32px;
`;

const ClinicalTrialCartItemTableContainer = styled.div`
  position: relative;
  min-width: 720px;
  min-height: 400px;
`;

const RequestListContainer = styled.div``;

const ClinicalTrialCartWrapper = styled.div`
  height: calc(100vh - 48px);
  background-color: ${palette.bgBlue};
  overflow: auto;
`;

const RequestItemContainer = styled.div`
  width: 400px;
  background-color: #eff1f8;
  border-radius: 12px;
  padding: 24px 24px 36px 24px;
`;

const ClinicalTrialCart = ({
  onAddEstimateItem,
  onMoveToSelect,
}: {
  onAddEstimateItem?: (cartItems: IClinicalTrialCartItem[]) => void;
  onMoveToSelect?: () => void;
}) => {
  const categoryDatas = useCategoryDatas();
  const [
    isEstimatePublishModalVisible,
    setIsEstimatePublishModalVisible,
  ] = useState(false);
  const { clinicalTrialStore } = useStores();
  const clinicalTrialEstimateRequest =
    clinicalTrialStore.clinicalTrialEstimateRequest;
  const { selectedCartItems, clinicalTrialCartItems } = clinicalTrialStore;
  const canPublishEstimate = selectedCartItems.length !== 0;
  const isAllCartItemSelected =
    clinicalTrialCartItems.length > 0 &&
    selectedCartItems.length === clinicalTrialCartItems.length;
  const totalPrice = selectedCartItems.reduce(
    (acc, { item, itemQuantity }) => acc + item.price * itemQuantity,
    0,
  );
  const handleRemoveCartItems = () => {
    clinicalTrialStore.removeClinicalTrialCartItems(
      selectedCartItems.map(
        ({ clinicalTrialCartItemId }) => clinicalTrialCartItemId,
      ),
    );
  };
  const handleShowEstimatePublishModal = () => {
    setIsEstimatePublishModalVisible(true);
  };
  const handleCloseEstimatePublishModal = () => {
    setIsEstimatePublishModalVisible(false);
  };
  const {
    previewClinicalTrialEstimate,
    isLoading: previewClinicalTrialEstimateLoading,
  } = usePreviewClinicalTrialEstimate();
  const {
    addClinicalTrialEstimate,
    isLoading: addClinicalTrialEstimateLoading,
  } = useAddClinicalTrialEstimate();
  const handlePreviewEstimate = async () => {
    const {
      data: { result: estimateUrl },
    } = await previewClinicalTrialEstimate(selectedCartItems);
    window.open(estimateUrl);
  };
  const handlePublishEstimate = async ({
    clientCompanyName,
    clientName,
  }: {
    clientCompanyName: string;
    clientName: string;
  }) => {
    setIsEstimatePublishModalVisible(false);
    await addClinicalTrialEstimate({
      clientCompanyName,
      clientName,
      cartItems: selectedCartItems,
      clinicalTrialEstimateRequestId:
        clinicalTrialEstimateRequest?.clinicalTrialEstimateRequestId,
    });
    clinicalTrialStore.emptyClinicalTrialCartItems();
    history.replace(path.clinicalTrial.estimate.list, {
      searchType: EClinicalTrialEstimateSearchType.CLIENT_COMPANY_NAME,
      searchKeyword: clientCompanyName,
    });
  };
  const { estimateRequestDetailMap } = useEstimateRequestDetails(
    clinicalTrialEstimateRequest?.clinicalTrialEstimateRequestId,
  );

  return (
    <ClinicalTrialCartWrapper>
      <ClinicalTrialCartContainer>
        {isEstimatePublishModalVisible && (
          <ClinicalTrialEstimatePublishModal
            onPublish={handlePublishEstimate}
            onClose={handleCloseEstimatePublishModal}
          />
        )}
        <Typography.Title align="center" color="darkNavy" medium>
          장바구니
        </Typography.Title>
        <Row
          align="middle"
          gutter={8}
          style={{ marginTop: 8, marginBottom: 18 }}
        >
          <Col>
            <CircleCheckIcon
              checked={isAllCartItemSelected}
              onClick={() => {
                isAllCartItemSelected
                  ? clinicalTrialStore.deselectAllClinicalTrialCartItems()
                  : clinicalTrialStore.selectAllClinicalTrialCartItems();
              }}
            />
          </Col>
          <Col>
            <Typography.Text type="secondary" color="darkNavy" medium>
              전체 선택 ({selectedCartItems.length}/
              {clinicalTrialCartItems.length})
            </Typography.Text>
          </Col>
          <Col>
            <Button
              style={{
                backgroundColor: palette.darkNavy,
                color: '#fff',
                width: 52,
                height: 20,
                padding: '2px 0 3px',
                fontSize: 10,
                fontWeight: 500,
              }}
              onClick={handleRemoveCartItems}
            >
              선택 삭제
            </Button>
          </Col>
        </Row>
        <ClinicalTrialCartBody>
          <ClinicalTrialCartItemTableContainer>
            {categoryDatas.map(({ categoryDataId, categoryDataName }) => {
              const cartItemsByCategory = clinicalTrialCartItems.filter(
                (cartItem) => cartItem.categoryDataId === categoryDataId,
              );
              if (cartItemsByCategory.length === 0) return null;
              return (
                <ClinicalTrialCartItemTable
                  key={categoryDataId}
                  categoryDataName={categoryDataName}
                  clinicalTrialCartItems={cartItemsByCategory}
                />
              );
            })}
          </ClinicalTrialCartItemTableContainer>
          {clinicalTrialEstimateRequest !== null && (
            <RequestListContainer>
              <Typography.Text bold gutter={{ bottom: 8 }}>
                {`(주) ${clinicalTrialEstimateRequest.companyNameKo} 님의 견적 요청 목록`}
              </Typography.Text>
              <RequestItemContainer>
                {Array.from(estimateRequestDetailMap.entries()).map(
                  ([categoryName, items]) => (
                    <ClinicalTrialRequestDetail
                      key={categoryName}
                      categoryName={categoryName}
                      items={items}
                    />
                  ),
                )}
              </RequestItemContainer>
            </RequestListContainer>
          )}
        </ClinicalTrialCartBody>
        <Typography.Text bold color="darkNavy" gutter={{ top: 24 }}>
          총 견적 금액
        </Typography.Text>
        <ClinicalTrialCartItemTotalPriceContainer>
          <Row justify="center" align="middle" gutter={64}>
            <Col span={13}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary" color="darkNavy" medium>
                    금액
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text type="secondary">
                    {totalPrice.toLocaleString()} 원
                  </Typography.Text>
                </Col>
              </Row>
              <Row justify="space-between" style={{ marginTop: 8 }}>
                <Col>
                  <Typography.Text type="secondary" color="darkNavy" medium>
                    부가세
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text type="secondary">
                    {(totalPrice * 0.1).toLocaleString()} 원
                  </Typography.Text>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  marginTop: 8,
                  borderTop: '1px dotted #42596840',
                  paddingTop: 16,
                }}
              >
                <Col>
                  <Typography.Text type="secondary" color="darkNavy" medium>
                    총 합계 (VAT 포함)
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Title bold color="primary">
                    {(totalPrice * 1.1).toLocaleString()} 원
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
            <Col span={11}>
              <Button
                loading={addClinicalTrialEstimateLoading}
                size="large"
                type="primary"
                block
                disabled={!canPublishEstimate}
                onClick={() => {
                  if (onAddEstimateItem) {
                    onAddEstimateItem(clinicalTrialStore.selectedCartItems);
                    clinicalTrialStore.emptyClinicalTrialCartItems();
                  } else {
                    handleShowEstimatePublishModal();
                  }
                }}
              >
                {onAddEstimateItem ? '이대로 견적에 추가' : '견적서 발행'}
              </Button>
              {!onMoveToSelect ? (
                <Row gutter={8} style={{ marginTop: 8 }}>
                  <Col span={12}>
                    <Button
                      loading={previewClinicalTrialEstimateLoading}
                      block
                      disabled={!canPublishEstimate}
                      type="primary"
                      style={{
                        fontSize: 12,
                        padding: '7px 10px',
                        letterSpacing: -0.7,
                        fontWeight: 500,
                        ...(canPublishEstimate
                          ? { color: '#fff' }
                          : { color: palette.text.disabled }),
                        ...(canPublishEstimate && {
                          backgroundColor: palette.darkNavy,
                        }),
                      }}
                      onClick={handlePreviewEstimate}
                    >
                      견적서 미리보기
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      block
                      style={{
                        fontSize: 12,
                        padding: '7px 10px',
                        letterSpacing: -0.7,
                        fontWeight: 500,
                        color: '#fff',
                        backgroundColor: palette.darkNavy,
                      }}
                      onClick={() =>
                        history.replace(path.clinicalTrial.estimate.select)
                      }
                    >
                      시험항목 추가
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Button
                  block
                  size="large"
                  style={{
                    marginTop: 8,
                    color: '#fff',
                    backgroundColor: palette.darkNavy,
                  }}
                  onClick={onMoveToSelect}
                >
                  시험항목 추가
                </Button>
              )}
            </Col>
          </Row>
        </ClinicalTrialCartItemTotalPriceContainer>
      </ClinicalTrialCartContainer>
    </ClinicalTrialCartWrapper>
  );
};

export default observer(ClinicalTrialCart);
