import { Button, Col, Row } from 'antd';
import { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import useUpdateEffect from 'hooks/useUpdateEffect';
import ClinicalTrialProductSelect from './ClinicalTrialProductSelect';
import ClinicalTrialPackageSelect from './ClinicalTrialPackageSelect';
import RefreshIcon from 'components/system/icon/RefreshIcon';
import palette from 'lib/styles/palette';
import CartIcon from 'components/system/icon/CartIcon';
import { useStores } from 'stores/Context';
import ClinicalTrialRecommendedPackageModal from './ClinicalTrialRecommendedPackageModal';
import history from 'lib/history';
import path from 'lib/path';
import {
  useCategoryDatas,
  useClinicalTrialPackages,
} from 'hooks/clinicalTrial/clinicalTrial';
import ClinicalTrialModal from '../ClinicalTrialModal';

enum ESelectType {
  SINGLE = 'SINGLE',
  PACKAGE = 'PACKAGE',
}

const TabContainer = styled(Row)`
  padding: 0 16px;
`;

const TypeTab = styled.div<{ active?: boolean }>`
  width: 100px;
  background-color: #497cc8;
  color: #fff;
  text-align: center;
  padding: 8px 0;
  opacity: 0.4;
  font-weight: 500;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      background-color: #4e5968;
    `}
`;

const ClinicalTrialSelectContainer = styled.div``;

const HeaderContainer = styled.div`
  padding-top: 16px;
`;

const CategoryContainer = styled(Row)`
  margin-top: 4px;
  overflow: auto;
  height: 54px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CategoryTab = styled.div<{ active: boolean }>`
  width: 148px;
  padding: 14px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: ${palette.text.disabled};
  box-shadow: 0px -1px 0 #dfdfdf inset;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      color: ${palette.text.primary};
      box-shadow: 0px -4px 0 ${palette.primary} inset;
    `}
`;

const SelectActionContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px 20px;
  z-index: 2;
  background-color: ${palette.gray5};
`;

const SelectActionButton = styled(Button)<{ color: string }>`
  border: none;
  background-color: ${({ color }) => color} !important;
  color: #fff !important;
  width: 200px;
`;

const CartButton = styled(Button)`
  width: 38px;
  height: 38px;
  padding: 6px;
`;

const ClinicalTrialSelect = ({
  onMoveToCart,
}: {
  onMoveToCart?: () => void;
}) => {
  const [selectType, setSelectType] = useState<ESelectType>(ESelectType.SINGLE);
  const categoryDatas = useCategoryDatas();
  const clinicalTrialPackages = useClinicalTrialPackages();
  const categoryContainerRef = useRef<HTMLDivElement>(null);
  const [
    recommendedPackageModalVisible,
    setRecommendedPackageModalVisible,
  ] = useState(false);
  const [proposalMode, setProposalMode] = useState(true);
  const { clinicalTrialStore, clinicalTrialToastStore } = useStores();
  const selectCount =
    selectType === ESelectType.SINGLE
      ? clinicalTrialStore.selectedClinicalTrialProducts.length
      : clinicalTrialStore.clinicalTrialPackageSelections.length;
  const [categoryChangeModalProps, setCategoryChangeModalProps] = useState<{
    changingCategoryDataId: number;
    onOk?: () => void;
  } | null>(null);
  const [
    moveToCartWarringModalVisible,
    setMoveToCartWarringModalVisible,
  ] = useState(false);
  const handleClickCategory = (categoryDataId: number) => {
    if (categoryDataId === clinicalTrialStore.categoryDataId) return;
    if (
      clinicalTrialStore.selectedClinicalTrialProducts.length > 0 ||
      clinicalTrialStore.clinicalTrialPackageSelections.length > 0
    ) {
      setCategoryChangeModalProps({
        changingCategoryDataId: categoryDataId,
      });
    } else {
      clinicalTrialStore.categoryDataId = categoryDataId;
    }
  };

  const handleClickRequestItem = (
    categoryDataId: number,
    selectClinicalTrial: () => void,
  ) => {
    if (
      (clinicalTrialStore.selectedClinicalTrialProducts.length > 0 ||
        clinicalTrialStore.clinicalTrialPackageSelections.length > 0) &&
      categoryDataId !== clinicalTrialStore.categoryDataId
    ) {
      setCategoryChangeModalProps({
        changingCategoryDataId: categoryDataId,
        onOk: selectClinicalTrial,
      });
    } else {
      if (categoryDataId !== clinicalTrialStore.categoryDataId) {
        clinicalTrialStore.categoryDataId = categoryDataId;
      }
      setTimeout(() => selectClinicalTrial(), 0);
    }
  };

  const handleChangeCategory = () => {
    if (categoryChangeModalProps) {
      clinicalTrialStore.categoryDataId =
        categoryChangeModalProps.changingCategoryDataId;
      setCategoryChangeModalProps(null);
    }
  };

  const handleRefreshSelect = () => {
    if (selectType === ESelectType.SINGLE) {
      clinicalTrialStore.emptyClinicalTrialProducts();
    } else {
      clinicalTrialStore.emptyClinicalTrialPackageSelections();
    }
  };

  const handleChangeSelectType = (newSelectType: ESelectType) => {
    if (newSelectType === selectType) return;
    setSelectType(newSelectType);
    clinicalTrialStore.emptyClinicalTrialPackageSelections();
    clinicalTrialStore.emptyClinicalTrialProducts();
  };

  const handleShowRecommendedPackageModal = () => {
    const selectedClinicalTrialItemIds = clinicalTrialStore.selectedClinicalTrialProducts.map(
      ({ clinicalTrialProductId }) => clinicalTrialProductId,
    );
    const hasSelectablePackages = clinicalTrialPackages.some(
      ({ clinicalTrialPackageProducts }) =>
        clinicalTrialPackageProducts.some((clinicalTrialItems) =>
          clinicalTrialItems.some((clinicalTrialItem) =>
            selectedClinicalTrialItemIds.includes(
              clinicalTrialItem.clinicalTrialProductId,
            ),
          ),
        ),
    );
    if (hasSelectablePackages) {
      setProposalMode(true);
      setRecommendedPackageModalVisible(true);
    } else {
      clinicalTrialToastStore.showClinicalTrialToast({
        message: '선택하신 항목에 관련된 패키지 상품이 없습니다.',
      });
    }
  };

  const handleCloseRecommendedPackageModal = () => {
    setRecommendedPackageModalVisible(false);
  };

  const showToastAfterAddToCart = () => {
    clinicalTrialToastStore.showClinicalTrialToast({
      message: '선택하신 항목을 장바구니에 담았습니다. ',
      buttonProps: {
        text: '장바구니 가기',
        onClick: () => {
          if (onMoveToCart) {
            onMoveToCart();
          } else {
            history.replace(path.clinicalTrial.estimate.cart);
          }
        },
      },
    });
  };

  const handleAddToCart = () => {
    if (selectType === ESelectType.PACKAGE) {
      clinicalTrialStore.addClinicalTrialCartItems(
        clinicalTrialStore.clinicalTrialPackageSelections,
      );
      showToastAfterAddToCart();
      return;
    }
    const selectedClinicalTrialItemIds = clinicalTrialStore.selectedClinicalTrialProducts.map(
      ({ clinicalTrialProductId }) => clinicalTrialProductId,
    );
    const hasSelectablePackages = clinicalTrialPackages.some(
      ({ clinicalTrialPackageProducts }) =>
        clinicalTrialPackageProducts.every((clinicalTrialItems) =>
          clinicalTrialItems.some(({ clinicalTrialProductId }) =>
            selectedClinicalTrialItemIds.includes(clinicalTrialProductId),
          ),
        ),
    );
    if (hasSelectablePackages) {
      setProposalMode(false);
      setRecommendedPackageModalVisible(true);
      return;
    }
    clinicalTrialStore.addClinicalTrialCartItems(
      clinicalTrialStore.selectedClinicalTrialProducts,
    );
    showToastAfterAddToCart();
  };

  const handleMoveToCart = (shouldCheckSelected: boolean) => {
    if (clinicalTrialStore.clinicalTrialCartItems.length === 0) {
      clinicalTrialToastStore.showClinicalTrialToast({
        message: '장바구니가 비었습니다.',
      });
      return;
    }
    if (
      (shouldCheckSelected &&
        clinicalTrialStore.selectedClinicalTrialProducts.length > 0) ||
      clinicalTrialStore.clinicalTrialPackageSelections.length > 0
    ) {
      setMoveToCartWarringModalVisible(true);
      return;
    }
    clinicalTrialStore.emptyClinicalTrialProducts();
    clinicalTrialStore.emptyClinicalTrialPackageSelections();
    if (onMoveToCart) {
      onMoveToCart();
    } else {
      history.replace(path.clinicalTrial.estimate.cart);
    }
  };

  useUpdateEffect(() => {
    clinicalTrialStore.categoryDataId = categoryDatas[0].categoryDataId;
    categoryContainerRef.current?.scrollTo(0, 0);
  }, [selectType]);

  return (
    <ClinicalTrialSelectContainer>
      {recommendedPackageModalVisible && (
        <ClinicalTrialRecommendedPackageModal
          proposalMode={proposalMode}
          onAddToCart={showToastAfterAddToCart}
          onClose={handleCloseRecommendedPackageModal}
        />
      )}
      {categoryChangeModalProps !== null && (
        <ClinicalTrialModal
          width={360}
          style={{ paddingLeft: 40, paddingRight: 40 }}
        >
          <Typography.Title
            type="secondary"
            align="center"
            medium
            color="darkNavy"
            gutter={{ top: 48, bottom: 56 }}
          >
            제품 유형 탭을 이동하시면
            <br />
            선택 항목이 초기화 됩니다.
            <br />
            이동하시겠습니까?
          </Typography.Title>
          <Button
            type="primary"
            block
            size="large"
            style={{ height: 56 }}
            onClick={() => {
              handleChangeCategory();
              setTimeout(() => {
                if (categoryChangeModalProps.onOk) {
                  categoryChangeModalProps.onOk();
                }
              }, 0);
            }}
          >
            <Typography.Title type="secondary" medium>
              탭 이동하기
            </Typography.Title>
          </Button>
          <Typography.Title
            type="secondary"
            color="darkNavy"
            align="center"
            medium
            gutter={{ top: 24 }}
            style={{ cursor: 'pointer' }}
            onClick={() => setCategoryChangeModalProps(null)}
          >
            취소
          </Typography.Title>
        </ClinicalTrialModal>
      )}
      {moveToCartWarringModalVisible && (
        <ClinicalTrialModal
          width={360}
          style={{ paddingLeft: 40, paddingRight: 40 }}
        >
          <Typography.Title
            type="secondary"
            align="center"
            medium
            color="darkNavy"
            gutter={{ top: 48, bottom: 56 }}
          >
            장바구니로 이동하시면
            <br />
            선택 항목이 초기화 됩니다.
            <br />
            이동하시겠습니까?
          </Typography.Title>
          <Button
            type="primary"
            block
            size="large"
            style={{ height: 56 }}
            onClick={() => handleMoveToCart(false)}
          >
            <Typography.Title type="secondary" medium>
              장바구니로 이동
            </Typography.Title>
          </Button>
          <Typography.Title
            type="secondary"
            color="darkNavy"
            align="center"
            medium
            gutter={{ top: 24 }}
            style={{ cursor: 'pointer' }}
            onClick={() => setMoveToCartWarringModalVisible(false)}
          >
            취소
          </Typography.Title>
        </ClinicalTrialModal>
      )}
      <HeaderContainer>
        <TabContainer>
          <Row gutter={4} style={{ width: '100%' }}>
            <Col>
              <TypeTab
                active={selectType === ESelectType.SINGLE}
                onClick={() => handleChangeSelectType(ESelectType.SINGLE)}
              >
                단일 시험
              </TypeTab>
            </Col>
            <Col flex="auto">
              <TypeTab
                active={selectType === ESelectType.PACKAGE}
                onClick={() => handleChangeSelectType(ESelectType.PACKAGE)}
              >
                패키지 시험
              </TypeTab>
            </Col>
            <Col>
              <CartButton size="large" onClick={() => handleMoveToCart(true)}>
                <CartIcon color={palette.text.primary} />
              </CartButton>
            </Col>
          </Row>
        </TabContainer>
        <CategoryContainer wrap={false} ref={categoryContainerRef}>
          {categoryDatas.map((categoryData) => (
            <Col key={categoryData.categoryDataId}>
              <CategoryTab
                active={
                  categoryData.categoryDataId ===
                  clinicalTrialStore.categoryDataId
                }
                onClick={() => handleClickCategory(categoryData.categoryDataId)}
              >
                {categoryData.categoryDataName}
              </CategoryTab>
            </Col>
          ))}
        </CategoryContainer>
      </HeaderContainer>
      {selectType === ESelectType.SINGLE ? (
        <ClinicalTrialProductSelect
          onClickRequestItem={handleClickRequestItem}
        />
      ) : (
        <ClinicalTrialPackageSelect />
      )}
      {selectCount > 0 && (
        <SelectActionContainer>
          <Row gutter={8} align="middle">
            <Col flex="auto">
              <Row align="middle" gutter={8} onClick={handleRefreshSelect}>
                <Col>
                  <RefreshIcon width={20} height={20} />
                </Col>
                <Col>
                  <Typography.Text type="secondary" color="darkNavy" medium>
                    선택 초기화
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Typography.Text color="darkNavy" medium>
                총{' '}
                <Typography.Text color="primary" inline medium>
                  {selectCount}개
                </Typography.Text>{' '}
                선택
              </Typography.Text>
            </Col>
            {selectType === ESelectType.SINGLE && (
              <Col>
                <SelectActionButton
                  size="large"
                  color={palette.darkNavy}
                  onClick={handleShowRecommendedPackageModal}
                >
                  추천 패키지 보기
                </SelectActionButton>
              </Col>
            )}
            <Col>
              <SelectActionButton
                size="large"
                color={palette.primary}
                onClick={handleAddToCart}
              >
                장바구니 담기
              </SelectActionButton>
            </Col>
          </Row>
        </SelectActionContainer>
      )}
    </ClinicalTrialSelectContainer>
  );
};

export default observer(ClinicalTrialSelect);
