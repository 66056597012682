import { PrinterOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Upload } from 'antd';
import printJS from 'print-js';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as Add } from 'assets/icon/add.svg';
import { Typography } from 'components/system';
import FileViewer from 'components/file/FileViewer';
import { IFile } from 'types/common';
import palette from 'lib/styles/palette';
import FileList from 'components/file/FileList';
import { IFileListModalProps } from 'types/modal';

const Container = styled.div<{ uploadMode: boolean }>`
  ${({ uploadMode }) =>
    !uploadMode &&
    css`
      .ant-spin-container {
        height: 556px;
      }
    `}
`;

const StyledUploadDragger = styled(Upload.Dragger)`
  border: 1px solid ${palette.inactive} !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  height: 406px !important;

  .ant-btn {
    width: 104px;
    height: 104px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0;
    color: #727272;

    .material-icons {
      margin-right: 0;
      color: ${palette.warmGray};
    }
  }

  .ant-upload p {
    padding-top: 16px;
    font-size: 10px;
    color: #7e7e7e;
  }
`;

const FileListModal = ({
  title,
  files,
  onClose,
  onUpload,
  onDelete,
  uploadMode = false,
}: IFileListModalProps) => {
  const [previewedFile, setPreviewedFile] = useState<IFile | null>(null);

  const handleClickPrint = () => {
    if (!previewedFile) {
      message.warning('선택된 파일이 없습니다.');
      return;
    }

    printJS(decodeURIComponent(previewedFile.url));
  };

  return (
    <Modal
      title={title}
      footer={null}
      width={864}
      visible={files !== null}
      onCancel={onClose}
    >
      <Container uploadMode={uploadMode}>
        <Row gutter={16}>
          <Col flex={'1 1 50%'}>
            <FileList
              header={
                <Typography.Text type="secondary" gutter={{ bottom: 8 }}>
                  파일 목록
                </Typography.Text>
              }
              upload={
                uploadMode ? (
                  <StyledUploadDragger
                    multiple
                    beforeUpload={(file) => {
                      onUpload?.(file);
                      return false;
                    }}
                    itemRender={() => null}
                  >
                    <Button>
                      <Add />
                      <br />
                      파일 선택
                    </Button>
                    <Typography.Text type="secondary" gutter={{ top: 4 }}>
                      버튼 클릭 혹은 드래그하여
                      <br />
                      파일을 첨부하세요.
                    </Typography.Text>
                  </StyledUploadDragger>
                ) : null
              }
              onFileClick={(file) => setPreviewedFile(file)}
              selectedFile={previewedFile}
              style={{ height: 556 }}
              files={files}
              onDelete={onDelete}
            />
          </Col>
          <Col flex={'1 1 50%'}>
            <FileViewer bodyStyle={{ height: 556 }} file={previewedFile?.url} />
          </Col>
        </Row>
        {!uploadMode ? (
          <Row gutter={8} style={{ marginTop: 8 }} justify="end">
            <Col>
              <Button
                icon={
                  <PrinterOutlined style={{ color: palette.text.primary }} />
                }
                style={{ height: 32 }}
                onClick={handleClickPrint}
              >
                인쇄하기
              </Button>
            </Col>
          </Row>
        ) : (
          <Row
            gutter={8}
            justify="center"
            style={{ marginTop: 44, paddingBottom: 40 }}
          >
            <Col>
              <Button onClick={onClose}>취소</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onClose}>
                확인
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </Modal>
  );
};

export default FileListModal;
