import {
  Button,
  message,
  Modal,
  Radio,
  Row,
  Table,
  Pagination,
  Col,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import styled from 'styled-components';
import { CloseCircleOutlined } from '@ant-design/icons';

import {
  EClinicalTrialEstimateRequestStatus,
  IClinicalTrialEstimateRequest,
  IClinicalTrialEstimateRequestDetail,
} from 'types/clinicalTrial/process/estimateRequest';
import { ReactComponent as Solution } from 'assets/icon/solution.svg';
import palette from 'lib/styles/palette';
import ClinicalTrialRequestDetailModal from '../ClinicalTrialRequestDetailModal';
import { Typography } from 'components/system';
import history from 'lib/history';
import path from 'lib/path';
import { useStores } from 'stores/Context';
import {
  useEstimateRequests,
  useGetEstimateRequestDetails,
  useUpdateEstimateRequestsStatus,
} from 'hooks/clinicalTrial/process/estimateRequest';

const Container = styled.div`
  position: relative;

  .ant-table-thead > tr > th {
    border: none;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-selection-column {
    padding-left: 24px;
    padding-right: 0px;
  }

  table > tbody > tr {
    background-color: #fff;
  }

  .ant-table-footer {
    background-color: #f0f2f5;
    padding: 16px 0;
  }
`;

const StatusTag = styled.div<{ isCanceled?: boolean }>`
  padding: 12px 16px;
  font-size: 14px;
  width: 100px;
  height: 44px;
  background-color: ${({ isCanceled }) =>
    isCanceled ? palette.text.gray : palette.primary};
  color: #fff;
  border-radius: 4px;
  margin: 0 auto;
`;

const filterOptions = [
  { label: '전체', value: 'ALL' },
  { label: '새 견적 요청', value: 'REQUEST' },
  { label: '취소된 요청', value: 'CANCEL' },
];

const ClinicalTrialEstimateRequestList = () => {
  const { clinicalTrialStore } = useStores();
  const [
    clinicalTrialEstimateRequestItems,
    setClinicalTrialEstimateRequestItems,
  ] = useState<IClinicalTrialEstimateRequestDetail[]>([]);
  const { getEstimateRequestDetails } = useGetEstimateRequestDetails();
  const handleClickEstimate = (clinicalTrialEstimateRequestId: number) => {
    getEstimateRequestDetails(clinicalTrialEstimateRequestId, {
      onSuccess: (res) => {
        setClinicalTrialEstimateRequestItems(res.data.result);
      },
    });
  };
  const columns: ColumnsType<IClinicalTrialEstimateRequest> = [
    {
      title: 'No.',
      align: 'left',
      render: (_, __, idx) => `${idx + 1}`,
    },
    {
      title: '견적요청일',
      align: 'center',
      dataIndex: 'estimateRequestRegisterDt',
    },
    {
      title: '아이디 (사업자등록번호)',
      align: 'center',
      dataIndex: 'bizNumber',
      render: (bizNumber) => bizNumber || '-',
    },

    {
      title: '회사명 (국문)',
      align: 'center',
      dataIndex: 'companyNameKo',
    },
    {
      title: '담당자 성함',
      align: 'center',
      dataIndex: 'clientName',
    },
    {
      title: '연락처',
      align: 'center',
      dataIndex: 'clientTel',
    },
    {
      title: '이메일',
      align: 'center',
      dataIndex: 'clientEmail',
    },
    {
      title: '견적 요청 목록',
      align: 'center',
      dataIndex: 'clinicalTrialEstimateRequestId',
      render: (clinicalTrialEstimateRequestId) => (
        <Solution
          onClick={() => handleClickEstimate(clinicalTrialEstimateRequestId)}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
          }}
        />
      ),
    },
    {
      title: '견적서 작성',
      align: 'center',
      dataIndex: 'status',
      render: (_, { status, clinicalTrialEstimateRequestId, companyNameKo }) =>
        status !== EClinicalTrialEstimateRequestStatus.CANCEL ? (
          <StatusTag
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push(path.clinicalTrial.estimate.select);
              clinicalTrialStore.clinicalTrialEstimateRequest = {
                clinicalTrialEstimateRequestId,
                companyNameKo,
              };
            }}
          >
            견적서 작성
          </StatusTag>
        ) : (
          <StatusTag isCanceled>취소 처리됨</StatusTag>
        ),
    },
  ];

  const [page, setPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [filterOption, setFilterOption] = useState('ALL');
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const { estimateRequests, totalElements } = useEstimateRequests({
    page,
    ...(filterOption !== 'ALL' && { status: filterOption }),
  });
  const {
    updateEstimateRequestsStatus,
    isLoading: isCancelLoading,
  } = useUpdateEstimateRequestsStatus();
  const handleCancelEstimateRequests = () => {
    if (selectedIds.length === 0) {
      return message.warn('선택된 견적요청이 없습니다.');
    }
    setIsOpenCancelModal(true);
  };
  const cancelEstimateRequests = () => {
    updateEstimateRequestsStatus(
      selectedIds.map((id) => ({
        clinicalTrialEstimateRequestId: id,
        status: EClinicalTrialEstimateRequestStatus.CANCEL,
      })),
      {
        onSuccess: () => {
          message.success('취소되었습니다.');
          setIsOpenCancelModal(false);
          setSelectedIds([]);
        },
      },
    );
  };
  const handleChangeFilter = (option: string) => {
    setFilterOption(option);
    setPage(1);
    setSelectedIds([]);
  };

  return (
    <Container>
      <ClinicalTrialRequestDetailModal
        clinicalTrialEstimateRequestItems={clinicalTrialEstimateRequestItems}
        visible={clinicalTrialEstimateRequestItems.length > 0}
        onClose={() => setClinicalTrialEstimateRequestItems([])}
      />
      <Modal
        title="취소 처리"
        width={360}
        okText="취소 처리"
        cancelText="돌아가기"
        visible={isOpenCancelModal}
        onOk={cancelEstimateRequests}
        onCancel={() => setIsOpenCancelModal(false)}
      >
        <Typography.Text type="tertiary" align="center">
          견적 요청을 정말 취소 처리 하시겠습니까?
          <br />이 작업은 되돌릴 수 없습니다.
        </Typography.Text>
      </Modal>
      <Radio.Group
        options={filterOptions}
        value={filterOption}
        onChange={(e) => handleChangeFilter(e.target.value)}
        style={{ display: 'flex', justifyContent: 'end', marginBottom: 12 }}
        optionType="button"
        buttonStyle="solid"
      />
      <Table
        columns={columns}
        dataSource={estimateRequests}
        rowKey="clinicalTrialEstimateRequestId"
        rowSelection={{
          selectedRowKeys: selectedIds,
          onChange: (keys) => setSelectedIds(keys as number[]),
          getCheckboxProps: (record) => ({
            disabled:
              record.status === EClinicalTrialEstimateRequestStatus.CANCEL,
          }),
        }}
        pagination={false}
        footer={() => (
          <Row>
            <Col style={{ position: 'absolute' }}>
              <Button
                icon={<CloseCircleOutlined />}
                onClick={handleCancelEstimateRequests}
                loading={isCancelLoading}
              >
                취소처리
              </Button>
            </Col>
            <Col style={{ margin: '0 auto' }}>
              <Pagination
                current={page}
                total={totalElements}
                onChange={setPage}
              />
            </Col>
          </Row>
        )}
      />
    </Container>
  );
};

export default ClinicalTrialEstimateRequestList;
