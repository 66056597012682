import { Card, Col, Row } from 'antd';
import { Typography } from 'components/system';
import styled from 'styled-components';
import { useMainStat } from 'hooks/stats';
import palette from 'lib/styles/palette';

interface IProps {
  amount: number;
  opacity: number;
  label: string;
}

const TotalDataStatusBlock = styled(Card)`
  height: 100%;
  .ant-card-body {
    padding: 32px 40px;
  }
`;

const StatBlock = styled.div<Pick<IProps, 'opacity'>>`
  width: 128px;
  cursor: pointer;

  .stat {
    font-size: 56px;
    line-height: 0.8;
    letter-spacing: -1.65px;
    text-align: center;
    font-weight: 500;
    color: ${palette.primary};
  }

  .label-wrap {
    margin-top: 10px;
    text-align: center;

    .label {
      letter-spacing: -0.5px;
    }
  }
`;

const Stat = ({ amount, opacity, label }: IProps) => {
  return (
    <StatBlock opacity={opacity}>
      <div className="stat">{amount}</div>
      <div className="label-wrap">
        <span>{label}</span>
      </div>
    </StatBlock>
  );
};

const TotalDataStatusCard = () => {
  const {
    prdRegCount,
    cerFinCount,
    brandCompanyCount,
    manufacturerCount,
  } = useMainStat();
  return (
    <TotalDataStatusBlock>
      <Typography.Headline type="secondary">총 누적 데이터</Typography.Headline>
      <Row gutter={8} style={{ marginTop: 36 }} justify="space-around">
        <Col>
          <Stat amount={prdRegCount} opacity={0.25} label="제품 등록" />
        </Col>
        <Col>
          <Stat amount={cerFinCount} opacity={0.5} label="인증 완료" />
        </Col>
        <Col>
          <Stat
            amount={brandCompanyCount}
            opacity={0.75}
            label="브랜드사 가입"
          />
        </Col>
        <Col>
          <Stat amount={manufacturerCount} opacity={1} label="제조사 가입" />
        </Col>
      </Row>
    </TotalDataStatusBlock>
  );
};

export default TotalDataStatusCard;
