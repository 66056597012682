import qs from 'qs';

import { IAPIResponse } from 'types/common';
import { INewAlarmExists, INotification } from 'types/notification';
import client from 'lib/api/client';

export const getNewUserExist = () =>
  client.get<IAPIResponse<INewAlarmExists>>('/admin/alarm/new-user');

export const getNewEstimateExist = () =>
  client.get<IAPIResponse<INewAlarmExists>>('/admin/alarm/new-estimate');

export const readNewEstimate = () =>
  client.post<IAPIResponse<INewAlarmExists>>('/admin/alarm/read/new-estimate');

export const getNotifications = (userId: number) =>
  client.get<IAPIResponse<INotification[]>>(`/user-notices/${userId}`);

export const readNotification = (userNoticeId: number) =>
  client.patch(
    '/user-notices/confirm',
    qs.stringify({ userNoticeIds: [userNoticeId] }, { indices: false }),
  );

export const readAllNotification = (userId: number) =>
  client.patch(`/user-notices/confirm/all`, qs.stringify({ userId }));
