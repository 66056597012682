import * as React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

interface IPrivateRouteProps {
  path: string;
  component: React.ComponentType<RouteComponentProps>;
  check: boolean;
  redirect: string;
  exact?: boolean;
}

function PrivateRoute({
  path,
  component: Component,
  check,
  redirect,
  ...rest
}: IPrivateRouteProps) {
  return (
    <Route
      path={path}
      render={(props) =>
        check ? <Component {...props} /> : <Redirect to={redirect} />
      }
      {...rest}
    />
  );
}

export default PrivateRoute;
