import client from 'lib/api/client';
import { IAPIPageableResponse, IAPIResponse } from 'types/common';
import {
  EEstimateAdditionalStatus,
  IEstimate,
  IEstimateAdditionalForm,
  IEstimateCountry,
  IEstimateDepositHistory,
  IEstimateHistory,
  IEstimateDeposit,
} from 'types/estimate';

export const getEstimates = async ({
  page,
  isDepositFinish,
  isReceiveContractFinish,
  companyNameKo,
  companyNameEn,
  estimateCode,
}: {
  page: number;
  isDepositFinish: string;
  isReceiveContractFinish: string;
  companyNameKo?: string;
  companyNameEn?: string;
  estimateCode?: string;
}) => {
  const res = await client.post<IAPIPageableResponse<IEstimate[]>>(
    `/admin/estimates/${page}/10`,
    {
      ...(isDepositFinish !== 'all' && {
        isDepositFinish: isDepositFinish === 'true',
      }),
      ...(isReceiveContractFinish !== 'all' && {
        isReceiveContractFinish: isReceiveContractFinish === 'true',
      }),
      ...(companyNameKo && { companyNameKo }),
      ...(companyNameEn && { companyNameEn }),
      ...(estimateCode && { estimateCode }),
    },
  );
  return res.data;
};

export const checkEstimateDeposit = ({
  estimateId,
  ...depositInfo
}: IEstimateDeposit) =>
  client.patch(`/admin/estimates/${estimateId}/deposit`, depositInfo);

export const receiptEstimateContract = (estimateId: number) =>
  client.patch(`/admin/estimates/${estimateId}/contract`);

export const changeEstimateAdditionalStatus = async ({
  estimateAdditionalId,
  status,
}: {
  estimateAdditionalId: number;
  status: EEstimateAdditionalStatus;
}) => {
  const res = await client.patch(
    `/admin/estimate-additional/status/${estimateAdditionalId}/${status}`,
  );
  return res.data;
};

export const addEstimate = async ({
  certTargetId,
  estimateAdditionals,
  isPreview,
}: {
  certTargetId: number;
  estimateAdditionals: IEstimateAdditionalForm[];
  isPreview: boolean;
}) => {
  const res = await client.post<IAPIResponse<string>>(
    `/admin/estimate-additional/register/${certTargetId}/${isPreview}`,
    estimateAdditionals,
  );
  return res.data;
};

export const getEstimateCountries = async (estimateId: number) => {
  const res = await client.get<IAPIResponse<IEstimateCountry[]>>(
    `/admin/estimate/items/${estimateId}`,
  );
  return res.data;
};

export const updateEstimateCountries = async ({
  isPreview,
  estimateCountries,
}: {
  isPreview: boolean;
  estimateCountries: IEstimateCountry[];
}) => {
  const res = await client.post(
    `/admin/estimate/items/${isPreview}`,
    estimateCountries.map(({ estimateCountryId, estimateCountryItems }) => ({
      estimateCountryId,
      estimateCountryItems: estimateCountryItems.map(
        ({ itemName, unitPrice, quantity, description }) => ({
          itemName,
          unitPrice,
          quantity,
          description,
        }),
      ),
    })),
  );
  return res.data;
};

export const getEstimateHistories = async (estimateId: number) => {
  const res = await client.get<IAPIResponse<IEstimateHistory[]>>(
    `/admin/estimate/history/${estimateId}`,
  );
  return res.data;
};

export const getEstimateDepositHistories = (estimateId: number) =>
  client.get<IAPIResponse<IEstimateDepositHistory[]>>(
    `/admin/estimates/${estimateId}/deposit-histories`,
  );
