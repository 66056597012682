import { Button, DatePicker, Drawer, Radio } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import CloseIcon from 'components/CloseIcon';

const FilterDrawerHeader = styled.div`
  position: relative;
  padding: 10px 0;

  .ant-btn {
    position: absolute;
    top: 9px;
    left: 16px;
    color: ${palette.text.disabled};
    font-size: 12px;
    font-weight: 500;
  }

  .close-icon {
    position: absolute;
    top: 9px;
    right: 16px;
  }
`;

const FilterDrawerBody = styled.div`
  height: calc(100vh - 48px - 48px - 44px);
  padding: 36px 16px 26px;
  background-color: ${palette.gray5};

  .ant-radio-group {
    width: 100%;
    display: flex;

    .ant-radio-button-wrapper {
      flex: 0 0 50%;
      border-radius: 0;
      box-shadow: none;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: ${palette.text.disabled};

      &.ant-radio-button-wrapper-checked {
        color: ${palette.text.primary};
      }
    }
  }

  .ant-picker-range {
    border-radius: 0;
    height: 40px;
    box-shadow: none;

    .ant-picker-input {
      input {
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .ant-picker-dropdown {
    left: -40px !important;
  }
  .ant-picker-panel {
    &:nth-child(2) {
      display: none;
    }
  }
`;

const RefreshButton = styled(Button)`
  &:focus {
    border: 1px solid #d9d9d9 !important;
    border: none;
    box-shadow: none;

    &:after {
      display: none;
    }
  }
`;

const FilterDrawer = ({
  visible,
  isConfirmed,
  onChangeIsConfirmed,
  publishDuration,
  onChangePublishingDuration,
  onClose,
  onInitialize,
}: {
  visible: boolean;
  isConfirmed: boolean | null;
  onChangeIsConfirmed: (isConfirmed: boolean) => void;
  publishDuration: { from: string; to: string } | null;
  onChangePublishingDuration: (publishDuration: {
    from: string;
    to: string;
  }) => void;
  onClose: () => void;
  onInitialize: () => void;
}) => {
  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      style={{ top: 48, zIndex: 10 }}
      bodyStyle={{ padding: 0 }}
      maskStyle={{ backgroundColor: 'transparent' }}
      contentWrapperStyle={{ overflow: 'visible' }}
      width={260}
      closable={false}
    >
      <FilterDrawerHeader>
        <Typography.Text align="center" color="darkNavy" medium>
          필터
        </Typography.Text>
        <RefreshButton size="small" onClick={onInitialize}>
          초기화
        </RefreshButton>
        <CloseIcon color={palette.text.darkNavy} onClick={onClose} />
      </FilterDrawerHeader>
      <FilterDrawerBody>
        <Typography.Text
          type="quaternary"
          medium
          color="darkNavy"
          gutter={{ bottom: 4 }}
        >
          확정 여부
        </Typography.Text>
        <Radio.Group
          size="large"
          value={isConfirmed}
          onChange={(e) => onChangeIsConfirmed(e.target.value)}
        >
          <Radio.Button value={true}>확정</Radio.Button>
          <Radio.Button value={false}>미확정</Radio.Button>
        </Radio.Group>
        <Typography.Text
          type="quaternary"
          medium
          color="darkNavy"
          gutter={{ top: 24, bottom: 4 }}
        >
          기간
        </Typography.Text>
        <DatePicker.RangePicker
          placeholder={['선택', '선택']}
          value={
            publishDuration
              ? [moment(publishDuration.from), moment(publishDuration.to)]
              : null
          }
          disabledDate={(date) => {
            return date && date > moment();
          }}
          style={{
            borderColor: publishDuration !== null ? palette.primary : undefined,
          }}
          onChange={(duration) => {
            if (duration && duration[0] && duration[1]) {
              onChangePublishingDuration({
                from: duration[0].format('YYYY-MM-DD'),
                to: duration[1].format('YYYY-MM-DD'),
              });
            }
          }}
        />
      </FilterDrawerBody>
    </Drawer>
  );
};

export default FilterDrawer;
