import { IUser } from './auth';

export enum ECompanyStatus {
  RDY = 'RDY', // READY
  CFM = 'CFM', // CONFIRM
  REJ = 'REJ', // REJECT
  WIH = 'WIH', // WITHDRAW
  STP = 'STP', // STP
}

export enum ECompanyType {
  ALL = 'ALL',
  BRAND = 'BRAND',
  MANUFACTURE = 'MANUFACTURE',
  MATERIAL = 'MATERIAL',
}

export interface ICompanyPageState {
  page?: number;
  searchType?: ECompanySearchType;
  searchValue?: string;
}
export interface ICompanyListPageState extends ICompanyPageState {
  companyType?: ECompanyType;
}

export interface ICompanyBasic {
  companyId: number;
  companyNameKo: string;
  companyNameEn: string;
  isManufacturer: boolean;
}
export interface ICompany extends ICompanyBasic {
  addressKo?: string;
  addressEn?: string;
  ceoNameKo: string;
  ceoNameEn: string;
  tel: string;
  fax: string;
  homepageUrl: string;
  bizNumber: string;
  bizLicenseUrl: string;
  bizLicenseEnUrl: string;
  mainItem?: string;
  introduction?: string;
  status: ECompanyStatus;
  companyType: ECompanyType;
  isCompanyNameUserInserted: boolean;
  isUseOfLogoAgreed: boolean;
  users: IUser[];
}
export interface ICompanyUpdate {
  companyId: number;
  bizLicenseFile?: File;
  bizLicenseEnFile?: File;
}
export interface ICompanyStatus {
  companyId: number;
  status: ECompanyStatus;
  rejectReason?: string;
}

export enum EBuyerStatus {
  NOR = 'NOR',
  DEL = 'DEL',
}
export interface IBuyer {
  buyerId: number;
  brandCompanyName: string;
  brandCompany: ICompany;
  companyName: string;
  companyTel: string;
  companyAddress: string;
  ceoName: string;
  representativeName: string;
  companyEmail: string;
  countryId: number;
  status: EBuyerStatus;
}

export interface IProductBuyer {
  productBuyerId: number;
  buyer: IBuyer;
}

export enum ECompanySearchType {
  COMPANY_NAME_KO = 'companyNameKo',
  COMPANY_NAME_EN = 'companyNameEn',
  BIZ_NUMBER = 'bizNumber',
  CEO_NAME = 'ceoNameKo',
}

export interface IFactory {
  companyFactoryId?: number;
  materialFactoryId?: number;
  addressKo: string;
  addressEn: string;
}
