import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';

import palette from 'lib/styles/palette';

export const CircleCheckIcon = styled(CheckOutlined)<{ checked: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 24px;
  font-size: 12px;
  background-color: ${({ checked }) =>
    checked ? palette.primary : palette.disabled};
  color: #fff;
  cursor: pointer;
`;

export const RecommendLabel = styled.div`
  background-color: #00cca1;
  color: #fff;
  width: 32px;
  height: 16px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 18px;
  border-radius: 4px;
`;
