export interface IClinicalTrial {
  clinicalTrialId: number;
  clinicalTrialName: string;
  type: EClinicalTrialType;
  methods: IClinicalTrialMethod[];
  clinicalTrialProducts: IClinicalTrialProduct[];
}

export interface ICategoryData {
  categoryDataId: number;
  categoryDataName: string;
  clinicalTrialIds: number[];
  clinicalTrialPackageIds: number[];
}

export enum EClinicalTrialType {
  FUNCTIONAL = 'FUNCTIONAL',
  STABILITY = 'STABILITY',
  VALIDITY = 'VALIDITY',
  IN_VITRO = 'IN_VITRO',
}

export enum EClinicalTrialProductOptionType {
  COMMON = 'COMMON',
}

export interface IClinicalTrialProductOption {
  clinicalTrialProductOptionId: number;
  optionType: EClinicalTrialProductOptionType;
  productOptionName: string;
}

export interface IClinicalTrialProduct {
  clinicalTrialProductId: number;
  clinicalTrialName: string;
  agency: IClinicalTrialAgency;
  method: IClinicalTrialMethod;
  price: number;
  type: EClinicalTrialType;
  duration: number;
  remark: string | null;
  options: IClinicalTrialProductOption[];
  discountInfo: string | null;
}

export interface IClinicalTrialAgency {
  clinicalTrialAgencyId: number;
  agencyName: string;
  countryName: string;
}

export interface IClinicalTrialMethod {
  clinicalTrialMethodId: number;
  methodName: string;
  description: string | null;
  imageUrl: string | null;
}

export interface IClinicalTrialPackage {
  clinicalTrialPackageId: number;
  packageName: string;
  agency: IClinicalTrialAgency;
  clinicalTrialPackageProducts: IClinicalTrialProduct[][];
  price: number;
  remark: string | null;
}

export interface IClinicalTrialCartItemOption {
  clinicalTrialProductOptionId: number;
  clinicalTrialProductId: number;
  clinicalTrialName: string;
  clinicalTrialPackageName?: string;
  productOptionName: string;
  itemQuantity: number;
}

export interface IClinicalTrialCartItem {
  clinicalTrialCartItemId: string;
  isSelected: boolean;
  item: IClinicalTrialPackage | IClinicalTrialProduct;
  itemQuantity: number;
  categoryDataId: number;
  options: IClinicalTrialCartItemOption[];
}

export enum EOrderType {
  RECOMMEND = 'RECOMMEND',
  LOWER_PRICE = 'LOWER_PRICE',
  SHORT_DURATION = 'SHORT_DURATION',
  AGENCY_NAME = 'AGENCY_NAME',
  COUNTRY_NAME = 'COUNTRY_NAME',
}

export interface IClinicalTrialPackageSelection {
  clinicalTrialPackage: IClinicalTrialPackage;
  clinicalTrialProducts: IClinicalTrialProduct[];
}

export interface IClinicalTrialSelectToast {
  id: string;
  message: string;
  buttonProps?: { text: string; onClick: () => void };
}

export interface IClinicalTrialOptionItem {
  clinicalTrialProductOptionId: number;
  itemUnitPrice?: number;
  itemQuantity: number;
  remark?: string | null;
}

export interface IClinicalTrialEstimatePackageItem {
  clinicalTrialPackageId: number;
  itemQuantity: number;
  remark?: string | null;
  discountRemark?: string | null;
  clinicalTrialProducts: {
    clinicalTrialProductId: number;
    optionItems: IClinicalTrialOptionItem[];
  }[];
}

export interface IClinicalTrialEstimateProductItem {
  clinicalTrialProductId: number;
  itemQuantity: number;
  optionItems: IClinicalTrialOptionItem[];
  remark?: string | null;
}

export interface IClinicalTrialEstimateItemOfCategory {
  categoryDataId: number;
  packageItems: IClinicalTrialEstimatePackageItem[];
  productItems: IClinicalTrialEstimateProductItem[];
}

export interface IClinicalTrialEstimateAdd {
  clientCompanyName: string;
  clientName: string;
  itemsOfCategory: IClinicalTrialEstimateItemOfCategory[];
  clinicalTrialEstimateRequestId?: number;
}

export enum EClinicalTrialEstimateSearchType {
  ALL = 'ALL',
  ESTIMATE_NO = 'ESTIMATE_NO',
  CLIENT_COMPANY_NAME = 'CLIENT_COMPANY_NAME',
  CLIENT_NAME = 'CLIENT_NAME',
  ADMIN_USER_NAME = 'ADMIN_USER_NAME',
}

export enum EClinicalTrialEstimateStatus {
  RDY = 'RDY',
  CFM = 'CFM',
  STP = 'STP',
  COM = 'COM',
}

export interface IClinicalTrialEstimateSearchParams {
  page: number;
  searchType: EClinicalTrialEstimateSearchType;
  searchKeyword: string;
  isConfirmed: boolean | null;
  publishDuration: {
    from: string;
    to: string;
  } | null;
}
export interface IClinicalTrialEstimateBasic {
  clinicalTrialEstimateId: number;
  estimateNo: string;
  clientCompanyName: string;
  clientName: string;
  clientTel: string | null;
  clientEmail: string | null;
  clinicalTrialCount: number;
  totalPrice: number;
  adminUserName: string;
  publishDt: string;
  estimateUrl: string;
  status: EClinicalTrialEstimateStatus;
  userId: number | null;
}

export interface IClinicalTrialEstimateClientInfo {
  clientCompanyName: string;
  clientName: string;
  clientCompanyAddress: string;
  clientEmail: string;
  clientTel: string;
  remark: string;
}

export interface IClinicalTrialEstimate {
  clinicalTrialEstimateId: number;
  clientCompanyName: string;
  estimateNo: string;
  publishDt: string;
  appendix: string | null;
  totalPrice: number;
  estimateItems: IClinicalTrialEstimateItem[];
}

export enum EClinicalTrialEstimateItemType {
  PACKAGE = 'PACKAGE',
  PRODUCT = 'PRODUCT',
  ETC = 'ETC',
  DISCOUNT = 'DISCOUNT',
}

export interface IClinicalTrialEstimateItem {
  clinicalTrialEstimateItemId: number | string;
  categoryDataId: number;
  clinicalTrialPackageId?: number;
  clinicalTrialProductId?: number;
  itemName: string;
  agencyName: string;
  itemUnitPrice: number;
  itemQuantity: number;
  remark: string | null;
  estimateItemOptions: IClinicalTrialEstimateItemOption[];
  itemType: EClinicalTrialEstimateItemType;
}

export interface IClinicalTrialEstimateItemOption {
  clinicalTrialEstimateItemOptionId: number | string;
  clinicalTrialProductOptionId: number;
  clinicalTrialProductId?: number;
  itemName: string;
  agencyName: string;
  itemQuantity: number;
  itemUnitPrice: number;
  remark: string;
}

export interface IClinicalTrialEstimateUpdate {
  clinicalTrialEstimateId: number;
  clientCompanyName?: string;
  appendix?: string;
  updateEstimateItems: IClinicalTrialEstimateItemUpdate[];
  updateEstimateItemOptions: IClinicalTrialEstimateItemOptionUpdate[];
  deletedEstimateItemIds: number[];
  itemsOfCategory: IClinicalTrialEstimateItemOfCategory[];
  addEstimateItemEtcs: IClinicalTrialEtcEstimateItem[];
}

export interface IClinicalTrialEstimateItemUpdate {
  clinicalTrialEstimateItemId: number;
  itemName?: string;
  agencyName?: string;
  itemUnitPrice?: number;
  itemQuantity?: number;
  remark?: string;
}

export interface IClinicalTrialEstimateItemOptionUpdate {
  clinicalTrialEstimateItemOptionId: number;
  itemQuantity?: number;
  remark?: string;
}

export interface IClinicalTrialEtcEstimateItem {
  categoryDataId: number;
  itemName: string;
  agencyName: string;
  itemUnitPrice: number;
  itemQuantity: number;
  remark: string | null;
}
