import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Card, Col, Row, Statistic, Tabs } from 'antd';

import { useCertificate } from 'hooks/certificate';
import PageTemplate from 'templates/PageTemplate';
import CertificateInfoList from 'components/certificate/CertificateInfoList';
import CertificateDocumentList from 'components/certificate/CertificateDocumentList';
import ChecklistAnswers from 'components/checklist/ChecklistAnswers';

const CertificateDetailPage = () => {
  const location = useLocation();
  const { certTargetId } = useParams<{ certTargetId: string }>();
  const { certificate, handleRefresh } = useCertificate(Number(certTargetId));
  useEffect(() => {
    handleRefresh();
  }, [location]);
  if (!certificate) {
    return null;
  }
  const { certRequestNo, estimateTarget, estimate } = certificate;
  const {
    productDetails,
    countryName,
    netWeight,
    netWeightUnit,
  } = estimateTarget;

  return (
    <PageTemplate title="진행중인 제품 관리" back>
      <Card>
        <Row wrap={false}>
          <Col span={5} style={{ textAlign: 'center' }}>
            <Statistic title="인증 신청 번호" value={certRequestNo} />
          </Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            <Statistic title="회사명" value={estimate.company.companyNameKo} />
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Statistic
              title="제품명 (용량)"
              value={`${productDetails[0].productNameEn} ${
                netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
              }`}
            />
          </Col>
          <Col span={5} style={{ textAlign: 'center' }}>
            <Statistic title="국가" value={countryName} />
          </Col>
        </Row>
      </Card>
      <Tabs style={{ marginTop: 16 }} destroyInactiveTabPane>
        <Tabs.TabPane key="info" tab="입력 정보">
          <CertificateInfoList certificate={certificate} />
        </Tabs.TabPane>
        <Tabs.TabPane key="document" tab="서류 정보">
          <CertificateDocumentList certificate={certificate} />
        </Tabs.TabPane>
        <Tabs.TabPane key="checklist" tab="체크리스트">
          <ChecklistAnswers />
        </Tabs.TabPane>
      </Tabs>
    </PageTemplate>
  );
};

export default observer(CertificateDetailPage);
