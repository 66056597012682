import { Button, Col, DatePicker, Input, message, Row, Select } from 'antd';
import styled from 'styled-components';
import { Typography } from 'components/system';
import { useState } from 'react';
import useUpdateEffect from 'hooks/useUpdateEffect';
import moment from 'moment';
import { EPeriodRangeType, IPeriod } from 'types/stats';
import { DATE_FORMAT } from 'lib/consts';

interface IProps {
  label?: string;
  style?: React.CSSProperties;
  onSearch: (periodRangeType: EPeriodRangeType, { from, to }: IPeriod) => void;
}

const PeriodRangeSelectBlock = styled(Row)``;

const periodRangeTypeOptions: { label: string; value: EPeriodRangeType }[] = [
  { label: '전체 기간', value: EPeriodRangeType.ALL },
  { label: '월 선택', value: EPeriodRangeType.MONTH },
  { label: '일 선택', value: EPeriodRangeType.DATE },
];

const PeriodRangeSelect = ({ label = '기간별', style, onSearch }: IProps) => {
  const [periodRangeType, setPeriodRangeType] = useState(
    periodRangeTypeOptions[0].value,
  );
  const [periodFrom, setPeriodFrom] = useState(moment());
  const [periodTo, setPeriodTo] = useState(moment());
  const handleClickSearch = () => {
    switch (periodRangeType) {
      case EPeriodRangeType.ALL: {
        return onSearch(periodRangeType, {
          from: '2021-07-31',
          to: moment().format(DATE_FORMAT),
        });
      }
      default: {
        return onSearch(periodRangeType, {
          from: periodFrom.startOf(periodRangeType).format(DATE_FORMAT),
          to: periodTo.endOf(periodRangeType).format(DATE_FORMAT),
        });
      }
    }
  };

  useUpdateEffect(() => {
    setPeriodFrom(moment());
    setPeriodTo(moment());
  }, [periodRangeType]);
  return (
    <PeriodRangeSelectBlock
      align="middle"
      justify="center"
      gutter={8}
      style={style}
    >
      <Col>
        <Typography.Text type="secondary" bold>
          {label}
        </Typography.Text>
      </Col>
      <Col>
        <Select
          options={periodRangeTypeOptions}
          value={periodRangeType}
          style={{ width: 140 }}
          onChange={setPeriodRangeType}
        />
      </Col>
      <Col>
        <Row gutter={4} align="middle">
          <Col>
            {periodRangeType === EPeriodRangeType.ALL ? (
              <Input
                value="전체"
                disabled
                style={{ width: 140, color: '#888' }}
              />
            ) : (
              <DatePicker
                picker={periodRangeType}
                value={periodFrom}
                style={{ width: 140 }}
                onChange={(date) => {
                  if (!date) return;
                  if (date.isBefore('2021-07-31'))
                    return message.warn('2021년 7월 31일부터 조회 가능합니다.');
                  setPeriodFrom(date);
                }}
              />
            )}
          </Col>
          <Col>
            <Typography.Text type="secondary" bold>
              부터
            </Typography.Text>
          </Col>
          <Col>
            {periodRangeType === EPeriodRangeType.ALL ? (
              <Input
                value="전체"
                disabled
                style={{ width: 140, color: '#888' }}
              />
            ) : (
              <DatePicker
                picker={periodRangeType}
                value={periodTo}
                style={{ width: 140 }}
                onChange={(date) => {
                  if (!date) return;
                  if (date.isBefore('2021-07-31'))
                    return message.warn('2021년 7월 31일부터 조회 가능합니다.');
                  setPeriodTo(date);
                }}
              />
            )}
          </Col>
          <Col>
            <Typography.Text type="secondary" bold>
              까지
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col style={{ paddingLeft: 8 }}>
        <Button type="primary" onClick={handleClickSearch}>
          조회
        </Button>
      </Col>
    </PeriodRangeSelectBlock>
  );
};

export default PeriodRangeSelect;
