import { Table, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { nanoid } from 'nanoid';
import { useQuery } from 'react-query';

import * as estimateApi from 'lib/api/estimate';
import { IEstimateDepositHistory } from 'types/estimate';

interface IProps {
  estimateId: number;
  onClose: () => void;
}

const EstimateAdditionalAddModal = ({ estimateId, onClose }: IProps) => {
  const { data: estimateDepositHistories = [] } = useQuery(
    ['estimate/getDepositHistories', estimateId],
    () => estimateApi.getEstimateDepositHistories(estimateId),
    { select: (res) => res.data.result },
  );
  const columns: ColumnsType<IEstimateDepositHistory> = [
    {
      title: '입금 확인 금액',
      dataIndex: 'paidAmount',
      align: 'center',
      render: (paidAmount: number) => `${paidAmount.toLocaleString()} 원`,
    },
    {
      title: '실 입금 날짜',
      dataIndex: 'depositDate',
      align: 'center',
    },
    {
      title: '관리자 ID',
      dataIndex: 'adminUsername',
      align: 'center',
    },
    {
      title: '일시',
      dataIndex: 'registerDt',
      align: 'center',
      render: (registerDt) => registerDt.replace('T', ' '),
    },
  ];
  return (
    <Modal
      title="입금 확인 이력"
      width={800}
      visible
      footer={null}
      onCancel={onClose}
    >
      <Table
        columns={columns}
        dataSource={estimateDepositHistories}
        rowKey={() => nanoid()}
      />
    </Modal>
  );
};

export default EstimateAdditionalAddModal;
