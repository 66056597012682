import { makeAutoObservable, toJS } from 'mobx';
import { nanoid } from 'nanoid';

import RootStore from 'stores/RootStore';
import {
  IClinicalTrialCartItem,
  IClinicalTrialProduct,
  IClinicalTrialPackage,
  IClinicalTrialPackageSelection,
  IClinicalTrialSelectToast,
  IClinicalTrialCartItemOption,
} from 'types/clinicalTrial';

export default class ClinicalTrialStore {
  rootStore;
  private _categoryDataId: number;
  private _clinicalTrialEstimateRequest: {
    clinicalTrialEstimateRequestId: number;
    companyNameKo: string;
  } | null;
  private _clinicalTrailSelectToasts: IClinicalTrialSelectToast[];
  private _selectedClinicalTrialProducts: IClinicalTrialProduct[];
  private _clinicalTrialPackageSelections: IClinicalTrialPackageSelection[];
  private _clinicalTrialCartItems: IClinicalTrialCartItem[];

  constructor(root: RootStore) {
    this.rootStore = root;
    this._categoryDataId = 11; // HINT : 서버에서 내려오는 첫 번째 카테고리의 id
    this._clinicalTrialEstimateRequest = null;
    this._clinicalTrailSelectToasts = [];
    this._selectedClinicalTrialProducts = [];
    this._clinicalTrialPackageSelections = [];
    this._clinicalTrialCartItems = [];

    makeAutoObservable(this);
  }

  get categoryDataId() {
    return this._categoryDataId;
  }

  set categoryDataId(categoryDataId: number) {
    this._selectedClinicalTrialProducts = [];
    this._clinicalTrialPackageSelections = [];
    this._categoryDataId = categoryDataId;
  }

  get clinicalTrialEstimateRequest() {
    return this._clinicalTrialEstimateRequest;
  }

  set clinicalTrialEstimateRequest(
    clinicalTrialEstimateRequest: {
      clinicalTrialEstimateRequestId: number;
      companyNameKo: string;
    } | null,
  ) {
    this._clinicalTrialEstimateRequest = clinicalTrialEstimateRequest;
  }

  get selectedClinicalTrialProducts() {
    return toJS(this._selectedClinicalTrialProducts);
  }

  selectClinicalTrialProduct(product: IClinicalTrialProduct) {
    this._selectedClinicalTrialProducts.push(product);
  }

  deselectClinicalTrialProduct(product: IClinicalTrialProduct) {
    this._selectedClinicalTrialProducts = this._selectedClinicalTrialProducts.filter(
      ({ clinicalTrialProductId }) =>
        clinicalTrialProductId !== product.clinicalTrialProductId,
    );
  }

  deselectClinicalTrialProducts(products: IClinicalTrialProduct[]) {
    this._selectedClinicalTrialProducts = this._selectedClinicalTrialProducts.filter(
      ({ clinicalTrialProductId }) =>
        products.every(
          (product) =>
            product.clinicalTrialProductId !== clinicalTrialProductId,
        ),
    );
  }

  emptyClinicalTrialProducts() {
    this._selectedClinicalTrialProducts = [];
  }

  get clinicalTrialPackageSelections() {
    return this._clinicalTrialPackageSelections;
  }

  getIsSelectedClinicalTrialPackage(clinicalTrialPackageId: number) {
    return this._clinicalTrialPackageSelections.some(
      ({ clinicalTrialPackage }) =>
        clinicalTrialPackage.clinicalTrialPackageId === clinicalTrialPackageId,
    );
  }

  selectClinicalTrialPackage(
    clinicalTrialPackageSelection: IClinicalTrialPackageSelection,
  ) {
    this._clinicalTrialPackageSelections.push(clinicalTrialPackageSelection);
  }

  deselectClinicalTrialPackage(clinicalTrialPackage: IClinicalTrialPackage) {
    this._clinicalTrialPackageSelections = this._clinicalTrialPackageSelections.filter(
      (clinicalTrialPackageSelection) =>
        clinicalTrialPackageSelection.clinicalTrialPackage
          .clinicalTrialPackageId !==
        clinicalTrialPackage.clinicalTrialPackageId,
    );
  }

  changeClinicalTrialProduct({
    clinicalTrialPackageId,
    clinicalTrialPackageItemIndex,
    clinicalTrialProduct,
  }: {
    clinicalTrialPackageId: number;
    clinicalTrialPackageItemIndex: number;
    clinicalTrialProduct: IClinicalTrialProduct;
  }) {
    const targetSelection = this._clinicalTrialPackageSelections.find(
      (clinicalTrialPackageSelection) =>
        clinicalTrialPackageSelection.clinicalTrialPackage
          .clinicalTrialPackageId === clinicalTrialPackageId,
    );
    if (!targetSelection) {
      throw new Error('Invalid clinicalTrialPackageId');
    }
    targetSelection.clinicalTrialProducts[
      clinicalTrialPackageItemIndex
    ] = clinicalTrialProduct;
  }

  emptyClinicalTrialPackageSelections() {
    this._clinicalTrialPackageSelections = [];
  }

  get clinicalTrialCartItems() {
    return this._clinicalTrialCartItems;
  }

  get selectedCartItems() {
    return this._clinicalTrialCartItems.filter(({ isSelected }) => isSelected);
  }

  emptyClinicalTrialCartItems() {
    this._clinicalTrialCartItems = [];
  }

  addClinicalTrialCartItems(
    items: (IClinicalTrialProduct | IClinicalTrialPackageSelection)[],
  ) {
    items.forEach((item) => {
      if ('clinicalTrialProductId' in item) {
        const sameItem = this._clinicalTrialCartItems.find(
          (cartItem) =>
            'clinicalTrialProductId' in cartItem.item &&
            cartItem.categoryDataId === this.categoryDataId &&
            cartItem.item.clinicalTrialProductId ===
              item.clinicalTrialProductId,
        );
        if (sameItem) {
          sameItem.itemQuantity += 1;
        } else {
          this._clinicalTrialCartItems.push({
            clinicalTrialCartItemId: nanoid(),
            isSelected: false,
            item,
            itemQuantity: 1,
            categoryDataId: this.categoryDataId,
            options: item.options.map(
              ({ clinicalTrialProductOptionId, productOptionName }) => ({
                clinicalTrialProductOptionId,
                clinicalTrialProductId: item.clinicalTrialProductId,
                clinicalTrialName: item.clinicalTrialName,
                productOptionName,
                itemQuantity: 0,
              }),
            ),
          });
        }
        this.deselectClinicalTrialProduct(item);
      } else {
        const clinicalTrialItemIds = item.clinicalTrialProducts.map(
          ({ clinicalTrialProductId }) => clinicalTrialProductId,
        );
        const samePackage = this._clinicalTrialCartItems.find(
          (cartItem) =>
            'clinicalTrialPackageId' in cartItem.item &&
            cartItem.categoryDataId === this.categoryDataId &&
            cartItem.item.clinicalTrialPackageProducts.length ===
              clinicalTrialItemIds.length &&
            cartItem.item.clinicalTrialPackageProducts.every(
              ([{ clinicalTrialProductId }]) =>
                clinicalTrialItemIds.includes(clinicalTrialProductId),
            ),
        );
        if (samePackage) {
          samePackage.itemQuantity += 1;
        } else {
          this._clinicalTrialCartItems.push({
            clinicalTrialCartItemId: nanoid(),
            isSelected: false,
            item: {
              ...item.clinicalTrialPackage,
              clinicalTrialPackageProducts: item.clinicalTrialProducts.map(
                (item) => [item],
              ),
            },
            itemQuantity: 1,
            categoryDataId: this.categoryDataId,
            options: item.clinicalTrialProducts.reduce(
              (acc, { clinicalTrialProductId, clinicalTrialName, options }) => {
                acc = acc.concat(
                  options.map(
                    ({ clinicalTrialProductOptionId, productOptionName }) => ({
                      clinicalTrialProductOptionId,
                      clinicalTrialProductId,
                      clinicalTrialPackageName:
                        item.clinicalTrialPackage.packageName,
                      clinicalTrialName: clinicalTrialName,
                      productOptionName,
                      itemQuantity: 0,
                    }),
                  ),
                );
                return acc;
              },
              [] as IClinicalTrialCartItemOption[],
            ),
          });
        }
        this.deselectClinicalTrialPackage(item.clinicalTrialPackage);
      }
    });
  }

  selectAllClinicalTrialCartItems() {
    this.clinicalTrialCartItems.forEach((cartItem) => {
      cartItem.isSelected = true;
    });
  }

  deselectAllClinicalTrialCartItems() {
    this.clinicalTrialCartItems.forEach((cartItem) => {
      cartItem.isSelected = false;
    });
  }

  removeClinicalTrialCartItems(clinicalTrialCartItemIds: string[]) {
    this._clinicalTrialCartItems = this._clinicalTrialCartItems.filter(
      ({ clinicalTrialCartItemId }) =>
        !clinicalTrialCartItemIds.includes(clinicalTrialCartItemId),
    );
  }

  changeClinicalTrialCartItem(
    clinicalTrialCartItemId: string,
    {
      isSelected,
      itemQuantity,
    }: {
      isSelected?: boolean;
      itemQuantity?: number;
    },
  ) {
    const targetCartItem = this._clinicalTrialCartItems.find(
      (clinicalTrialCartItem) =>
        clinicalTrialCartItem.clinicalTrialCartItemId ===
        clinicalTrialCartItemId,
    );
    if (!targetCartItem) {
      throw new Error('Invalid cart item');
    }
    if (typeof isSelected !== 'undefined') {
      targetCartItem.isSelected = isSelected;
    }
    if (typeof itemQuantity !== 'undefined') {
      targetCartItem.itemQuantity = itemQuantity;
    }
  }

  changeClinicalTrialCartItemOption(
    clinicalTrialCartItemId: string,
    clinicalTrialProductOptionId: number,
    itemQuantity: number,
  ) {
    const targetCartItem = this._clinicalTrialCartItems.find(
      (clinicalTrialCartItem) =>
        clinicalTrialCartItem.clinicalTrialCartItemId ===
        clinicalTrialCartItemId,
    );
    if (!targetCartItem) {
      throw new Error('Invalid cart item');
    }
    const targetCartItemOption = targetCartItem.options.find(
      (cartItemOption) =>
        cartItemOption.clinicalTrialProductOptionId ===
        clinicalTrialProductOptionId,
    );
    if (!targetCartItemOption) {
      throw new Error('Invalid clinicalTrialProductOptionId');
    }
    targetCartItemOption.itemQuantity = Math.min(
      targetCartItem.itemQuantity,
      itemQuantity,
    );
  }
}
