import { Card } from 'antd';
import styled from 'styled-components';
import { RightOutlined, TableOutlined } from '@ant-design/icons';
import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { ReactChart } from 'chartjs-react';
import PeriodRangeSelect from './PeriodRangeSelect';
import { useQuery } from 'react-query';
import { useState } from 'react';
import moment from 'moment';
import { useMemo } from 'react';
import * as statsApi from 'lib/api/stats';
import { EPeriodRangeType, IPeriod } from 'types/stats';
import SalesDetailModal from './SalesDetailModal';
import { ChartData, ChartOptions } from 'chart.js';
const SalesChartBlock = styled(Card)`
  position: relative;

  .ant-card-body {
    padding: 56px 56px 86px;
  }
`;

const ShowAllBlock = styled.div`
  position: absolute;
  top: 56px;
  right: 56px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SalesChart = () => {
  const [salesDetailModalVisible, setSalesDetailModalVisible] = useState(false);
  const [periodType, setPeriodType] = useState(EPeriodRangeType.ALL);
  const [period, setPeriod] = useState<IPeriod>({
    from: '2021-03-23',
    to: moment().format('YYYY-MM-DD'),
  });
  const { data: salesStats = [] } = useQuery(
    ['stats/getSalesStats', period],
    () =>
      statsApi.getPeriodSalesStats(
        period,
        periodType === EPeriodRangeType.MONTH ? 'monthly' : 'daily',
      ),
    { select: (res) => res.data.result },
  );
  const { totalSales, totalCertCount } = useMemo(() => {
    if (typeof salesStats === 'undefined')
      return { totalSales: 0, totalCertCount: 0 };
    return salesStats.reduce(
      ({ totalSales, totalCertCount }, { sumSales, countCertFin }) => ({
        totalSales: totalSales + sumSales,
        totalCertCount: totalCertCount + countCertFin,
      }),
      { totalSales: 0, totalCertCount: 0 },
    );
  }, [salesStats]);
  const salesChartData: ChartData = useMemo(() => {
    const labels =
      salesStats.map(({ targetDate, targetMonth }) => {
        if (salesStats.length > 31) {
          return '';
        }
        if (periodType === EPeriodRangeType.MONTH) {
          return targetMonth;
        }
        return targetDate;
      }) || [];
    const data =
      salesStats?.map(({ sumSales, countCertFin }) => ({
        y: sumSales,
        x: countCertFin,
      })) || [];
    return {
      labels,
      datasets: [
        {
          borderWidth: 2,
          borderColor: 'rgba(50, 115, 220)',
          backgroundColor: 'rgba(26, 115, 232, 0.1)',
          fill: true,
          pointBorderWidth: salesStats.length > 31 ? 0 : 1,
          pointBackgroundColor: 'rgba(0,0,0,0)',
          data,
        },
      ],
    };
  }, [salesStats]);

  const salesCartOptions: ChartOptions = useMemo(
    () => ({
      layout: {
        padding: 30,
      },
      aspectRatio: 4,
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          type: 'linear',
          grid: {
            borderDash: [5, 5],
            display: true,
          },
          beginAtZero: true,
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: salesStats && salesStats.length < 31,
          displayColors: false,
          backgroundColor: '#fff',
          bodyColor: '#222',
          borderColor: palette.primary,
          borderWidth: 1,
          callbacks: {
            title: () => '',
            label: function ({ label, raw: { x, y } }: any) {
              return `${label} : ${y}원 / ${x}건`;
            },
          },
        },
      },
    }),
    [salesStats],
  );

  const handleSearchPeriod = (
    periodType: EPeriodRangeType,
    period: IPeriod,
  ) => {
    setPeriodType(periodType);
    setPeriod(period);
  };

  return (
    <>
      <SalesChartBlock>
        <PeriodRangeSelect onSearch={handleSearchPeriod} />
        <ShowAllBlock onClick={() => setSalesDetailModalVisible(true)}>
          <TableOutlined style={{ fontSize: 14 }} />
          <Typography.Text
            type="secondary"
            gutter={{ left: 4, right: 4 }}
            bold
            inline
          >
            자세히 보기
          </Typography.Text>
          <RightOutlined style={{ fontSize: 12 }} />
        </ShowAllBlock>
        <Typography.Headline
          gutter={{ top: 56, bottom: 46 }}
          style={{ textAlign: 'center' }}
        >
          {totalSales.toLocaleString()}
          <small style={{ fontWeight: 300 }}>원</small>{' '}
          {totalCertCount.toLocaleString()}
          <small style={{ fontWeight: 300 }}>건</small>
        </Typography.Headline>
        <ReactChart
          type="line"
          data={salesChartData}
          options={salesCartOptions}
        />
      </SalesChartBlock>
      <SalesDetailModal
        visible={salesDetailModalVisible}
        periodType={periodType}
        salesStats={salesStats}
        onClose={() => setSalesDetailModalVisible(false)}
      />
    </>
  );
};

export default SalesChart;
