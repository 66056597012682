import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { useStores } from 'stores/Context';
import palette from 'lib/styles/palette';

export const ClinicalTrialToastContainer = styled.div`
  position: fixed;
  bottom: 72px;
  left: 50%;
  width: 720px;
  transform: translateX(-50%);
  z-index: 120;
`;

export const ClinicalTrialToastItemContainer = styled.div`
  color: #fff;
  background-color: ${palette.gray90};
  padding: 12px 24px;
  border-radius: 8px;

  & + & {
    margin-top: 12px;
  }
`;

const ClinicalTrialToast = () => {
  const { clinicalTrialToastStore } = useStores();
  if (clinicalTrialToastStore.clinicalTrialToasts.length === 0) return null;
  return (
    <ClinicalTrialToastContainer>
      {clinicalTrialToastStore.clinicalTrialToasts.map(
        ({ id, message, buttonProps }) => (
          <ClinicalTrialToastItemContainer key={id}>
            <Row justify="space-between" align="middle">
              <Col>
                <Typography.Text medium>{message}</Typography.Text>
              </Col>
              {buttonProps && (
                <Col>
                  <Button onClick={buttonProps.onClick}>
                    {buttonProps.text}
                  </Button>
                </Col>
              )}
            </Row>
          </ClinicalTrialToastItemContainer>
        ),
      )}
    </ClinicalTrialToastContainer>
  );
};

export default observer(ClinicalTrialToast);
