import { Spin } from 'antd';
import styled from 'styled-components';

import { useGetProductKeepWarning } from 'hooks/certificate';
import { Typography } from 'components/system';
import { IProductKeepWarningBasic } from 'types/certificate';

const Wrapper = styled.div`
  text-align: center;
  padding-top: 24px;
`;

const KeepWarningModalContent = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const { data: keepWarning = null, isLoading } = useGetProductKeepWarning({
    productId,
    countryId,
  });

  const getKeepWarningTitle = (
    keepWarning: IProductKeepWarningBasic['productLine'],
  ) => {
    switch (keepWarning) {
      case 'LID_PRODUCT':
        return '캡/뚜껑 있는 제품';
      case 'MASK_PACK':
        return '마스크팩';
      case 'NOT_APPLICABLE':
        return '해당 없음';
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Wrapper>
        사용 보관상의 주의사항:{' '}
        {keepWarning !== null && (
          <Typography.Text type="secondary" medium inline>
            {getKeepWarningTitle(keepWarning.productLine)}
          </Typography.Text>
        )}
      </Wrapper>
    </Spin>
  );
};

export default KeepWarningModalContent;
