import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';
import { Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import koKR from 'antd/es/locale/ko_KR';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { registerables } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import 'moment/locale/ko';

import 'index.less';
import AppRouter from 'routers/AppRouter';
import GlobalStyle from 'styles/GlobalStyle';
import { StoreProvider } from 'stores/Context';
import RootStore from 'stores/RootStore';
import history from 'lib/history';

moment.locale('ko');
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
ReactChart.register(...registerables, ChartDataLabels);

export const rootStore = new RootStore();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

ReactDOM.render(
  <Router history={history}>
    <ConfigProvider
      locale={koKR}
      getPopupContainer={(trigger) => trigger?.parentElement || document.body}
    >
      <GlobalStyle />
      <StoreProvider value={rootStore}>
        <QueryClientProvider client={queryClient}>
          <AppRouter />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </StoreProvider>
    </ConfigProvider>
  </Router>,
  document.getElementById('root'),
);
