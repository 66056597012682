import palette from 'lib/styles/palette';
import styled from 'styled-components';

const MinusIcon = styled.div`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${palette.inactive};
  cursor: pointer;
  background-color: #fff;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 12px;
    height: 2px;
    background-color: ${palette.primary};
  }
`;

export default MinusIcon;
