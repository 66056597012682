import { useMemo } from 'react';
import { useQuery } from 'react-query';

import * as checklistApi from 'lib/api/checklist';

export const useChecklistAnswers = (certTargetId: number) => {
  const {
    data: checklistAnswers = [],
    refetch,
  } = useQuery(
    'checklist/getChecklistAnswers',
    () => checklistApi.getChecklistAnswers(certTargetId),
    { select: (res) => res.data.result },
  );

  return useMemo(() => ({ checklistAnswers, refetch }), [checklistAnswers]);
};
