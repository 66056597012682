import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  html, body {
    width: 100%;
    height: 100%;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    min-height: 100%;
    overscroll-behavior: none;
  }

  #root {
    min-height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  pre {
    margin-bottom: 0 !important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

`;

export default GlobalStyle;
