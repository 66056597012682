import styled from 'styled-components';
import { Row, Col } from 'antd';

import { Typography } from 'components/system';
import DayCertificateStatus from 'components/stats/mainStats/DayCertificateStatus';
import DaySales from 'components/stats/mainStats/DaySales';
import NeedToCheck from 'components/stats/mainStats/NeedToCheck';
import NotificationCard from 'components/stats/mainStats/NotificationCard';
import MainStatsTable from 'components/stats/mainStats/MainStatsTable';
import TotalDataStatusCard from 'components/stats/mainStats/TotalDataStatusCard';
import InfoIcon from 'components/system/icon/InfoIcon';

const MainStatsBlock = styled.div``;

const MainStatsPage = () => {
  return (
    <MainStatsBlock>
      <Row gutter={8}>
        <Col>
          <InfoIcon />
        </Col>
        <Col>
          <Typography.Text type="secondary" bold>
            통계의 모든 총 합계 데이터는 전일 23시 59분까지 집계된 데이터입니다.
          </Typography.Text>
        </Col>
      </Row>
      <Row gutter={34} style={{ marginTop: 16 }}>
        <Col span={10}>
          <DayCertificateStatus />
        </Col>
        <Col span={9}>
          <DaySales />
        </Col>
        <Col span={5}>
          <NeedToCheck />
        </Col>
      </Row>
      <Row gutter={34} style={{ marginTop: 36 }}>
        <Col span={13}>
          <MainStatsTable />
        </Col>
        <Col span={11} style={{ height: '100%' }}>
          <NotificationCard />
          <TotalDataStatusCard />
        </Col>
      </Row>
    </MainStatsBlock>
  );
};

export default MainStatsPage;
