import axios from 'axios';
import JSZip from 'jszip';

export const createFormData = (object: any) => {
  const formData = new FormData();
  for (const key in object) {
    const value = object[key];
    if (typeof value === 'undefined') {
      continue;
    }
    if (value instanceof FileList) {
      Array.prototype.forEach.call(value, (file) => {
        formData.append(key, file);
      });
    } else if (value instanceof Array) {
      value.forEach((element, index) => {
        if (element instanceof File || element === null) {
          formData.append(key, element);
        } else if (element instanceof Object) {
          Object.keys(element).forEach((fieldKey) => {
            formData.append(`${key}[${index}].${fieldKey}`, element[fieldKey]);
          });
        } else {
          formData.append(`${key}[${index}]`, element);
        }
      });
    } else {
      formData.append(key, value);
    }
  }
  return formData;
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const downloadFile = async (file: string | File, name?: string) => {
  if (file instanceof File) {
    const link = document.createElement('a');
    link.download = file.name;
    link.href = URL.createObjectURL(file);
    link.click();
  } else {
    const filename = name || getFilenameFromUrl(file);
    const response = await axios.get(file, { responseType: 'blob' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(response.data);
    a.download = filename;
    a.click();
  }
};

const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      const result = reader.result;
      if (typeof result === 'string') {
        resolve(result.split(',')[1]);
      } else {
        reject(new Error("Type of result isn't string"));
      }
    };
    reader.onerror = (error) => reject(error);
  });

export const downloadFiles = async (
  files: { name: string; url: string }[],
  zipName = 'file.zip',
) => {
  const zip = new JSZip();
  for (const { name, url } of files) {
    const blobData = await axios.get<Blob>(url, {
      responseType: 'blob',
    });
    const binaryData = await blobToBase64(blobData.data);
    zip.file(
      `${name}${!name.includes('.') ? url.slice(url.lastIndexOf('.')) : ''}`,
      binaryData,
      {
        base64: true,
      },
    );
  }

  return new Promise((resolve) => {
    zip.generateAsync({ type: 'base64' }).then((content) => {
      const a = document.createElement('a');
      a.href = `data:application/zip;base64, ${content}`;
      a.download = zipName;
      a.click();
      resolve(undefined);
    });
  });
};

export const getFilenameFromUrl = (url: string, excludeSuffix = false) => {
  if (!url || !url.includes('/') || !url.includes('.')) {
    throw new Error('Invalid URL');
  }
  return decodeURIComponent(
    url.slice(
      url.lastIndexOf('/') + 1,
      excludeSuffix ? url.lastIndexOf('.') : undefined,
    ),
  );
};
