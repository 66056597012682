import { Divider, Form, Typography } from 'antd';
import { useProductDetail } from 'hooks/product';
import { ICompany } from 'types/company';

const ProductDetailModalContent: React.FC<{
  company: ICompany;
  productId: number;
  countryId: number;
}> = ({ company, productId, countryId }) => {
  const { data: productDetail } = useProductDetail({ productId, countryId });
  const {
    manufacturerNameKo,
    manufacturerNameEn,
    manufacturerAddressEn,
    manufacturerTel,
    manufacturerFax,
  } = {
    ...productDetail,
  };
  const { companyNameKo, companyNameEn, addressKo, addressEn } = company;
  return (
    <Form labelCol={{ span: 8 }} size="small" style={{ paddingTop: 20 }}>
      <Form.Item label="화장품 제조업자 (국문)">
        <Typography.Text strong>{manufacturerNameKo}</Typography.Text>
      </Form.Item>
      <Form.Item label="화장품 제조업자 (영문)">
        <Typography.Text strong>{manufacturerNameEn}</Typography.Text>
      </Form.Item>
      <Form.Item label="화장품제조업자 주소 (영문)">
        <Typography.Text strong>{manufacturerAddressEn}</Typography.Text>
      </Form.Item>
      <Form.Item label="화장품제조업자 전화번호">
        <Typography.Text strong>{manufacturerTel}</Typography.Text>
      </Form.Item>
      <Form.Item label="화장품제조업자 팩스번호">
        <Typography.Text strong>{manufacturerFax}</Typography.Text>
      </Form.Item>
      <Divider />
      <Form.Item label="화장품책임판매업자 (국문)">
        <Typography.Text strong>{companyNameKo}</Typography.Text>
      </Form.Item>
      <Form.Item label="화장품책임판매업자 (영문)">
        <Typography.Text strong>{companyNameEn}</Typography.Text>
      </Form.Item>
      <Form.Item label="화장품책임판매업자 주소 (국문)">
        <Typography.Text strong>{addressKo}</Typography.Text>
      </Form.Item>
      <Form.Item label="화장품책임판매업자 주소 (영문)">
        <Typography.Text strong>{addressEn}</Typography.Text>
      </Form.Item>
    </Form>
  );
};

export default ProductDetailModalContent;
