import { Button, Col, Form, Input, InputNumber, Row, Table } from 'antd';
import styled from 'styled-components';
import produce from 'immer';

import { Typography } from 'components/system';
import EditIcon from 'components/system/icon/EditIcon';
import MinusIcon from 'components/system/icon/MinusIcon';
import palette from 'lib/styles/palette';
import {
  EClinicalTrialEstimateItemType,
  IClinicalTrialEstimateItem,
} from 'types/clinicalTrial';
import { requireRule } from 'lib/validate';

const ClinicalTrialEstimateItemTableContainer = styled.div`
  & + & {
    margin-top: 24px;
  }
  & > .ant-table-wrapper {
    box-shadow: 0 0 6px 0 rgba(7, 7, 7, 0.1);

    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
      margin: 0;
    }

    table {
      col.ant-table-expand-icon-col {
        width: 0;
      }
      tr:last-child > td {
        border-bottom: none;
      }
      tr.ant-table-expanded-row > td {
        padding: 0;
      }
      th,
      td {
        color: ${palette.darkNavy};
        padding: 5px 4px 4px;
        font-weight: 500;
        background-color: #fff !important;

        &:last-child {
          padding-right: 16px;
        }
      }

      th {
        font-size: 10px;
      }

      td {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .ant-table-footer {
      padding: 12px 16px;
      background-color: #fff;
      background-image: linear-gradient(
        to right,
        ${palette.darkNavy}40 33%,
        ${palette.darkNavy}00 0%
      );
      background-position: top;
      background-size: 4px 1px;
      background-repeat: repeat-x;
    }

    .option-table td {
      &:first-child {
        position: relative;
        padding-left: 20px;

        &::before {
          position: absolute;
          top: 16px;
          left: 4px;
          content: '└';
        }
      }
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 0;
  }

  .ant-input,
  .ant-input-number-input {
    height: 24px;
    font-size: 12px;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-input-number-input {
    text-align: right;
    padding: 0 8px;
  }

  .ant-form-item-explain {
    font-size: 10px;
    min-height: 0;
  }
`;

const EtcItemAddButton = styled(Button)`
  font-size: 12px;
  color: ${palette.text.disabled};
  line-height: 1;

  &:focus,
  &:hover {
    color: ${palette.text.disabled};
    border: 1px solid ${palette.inactive};
    box-shadow: none;
  }
  s &::after {
    display: none;
  }
`;

const ClinicalTrialEstimateItemTable = ({
  categoryDataId,
  categoryDataName,
  itemIndexes,
  estimateItems,
  onDeleteItem,
  onAddEtcEstimateItem,
}: {
  categoryDataId: number;
  categoryDataName: string;
  itemIndexes: number[];
  estimateItems: IClinicalTrialEstimateItem[];
  onDeleteItem: (clinicalTrialEstimateItemId: number | string) => void;
  onAddEtcEstimateItem: (categoryDataId: number) => void;
}) => {
  return (
    <ClinicalTrialEstimateItemTableContainer>
      <Typography.Text bold color="darkNavy" gutter={{ bottom: 8 }}>
        {categoryDataName}
      </Typography.Text>
      <Table
        dataSource={estimateItems}
        rowKey="clinicalTrialEstimateItemId"
        pagination={false}
        expandable={{
          expandedRowRender: ({ estimateItemOptions }, itemIndex) => (
            <Table
              className="option-table"
              pagination={false}
              showHeader={false}
              dataSource={estimateItemOptions}
              rowKey="clinicalTrialEstimateItemOptionId"
              columns={[
                {
                  title: '품목명',
                  width: 276,
                  dataIndex: 'itemName',
                },
                {
                  title: '시험기관',
                  width: 140,
                  align: 'center',
                  dataIndex: 'agencyName',
                },
                {
                  title: '단가 (원)',
                  width: 88,
                  align: 'center',
                  render: (_, __, optionIndex) => (
                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) =>
                        getFieldValue([
                          'estimateItems',
                          itemIndexes[itemIndex],
                          'estimateItemOptions',
                          optionIndex,
                          'itemQuantity',
                        ]) > 0 && (
                          <Form.Item
                            name={[
                              'estimateItems',
                              itemIndexes[itemIndex],
                              'estimateItemOptions',
                              optionIndex,
                              'itemUnitPrice',
                            ]}
                          >
                            <InputNumber
                              min={0}
                              inputMode="numeric"
                              controls={false}
                              formatter={(value) =>
                                Number(value).toLocaleString() || ''
                              }
                            />
                          </Form.Item>
                        )
                      }
                    </Form.Item>
                  ),
                },
                {
                  title: '수량',
                  width: 40,
                  align: 'center',
                  render: (_, __, optionIndex) => (
                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) => (
                        <Form.Item
                          name={[
                            'estimateItems',
                            itemIndexes[itemIndex],
                            'estimateItemOptions',
                            optionIndex,
                            'itemQuantity',
                          ]}
                        >
                          <InputNumber
                            min={0}
                            max={getFieldValue([
                              'estimateItems',
                              itemIndexes[itemIndex],
                              'itemQuantity',
                            ])}
                            controls={false}
                            formatter={(value) =>
                              Number(value).toLocaleString() || ''
                            }
                            inputMode="numeric"
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  ),
                },
                {
                  title: '금액 (원)',
                  width: 88,
                  align: 'center',
                  render: (_, __, optionIndex) => (
                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) => {
                        const price =
                          getFieldValue([
                            'estimateItems',
                            itemIndexes[itemIndex],
                            'estimateItemOptions',
                            optionIndex,
                            'itemUnitPrice',
                          ]) *
                          getFieldValue([
                            'estimateItems',
                            itemIndexes[itemIndex],
                            'estimateItemOptions',
                            optionIndex,
                            'itemQuantity',
                          ]);
                        return (
                          <Typography.Text
                            type="secondary"
                            color={price === 0 ? 'disabled' : 'darkNavy'}
                            medium
                          >
                            {price.toLocaleString()}
                          </Typography.Text>
                        );
                      }}
                    </Form.Item>
                  ),
                },
                {
                  title: '비고',
                  width: 88,
                  align: 'center',
                  render: (_, __, optionIndex) => (
                    <Form.Item
                      name={[
                        'estimateItems',
                        itemIndexes[itemIndex],
                        'estimateItemOptions',
                        optionIndex,
                        'remark',
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  ),
                },
              ]}
            />
          ),
          rowExpandable: () => true,
          expandIcon: () => null,
          indentSize: 0,
          expandedRowKeys: estimateItems
            .filter(({ estimateItemOptions }) => estimateItemOptions.length > 0)
            .map(
              ({ clinicalTrialEstimateItemId }) => clinicalTrialEstimateItemId,
            ),
        }}
        columns={[
          {
            title: '품목명',
            width: 268,
            render: (_, { itemName, itemType }, index) => (
              <>
                <Form.Item
                  style={{ display: 'none' }}
                  hidden
                  name={[
                    'estimateItems',
                    itemIndexes[index],
                    'clinicalTrialEstimateItemId',
                  ]}
                >
                  <Input />
                </Form.Item>
                {itemType !== EClinicalTrialEstimateItemType.ETC ? (
                  itemName
                ) : (
                  <Form.Item
                    rules={[requireRule]}
                    name={['estimateItems', itemIndexes[index], 'itemName']}
                  >
                    <Input />
                  </Form.Item>
                )}
              </>
            ),
          },
          {
            title: '시험기관',
            width: 140,
            align: 'center',
            render: (_, { agencyName, itemType }, index) =>
              itemType !== EClinicalTrialEstimateItemType.ETC ? (
                agencyName
              ) : (
                <Form.Item
                  rules={[requireRule]}
                  name={['estimateItems', itemIndexes[index], 'agencyName']}
                >
                  <Input style={{ textAlign: 'center' }} />
                </Form.Item>
              ),
          },
          {
            title: '단가 (원)',
            width: 88,
            align: 'center',
            render: (_, __, index) => (
              <Form.Item
                name={['estimateItems', itemIndexes[index], 'itemUnitPrice']}
              >
                <InputNumber
                  formatter={(value) => Number(value).toLocaleString() || ''}
                  inputMode="numeric"
                  controls={false}
                />
              </Form.Item>
            ),
          },
          {
            title: '수량',
            width: 40,
            align: 'center',
            render: (_, { itemType, clinicalTrialPackageId }, index) => (
              <Form.Item shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => (
                  <Form.Item
                    name={['estimateItems', itemIndexes[index], 'itemQuantity']}
                  >
                    <InputNumber
                      min={1}
                      max={99}
                      inputMode="numeric"
                      disabled={
                        itemType === EClinicalTrialEstimateItemType.DISCOUNT
                      }
                      controls={false}
                      style={{ color: palette.text.black }}
                      formatter={(value) =>
                        Number(value).toLocaleString() || ''
                      }
                      onChange={
                        itemType === EClinicalTrialEstimateItemType.PACKAGE
                          ? (itemQuantity) => {
                              const estimateItems = getFieldValue(
                                'estimateItems',
                              ) as IClinicalTrialEstimateItem[];
                              setFieldsValue({
                                estimateItems: produce(
                                  estimateItems,
                                  (proxy) => {
                                    const discountItem = proxy.find(
                                      (estimateItem) =>
                                        estimateItem.categoryDataId ===
                                          categoryDataId &&
                                        estimateItem.itemType ===
                                          EClinicalTrialEstimateItemType.DISCOUNT &&
                                        estimateItem.clinicalTrialPackageId ===
                                          clinicalTrialPackageId,
                                    );
                                    if (!discountItem) {
                                      throw new Error(
                                        'Invalid clinicalTrialPackageId',
                                      );
                                    }
                                    discountItem.itemQuantity = itemQuantity;
                                  },
                                ),
                              });
                            }
                          : undefined
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            ),
          },
          {
            title: '금액 (원)',
            width: 88,
            align: 'center',
            render: (_, __, index) => (
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const price =
                    getFieldValue([
                      'estimateItems',
                      itemIndexes[index],
                      'itemUnitPrice',
                    ]) *
                    getFieldValue([
                      'estimateItems',
                      itemIndexes[index],
                      'itemQuantity',
                    ]);
                  return (
                    <Typography.Text
                      type="secondary"
                      color={price === 0 ? 'disabled' : 'darkNavy'}
                      medium
                    >
                      {price.toLocaleString()}
                    </Typography.Text>
                  );
                }}
              </Form.Item>
            ),
          },
          {
            title: '비고',
            width: 88,
            align: 'center',
            render: (_, { clinicalTrialEstimateItemId, itemType }, index) => (
              <>
                <Form.Item
                  name={['estimateItems', itemIndexes[index], 'remark']}
                >
                  <Input />
                </Form.Item>
                {itemType !== EClinicalTrialEstimateItemType.DISCOUNT && (
                  <MinusIcon
                    style={{
                      position: 'absolute',
                      top: 14,
                      right: -32,
                    }}
                    onClick={() => onDeleteItem(clinicalTrialEstimateItemId)}
                  />
                )}
              </>
            ),
          },
        ]}
        footer={() => (
          <Row align="middle">
            <Col span={13}>
              <EtcItemAddButton
                size="small"
                color="disabled"
                icon={
                  <EditIcon
                    width={12}
                    height={12}
                    color={palette.text.disabled}
                    style={{
                      marginRight: 4,
                      position: 'relative',
                      top: 1,
                    }}
                  />
                }
                onClick={() => onAddEtcEstimateItem(categoryDataId)}
              >
                항목 직접 추가
              </EtcItemAddButton>
            </Col>
            <Col span={2}>
              <Typography.Text color="darkNavy" medium>
                합계
              </Typography.Text>
            </Col>
            <Col span={9}>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const items = (getFieldValue(
                    'estimateItems',
                  ) as IClinicalTrialEstimateItem[])?.filter((_, index) =>
                    itemIndexes.includes(index),
                  );
                  if (!items) return null;
                  const totalPrice = items.reduce(
                    (
                      acc,
                      { itemUnitPrice, itemQuantity, estimateItemOptions },
                    ) =>
                      acc +
                      itemUnitPrice * itemQuantity +
                      estimateItemOptions.reduce(
                        (acc, { itemQuantity, itemUnitPrice }) =>
                          acc + itemQuantity * itemUnitPrice,
                        0,
                      ),
                    0,
                  );
                  return (
                    <Typography.Title
                      type="secondary"
                      medium
                      color="darkNavy"
                      align="right"
                    >
                      {totalPrice.toLocaleString()}
                    </Typography.Title>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        )}
      />
    </ClinicalTrialEstimateItemTableContainer>
  );
};

export default ClinicalTrialEstimateItemTable;
