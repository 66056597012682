import { useEffect } from 'react';
import { Button, Form, Input, message, Spin } from 'antd';
import styled from 'styled-components';

import ClinicalTrialModal from 'components/clinicalTrial/estimate/ClinicalTrialModal';
import { Typography } from 'components/system';
import TextArea from 'antd/lib/input/TextArea';
import palette from 'lib/styles/palette';
import {
  useClientInfo,
  useUpdateClientInfos,
} from 'hooks/clinicalTrial/process/processingEstimate';
import { IClientInfo } from 'types/clinicalTrial/process/processingEstimate';
import { requireRule } from 'lib/validate';
import { getUpdatingObject } from 'lib/form';
import consts from 'lib/consts';

const Container = styled.div`
  width: 320px;
  margin: 0 auto;
  text-align: center;

  .ant-form-item-label {
    font-size: 16px;
    color: ${palette.darkNavy};
    font-weight: 700;
  }
  .ant-form-item-explain {
    text-align: left;
    font-size: 12px;
  }
  .ant-input {
    height: 44px;
  }
`;

const ClientInfoModal = ({
  clinicalTrialEstimateId,
  onClose,
}: {
  clinicalTrialEstimateId: number;
  onClose: () => void;
}) => {
  const [form] = Form.useForm<IClientInfo>();
  const { clientInfos, getLoading } = useClientInfo(clinicalTrialEstimateId);
  useEffect(() => {
    if (clientInfos) {
      form.setFieldsValue(clientInfos);
    }
  }, [clientInfos]);

  const { updateClientInfos, updateLoading } = useUpdateClientInfos();
  const handleSubmit = (formValue: IClientInfo) => {
    const updatingObject = getUpdatingObject(formValue, clientInfos);
    if (!updatingObject) {
      message.warning(consts.message.NO_NEED_TO_UPDATE);
    } else {
      updateClientInfos(
        {
          clinicalTrialEstimateId,
          ...formValue,
        },
        {
          onSuccess: () => {
            message.success('수정되었습니다.');
            onClose();
          },
        },
      );
    }
  };

  return (
    <ClinicalTrialModal width={560} onClose={onClose} closeOnEsc>
      <Container>
        <Typography.Title
          align="center"
          medium
          color="darkNavy"
          style={{ marginBottom: 24 }}
        >
          의뢰자 정보 입력
        </Typography.Title>
        <Spin spinning={getLoading}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              name="clientCompanyName"
              label="업체명"
              required
              rules={[requireRule]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="clientName"
              label="의뢰자"
              required
              rules={[requireRule]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="clientTel"
              label="의뢰자 연락처"
              required
              rules={[requireRule]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="clientEmail"
              label="의뢰자 이메일"
              required
              rules={[requireRule]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="clientCompanyAddress" label="업체 주소">
              <Input />
            </Form.Item>
            <Form.Item name="remark" label="비고">
              <TextArea
                maxLength={100}
                style={{ height: 104, resize: 'none' }}
              />
            </Form.Item>
            <Button
              style={{ width: 200, height: 40, fontSize: 18 }}
              type="primary"
              htmlType="submit"
              loading={updateLoading}
            >
              수정
            </Button>
          </Form>
        </Spin>
      </Container>
    </ClinicalTrialModal>
  );
};

export default ClientInfoModal;
