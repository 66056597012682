import { Spin } from 'antd';
import styled from 'styled-components';

import { useGetSeriesInfo } from 'hooks/certificate';
import { Typography } from 'components/system';

const Wrapper = styled.div`
  text-align: center;
  padding-top: 24px;
`;

const SeriesProductInfoModalContent = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId: number;
}) => {
  const { data: isSeriesProduct = null, isLoading } = useGetSeriesInfo({
    productId,
    countryId,
  });

  return (
    <Spin spinning={isLoading}>
      <Wrapper>
        시리즈 제품 여부:{' '}
        {isSeriesProduct !== null && (
          <Typography.Text type="secondary" medium inline>
            {isSeriesProduct ? 'O' : 'X'}
          </Typography.Text>
        )}
      </Wrapper>
    </Spin>
  );
};

export default SeriesProductInfoModalContent;
