import {
  Collapse,
  Empty,
  Form,
  Row,
  Col,
  Typography,
  Button,
  Upload,
} from 'antd';
import FileViewer from 'components/file/FileViewer';
import FormCard from 'components/form/FormCard';
import { useCompany, useFactories } from 'hooks/company';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PageTemplate from 'templates/PageTemplate';
import { EAuthority, EUserType } from 'types/auth';
import { ECompanyType } from 'types/company';

const CompanyDetailPage = () => {
  const params = useParams<{ companyId: string }>();
  const companyId = Number(params.companyId);
  const { company, updateCompany } = useCompany(companyId);
  const superUser = useMemo(
    () =>
      company?.users.find(
        ({ authority }) => authority === EAuthority.SUPER_USER,
      ),
    [company?.users],
  );
  const managers = useMemo(
    () =>
      company?.users.filter(
        ({ authority }) => authority !== EAuthority.SUPER_USER,
      ),
    [company?.users],
  );
  const factories = useFactories(companyId, company?.companyType);
  if (!company || !superUser) return null;
  const { companyType } = company;

  return (
    <PageTemplate title="회원 상세 정보" back>
      <FormCard title="회사 정보">
        <Form labelCol={{ span: 4 }} size="small">
          <Form.Item label="회사명 (영문)">
            <Typography.Text strong>{company.companyNameEn}</Typography.Text>
          </Form.Item>
          <Form.Item label="사업자등록번호">
            <Typography.Text strong>{company.bizNumber}</Typography.Text>
          </Form.Item>
          {companyType !== ECompanyType.MANUFACTURE && (
            <>
              <Form.Item label="사업장 소재지 (국문)">
                <Typography.Text strong>{company.addressKo}</Typography.Text>
              </Form.Item>
              <Form.Item label="사업장 소재지 (영문)">
                <Typography.Text strong>{company.addressEn}</Typography.Text>
              </Form.Item>
            </>
          )}
          {companyType !== ECompanyType.BRAND && (
            <Form.Item label="공장 소재지">
              {factories.map(
                ({
                  materialFactoryId,
                  companyFactoryId,
                  addressKo,
                  addressEn,
                }) => (
                  <Typography.Text
                    strong
                    style={{ display: 'block' }}
                    key={materialFactoryId || companyFactoryId}
                  >
                    {addressKo} / {addressEn}
                  </Typography.Text>
                ),
              )}
            </Form.Item>
          )}
          <Form.Item label="대표자 성명 (국문)">
            <Typography.Text strong>{company.ceoNameKo}</Typography.Text>
          </Form.Item>
          <Form.Item label="대표자 성명 (영문)">
            <Typography.Text strong>{company.ceoNameEn}</Typography.Text>
          </Form.Item>
          <Form.Item label="회사 전화번호">
            <Typography.Text strong>{company.tel}</Typography.Text>
          </Form.Item>
          <Form.Item label="팩스 (FAX)">
            <Typography.Text strong>{company.fax}</Typography.Text>
          </Form.Item>
          {companyType !== ECompanyType.MATERIAL ? (
            <Form.Item label="홈페이지 URL">
              <Typography.Text strong>{company.homepageUrl}</Typography.Text>
            </Form.Item>
          ) : (
            <>
              <Form.Item label="주요 품목">
                <Typography.Text strong>{company.mainItem}</Typography.Text>
              </Form.Item>
              <Form.Item label="회사 소개(설명)">
                <Typography.Text strong>{company.introduction}</Typography.Text>
              </Form.Item>
            </>
          )}
          <Row>
            <Col xl={12} lg={24}>
              <Form.Item label="국문 사업자등록증" labelCol={{ xl: 8, lg: 4 }}>
                <FileViewer
                  title={
                    <Row gutter={8}>
                      <Col>
                        <Typography.Text>미리보기</Typography.Text>
                      </Col>
                      <Col>
                        <Upload
                          accept=".pdf"
                          itemRender={() => null}
                          beforeUpload={(file) => {
                            updateCompany({
                              bizLicenseFile: file,
                            });
                            return false;
                          }}
                        >
                          <Button style={{ width: 64 }}>수정</Button>
                        </Upload>
                      </Col>
                    </Row>
                  }
                  file={company.bizLicenseUrl}
                  style={{ maxWidth: 500 }}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={24}>
              <Form.Item label="영문 사업자등록증" labelCol={{ xl: 6, lg: 4 }}>
                <FileViewer
                  title={
                    <Row gutter={8}>
                      <Col>
                        <Typography.Text>미리보기</Typography.Text>
                      </Col>
                      <Col>
                        <Upload
                          accept=".pdf"
                          itemRender={() => null}
                          beforeUpload={(file) => {
                            updateCompany({
                              bizLicenseEnFile: file,
                            });
                            return false;
                          }}
                        >
                          <Button style={{ width: 64 }}>수정</Button>
                        </Upload>
                      </Col>
                    </Row>
                  }
                  file={company.bizLicenseEnUrl}
                  style={{ maxWidth: 500 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="로고, 슬로건의 사용">
            <Typography.Text strong>
              {company.isUseOfLogoAgreed ? '동의' : '동의 안함'}
            </Typography.Text>
          </Form.Item>
        </Form>
      </FormCard>
      <FormCard title="슈퍼 유저 정보">
        <Form labelCol={{ span: 4 }} size="small">
          <Form.Item label="아이디(사업자등록번호)">
            <Typography.Text strong>
              {superUser.username.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')}
            </Typography.Text>
          </Form.Item>
          <Form.Item label="이름">
            <Typography.Text strong>{superUser.name}</Typography.Text>
          </Form.Item>
          <Form.Item label="부서명">
            <Typography.Text strong>{superUser.deptName}</Typography.Text>
          </Form.Item>
          <Form.Item label="직급">
            <Typography.Text strong>{superUser.positionName}</Typography.Text>
          </Form.Item>
          <Form.Item label="휴대폰 번호">
            <Typography.Text strong>{superUser.mobile}</Typography.Text>
          </Form.Item>
          <Form.Item label="E-mail" style={{ marginBottom: 0 }}>
            <Typography.Text strong>{superUser.email}</Typography.Text>
          </Form.Item>
        </Form>
      </FormCard>
      <FormCard
        title="담당자 정보"
        bodyStyle={{ paddingLeft: 32, paddingRight: 32 }}
      >
        {managers && managers.length !== 0 ? (
          <Collapse expandIconPosition="right" accordion>
            {managers.map((manager) => (
              <Collapse.Panel
                header={`${manager.username} / ${manager.name}`}
                key={manager.userId}
              >
                <Form labelCol={{ span: 4 }} size="small">
                  <Form.Item label="담당자 소속">
                    <Typography.Text strong>
                      {manager.userType === EUserType.BRAND
                        ? '브랜드사'
                        : manager.userType === EUserType.MANUFACTURE
                        ? '제조사'
                        : '원료사'}
                    </Typography.Text>
                  </Form.Item>
                  <Form.Item label="이름">
                    <Typography.Text strong>{manager.name}</Typography.Text>
                  </Form.Item>
                  <Form.Item label="부서명">
                    <Typography.Text strong>{manager.deptName}</Typography.Text>
                  </Form.Item>
                  <Form.Item label="직급">
                    <Typography.Text strong>
                      {manager.positionName}
                    </Typography.Text>
                  </Form.Item>
                  {companyType === ECompanyType.MATERIAL && (
                    <Form.Item label="회사 직통 번호">
                      <Typography.Text strong>
                        {manager.directTelNumber}
                      </Typography.Text>
                    </Form.Item>
                  )}
                  <Form.Item label="휴대폰 번호">
                    <Typography.Text strong>{manager.mobile}</Typography.Text>
                  </Form.Item>
                  <Form.Item label="E-mail" style={{ marginBottom: 0 }}>
                    <Typography.Text strong>{manager.email}</Typography.Text>
                  </Form.Item>
                </Form>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </FormCard>
    </PageTemplate>
  );
};

export default CompanyDetailPage;
