import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import parse from 'html-react-parser';
import SockJS from 'sockjs-client';
import StompJS from 'stompjs';
import produce from 'immer';

import * as notificationApi from 'lib/api/notification';
import { useStores } from 'stores/Context';
import { IAPIResponse } from 'types/common';
import { INewAlarmExists, INotification } from 'types/notification';
import history from 'lib/history';
import path from 'lib/path';
import { useLocation } from 'react-router-dom';

export const useNewExists = () => {
  const queryClient = useQueryClient();

  const { data: isNewUserExists } = useQuery(
    'notification/isNewUserExists',
    notificationApi.getNewUserExist,
    {
      staleTime: Number.MAX_VALUE,
      select: (res) => res.data.result.isNewAlarmExists,
    },
  );
  const { data: isNewEstimateExists } = useQuery(
    'notification/isNewEstimateExists',
    notificationApi.getNewEstimateExist,
    {
      staleTime: Number.MAX_VALUE,
      select: (res) => res.data.result.isNewAlarmExists,
    },
  );

  const { mutate: readNewEstimateMutate } = useMutation(
    notificationApi.readNewEstimate,
  );

  const readNewEstimate = useCallback(() => {
    const isNewAlarmExists = queryClient.getQueryData<
      AxiosResponse<IAPIResponse<INewAlarmExists>>
    >('notification/isNewEstimateExists')?.data?.result.isNewAlarmExists;
    if (isNewAlarmExists) {
      readNewEstimateMutate();
    }
  }, [readNewEstimateMutate]);

  return useMemo(
    () => ({ isNewUserExists, isNewEstimateExists, readNewEstimate }),
    [isNewUserExists, isNewEstimateExists, readNewEstimate],
  );
};

export const useNotifications = () => {
  const queryClient = useQueryClient();
  const { notificationStore, authStore } = useStores();
  const { userId } = authStore.user;
  const { data: notifications, refetch } = useQuery(
    'notification/getNotifications',
    () => notificationApi.getNotifications(userId),
    {
      staleTime: Number.MAX_VALUE,
      select: (res: AxiosResponse<IAPIResponse<INotification[]>>) => {
        const { result } = res.data;
        return result;
      },
      onSuccess: (result) => {
        if (result.length > 0 && !result[0].isConfirm) {
          const noti = result[0];
          showNotification(noti);
        }
        notificationStore.setHasNewNotification(
          result.slice(1).some(({ isConfirm }) => !isConfirm),
        );
      },
    },
  );
  const { mutate: readAllNotifications } = useMutation(
    () => notificationApi.readAllNotification(userId),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
  const { mutate: readNotification } = useMutation((userNoticeId: number) => {
    queryClient.setQueryData('notification/getNotifications', (old) =>
      produce(old, (draft: AxiosResponse<IAPIResponse<INotification[]>>) => {
        const targetNoti = draft.data.result.find(
          (noti: INotification) => noti.userNoticeId === userNoticeId,
        );
        if (targetNoti) {
          targetNoti.isConfirm = true;
        }
      }),
    );
    return notificationApi.readNotification(userNoticeId);
  });
  const showNotification = useCallback((noti: INotification) => {
    notification.open({
      key: 'noti',
      icon: null,
      message: noti.title,
      description: parse(noti.content),
      duration: 0,
      style: { cursor: 'pointer' },
      onClick: () => {
        if (noti.noticePath) {
          history.push(noti.noticePath);
          notification.close('noti');
        }
      },
    });
    readNotification(noti.userNoticeId);
  }, []);
  return useMemo(
    () => ({
      notifications,
      readNotification,
      readAllNotifications,
      showNotification,
    }),
    [notifications],
  );
};

export const useNotificationSocket = () => {
  const queryClient = useQueryClient();
  const { showNotification } = useNotifications();
  const { authStore } = useStores();
  const { userId } = authStore.user;
  const { pathname } = useLocation();
  const isClinicalTrialEstimatePage = pathname.startsWith(
    path.clinicalTrial.estimate.root,
  );

  useEffect(() => {
    const socket = new SockJS(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/socket/connection`,
    );
    const client = StompJS.over(socket);
    if (process.env.NODE_ENV !== 'development') {
      client.debug = () => undefined;
    }
    if (!isClinicalTrialEstimatePage) {
      client.connect({}, () => {
        client.subscribe(`/api/v1/socket/subscribe/${userId}`, ({ body }) => {
          const noti = JSON.parse(body) as INotification;
          queryClient.setQueryData(
            'notification/getNotifications',
            (draftNotificationsResponse) =>
              produce(
                draftNotificationsResponse,
                (proxy: AxiosResponse<IAPIResponse<INotification[]>>) => {
                  proxy.data.result.unshift(noti);
                },
              ),
          );
          showNotification(noti);
        });
        client.subscribe(
          `/api/v1/socket/subscribe/${userId}/badge/sign-up`,
          ({ body }) => {
            const result = JSON.parse(body) as INewAlarmExists;
            queryClient.setQueryData(
              'notification/isNewUserExists',
              (draftNotificationsResponse) =>
                produce(
                  draftNotificationsResponse,
                  (proxy: AxiosResponse<IAPIResponse<INewAlarmExists>>) => {
                    proxy.data.result = result;
                  },
                ),
            );
          },
        );
        client.subscribe(
          `/api/v1/socket/subscribe/${userId}/badge/issued-estimate`,
          ({ body }) => {
            const result = JSON.parse(body) as INewAlarmExists;
            queryClient.setQueryData(
              'notification/isNewEstimateExists',
              (draftNotificationsResponse) =>
                produce(
                  draftNotificationsResponse,
                  (proxy: AxiosResponse<IAPIResponse<INewAlarmExists>>) => {
                    proxy.data.result = result;
                  },
                ),
            );
          },
        );
      });
    }
    return () => {
      if (!isClinicalTrialEstimatePage) {
        client.disconnect(() => undefined);
      }
    };
  }, [isClinicalTrialEstimatePage]);
};
