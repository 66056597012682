import { Form, Typography as Typo } from 'antd';

import { useProductInternalResponsibleCompany } from 'hooks/product';

const ProductInternalResponsibleCompanyModal: React.FC<{
  productId: number;
  countryId: number;
}> = ({ productId, countryId }) => {
  const {
    data: internalResponsibleCompany,
  } = useProductInternalResponsibleCompany({
    productId,
    countryId,
  });
  const {
    isOwnSubsidiary,
    isCreateNewNMPAAccount,
    newNMPAAccountId,
    newNMPAAccountPassword,
    fileName,
    documentFile,
    companyTel,
    companyEmail,
  } = {
    ...internalResponsibleCompany,
  };

  return (
    <Form labelCol={{ span: 8 }} size="small" style={{ paddingTop: 20 }}>
      <Form.Item label="경내책임회사 보유 여부">
        <Typo.Text strong>
          {isOwnSubsidiary
            ? '자회사 또는 중국 법인으로 경내책임회사를 보유하고 있습니다.'
            : '타 경내책임회사와 위생 허가를 진행합니다.'}
        </Typo.Text>
      </Form.Item>
      {isOwnSubsidiary && (
        <Form.Item label="NMPA 신규 계정 생성 여부">
          <Typo.Text strong>
            {isCreateNewNMPAAccount
              ? '네, 법규 개정 후 신규 계정을 생성하였습니다.'
              : '아니오,  없거나 진행 중입니다.'}
          </Typo.Text>
        </Form.Item>
      )}
      {isCreateNewNMPAAccount && (
        <>
          <Form.Item label="신규 NMPA 계정 아이디 : ">
            <Typo.Text strong>{newNMPAAccountId}</Typo.Text>
          </Form.Item>
          <Form.Item label="신규 NMPA 계정 비밀번호 : ">
            <Typo.Text strong>{newNMPAAccountPassword}</Typo.Text>
          </Form.Item>
        </>
      )}
      {companyTel && (
        <Form.Item label="경내책임회사 전화번호 : ">
          <Typo.Text strong>{companyTel}</Typo.Text>
        </Form.Item>
      )}
      {companyEmail && (
        <Form.Item label="경내책임회사 이메일 : ">
          <Typo.Text strong>{companyEmail}</Typo.Text>
        </Form.Item>
      )}
      {documentFile && fileName && (
        <Form.Item label="경내책임회사 사업자등록증 (PDF) : ">
          <Typo.Link href={documentFile}>{fileName}</Typo.Link>
        </Form.Item>
      )}
    </Form>
  );
};

export default ProductInternalResponsibleCompanyModal;
