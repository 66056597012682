import { useMemo } from 'react';

import { useProcessingEstimateDetail } from 'hooks/clinicalTrial/process/processingEstimateDetail';
import ClinicalTrialProcessItemTable from './ClinicalTrialProcessItemTable';
import { IClinicalTrialProcessItem } from 'types/clinicalTrial/process/processingEstimateDetail';

const ClinicalTrialProcessingEstimateDetail = ({
  clinicalTrialEstimateId,
}: {
  clinicalTrialEstimateId: number;
}) => {
  const { clinicalTrialProcessItems } = useProcessingEstimateDetail(
    clinicalTrialEstimateId,
  );

  const categoryNameMap = useMemo(() => {
    if (clinicalTrialProcessItems.length > 0) {
      return clinicalTrialProcessItems.reduce(
        (acc, { categoryDataId, categoryName }) => {
          acc.set(categoryDataId, categoryName);
          return acc;
        },
        new Map<number, string>(),
      );
    }
    return null;
  }, [clinicalTrialProcessItems]);

  const clinicalTrialProcessItemMap = useMemo(() => {
    if (clinicalTrialProcessItems.length > 0) {
      return clinicalTrialProcessItems.reduce((acc, item) => {
        acc.set(item.categoryDataId, [
          ...(acc.get(item.categoryDataId) || []),
          item,
        ]);
        return acc;
      }, new Map<number, IClinicalTrialProcessItem[]>());
    }
    return null;
  }, [clinicalTrialProcessItems]);

  if (!clinicalTrialProcessItemMap || !categoryNameMap) return null;
  return (
    <>
      {Array.from(clinicalTrialProcessItemMap.keys()).map((key) => (
        <ClinicalTrialProcessItemTable
          key={key}
          clinicalTrialEstimateId={clinicalTrialEstimateId}
          categoryDataId={key}
          categoryName={categoryNameMap.get(key) || '-'}
          clinicalTrialProcessItems={clinicalTrialProcessItemMap.get(key) || []}
        />
      ))}
    </>
  );
};

export default ClinicalTrialProcessingEstimateDetail;
