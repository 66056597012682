import { useMutation } from 'react-query';
import { Modal } from 'antd';
import * as authApi from 'lib/api/auth';
import { setToken } from 'lib/api/client';
import { useStores } from 'stores/Context';

export const useLogin = () => {
  const { authStore } = useStores();
  return useMutation(authApi.login, {
    onSuccess: ({ data: { result: user } }) => {
      const { token, refreshToken } = user;
      setToken(token, refreshToken);
      authStore.user = user;
    },
    onError: (error: Error) => {
      Modal.info({ content: error.message, icon: null });
    },
  });
};
