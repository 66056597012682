import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Table, Typography } from 'antd';
import NoticeAddModal from 'components/service/NoticeAddModal';
import NoticeEditModal from 'components/service/NoticeEditModal';
import { useDeleteNotices, useNotices } from 'hooks/service';
import { useMemo, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { INotice } from 'types/service';

const NoticePage = () => {
  const [page, setPage] = useState(1);
  const { data, refetch } = useNotices({ page });
  const { content: notices, totalElements } = { ...data };
  const [selectedNoticeIds, setSelectedNoticeIds] = useState<number[]>([]);
  const [noticeAddModalVisible, setNoticeAddModalVisible] = useState(false);
  const [previewedNotice, setPreviewedNotice] = useState<INotice | null>(null);
  const columns = useMemo(
    () => [
      { title: '번호', dataIndex: 'noticeId', align: 'center' as const },
      {
        title: '제목',
        align: 'center' as const,
        render: (notice: INotice) => (
          <Typography.Link onClick={() => setPreviewedNotice(notice)}>
            {notice.title}
          </Typography.Link>
        ),
      },
      {
        title: '종류',
        dataIndex: 'noticeTypeString',
        align: 'center' as const,
      },
      {
        title: '공개 여부',
        align: 'center' as const,
        render: ({ isLive }) => (isLive ? '공개' : '비공개'),
      },
    ],
    [],
  );
  const { mutate: deleteNoticesMutate, isLoading } = useDeleteNotices({
    onSuccess: () => {
      refetch();
    },
  });
  const closeNoticeEditModal = (isModified: boolean) => {
    if (isModified) {
      refetch();
    }
    setPreviewedNotice(null);
  };
  const closeNoticeAddModal = (isSuccess: boolean) => {
    if (isSuccess) {
      refetch();
    }
    setNoticeAddModalVisible(false);
  };
  const deleteNotices = () => {
    if (!selectedNoticeIds.length) return message.warn('대상을 선택해 주세요.');
    deleteNoticesMutate(selectedNoticeIds);
  };
  return (
    <PageTemplate title="공지사항">
      {noticeAddModalVisible && (
        <NoticeAddModal onClose={closeNoticeAddModal} />
      )}
      {previewedNotice && (
        <NoticeEditModal
          notice={previewedNotice}
          onClose={closeNoticeEditModal}
        />
      )}

      <Row justify="end" gutter={8} style={{ marginBottom: 8 }}>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setNoticeAddModalVisible(true)}
          >
            새로운 공지사항 작성하기
          </Button>
        </Col>
        <Col>
          <Button
            loading={isLoading}
            icon={<DeleteOutlined />}
            onClick={deleteNotices}
          >
            선택 삭제
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={notices}
        rowKey={({ noticeId }) => noticeId}
        pagination={{
          current: page,
          total: totalElements,
          pageSize: 20,
          onChange: setPage,
        }}
        rowSelection={{
          selectedRowKeys: selectedNoticeIds,
          onChange: (keys) => {
            setSelectedNoticeIds(
              keys.map<number>((key) => Number(key)),
            );
          },
        }}
      />
    </PageTemplate>
  );
};

export default NoticePage;
