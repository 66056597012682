/* eslint-disable @typescript-eslint/no-non-null-assertion */
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
import { useQuery, useMutation } from 'react-query';
import { useMemo } from 'react';

import * as clinicalTrialApi from 'lib/api/clinicalTrial';
import {
  EClinicalTrialEstimateItemType,
  IClinicalTrialCartItem,
  IClinicalTrialCartItemOption,
  IClinicalTrialEstimate,
  IClinicalTrialEstimateClientInfo,
  IClinicalTrialEstimateItem,
  IClinicalTrialEstimateItemOfCategory,
  IClinicalTrialEstimateItemOption,
  IClinicalTrialEstimatePackageItem,
  IClinicalTrialEstimateSearchParams,
  IClinicalTrialEstimateUpdate,
  IClinicalTrialPackage,
  IClinicalTrialProduct,
} from 'types/clinicalTrial';
import consts from 'lib/consts';

export const isPackage = (
  item: IClinicalTrialPackage | IClinicalTrialProduct,
): item is IClinicalTrialPackage => 'clinicalTrialPackageId' in item;

export const useCategoryDatas = () => {
  const { data: categoryDatas = [] } = useQuery(
    'clinicalTrial/getCategoryDatas',
    clinicalTrialApi.getCategoryDatas,
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );
  return categoryDatas;
};

export const useCategoryData = (categoryDataId: number) => {
  const categoryDatas = useCategoryDatas();
  const categoryData = categoryDatas.find(
    (categoryData) => categoryData.categoryDataId === categoryDataId,
  );
  return categoryData;
};

export const useClinicalTrials = () => {
  const { data: clinicalTrials = [] } = useQuery(
    'clinicalTrial/getClinicalTrials',
    clinicalTrialApi.getClinicalTrials,
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );
  return clinicalTrials;
};

export const useClinicalTrialPackages = () => {
  const { data: clinicalTrialPackages = [] } = useQuery(
    'clinicalTrial/getClinicalTrialPackages',
    clinicalTrialApi.getClinicalTrialPackages,
    {
      select: (res) => res.data.result,
      staleTime: Number.MAX_VALUE,
    },
  );
  return clinicalTrialPackages;
};

const parsePackageCartItemToEstimateItem = (
  cartItem: IClinicalTrialPackage,
  itemQuantity: number,
  options: IClinicalTrialCartItemOption[],
) => ({
  clinicalTrialPackageId: cartItem.clinicalTrialPackageId,
  itemQuantity,
  clinicalTrialProducts: cartItem.clinicalTrialPackageProducts.map(
    ([{ clinicalTrialProductId }]) => ({
      clinicalTrialProductId,
      optionItems: options
        .filter(
          (option) => option.clinicalTrialProductId === clinicalTrialProductId,
        )
        .map(({ clinicalTrialProductOptionId, itemQuantity }) => ({
          clinicalTrialProductOptionId,
          itemQuantity,
        })),
    }),
  ),
});

const parseProductCartItemToEstimateItem = (
  cartItem: IClinicalTrialProduct,
  itemQuantity: number,
  options: IClinicalTrialCartItemOption[],
) => ({
  clinicalTrialProductId: cartItem.clinicalTrialProductId,
  itemQuantity,
  optionItems: options.map(
    ({ clinicalTrialProductOptionId, itemQuantity }) => ({
      clinicalTrialProductOptionId,
      itemQuantity,
    }),
  ),
});

const parseCartItemsToEstimateItemsOfCategory = (
  cartItems: IClinicalTrialCartItem[],
) =>
  cartItems.reduce((acc, { categoryDataId, item, itemQuantity, options }) => {
    const itemIsPackage = isPackage(item);
    const targetItemOfCategory = acc.find(
      (itemOfCategory) => itemOfCategory.categoryDataId === categoryDataId,
    );

    if (targetItemOfCategory) {
      if (itemIsPackage) {
        targetItemOfCategory.packageItems.push(
          parsePackageCartItemToEstimateItem(
            item as IClinicalTrialPackage,
            itemQuantity,
            options,
          ),
        );
      } else {
        targetItemOfCategory.productItems.push(
          parseProductCartItemToEstimateItem(
            item as IClinicalTrialProduct,
            itemQuantity,
            options,
          ),
        );
      }
    } else {
      acc.push({
        categoryDataId,
        packageItems: itemIsPackage
          ? [
              parsePackageCartItemToEstimateItem(
                item as IClinicalTrialPackage,
                itemQuantity,
                options,
              ),
            ]
          : [],
        productItems: !itemIsPackage
          ? [
              parseProductCartItemToEstimateItem(
                item as IClinicalTrialProduct,
                itemQuantity,
                options,
              ),
            ]
          : [],
      });
    }
    return acc;
  }, [] as IClinicalTrialEstimateItemOfCategory[]);

export const usePreviewClinicalTrialEstimate = () => {
  const { mutateAsync: previewClinicalTrialEstimate, isLoading } = useMutation(
    (cartItems: IClinicalTrialCartItem[]) => {
      return clinicalTrialApi.previewClinicalTrialEstimate(
        parseCartItemsToEstimateItemsOfCategory(cartItems),
      );
    },
  );

  return useMemo(() => ({ previewClinicalTrialEstimate, isLoading }), [
    previewClinicalTrialEstimate,
    isLoading,
  ]);
};

export const useAddClinicalTrialEstimate = () => {
  const { mutateAsync: addClinicalTrialEstimate, isLoading } = useMutation(
    ({
      clientCompanyName,
      clientName,
      cartItems,
      clinicalTrialEstimateRequestId,
    }: {
      clientCompanyName: string;
      clientName: string;
      cartItems: IClinicalTrialCartItem[];
      clinicalTrialEstimateRequestId?: number;
    }) => {
      return clinicalTrialApi.addClinicalTrialEstimate({
        clientCompanyName,
        clientName,
        itemsOfCategory: parseCartItemsToEstimateItemsOfCategory(cartItems),
        clinicalTrialEstimateRequestId,
      });
    },
  );

  return useMemo(() => ({ addClinicalTrialEstimate, isLoading }), [
    addClinicalTrialEstimate,
    isLoading,
  ]);
};

export const useClinicalTrialEstimates = ({
  page,
  searchType,
  searchKeyword,
  isConfirmed,
  publishDuration,
}: IClinicalTrialEstimateSearchParams) => {
  const {
    data: { totalElements, content: clinicalTrialEstimates } = {
      size: 0,
      totalElements: 0,
      content: [],
    },
    refetch: refetchClinicalTrialEstimates,
  } = useQuery(
    [
      'clinicalTrial/estimate/getClinicalTrialEstimates',
      page,
      searchType,
      searchKeyword,
      isConfirmed,
      publishDuration,
    ],
    () =>
      clinicalTrialApi.getClinicalTrialEstimates({
        page,
        searchType,
        searchKeyword,
        isConfirmed,
        publishDuration,
      }),
    { select: (res) => res.data.result },
  );
  const { mutate: confirmClinicalTrialEstimate } = useMutation(
    clinicalTrialApi.confirmClinicalTrialEstimate,
    {
      onSuccess: () => {
        refetchClinicalTrialEstimates();
      },
    },
  );
  return useMemo(
    () => ({
      clinicalTrialEstimates,
      totalElements,
      refetchClinicalTrialEstimates,
      confirmClinicalTrialEstimate,
    }),
    [
      clinicalTrialEstimates,
      totalElements,
      refetchClinicalTrialEstimates,
      confirmClinicalTrialEstimate,
    ],
  );
};

export const useClinicalTrialEstimateClientInfo = (
  clinicalTrialEstimateId: number,
) => {
  const {
    data: clinicalTrialEstimateClientInfo = null,
  } = useQuery(
    'clinicalTrial/estimate/getClinicalTrialEstimateClientInfo',
    () =>
      clinicalTrialApi.getClinicalTrialEstimateClientInfo(
        clinicalTrialEstimateId,
      ),
    { select: (res) => res.data.result },
  );
  const {
    mutate: updateClinicalTrialEstimateClientInfo,
    isLoading: updateLoading,
  } = useMutation((clientInfo: IClinicalTrialEstimateClientInfo) =>
    clinicalTrialApi.updateClinicalTrialEstimateClientInfo(
      clinicalTrialEstimateId,
      clientInfo,
    ),
  );

  return useMemo(
    () => ({
      clinicalTrialEstimateClientInfo,
      updateClinicalTrialEstimateClientInfo,
      updateLoading,
    }),
    [
      clinicalTrialEstimateClientInfo,
      updateClinicalTrialEstimateClientInfo,
      updateLoading,
    ],
  );
};

const getUpdatingEstimateItem = (
  updated: Pick<
    IClinicalTrialEstimateItem,
    'itemName' | 'agencyName' | 'itemUnitPrice' | 'itemQuantity' | 'remark'
  >,
  original: IClinicalTrialEstimateItem,
) => {
  const result = {
    ...(updated.itemName !== original.itemName && {
      itemName: updated.itemName,
    }),
    ...(updated.agencyName !== original.agencyName && {
      agencyName: updated.agencyName,
    }),
    ...(updated.itemUnitPrice !== original.itemUnitPrice && {
      itemUnitPrice: updated.itemUnitPrice,
    }),
    ...(updated.itemQuantity !== original.itemQuantity && {
      itemQuantity: updated.itemQuantity,
    }),
    ...(updated.remark !== null &&
      updated.remark !== original.remark && {
        remark: updated.remark,
      }),
  };
  return result;
};

export const useClinicalTrialEstimate = (clinicalTrialEstimateId: number) => {
  const { data: clinicalTrialEstimate = null } = useQuery(
    [
      'clinicalTrial/estimate/getClinicalTrialEstimate',
      clinicalTrialEstimateId,
    ],
    () => clinicalTrialApi.getClinicalTrialEstimate(clinicalTrialEstimateId),
    { select: (res) => res.data.result },
  );

  const {
    mutate: updateClinicalTrialEstimate,
    isLoading: updateLoading,
  } = useMutation(
    ({
      clientCompanyName,
      appendix,
      estimateItems,
    }: IClinicalTrialEstimate) => {
      if (!clinicalTrialEstimate) throw Error('Invalid clinicalTrialEstimate');
      const updatingEstimate: IClinicalTrialEstimateUpdate = {
        clinicalTrialEstimateId: clinicalTrialEstimateId,
        updateEstimateItems: [],
        updateEstimateItemOptions: [],
        deletedEstimateItemIds: [],
        itemsOfCategory: [],
        addEstimateItemEtcs: [],
      };
      if (clientCompanyName !== clinicalTrialEstimate.clientCompanyName) {
        updatingEstimate.clientCompanyName = clientCompanyName;
      }
      if (appendix !== null && appendix !== clinicalTrialEstimate.appendix) {
        updatingEstimate.appendix = appendix;
      }

      const updateEstimateItemIfChanged = ({
        clinicalTrialEstimateItemId,
        itemName,
        agencyName,
        itemUnitPrice,
        itemQuantity,
        remark,
        estimateItemOptions,
      }: {
        clinicalTrialEstimateItemId: number;
        itemName: string;
        agencyName: string;
        itemUnitPrice: number;
        itemQuantity: number;
        remark: string | null;
        estimateItemOptions: IClinicalTrialEstimateItemOption[];
      }) => {
        const originalEstimateItem = clinicalTrialEstimate.estimateItems.find(
          (estimateItem) =>
            estimateItem.clinicalTrialEstimateItemId ===
            clinicalTrialEstimateItemId,
        );
        if (!originalEstimateItem) {
          throw new Error('Invalid estimateItem');
        }
        const updatingEstimateItem = getUpdatingEstimateItem(
          { itemName, agencyName, itemUnitPrice, itemQuantity, remark },
          originalEstimateItem,
        );
        if (Object.keys(updatingEstimateItem).length > 0) {
          updatingEstimate.updateEstimateItems.push({
            clinicalTrialEstimateItemId: clinicalTrialEstimateItemId,
            ...updatingEstimateItem,
          });
        }

        for (const estimateItemOption of estimateItemOptions) {
          const originalEstimateItemOption = originalEstimateItem.estimateItemOptions.find(
            ({ clinicalTrialEstimateItemOptionId }) =>
              estimateItemOption.clinicalTrialEstimateItemOptionId ===
              clinicalTrialEstimateItemOptionId,
          );
          if (typeof originalEstimateItemOption === 'undefined') {
            throw new Error('Invalid originalEstimateItem');
          }
          const updatingEstimateItemOption = {
            ...(estimateItemOption.itemUnitPrice !==
              originalEstimateItemOption.itemUnitPrice && {
              itemUnitPrice: estimateItemOption.itemUnitPrice,
            }),
            ...(estimateItemOption.itemQuantity !==
              originalEstimateItemOption.itemQuantity && {
              itemQuantity: estimateItemOption.itemQuantity,
            }),
            ...(estimateItemOption.remark !== null &&
              estimateItemOption.remark !==
                originalEstimateItemOption.remark && {
                remark: estimateItemOption.remark,
              }),
          };
          if (Object.keys(updatingEstimateItemOption).length > 0) {
            updatingEstimate.updateEstimateItemOptions.push({
              clinicalTrialEstimateItemOptionId: estimateItemOption.clinicalTrialEstimateItemOptionId as number,
              ...updatingEstimateItemOption,
            });
          }
        }
      };
      // HINT : 추가 및 수정
      estimateItems.forEach(
        ({
          clinicalTrialEstimateItemId,
          categoryDataId,
          clinicalTrialPackageId,
          clinicalTrialProductId,
          itemType,
          itemName,
          itemUnitPrice,
          itemQuantity,
          agencyName,
          remark,
          estimateItemOptions,
        }) => {
          if (typeof clinicalTrialEstimateItemId === 'number') {
            // HINT : 기존 아이템이면 변경되었을 경우 수정
            updateEstimateItemIfChanged({
              clinicalTrialEstimateItemId,
              itemName,
              agencyName,
              itemUnitPrice,
              itemQuantity,
              remark,
              estimateItemOptions,
            });
            return;
          }
          switch (itemType) {
            case EClinicalTrialEstimateItemType.PACKAGE: {
              const targetCategory = updatingEstimate.itemsOfCategory.find(
                (category) => category.categoryDataId === categoryDataId,
              );
              const newPackageItem = {
                clinicalTrialPackageId: clinicalTrialPackageId!,
                itemQuantity,
                remark,
                // HINT : 견적에 새로운 패키지 아이템이 추가될 경우 연결되어있는 할인 아이템의 remark도 함께 보냄
                discountRemark: estimateItems.find(
                  (estimateItem) =>
                    estimateItem.categoryDataId === categoryDataId &&
                    estimateItem.clinicalTrialPackageId ===
                      clinicalTrialPackageId &&
                    estimateItem.itemType ===
                      EClinicalTrialEstimateItemType.DISCOUNT,
                )!.remark,
                clinicalTrialProducts: estimateItemOptions.reduce(
                  (
                    acc,
                    {
                      clinicalTrialProductOptionId,
                      clinicalTrialProductId,
                      itemUnitPrice,
                      itemQuantity,
                      remark,
                    },
                  ) => {
                    const targetProduct = acc.find(
                      (product) =>
                        product.clinicalTrialProductId ===
                        clinicalTrialProductId,
                    );
                    if (targetProduct) {
                      targetProduct.optionItems.push({
                        clinicalTrialProductOptionId,
                        itemUnitPrice,
                        itemQuantity,
                        remark,
                      });
                    } else {
                      acc.push({
                        clinicalTrialProductId: clinicalTrialProductId!,
                        optionItems: [
                          {
                            clinicalTrialProductOptionId,
                            itemUnitPrice,
                            itemQuantity,
                            remark,
                          },
                        ],
                      });
                    }
                    return acc;
                  },
                  [] as IClinicalTrialEstimatePackageItem['clinicalTrialProducts'],
                ),
              };
              if (targetCategory) {
                targetCategory.packageItems.push(newPackageItem);
              } else {
                updatingEstimate.itemsOfCategory.push({
                  categoryDataId,
                  packageItems: [newPackageItem],
                  productItems: [],
                });
              }
              return;
            }
            case EClinicalTrialEstimateItemType.PRODUCT: {
              const targetCategory = updatingEstimate.itemsOfCategory.find(
                (category) => category.categoryDataId === categoryDataId,
              );
              const newProductItem = {
                clinicalTrialProductId: clinicalTrialProductId!,
                itemQuantity,
                remark,
                optionItems: estimateItemOptions.map(
                  ({
                    clinicalTrialProductOptionId,
                    itemUnitPrice,
                    itemQuantity,
                    remark,
                  }) => ({
                    clinicalTrialProductOptionId,
                    itemUnitPrice,
                    itemQuantity,
                    remark,
                  }),
                ),
              };
              if (targetCategory) {
                targetCategory.productItems.push(newProductItem);
              } else {
                updatingEstimate.itemsOfCategory.push({
                  categoryDataId,
                  packageItems: [],
                  productItems: [newProductItem],
                });
              }
              return;
            }
            case EClinicalTrialEstimateItemType.ETC: {
              updatingEstimate.addEstimateItemEtcs.push({
                categoryDataId,
                itemName,
                agencyName,
                itemUnitPrice,
                itemQuantity,
                remark,
              });
            }
          }
        },
      );

      // HINT : 삭제
      const estimateItemIds = estimateItems.map(
        ({ clinicalTrialEstimateItemId }) => clinicalTrialEstimateItemId,
      );

      clinicalTrialEstimate.estimateItems.forEach(
        ({ clinicalTrialEstimateItemId }) => {
          if (!estimateItemIds.includes(clinicalTrialEstimateItemId)) {
            updatingEstimate.deletedEstimateItemIds.push(
              clinicalTrialEstimateItemId as number,
            );
          }
        },
      );

      if (
        typeof updatingEstimate.clientCompanyName === 'undefined' &&
        typeof updatingEstimate.appendix === 'undefined' &&
        updatingEstimate.updateEstimateItems.length === 0 &&
        updatingEstimate.updateEstimateItemOptions.length === 0 &&
        updatingEstimate.deletedEstimateItemIds.length === 0 &&
        updatingEstimate.itemsOfCategory.length === 0 &&
        updatingEstimate.addEstimateItemEtcs.length === 0
      ) {
        throw new Error(consts.message.NO_NEED_TO_UPDATE);
      }
      return clinicalTrialApi.updateClinicalTrialEstimate(updatingEstimate);
    },
  );

  return useMemo(
    () => ({
      clinicalTrialEstimate,
      updateClinicalTrialEstimate,
      updateLoading,
    }),
    [clinicalTrialEstimate, updateClinicalTrialEstimate, updateLoading],
  );
};
