import {
  DeleteOutlined,
  MinusCircleOutlined,
  PaperClipOutlined,
  PlusOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Typography,
} from 'antd';
import Editor from 'components/Editor';
import FooterBox from 'components/FooterBox';
import { useUpdateFaq } from 'hooks/service';
import consts from 'lib/consts';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IFaq } from 'types/service';

interface IFaqEditModalProps {
  faq: IFaq;
  onClose: (isModified: boolean) => void;
}
const StyledDeleteOutlined = styled(DeleteOutlined)`
  &:hover {
    color: ${palette.error[0]};
  }
`;

const FaqEditModal: React.FC<IFaqEditModalProps> = ({ faq, onClose }) => {
  const [form] = Form.useForm();
  const [content, setContent] = useState(faq.content);
  const [contentValidMap, setContentValidMap] = useState<{
    validateStatus:
      | ''
      | 'error'
      | 'success'
      | 'warning'
      | 'validating'
      | undefined;
    help: string;
  }>({
    validateStatus: '',
    help: '',
  });
  const { faqAttaches } = faq;
  const [attachIdsToBeDeleted, setAttachIdsToBeDeleted] = useState<number[]>(
    [],
  );
  const { mutate: updateFaqMutate, isLoading } = useUpdateFaq({
    onSuccess: () => {
      message.info('수정되었습니다.');
      onClose(true);
    },
  });

  const editFaq = ({
    title,
    isLive,
    files,
  }: {
    title: string;
    isLive: boolean;
    files: FileList[];
  }) => {
    if (contentValidMap.validateStatus !== '') return;
    updateFaqMutate({
      faqId: faq.faqId,
      title,
      isLive,
      content,
      ...(files &&
        files.length && {
          files: files
            .filter((fileList) => fileList instanceof FileList)
            .map((fileList) => fileList[0]),
        }),
      attachIdsToBeDeleted,
    });
  };
  const changeContent = (content: string) => {
    setContent(content);
    setContentValidMap((draft) =>
      content.replace(/<[^>]*>/g, '') === ''
        ? {
            validateStatus: 'error' as const,
            help: consts.message.REQUIRED_FIELD,
          }
        : draft.validateStatus !== ''
        ? {
            validateStatus: '',
            help: '',
          }
        : draft,
    );
    if (content.replace(/<[^>]*>/g, '') === '') {
      setContentValidMap({
        validateStatus: 'error' as const,
        help: consts.message.REQUIRED_FIELD,
      });
    } else if (contentValidMap.help !== '') {
      setContentValidMap({
        validateStatus: '',
        help: '',
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      title: faq.title,
      isLive: faq.isLive,
    });
  }, [faq, form]);
  return (
    <Modal
      title="FAQ 상세"
      visible={true}
      width={800}
      footer={null}
      onCancel={() => onClose(false)}
    >
      <Form form={form} onFinish={editFaq} colon={false} labelCol={{ span: 4 }}>
        <Form.Item
          label="제목"
          name="title"
          rules={[{ required: true, message: consts.message.REQUIRED_FIELD }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="내용"
          {...contentValidMap}
          style={{ marginBottom: 0 }}
        >
          <Editor value={content} setValue={changeContent} />
        </Form.Item>
        <Form.Item label="공개 여부" name="isLive">
          <Radio.Group
            options={[
              { value: true, label: '공개' },
              { value: false, label: '비공개' },
            ]}
          />
        </Form.Item>
        <Form.Item label="첨부파일">
          {faqAttaches.length > 0 && (
            <Form.Item>
              {faqAttaches.map(({ faqAttachId, attachUrl, filename }) => {
                const isToBeDeleted = attachIdsToBeDeleted.includes(
                  faqAttachId,
                );
                return (
                  <Row align="middle" key={faqAttachId}>
                    <Col>
                      <Button
                        key={faqAttachId}
                        type="link"
                        icon={<PaperClipOutlined style={{ color: '#333' }} />}
                        style={{ paddingLeft: 0 }}
                        onClick={() => downloadFile(attachUrl, filename)}
                      >
                        <Typography.Text
                          style={
                            isToBeDeleted
                              ? {
                                  color: '#aaa',
                                  textDecoration: 'line-through',
                                }
                              : {
                                  color: 'inherit',
                                }
                          }
                        >
                          {filename}
                        </Typography.Text>
                      </Button>
                    </Col>
                    <Col>
                      {!isToBeDeleted ? (
                        <StyledDeleteOutlined
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            setAttachIdsToBeDeleted((draft) =>
                              draft.concat(faqAttachId),
                            )
                          }
                        />
                      ) : (
                        <RollbackOutlined
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            setAttachIdsToBeDeleted((draft) =>
                              draft.filter((item) => item !== faqAttachId),
                            )
                          }
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Form.Item>
          )}
          <Form.List name="files" initialValue={[]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item key={field.key}>
                    <Form.Item
                      noStyle
                      {...field}
                      name={[field.name]}
                      style={{ marginBottom: 8 }}
                      valuePropName="files"
                    >
                      <Input
                        type="file"
                        style={{
                          display: 'inline-block',
                          width: 'auto',
                          border: 'none',
                          padding: 0,
                        }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Form.Item>
                ))}
                <Button
                  type="dashed"
                  onClick={add}
                  style={{ width: 320 }}
                  icon={<PlusOutlined />}
                >
                  추가 업로드
                </Button>
              </>
            )}
          </Form.List>
        </Form.Item>
        <FooterBox>
          <Button loading={isLoading} type="primary" htmlType="submit">
            수정하기
          </Button>
        </FooterBox>
      </Form>
    </Modal>
  );
};

export default FaqEditModal;
