import { Modal, Table } from 'antd';
import { nanoid } from 'nanoid';

import { Typography } from 'components/system';
import { IBuyerCertificateStat } from 'types/stats';
import { ColumnsType } from 'antd/lib/table';

interface IProps {
  visible: boolean;
  data?: IBuyerCertificateStat[];
  onClose: () => void;
}

const BuyerDetailModal = ({ visible, data, onClose }: IProps) => {
  const columns: ColumnsType<IBuyerCertificateStat> = [
    {
      title: '구분',
      dataIndex: 'brandCompanyId',
      align: 'center' as const,
      render: (_, __, index) => `${index + 1}`,
    },
    {
      title: '브랜드사',
      dataIndex: 'companyNameKo',
      align: 'center' as const,
    },
    {
      title: '인증 완료 수',
      dataIndex: 'countCertFin',
      align: 'center' as const,
    },
  ];

  return (
    <Modal visible={visible} footer={null} width={800} onCancel={onClose}>
      <Typography.Text>진행 브랜드사</Typography.Text>
      <Table columns={columns} dataSource={data} rowKey={() => nanoid()} />
    </Modal>
  );
};

export default BuyerDetailModal;
