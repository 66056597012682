import styled from 'styled-components';

import ClinicalTrialSelect from 'components/clinicalTrial/estimate/clinicalTrialSelect/ClinicalTrialSelect';

const ClinicalTrialSelectPageContainer = styled.div``;

const ClinicalTrialSelectPage = () => {
  return (
    <ClinicalTrialSelectPageContainer>
      <ClinicalTrialSelect />
    </ClinicalTrialSelectPageContainer>
  );
};

export default ClinicalTrialSelectPage;
