import { makeAutoObservable } from 'mobx';

import { IUser } from 'types/auth';
import RootStore from 'stores/RootStore';
export default class AuthStore {
  rootStore;
  private _user: IUser | null;

  logout() {
    this._user = null;
  }

  get isLoggedIn() {
    return this._user !== null;
  }

  get user() {
    if (this._user === null) throw Error('Invalid User');
    return this._user;
  }

  set user(user: IUser) {
    this._user = user;
  }

  constructor(root: RootStore) {
    this.rootStore = root;
    this._user = null;
    makeAutoObservable(this);
  }
}
