import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { BellOutlined, ExportOutlined } from '@ant-design/icons';
import { Button, Layout, Space, Tag, Typography, Badge } from 'antd';

import { clearToken } from 'lib/api/client';
import { useStores } from 'stores/Context';
import { EAuthority } from 'types/auth';
import NotificationDrawer from 'components/NotificationDrawer';

const HomeHeaderBlock = styled(Layout.Header)`
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 16px;
  background-color: #fff;
  justify-content: space-between;
  height: 48px;
  z-index: 200;
`;

const Logo = () => {
  return (
    <Space style={{ alignItems: 'center' }}>
      <Link to="/">
        <img
          src="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/logo.png"
          alt=""
          style={{ width: 90 }}
        />
      </Link>
      <Typography.Text
        style={{
          position: 'relative',
          paddingLeft: 16,
          fontSize: 16,
          borderLeft: '1px solid #333',
          marginLeft: 12,
        }}
        strong
      >
        관리자 페이지
      </Typography.Text>
    </Space>
  );
};
const HomeHeader = () => {
  const { authStore, notificationStore } = useStores();
  const handleLogout = useCallback(() => {
    clearToken();
  }, []);
  return (
    <>
      <NotificationDrawer />
      <HomeHeaderBlock>
        <Logo />
        <Space>
          <Badge
            dot={notificationStore.hasNewNotification}
            style={{ top: -2, right: 8 }}
          >
            <BellOutlined
              style={{ cursor: 'pointer', fontSize: 16, marginRight: 8 }}
              onClick={() => {
                notificationStore.setNotificationDrawerVisible(true);
              }}
            />
          </Badge>
          <Typography.Text>
            <Tag style={{ marginRight: 0 }}>
              {authStore.user.authority === EAuthority.SUPER_ADMIN
                ? '슈퍼 관리자'
                : '관리자'}
            </Tag>
          </Typography.Text>
          <Button
            type="link"
            icon={<ExportOutlined />}
            style={{ color: '#333' }}
            onClick={handleLogout}
          >
            로그아웃
          </Button>
        </Space>
      </HomeHeaderBlock>
    </>
  );
};

export default observer(HomeHeader);
