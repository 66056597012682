import { useState } from 'react';
import { CommentOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import EtcModal from './modal/EtcModal';
import {
  EClinicalTrialProcessItemStatus,
  IClinicalTrialProcessItem,
  IOptionItem,
} from 'types/clinicalTrial/process/processingEstimateDetail';
import {
  ProductNameModal,
  SampleReceiptModal,
  SampleDeliveryModal,
  StopReasonModal,
} from './modal/index';
import {
  useClinicalTrialProcessReports,
  useUpdateEstimateStopReason,
} from 'hooks/clinicalTrial/process/processingEstimateDetail';
import FileListModal from 'components/ui/Modal/FileListModal';
import { IFileListModalProps } from 'types/modal';

const statusSearchTypeOptions = [
  { value: 'ALL', label: '전체' },
  {
    value: EClinicalTrialProcessItemStatus.RDY,
    label: '진행전',
  },
  {
    value: EClinicalTrialProcessItemStatus.REV,
    label: '시료 수령',
  },
  {
    value: EClinicalTrialProcessItemStatus.DLV,
    label: '시료 발송',
  },
  { value: EClinicalTrialProcessItemStatus.COM, label: '완료' },
  { value: EClinicalTrialProcessItemStatus.STP, label: '중단' },
];

const StyledText = styled(Typography.Text)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled === false &&
    css`
      cursor: pointer;
      text-decoration: underline;
    `}
`;

const TableContainer = styled.div`
  tbody > tr {
    background-color: #fff;
  }
`;

const ClinicalTrialProcessItemTable = ({
  categoryName,
  categoryDataId,
  clinicalTrialEstimateId,
  clinicalTrialProcessItems,
}: {
  categoryName: string;
  categoryDataId: number;
  clinicalTrialEstimateId: number;
  clinicalTrialProcessItems: IClinicalTrialProcessItem[];
}) => {
  const [productNameModalProps, setProductNameModalProps] = useState<{
    productName: string | null;
    clinicalTrialProcessItemId: number;
  } | null>(null);
  const [sampleReceiptModalProps, setSampleReceiptModalProps] = useState<{
    sampleReceiptDate: string | null;
    clinicalTrialProcessItemId: number;
  } | null>(null);
  const [sampleDeliverytModalProps, setSampleDeliverytModalProps] = useState<{
    sampleDeliveryDate: string | null;
    completionDueDate: string | null;
    clinicalTrialProcessItemId: number;
  } | null>(null);
  const [stopReasonModalProps, setStopReasonModalProps] = useState<{
    stopReason: string | null;
    clinicalTrialProcessItemId: number;
  } | null>(null);
  const [
    updateEstimateStopReasonModalVisible,
    setUpdateEstimateStopReasonModalVisible,
  ] = useState(false);

  const getLabelType = (status: EClinicalTrialProcessItemStatus) => {
    switch (status) {
      case EClinicalTrialProcessItemStatus.RDY:
      case EClinicalTrialProcessItemStatus.DLV:
      case EClinicalTrialProcessItemStatus.REV:
        return 'primary';
      case EClinicalTrialProcessItemStatus.COM:
        return 'success';
      case EClinicalTrialProcessItemStatus.STP:
        return 'disabled';
    }
  };

  const renderReportColumn = (optionItems: IOptionItem[]) => {
    if (optionItems.length > 0) {
      const optionNames = optionItems.map(
        (optionsItem) => optionsItem.clinicalTrialItemOptionName,
      );
      const isReportEn = optionNames.includes('영문 성적서');
      const isReportCn = optionNames.includes('중문 성적서');

      if (isReportEn && isReportCn) return '영문 / 중문';
      if (isReportEn) return '영문';
      if (isReportCn) return '중문';
    }
    return '-';
  };

  const [
    fileListModalProps,
    setFileListModalProps,
  ] = useState<IFileListModalProps | null>(null);
  const { getClinicalTrialProcessReports } = useClinicalTrialProcessReports();
  const handleClickReport = (clinicalTrialProcessItemId: number) => {
    getClinicalTrialProcessReports(clinicalTrialProcessItemId, {
      onSuccess: (res) =>
        setFileListModalProps({
          title: '시험 레포트',
          files: res.data.result.map(({ filename, attachUrl }) => ({
            name: filename,
            url: attachUrl,
          })),
          onClose: () => setFileListModalProps(null),
        }),
    });
  };

  const renderCompletionDueDDayColumn = (
    completionDueDDay: number | null,
    status: EClinicalTrialProcessItemStatus,
    clinicalTrialProcessItemId: number,
  ) => {
    if (status === EClinicalTrialProcessItemStatus.STP) {
      return (
        <Typography.Text type="secondary" color="disabled">
          중단
        </Typography.Text>
      );
    }
    if (status === EClinicalTrialProcessItemStatus.COM) {
      return (
        <Row gutter={4} justify="center">
          <Col>
            <Typography.Text type="secondary" color="primary">
              시험 완료
            </Typography.Text>
          </Col>
          <Col>
            <FileSearchOutlined
              style={{ cursor: 'pointer', fontSize: 16 }}
              onClick={() => handleClickReport(clinicalTrialProcessItemId)}
            />
          </Col>
        </Row>
      );
    }
    return typeof completionDueDDay === 'number' ? (
      <Typography.Text
        type="secondary"
        color={completionDueDDay > 7 ? 'black' : 'error'}
      >
        {completionDueDDay === 0
          ? 'D-DAY'
          : `D${
              completionDueDDay > 0
                ? `-${completionDueDDay}`
                : `+${-completionDueDDay}`
            }`}
      </Typography.Text>
    ) : (
      '-'
    );
  };

  const columns: ColumnsType<IClinicalTrialProcessItem> = [
    {
      title: '상태',
      width: 120,
      align: 'center',
      render: (
        _,
        { status, stopReason, clinicalTrialProcessItemId, stopDate },
      ) => (
        <>
          <Typography.Label type={getLabelType(status)}>
            {statusSearchTypeOptions.find(({ value }) => value === status)
              ?.label || '-'}
          </Typography.Label>
          {status === EClinicalTrialProcessItemStatus.STP && (
            <Row gutter={4} align="middle" style={{ marginTop: 4 }}>
              <Col>
                <Typography.Text type="secondary" color="disabled">
                  {stopDate}
                </Typography.Text>
              </Col>
              <Col>
                <CommentOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setStopReasonModalProps({
                      stopReason,
                      clinicalTrialProcessItemId,
                    })
                  }
                />
              </Col>
            </Row>
          )}
        </>
      ),
    },
    {
      title: '시험 항목',
      width: 240,
      dataIndex: 'clinicalTrialItemName',
      align: 'center',
      render: (clinicalTrialItemName, { status }) => (
        <Typography.Text
          color={
            status === EClinicalTrialProcessItemStatus.STP
              ? 'disabled'
              : undefined
          }
        >
          {clinicalTrialItemName}
        </Typography.Text>
      ),
    },

    {
      title: '성적서',
      align: 'center',
      render: (_, { optionItems, status }) => (
        <Typography.Text
          color={
            status === EClinicalTrialProcessItemStatus.STP
              ? 'disabled'
              : undefined
          }
        >
          {renderReportColumn(optionItems)}
        </Typography.Text>
      ),
    },
    {
      title: '시험 기관',
      align: 'center',
      dataIndex: 'agencyName',
      render: (agencyName, { status }) => (
        <Typography.Text
          color={
            status === EClinicalTrialProcessItemStatus.STP
              ? 'disabled'
              : undefined
          }
        >
          {agencyName}
        </Typography.Text>
      ),
    },
    {
      title: (
        <Typography.Text asterisk type="secondary" medium>
          제품명
        </Typography.Text>
      ),
      align: 'center',
      dataIndex: 'productName',
      render: (_, { status, productName, clinicalTrialProcessItemId }) =>
        productName ? (
          <StyledText
            color={
              status === EClinicalTrialProcessItemStatus.STP
                ? 'disabled'
                : undefined
            }
            disabled={status === EClinicalTrialProcessItemStatus.STP}
            onClick={() =>
              status !== EClinicalTrialProcessItemStatus.STP &&
              setProductNameModalProps({
                productName,
                clinicalTrialProcessItemId,
              })
            }
          >
            {productName}
          </StyledText>
        ) : (
          <Button
            disabled={status === EClinicalTrialProcessItemStatus.STP}
            onClick={() =>
              setProductNameModalProps({
                productName,
                clinicalTrialProcessItemId,
              })
            }
          >
            입력
          </Button>
        ),
    },
    {
      title: (
        <Typography.Text asterisk type="secondary" medium>
          시료 수령일
        </Typography.Text>
      ),
      align: 'center',
      dataIndex: 'sampleReceiptDate',
      render: (
        _,
        { sampleReceiptDate, productName, clinicalTrialProcessItemId, status },
      ) =>
        productName ? (
          sampleReceiptDate ? (
            <StyledText
              color={
                status === EClinicalTrialProcessItemStatus.STP
                  ? 'disabled'
                  : undefined
              }
              disabled={status === EClinicalTrialProcessItemStatus.STP}
              onClick={() =>
                status !== EClinicalTrialProcessItemStatus.STP &&
                setSampleReceiptModalProps({
                  clinicalTrialProcessItemId,
                  sampleReceiptDate: sampleReceiptDate,
                })
              }
            >
              {sampleReceiptDate}
            </StyledText>
          ) : (
            <Button
              disabled={status === EClinicalTrialProcessItemStatus.STP}
              onClick={() =>
                setSampleReceiptModalProps({
                  clinicalTrialProcessItemId,
                  sampleReceiptDate: sampleReceiptDate,
                })
              }
            >
              입력
            </Button>
          )
        ) : (
          '-'
        ),
    },
    {
      title: (
        <Typography.Text asterisk type="secondary" medium>
          시료 발송일
        </Typography.Text>
      ),
      align: 'center',
      dataIndex: 'sampleDeliveryDate',
      render: (
        _,
        {
          sampleReceiptDate,
          sampleDeliveryDate,
          completionDueDate,
          clinicalTrialProcessItemId,
          status,
        },
      ) =>
        sampleReceiptDate ? (
          sampleDeliveryDate ? (
            <StyledText
              color={
                status === EClinicalTrialProcessItemStatus.STP
                  ? 'disabled'
                  : undefined
              }
              disabled={status === EClinicalTrialProcessItemStatus.STP}
              onClick={() =>
                status !== EClinicalTrialProcessItemStatus.STP &&
                setSampleDeliverytModalProps({
                  sampleDeliveryDate: sampleDeliveryDate,
                  clinicalTrialProcessItemId,
                  completionDueDate,
                })
              }
            >
              {sampleDeliveryDate}
            </StyledText>
          ) : (
            <Button
              disabled={status === EClinicalTrialProcessItemStatus.STP}
              onClick={() =>
                setSampleDeliverytModalProps({
                  sampleDeliveryDate: sampleDeliveryDate,
                  clinicalTrialProcessItemId,
                  completionDueDate,
                })
              }
            >
              입력
            </Button>
          )
        ) : (
          '-'
        ),
    },
    {
      title: '시험 완료 예정일',
      align: 'center',
      dataIndex: 'completionDueDDay',
      render: (_, { completionDueDDay, status, clinicalTrialProcessItemId }) =>
        renderCompletionDueDDayColumn(
          completionDueDDay,
          status,
          clinicalTrialProcessItemId,
        ),
    },
    {
      title: '시험 완료일',
      align: 'center',
      dataIndex: 'completionDate',
      render: (completionDueDate, { status }) =>
        status !== EClinicalTrialProcessItemStatus.STP ? (
          <Typography.Text type="secondary">
            {completionDueDate || '-'}
          </Typography.Text>
        ) : (
          <Typography.Text type="secondary" color="disabled">
            중단
          </Typography.Text>
        ),
    },
    {
      align: 'center',
      width: 60,
      render: (
        _,
        { status, stopReason, clinicalTrialProcessItemId, remark },
      ) => (
        <EtcModal
          clinicalTrialProcessItemId={clinicalTrialProcessItemId}
          status={status}
          stopReason={stopReason}
          remark={remark}
        />
      ),
    },
  ];

  const { updateEstimateStopReason } = useUpdateEstimateStopReason();

  return (
    <>
      {productNameModalProps && (
        <ProductNameModal
          {...productNameModalProps}
          onClose={() => setProductNameModalProps(null)}
        />
      )}
      {sampleReceiptModalProps && (
        <SampleReceiptModal
          {...sampleReceiptModalProps}
          onClose={() => setSampleReceiptModalProps(null)}
        />
      )}
      {sampleDeliverytModalProps && (
        <SampleDeliveryModal
          {...sampleDeliverytModalProps}
          onClose={() => setSampleDeliverytModalProps(null)}
        />
      )}
      {stopReasonModalProps && (
        <StopReasonModal
          {...stopReasonModalProps}
          onClose={() => setStopReasonModalProps(null)}
        />
      )}
      {updateEstimateStopReasonModalVisible && (
        <Modal
          title="중단"
          visible
          onOk={() => {
            updateEstimateStopReason(
              { clinicalTrialEstimateId, categoryDataId },
              {
                onSuccess: () => setUpdateEstimateStopReasonModalVisible(false),
              },
            );
          }}
          onCancel={() => setUpdateEstimateStopReasonModalVisible(false)}
          okText="중단"
        >
          모든 항목을 중단처리하시겠습니까?
        </Modal>
      )}
      {fileListModalProps && <FileListModal {...fileListModalProps} />}
      <Row justify="space-between" align="bottom">
        <Col>
          <Typography.Text type="secondary" medium>
            {categoryName}
          </Typography.Text>
        </Col>
        <Col>
          <Button
            disabled={clinicalTrialProcessItems.every(
              (item) =>
                item.status === EClinicalTrialProcessItemStatus.COM ||
                item.status === EClinicalTrialProcessItemStatus.STP,
            )}
            style={{ width: 92 }}
            onClick={() => setUpdateEstimateStopReasonModalVisible(true)}
          >
            중단
          </Button>
        </Col>
      </Row>
      <TableContainer>
        <Table
          bordered
          style={{ margin: '12px 0 24px' }}
          columns={columns}
          dataSource={clinicalTrialProcessItems}
          rowKey="clinicalTrialProcessItemId"
          pagination={false}
        />
      </TableContainer>
    </>
  );
};

export default ClinicalTrialProcessItemTable;
