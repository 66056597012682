import { CaretDownOutlined, CheckOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { EOrderType } from 'types/clinicalTrial';
import palette from 'lib/styles/palette';

const orderTypeMap: { [key in keyof typeof EOrderType]: string } = {
  [EOrderType.RECOMMEND]: '추천순',
  [EOrderType.LOWER_PRICE]: '낮은 가격순',
  [EOrderType.SHORT_DURATION]: '짧은 기간순',
  [EOrderType.AGENCY_NAME]: '시험 기관명순',
  [EOrderType.COUNTRY_NAME]: '국가순',
};

const OrderSelectContainer = styled.div`
  position: relative;
`;

const OrderOptionListContainer = styled.div`
  position: absolute;
  top: 29px;
  right: 0;
  width: 120px;
  z-index: 1;
  border-radius: 8px;
  overflow: hidden;
`;

const OrderOptionContainer = styled.div`
  position: relative;
  background-color: ${palette.gray90};
  padding: 6px 24px;
  color: #fff;
  cursor: pointer;

  & + & {
    border-top: 1px solid #7d7d7d;
  }

  .anticon {
    position: absolute;
    top: 9px;
    left: 8px;
  }
`;

const OrderTypeSelect = ({
  selectedType,
  orderTypes,
  onSelect,
}: {
  selectedType: EOrderType;
  orderTypes: EOrderType[];
  onSelect: (type: EOrderType) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSelect = (orderType: EOrderType) => {
    setIsOpen(false);
    onSelect(orderType);
  };
  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = () => {
        setIsOpen(false);
      };
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }
  }, [isOpen]);
  return (
    <OrderSelectContainer onClick={() => setIsOpen((draft) => !draft)}>
      <Typography.Text type="secondary" bold color="darkNavy">
        {orderTypeMap[selectedType]}{' '}
        <CaretDownOutlined style={{ marginLeft: 2 }} />
      </Typography.Text>
      {isOpen && (
        <OrderOptionListContainer>
          {orderTypes.map((orderType) => (
            <OrderOptionContainer
              key={orderType}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                handleSelect(orderType);
              }}
            >
              {selectedType === orderType && (
                <CheckOutlined style={{ fontSize: 10 }} />
              )}
              <Typography.Text type="tertiary">
                {orderTypeMap[orderType]}
              </Typography.Text>
            </OrderOptionContainer>
          ))}
        </OrderOptionListContainer>
      )}
    </OrderSelectContainer>
  );
};

export default OrderTypeSelect;
