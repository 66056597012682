import { ICompany } from './company';
import { IProductDetail } from './product';

export interface IEstimate {
  estimateId: number;
  companyId: number;
  company: ICompany;
  postAddress: string;
  seqNo: number;
  estimateCode: string;
  totalPrice: number;
  paidAmount: number;
  status: string;
  statusString: null;
  fileUrl: string;
  registerDt: string;
  estimateAdditionals: IEstimateAdditional[] | null;
  isDepositFinish: boolean;
  isReceiveContractFinish: boolean;
}

export enum EEstimateStatus {
  ISS_FIN = 'ISS-FIN',
  DEP_RDY = 'DEP-RDY',
  DEP_FIN = 'DEP-FIN',
  DEP_PAR = 'DEP-PAR',
  CON_RDY = 'CON-RDY',
  CON_FIN = 'CON-FIN',
}

export interface IEstimateAdditional {
  estimateAdditionalId: number;
  certTargetId: number;
  certRequestNo: string;
  productNameEn: string;
  countryNameKo: string;
  seqNo: number;
  estimateAdditionalCode: string;
  itemName: string;
  unitPrice: number;
  quantity: number;
  totalPrice: number;
  description: string;
  status: string;
  statusString: string;
  lastStatusUpdateDt: null;
  fileUrl: string;
  registerDt: string;
}

export enum EEstimateAdditionalStatus {
  DEP_RDY = 'DEP-RDY',
  DEP_FIN = 'DEP-FIN',
}

export interface IEstimateTarget {
  estimateTargetId: number;
  companyId: number;
  productId: number;
  netWeight: number;
  netWeightUnit: string;
  countryId: number;
  countryName: string;
  status: string;
  statusString: string;
  estimateId: null;
  productDetails: IProductDetail[];
}

export enum EEstimateTargetStatus {
  ONG = 'ONG',
  INI = 'INI',
  REG = 'REG',
  ERR = 'ERR',
}

export interface IEstimateAdditionalForm {
  itemName: string;
  unitPrice: number;
  quantity: number;
  description: string;
}

export enum EEstimateSearchType {
  COMPANY_NAME_KO = 'companyNameKo',
  COMPANY_NAME_EN = 'companyNameEn',
  ESTIMATE_CODE = 'estimateCode',
}

export interface IEstimateCountryItem {
  estimateCountryItemId: number | string;
  itemName: string;
  unitPrice: number;
  quantity: number;
  description: string;
}
export interface IEstimateCountry {
  estimateCountryId: number;
  estimateId: number;
  countryNameKo: string;
  estimateCountryItems: IEstimateCountryItem[];
}

export interface IEstimateHistory {
  no: number;
  estimateHistoryId: number;
  estimateId: number;
  estimateCode: string;
  totalPrice: number;
  fileUrl: string;
  registerDt: string;
}

export interface IEstimateDepositHistory {
  paidAmount: number;
  depositDate: string;
  adminUsername: number;
  registerDt: string;
}

export interface IEstimateDeposit {
  estimateId: number;
  depositDate: string;
  paidAmount: number;
  status: EEstimateStatus;
}
