import { useCallback, useMemo, useState } from 'react';
import { QueryClientProvider, useQueryClient, useMutation } from 'react-query';
import { SyncOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Table,
  Tooltip,
  Typography,
} from 'antd';

import {
  ECertificateInfoStatus,
  EDocumentCode,
  ICertificate,
  ICertificateInfo,
} from 'types/certificate';
import * as certificateApi from 'lib/api/certificate';
import palette from 'lib/styles/palette';
import { useCertificateInfos } from 'hooks/certificate';
import ProductNameModalContent from 'components/product/ProductNameModalContent';
import ProductDetailModalContent from 'components/product/ProductDetailModalContent';
import RequestImproveModal from 'components/certificate/RequestImproveModal';
import ProductInternalResponsibleCompanyModalContent from 'components/product/ProductInternalResponsibleCompanyModalContent';
import ProductStabilityTestModalContent from 'components/product/ProductStabilityTestModalContent';
import ProductChallengeTestModalContent from 'components/product/ProductChallengeTestModalContent';
import ProductPackingAttestationModalContent from 'components/product/ProductPackingAttestationModalContent';
import BuyerDetailModalContent from 'components/company/BuyerDetailModalContent';
import CorrectMessageModal from 'components/certificate/CorrectMessageModal';
import ProductPhysicalPropertyModalContent from 'components/product/ProductPhysicalPropertyModalContent';
import ProductShelfLifeModalContent from 'components/product/ProductShelfLifeModalContent';
import SeriesProductInfoModalContent from './modal/SeriesProductInfoModalContent';
import CountryVendorModalContent from './modal/CountryVendorModalContent';
import KeepWarningModalContent from './modal/KeepWarningModalContent';
import SeparateWasteModalContent from './modal/SeparateWasteModalContent';
import ProductCategoryModalContent from 'components/product/ProductCategoryModalContent';
import ProductSaleInfoModalContent from 'components/product/ProductSaleInfoModalContent';
import ProductVCRPAccountModalContent from 'components/product/ProductVCRPAccountModalContent';
import ProductVCRPAccountRequestModalContent from 'components/product/ProductVCRPAccountRequestModalContent';

const viewableDocumentCodes: EDocumentCode[] = [
  EDocumentCode.PN,
  EDocumentCode.CI,
  EDocumentCode.BI,
  EDocumentCode.PHY,
  EDocumentCode.STAB,
  EDocumentCode.CHAL,
  EDocumentCode.PACK,
  EDocumentCode.IRC,
  EDocumentCode.SLD,
  EDocumentCode.SPI,
  EDocumentCode.CV,
  EDocumentCode.KW,
  EDocumentCode.CAT,
  EDocumentCode.UPSI,
  EDocumentCode.VCRPAR,
  EDocumentCode.VCRPAC,
];
const CertificateInfoList = ({
  certificate,
}: {
  certificate: ICertificate;
}) => {
  const {
    certTargetId,
    estimateTarget: { productId, countryId, netWeight, netWeightUnit },
    estimate: { company },
  } = certificate;
  const queryClient = useQueryClient();
  const { data: certificateInfos = [], refetch } = useCertificateInfos(
    certTargetId,
  );
  const [displayedCertificateInfos, hidedCertificateInfos] = useMemo(
    () =>
      certificateInfos.reduce<[ICertificateInfo[], ICertificateInfo[]]>(
        (acc, curr) => {
          acc[curr.isDisplayed ? 0 : 1].push(curr);
          return acc;
        },
        [[], []],
      ),
    [certificateInfos],
  );

  const [
    productDocStatusIdBeImproved,
    setProductDocStatusIdBeImproved,
  ] = useState<null | number>(null);
  const [
    displayedCorrectRequestMessage,
    setDisplayedCorrectRequestMessage,
  ] = useState<string | null>(null);
  const [documentCodeForHide, setDocumentCodeForHide] = useState<
    EDocumentCode[]
  >([]);
  const [documentCodeForDisplay, setDocumentCodeForDisplay] = useState<
    EDocumentCode[]
  >([]);

  const { mutate: displayDocuments } = useMutation(
    certificateApi.displayDocuments,
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const { mutate: changeDocStatusToFinish } = useMutation(
    certificateApi.changeDocStatusToFinish,
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const handleChangeDocStatusToFinish = (productDocStatusId: number) => {
    Modal.confirm({
      icon: null,
      title: '검토 처리',
      content: '입력 정보를 검토 완료 처리하시겠습니까?',
      onOk: () => changeDocStatusToFinish(productDocStatusId),
    });
  };

  const handleHideDocuments = () => {
    if (!documentCodeForHide.length)
      return message.warn('삭제할 항목을 선택해 주세요.');
    Modal.confirm({
      title:
        '삭제하시게 되면 입력된 페이지가 유저에게서도 사라지게 됩니다. 해당 입력 정보를 유저 페이지에서 삭제하시겠습니까?',
      icon: null,
      okText: '삭제하기',
      cancelText: '아니오',
      onOk: () => {
        displayDocuments({
          certTargetId,
          documentCodes: documentCodeForHide,
          isDisplayed: false,
        });
        setDocumentCodeForHide([]);
      },
    });
  };

  const handleDisplayDocuments = () => {
    if (!documentCodeForDisplay.length)
      return message.warn('생성할 항목을 선택해 주세요.');
    Modal.confirm({
      title:
        '생성하시게 되면 새로운 입력 정보 페이지가 유저에게 보여지게 됩니다. 해당 입력 정보를 유저 페이지에 생성하시겠습니까?',
      icon: null,
      okText: '생성하기',
      cancelText: '아니오',
      onOk: () => {
        displayDocuments({
          certTargetId,
          documentCodes: documentCodeForDisplay,
          isDisplayed: true,
        });
        setDocumentCodeForDisplay([]);
      },
    });
  };

  const closeRequestImproveModal = (isSuccess: boolean) => {
    if (isSuccess) {
      refetch();
    }
    setProductDocStatusIdBeImproved(null);
  };

  const viewDocument = useCallback(
    ({ documentCode }: ICertificateInfo) => {
      switch (documentCode) {
        case EDocumentCode.PN: {
          Modal.info({
            title: '제품명 (용량)',
            width: 600,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductNameModalContent
                  productId={productId}
                  countryId={countryId}
                  netWeight={netWeight}
                  netWeightUnit={netWeightUnit}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.CI: {
          Modal.info({
            title: '회사 정보',
            width: 800,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductDetailModalContent
                  company={company}
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.BI: {
          Modal.info({
            title: '바이어/에이전시 정보',
            width: 920,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <BuyerDetailModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.CAT: {
          Modal.info({
            title: '카테고리',
            width: 600,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductCategoryModalContent productId={productId} />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.PHY: {
          Modal.info({
            title: 'Physical Properties',
            width: 600,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductPhysicalPropertyModalContent
                  productId={productId}
                  countryId={countryId}
                  netWeight={netWeight}
                  netWeightUnit={netWeightUnit}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.STAB: {
          Modal.info({
            title: 'Stability Test',
            width: 600,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductStabilityTestModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.CHAL: {
          Modal.info({
            title: 'Challenge Test',
            width: 800,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductChallengeTestModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.PACK: {
          Modal.info({
            title: 'Packing Attestation',
            width: 800,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductPackingAttestationModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
            okButtonProps: { title: '닫기' },
          });
          break;
        }
        case EDocumentCode.IRC: {
          Modal.info({
            title: '경내책임회사 정보',
            width: 800,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductInternalResponsibleCompanyModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.SLD: {
          Modal.info({
            title: 'Shelf Life Declaration',
            width: 432,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductShelfLifeModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.SPI: {
          Modal.info({
            title: '시리즈 제품 여부',
            width: 432,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <SeriesProductInfoModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.CV: {
          Modal.info({
            title: '판매처 정보',
            width: 432,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <CountryVendorModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.KW: {
          Modal.info({
            title: '사용 보관상의 주의사항',
            width: 432,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <KeepWarningModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.SWI: {
          Modal.info({
            title: '분리배출 용기 재질 표기 선택',
            width: 432,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <SeparateWasteModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.UPSI: {
          Modal.info({
            title: '미국내 제품 판매 정보',
            width: 600,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductSaleInfoModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.VCRPAR: {
          Modal.info({
            title: '보유 VCRP 계정 정보',
            width: 600,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductVCRPAccountModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
        case EDocumentCode.VCRPAC: {
          Modal.info({
            title: '신규 VCRP 계정 생성',
            width: 600,
            closable: true,
            maskClosable: true,
            content: (
              <QueryClientProvider client={queryClient}>
                <ProductVCRPAccountRequestModalContent
                  productId={productId}
                  countryId={countryId}
                />
              </QueryClientProvider>
            ),
            icon: null,
          });
          break;
        }
      }
    },
    [queryClient, productId, countryId, company],
  );

  const columns: ColumnsType<ICertificateInfo> = useMemo(
    () => [
      {
        title: 'No.',
        dataIndex: 'no',
        align: 'center',
      },
      {
        title: '입력 정보',
        align: 'center',
        render: (document) => {
          const {
            productDocStatusId,
            documentCode,
            documentName,
            status,
          } = document;

          if (
            productDocStatusId !== null &&
            status !== ECertificateInfoStatus.INP &&
            viewableDocumentCodes.includes(documentCode)
          ) {
            return (
              <Typography.Link onClick={() => viewDocument(document)}>
                {documentName}
              </Typography.Link>
            );
          }
          return documentName;
        },
      },
      {
        title: '최근 수정일',
        dataIndex: 'updateDate',
        align: 'center',
      },
      {
        title: '처리 현황',
        align: 'center',
        render: ({ status, statusString, correctRequestMessage }) => {
          return status !== ECertificateInfoStatus.MOD ? (
            <Typography.Text
              type={
                status === ECertificateInfoStatus.INP ? 'danger' : undefined
              }
            >
              {statusString}
            </Typography.Text>
          ) : (
            <Tooltip title={correctRequestMessage}>
              <Typography.Text type="danger">{statusString}</Typography.Text>
            </Tooltip>
          );
        },
      },
      {
        title: '검토 처리',
        align: 'center',
        render: ({ productDocStatusId, status }) => {
          if (status === ECertificateInfoStatus.ONG) {
            return (
              <Button
                onClick={() =>
                  handleChangeDocStatusToFinish(productDocStatusId)
                }
              >
                검토처리
              </Button>
            );
          }
          if (status === ECertificateInfoStatus.FIN) return '검토 완료';
          return null;
        },
      },
      {
        title: '보완 요청',
        align: 'center',
        render: ({
          productDocStatusId,
          status,
          correctRequestMessage,
          documentCode,
        }) => {
          if (
            status === ECertificateInfoStatus.ONG ||
            status === ECertificateInfoStatus.FIN
          ) {
            return (
              <Button
                disabled={documentCode === EDocumentCode.CAT}
                onClick={() =>
                  setProductDocStatusIdBeImproved(productDocStatusId)
                }
              >
                보완요청
              </Button>
            );
          }
          if (status === ECertificateInfoStatus.MOD) {
            return (
              <Button
                style={{
                  width: 84,
                  backgroundColor: '#f5f5f5',
                  color: palette.text.disabled,
                }}
                onClick={() =>
                  setDisplayedCorrectRequestMessage(correctRequestMessage)
                }
              >
                요청중 &gt;
              </Button>
            );
          }
          return null;
        },
      },
    ],
    [viewDocument],
  );
  const columnsForHidedDocumentsTable: ColumnsType<ICertificateInfo> = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'center',
    },
    {
      title: '입력 정보',
      align: 'center',
      render: (document) => {
        const {
          productDocStatusId,
          documentCode,
          documentName,
          status,
        } = document;
        if (
          productDocStatusId !== null &&
          status !== ECertificateInfoStatus.INP &&
          viewableDocumentCodes.includes(documentCode)
        ) {
          return (
            <Typography.Link onClick={() => viewDocument(document)}>
              {documentName}
            </Typography.Link>
          );
        }
        return documentName;
      },
    },
  ];
  return (
    <>
      <RequestImproveModal
        productDocStatusId={productDocStatusIdBeImproved}
        onClose={closeRequestImproveModal}
      />
      <CorrectMessageModal
        correctRequestMessage={displayedCorrectRequestMessage}
        onClose={() => setDisplayedCorrectRequestMessage(null)}
      />
      <Row style={{ marginBottom: 8 }} gutter={8}>
        <Col>
          <Button icon={<SyncOutlined />} onClick={() => refetch()}>
            새로고침
          </Button>
        </Col>
        <Col>
          <Button onClick={handleHideDocuments}>유저 페이지에서 삭제</Button>
        </Col>
      </Row>
      <Table
        size="small"
        columns={columns}
        dataSource={displayedCertificateInfos}
        pagination={false}
        rowKey={({ documentCode }) => documentCode}
        rowSelection={{
          selectedRowKeys: documentCodeForHide,
          onChange: (keys) => setDocumentCodeForHide(keys as EDocumentCode[]),
        }}
      />
      {!!hidedCertificateInfos.length && (
        <>
          <Row style={{ marginBottom: 8, marginTop: 64 }} gutter={8}>
            <Col>
              <Button onClick={handleDisplayDocuments}>
                유저 페이지에 생성
              </Button>
            </Col>
          </Row>
          <Table
            size="small"
            columns={columnsForHidedDocumentsTable}
            dataSource={hidedCertificateInfos}
            pagination={false}
            rowKey={({ documentCode }) => documentCode}
            rowSelection={{
              selectedRowKeys: documentCodeForDisplay,
              onChange: (keys) =>
                setDocumentCodeForDisplay(keys as EDocumentCode[]),
            }}
          />
        </>
      )}
    </>
  );
};

export default CertificateInfoList;
