import { Form, Typography } from 'antd';
import { useProductDetail } from 'hooks/product';

const ProductNameModalContent: React.FC<{
  productId: number;
  countryId: number;
  netWeight: number;
  netWeightUnit: string;
}> = ({ productId, countryId, netWeight, netWeightUnit }) => {
  const isChina = countryId === 3;
  const isJapan = countryId === 5;
  const { data: productDetail } = useProductDetail({ productId, countryId });
  const { productNameEn, countryBrandName, countryProductName } = {
    ...productDetail,
  };

  return (
    <Form labelCol={{ span: 8 }} size="small" style={{ paddingTop: 20 }}>
      {isChina && (
        <>
          <Form.Item label="중문 브랜드명">
            <Typography.Text strong>{countryBrandName || '-'}</Typography.Text>
          </Form.Item>
          <Form.Item label="영문 제품명 (용량)">
            <Typography.Text strong>{`${productNameEn} ${
              netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
            }`}</Typography.Text>
          </Form.Item>
          <Form.Item label="중문 제품명 (용량)">
            <Typography.Text strong>
              {`${countryProductName || '-'} ${
                netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
              }`}
            </Typography.Text>
          </Form.Item>
        </>
      )}
      {isJapan && (
        <>
          <Form.Item label="브랜드명 (일본어)">
            <Typography.Text strong>{countryBrandName || '-'}</Typography.Text>
          </Form.Item>
          <Form.Item label="제품명 (용량)">
            <Typography.Text strong>{`${productNameEn} ${
              netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
            }`}</Typography.Text>
          </Form.Item>
          <Form.Item label="제품명 (일본어)">
            <Typography.Text strong>
              {countryProductName || '-'}
            </Typography.Text>
          </Form.Item>
        </>
      )}
      {!isChina && !isJapan && (
        <Form.Item label="제품명 (용량)">
          <Typography.Text strong>{`${productNameEn} ${
            netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
          }`}</Typography.Text>
        </Form.Item>
      )}
    </Form>
  );
};

export default ProductNameModalContent;
