import palette from 'lib/styles/palette';

const InfoIcon = ({
  width = 24,
  height = 24,
  color = palette.primary,
  style,
  onClick,
}: {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    width={`${width}px`}
    height={`${height}px`}
    fill={color}
    style={{ cursor: onClick ? 'pointer' : 'default', ...style }}
    onClick={onClick}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
  </svg>
);

export default InfoIcon;
