import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import SearchInput from 'components/form/SearchInput';
import ClinicalTrialPackage from './ClinicalTrialPackage';
import palette from 'lib/styles/palette';
import {
  useCategoryData,
  useClinicalTrialPackages,
} from 'hooks/clinicalTrial/clinicalTrial';
import { useStores } from 'stores/Context';

const ClinicalTrialPackageSelectWrapper = styled.div`
  background-color: ${palette.bgBlue};
  height: calc(100vh - 48px - 112px);
  overflow: auto;
`;

const ClinicalTrialPackageSelectContainer = styled.div`
  max-width: 720px;
  margin: 0 auto;
  padding: 16px 0 56px;
`;

const ClinicalTrialPackageSelect = () => {
  const clinicalTrialPackages = useClinicalTrialPackages();
  const [searchKeyword, setSearchKeyword] = useState('');
  const { clinicalTrialStore } = useStores();
  const recommendedClinicalTrialPackageIds =
    useCategoryData(clinicalTrialStore.categoryDataId)
      ?.clinicalTrialPackageIds || [];
  const filteredClinicalTrialPackages = useMemo(
    () =>
      clinicalTrialPackages
        .sort((a, b) => {
          const aIsRecommended = recommendedClinicalTrialPackageIds.includes(
            a.clinicalTrialPackageId,
          );
          const bIsRecommended = recommendedClinicalTrialPackageIds.includes(
            b.clinicalTrialPackageId,
          );
          if (
            (aIsRecommended && bIsRecommended) ||
            (!aIsRecommended && !bIsRecommended)
          )
            return 0;
          if (aIsRecommended) return -1;
          return 1;
        })
        .filter(({ packageName, agency, clinicalTrialPackageProducts }) => {
          if (packageName.includes(searchKeyword)) return true;
          if (agency.agencyName.includes(searchKeyword)) return true;
          if (
            clinicalTrialPackageProducts.some((clinicalTrialPackageProducts) =>
              clinicalTrialPackageProducts.some(({ clinicalTrialName }) =>
                clinicalTrialName.includes(searchKeyword),
              ),
            )
          ) {
            return true;
          }
          return false;
        }),
    [clinicalTrialPackages, searchKeyword, recommendedClinicalTrialPackageIds],
  );
  return (
    <ClinicalTrialPackageSelectWrapper>
      <ClinicalTrialPackageSelectContainer>
        <Row justify="end">
          <Col>
            <SearchInput
              placeholder="패키지명, 시험 항목, 시험 기관 검색"
              containerStyle={{ width: 260 }}
              onSearch={setSearchKeyword}
            />
          </Col>
        </Row>
        {filteredClinicalTrialPackages.map((clinicalTrialPackage) => (
          <ClinicalTrialPackage
            key={clinicalTrialPackage.clinicalTrialPackageId}
            clinicalTrialPackage={clinicalTrialPackage}
            isRecommended={recommendedClinicalTrialPackageIds.includes(
              clinicalTrialPackage.clinicalTrialPackageId,
            )}
          />
        ))}
      </ClinicalTrialPackageSelectContainer>
    </ClinicalTrialPackageSelectWrapper>
  );
};

export default observer(ClinicalTrialPackageSelect);
