import { Form, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';

import consts from 'lib/consts';
import { useUpdateStopReason } from 'hooks/clinicalTrial/process/processingEstimateDetail';

const StopReasonModal = ({
  stopReason,
  clinicalTrialProcessItemId,
  onClose,
}: {
  stopReason: string | null;
  clinicalTrialProcessItemId: number;
  onClose: () => void;
}) => {
  const [form] = useForm<{ stopReason: string }>();
  const { updateStopReason, updateLoading } = useUpdateStopReason();
  const handleSubmit = (formValue: { stopReason: string }) => {
    const { stopReason: newStopReason } = formValue;
    if (newStopReason === stopReason) {
      message.warning(consts.message.NO_NEED_TO_UPDATE);
      return;
    } else {
      updateStopReason(
        {
          clinicalTrialProcessItemId,
          stopReason: newStopReason,
        },
        {
          onSuccess: onClose,
        },
      );
    }
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <Modal
      title="시험 중단 (사유)"
      visible
      onOk={handleOk}
      onCancel={onClose}
      confirmLoading={updateLoading}
      bodyStyle={{ paddingBottom: 0 }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="stopReason" initialValue={stopReason}>
          <TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StopReasonModal;
