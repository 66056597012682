import styled from 'styled-components';
import { Typography } from 'components/system';
import { Col, Row } from 'antd';

import CountryCertificateChart from 'components/stats/CountryCertificateChart';
import CountryCertificateTable from 'components/stats/CountryCertificateTable';
import BrandCertificateTable from 'components/stats/BrandCertificateTable';

const CertificateStatsPageBlock = styled.div``;

const CertificateStatsPage = () => {
  return (
    <CertificateStatsPageBlock>
      <Typography.Title gutter={{ bottom: 8 }}>
        인증 분석 - 국가별 전체 누적
      </Typography.Title>
      <CountryCertificateChart />
      <Row style={{ marginTop: 36 }} gutter={32}>
        <Col
          sm={{ span: 24 }}
          md={{ span: 12 }}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Typography.Title gutter={{ bottom: 8 }}>
            인증 분석 - 인증 상세 조회
          </Typography.Title>
          <CountryCertificateTable />
        </Col>
        <Col
          sm={{ span: 24 }}
          md={{ span: 12 }}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Typography.Title gutter={{ bottom: 8 }}>
            인증 분석 - 브랜드사별 조회
          </Typography.Title>
          <BrandCertificateTable />
        </Col>
      </Row>
    </CertificateStatsPageBlock>
  );
};

export default CertificateStatsPage;
