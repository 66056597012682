import { useMemo, useState } from 'react';
import { Modal, Table } from 'antd';
import { nanoid } from 'nanoid';

import { Typography } from 'components/system';
import PageSizeSelect, {
  pageSizeOptions,
} from 'components/system/data/PageSizeSelect';
import { EPeriodRangeType, IPeriodSalesStat } from 'types/stats';

interface IProps {
  visible: boolean;
  salesStats: IPeriodSalesStat[];
  periodType: EPeriodRangeType;
  onClose: () => void;
}

const SalesDetailModal = ({
  visible,
  salesStats,
  periodType,
  onClose,
}: IProps) => {
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const reversed = JSON.parse(JSON.stringify(salesStats));
  const columns = useMemo(
    () => [
      {
        title: '일자',
        dataIndex:
          periodType === EPeriodRangeType.MONTH ? 'targetMonth' : 'targetDate',
        align: 'center' as const,
      },
      {
        title: '매출액',
        dataIndex: 'sumSales',
        align: 'center' as const,
        render: (sumSales: number) => `${sumSales.toLocaleString()} 원`,
      },
      {
        title: '인증 완료 수',
        dataIndex: 'countCertFin',
        align: 'center' as const,
      },
    ],
    [periodType],
  );
  return (
    <Modal visible={visible} footer={null} width={800} onCancel={onClose}>
      <Typography.Title gutter={{ bottom: 24 }}>상세조회</Typography.Title>
      <PageSizeSelect pageSize={pageSize} onChange={setPageSize} />
      <Table
        columns={columns}
        dataSource={reversed.reverse()}
        pagination={{ pageSize }}
        rowKey={() => nanoid()}
      />
    </Modal>
  );
};

export default SalesDetailModal;
