import { FilePdfOutlined } from '@ant-design/icons';
import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { useEstimateHistories } from 'hooks/clinicalTrial/process/processingEstimate';
import { useStores } from 'stores/Context';
import { IEstimateHistory } from 'types/clinicalTrial/process/processingEstimate';

const EstimateHistoriesModal = ({
  clinicalTrialEstimateId,
  onClose,
}: {
  clinicalTrialEstimateId: number;
  onClose: () => void;
}) => {
  const { estimateHistories, getLoading } = useEstimateHistories(
    clinicalTrialEstimateId,
  );
  const { etcStore } = useStores();
  const columns: ColumnsType<IEstimateHistory> = [
    {
      title: 'No.',
      align: 'center',
      render: (_, __, index) => index + 1,
    },
    {
      title: '수정일자',
      dataIndex: 'updateDt',
      align: 'center',
      render: (updateDt) => (updateDt ? updateDt.slice(0, 10) : ''),
    },
    {
      title: '견적 금액 (부가세 별도)',
      dataIndex: 'totalPrice',
      align: 'center',
      render: (totalPrice: string) => `${totalPrice.toLocaleString()}원`,
    },
    {
      title: '견적서',
      dataIndex: 'estimateUrl',
      align: 'center',
      render: (estimateUrl: string) => (
        <FilePdfOutlined
          style={{ fontSize: 18, cursor: 'pointer' }}
          onClick={() => {
            etcStore.previewedFiles = [{ url: estimateUrl }];
          }}
        />
      ),
    },
  ];
  return (
    <Modal
      title="견적서 수정 이력"
      visible={true}
      width={768}
      footer={null}
      onCancel={onClose}
    >
      <Table
        columns={columns}
        dataSource={estimateHistories}
        rowKey="clinicalTrialEstimateHistoryId"
        loading={getLoading}
      />
    </Modal>
  );
};

export default EstimateHistoriesModal;
