import { useMemo, useState } from 'react';
import {
  DownloadOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  SyncOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Table,
  Typography,
  Upload,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { QueryClientProvider, useQueryClient } from 'react-query';

import {
  ECertificateInfoFormType,
  ECertificateInfoStatus,
  EDocumentCode,
  ICertificate,
  ICertificateInfo,
} from 'types/certificate';
import { useStores } from 'stores/Context';
import {
  useCertificateDocument,
  useCertificateDocuments,
  useCertificateInfos,
  useChangeForm,
  useUploadDocument,
} from 'hooks/certificate';
import { downloadFile, downloadFiles, getFilenameFromUrl } from 'lib/file';
import CpsrIssueModal from './CpsrIssueModal';
import ProductNecessaryInfoModalContent from 'components/product/ProductNecessaryInfoModalContent';

const disabledDocumentCodes = [
  EDocumentCode.ART,
  EDocumentCode.CGMP,
  EDocumentCode.MANUC,
  EDocumentCode.IFRA,
  EDocumentCode.ALLE,
  EDocumentCode.RMCOA,
  EDocumentCode.RMMSDS,
  EDocumentCode.THIRD,
  EDocumentCode.CFS,
  EDocumentCode.LOGOAI,
  EDocumentCode.COBRM,
  EDocumentCode.ROPQM,
  EDocumentCode.CFF,
];

const autoCreatedDocumentCodes = [
  EDocumentCode.COA,
  EDocumentCode.MSDS,
  EDocumentCode.SPEC,
  EDocumentCode.MANU,
  EDocumentCode.SAFE,
  EDocumentCode.NONANIM,
  EDocumentCode.NONHEXA,
  EDocumentCode.NONMIC,
  EDocumentCode.HMDC,
  EDocumentCode.UNDER,
  EDocumentCode.MBIO,
  EDocumentCode.TPCOA,
  EDocumentCode.TPHM,
  EDocumentCode.FOAC,
  EDocumentCode.MD,
];

const CertificateDocumentList: React.FC<{
  certificate: ICertificate;
}> = ({ certificate }) => {
  const queryClient = useQueryClient();

  const {
    certTargetId,
    estimateTarget,
    certRequestNo,
    cpsrGenerateFileUrl,
    cpsrUploadFileUrl,
  } = certificate;
  const {
    productId,
    countryId,
    productDetails,
    countryName,
    netWeight,
    netWeightUnit,
  } = estimateTarget;
  const [checkedDocumentCodes, setCheckedDocumentCodes] = useState<string[]>(
    [],
  );
  const { data: certificateDocuments, refetch } = useCertificateDocuments(
    certTargetId,
    productId,
    countryId,
  );
  const { data: certificateInfos = [] } = useCertificateInfos(certTargetId);
  const buyerDocument = useCertificateDocument({
    certTargetId,
    documentCode: EDocumentCode.BI,
  });
  const disabledCpsrIssueBtn = useMemo(() => {
    const requiredInfosForCpsr = certificateInfos.filter(
      ({ documentCode }) =>
        documentCode === EDocumentCode.PS || documentCode === EDocumentCode.BI,
    );
    return (
      requiredInfosForCpsr.filter(({ status }) => status !== 'INP').length !==
      requiredInfosForCpsr.length
    );
  }, [certificateInfos]);
  const { etcStore } = useStores();
  const [cpsrUrl, setCpsrUrl] = useState('');
  const cpsrDatas = useMemo(() => {
    return [
      {
        documentName: 'CPSR',
        cpsrUrl: cpsrUrl || cpsrUploadFileUrl || cpsrGenerateFileUrl,
      },
    ];
  }, [cpsrUrl, cpsrGenerateFileUrl, cpsrUploadFileUrl]);

  const { mutate: uploadDocumentMutate, isLoading } = useUploadDocument({
    onSuccess: () => {
      refetch();
    },
  });

  const [formType, setFormType] = useState<string | null>(null);
  const {
    mutate: changeFormMutate,
    isLoading: changeFormLoading,
  } = useChangeForm(certTargetId);

  const columns = useMemo(
    () => [
      {
        title: 'No.',
        dataIndex: 'no',
        align: 'center' as const,
      },
      {
        title: '서류명',
        align: 'center' as const,
        render: ({ documentCode, documentName }: ICertificateInfo) => {
          if (documentCode !== EDocumentCode.PRNIL) {
            return documentName;
          }

          return (
            <Typography.Link
              onClick={() => {
                Modal.info({
                  title: '제품 등록 필수 항목',
                  width: 600,
                  closable: true,
                  maskClosable: true,
                  content: (
                    <QueryClientProvider client={queryClient}>
                      <ProductNecessaryInfoModalContent
                        productId={productId}
                        countryId={countryId}
                      />
                    </QueryClientProvider>
                  ),
                  icon: null,
                  bodyStyle: { maxHeight: 800, overflowY: 'scroll' },
                });
              }}
            >
              {documentName}
            </Typography.Link>
          );
        },
      },
      {
        title: '유저 업로드 파일',
        align: 'center' as const,
        render: ({
          documentCode,
          uploadFileUrl,
          uploadFileUrls,
          originalUploadFileName,
          filenames,
        }: ICertificateInfo) => {
          if (
            (documentCode === EDocumentCode.ART ||
              documentCode === EDocumentCode.THIRD) &&
            uploadFileUrls &&
            uploadFileUrls.length !== 0
          ) {
            return (
              <FilePdfOutlined
                style={{ cursor: 'pointer', fontSize: 16 }}
                onClick={() => {
                  etcStore.previewedFiles = uploadFileUrls.map(
                    (url, index) => ({
                      url,
                      filename: filenames?.[index],
                    }),
                  );
                }}
              />
            );
          }
          if (uploadFileUrl !== null) {
            if (
              documentCode === EDocumentCode.RMCOA ||
              documentCode === EDocumentCode.RMMSDS ||
              documentCode === EDocumentCode.LOGOAI ||
              documentCode === EDocumentCode.FORM ||
              documentCode === EDocumentCode.FORMBR ||
              documentCode === EDocumentCode.FORMBRC
            ) {
              return (
                <PaperClipOutlined
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  onClick={() => {
                    downloadFile(uploadFileUrl, originalUploadFileName);
                  }}
                />
              );
            } else {
              return (
                <FilePdfOutlined
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  onClick={() => {
                    etcStore.previewedFiles = [
                      { url: uploadFileUrl, filename: originalUploadFileName },
                    ];
                  }}
                />
              );
            }
          }
          return null;
        },
      },
      {
        title: '레터헤드',
        children: [
          {
            title: '30COS',
            align: 'center' as const,
            render: ({
              formType,
              documentCode,
              documentUrl,
              documentUrls,
            }: ICertificateInfo) => {
              if (
                (documentCode === EDocumentCode.ART ||
                  documentCode === EDocumentCode.THIRD) &&
                documentUrls &&
                documentUrls.length !== 0
              ) {
                return (
                  <FilePdfOutlined
                    style={{ cursor: 'pointer', fontSize: 16 }}
                    onClick={async () => {
                      etcStore.previewedFiles = documentUrls.map((url) => ({
                        url,
                      }));
                    }}
                  />
                );
              }
              if (
                formType === ECertificateInfoFormType.C &&
                documentUrl !== null
              ) {
                return (
                  <FilePdfOutlined
                    style={{ cursor: 'pointer', fontSize: 16 }}
                    onClick={() => {
                      etcStore.previewedFiles = [{ url: documentUrl }];
                    }}
                  />
                );
              }
            },
          },
          {
            title: '제조사',
            align: 'center' as const,
            render: ({ formType, documentUrl }: ICertificateInfo) =>
              formType === ECertificateInfoFormType.M &&
              documentUrl !== null && (
                <FilePdfOutlined
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  onClick={() => {
                    etcStore.previewedFiles = [{ url: documentUrl }];
                  }}
                />
              ),
          },
          {
            title: '브랜드사',
            align: 'center' as const,
            render: ({ formType, documentUrl }: ICertificateInfo) =>
              formType === ECertificateInfoFormType.B &&
              documentUrl !== null && (
                <FilePdfOutlined
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  onClick={() => {
                    etcStore.previewedFiles = [{ url: documentUrl }];
                  }}
                />
              ),
          },
        ],
      },
      {
        title: '비고',
        align: 'center' as const,
        render: ({ documentCode }: ICertificateInfo) =>
          documentCode === EDocumentCode.CFF && (
            <Upload
              accept=".pdf"
              itemRender={() => null}
              beforeUpload={(file) => {
                uploadDocumentMutate({
                  documentCode: EDocumentCode.CFF,
                  productId,
                  countryId,
                  documentFile: file,
                });
                return false;
              }}
            >
              {isLoading ? (
                <LoadingOutlined />
              ) : (
                <UploadOutlined style={{ cursor: 'pointer', fontSize: 16 }} />
              )}
            </Upload>
          ),
      },
    ],
    [isLoading],
  );

  const [cpsrModalVisible, setCpsrModalVisible] = useState(false);
  const cpsrColumns: ColumnsType<{
    documentName: string;
    cpsrUrl: string | null;
  }> = useMemo(
    () => [
      {
        title: 'No.',
        align: 'center',
        render: (_, __, index) => index + 1,
      },
      { title: '서류명', dataIndex: 'documentName', align: 'center' },
      {
        title: '작업',
        align: 'center',
        render: () => {
          return (
            <Button
              disabled={disabledCpsrIssueBtn}
              onClick={() => setCpsrModalVisible(true)}
            >
              발행하기
            </Button>
          );
        },
      },
      {
        title: '발행된 서류',
        align: 'center',
        render: (record) =>
          record.cpsrUrl ? (
            <FilePdfOutlined
              style={{ cursor: 'pointer', fontSize: 16 }}
              onClick={() => {
                etcStore.previewedFiles = [{ url: record.cpsrUrl }];
              }}
            />
          ) : null,
      },
    ],
    [etcStore, disabledCpsrIssueBtn],
  );

  const changeForm = (formType: ECertificateInfoFormType) => {
    if (!checkedDocumentCodes.length)
      return message.warn('서류를 선택해 주세요.');
    changeFormMutate({
      certTargetId,
      formType,
      documentCodes: checkedDocumentCodes,
    });
    setFormType(formType);
  };

  const [downloadAllFilesLoading, setDownloadAllFilesLoading] = useState(false);

  const handleClickDownloadAllFiles = async () => {
    if (!certificateDocuments) return;
    const documentFiles = certificateDocuments.reduce<
      {
        name: string;
        url: string;
      }[]
    >((acc, { documentName, documentUrl, documentUrls }) => {
      if (documentUrl) {
        return acc.concat({ name: documentName, url: documentUrl });
      }
      if (documentUrls) {
        return acc.concat(
          documentUrls.map((documentUrl, index) => ({
            name: `${documentName}_${index + 1}`,
            url: documentUrl,
          })),
        );
      }
      return acc;
    }, []);
    const uploadFiles = certificateDocuments.reduce<
      {
        name: string;
        url: string;
      }[]
    >(
      (
        acc,
        { originalUploadFileName, filenames, uploadFileUrl, uploadFileUrls },
      ) => {
        if (uploadFileUrl) {
          return acc.concat({
            name: originalUploadFileName || getFilenameFromUrl(uploadFileUrl),
            url: uploadFileUrl,
          });
        }
        if (uploadFileUrls) {
          return acc.concat(
            uploadFileUrls.map((uploadFileUrl, index) => ({
              name: `${
                filenames?.[index] || getFilenameFromUrl(uploadFileUrl)
              }`.replace('.', `_${index + 1}.`),
              url: uploadFileUrl,
            })),
          );
        }
        return acc;
      },
      [],
    );
    setDownloadAllFilesLoading(true);
    await Promise.all([
      downloadFiles(
        documentFiles,
        `[인증서류]${certRequestNo}_${productDetails[0].productNameEn}_${countryName}.zip`,
      ),
      downloadFiles(
        uploadFiles,
        `[업로드파일]${certRequestNo}_${productDetails[0].productNameEn}_${countryName}.zip`,
      ),
    ]);
    setDownloadAllFilesLoading(false);
  };

  return (
    <>
      <CpsrIssueModal
        visible={cpsrModalVisible}
        certTargetId={certTargetId}
        onSetCpsrUrl={setCpsrUrl}
        productName={`${productDetails[0].productNameEn} ${
          netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
        }`}
        closeModal={() => setCpsrModalVisible(false)}
      />
      <Row gutter={8}>
        <Col>
          <Button icon={<SyncOutlined />} onClick={() => refetch()}>
            새로고침
          </Button>
        </Col>
        <Col flex="auto">
          <Button
            loading={downloadAllFilesLoading}
            icon={<DownloadOutlined />}
            onClick={handleClickDownloadAllFiles}
          >
            모든 서류 다운로드
          </Button>
        </Col>
        <Col>
          <Button
            loading={
              changeFormLoading && formType === ECertificateInfoFormType.C
            }
            onClick={() => changeForm(ECertificateInfoFormType.C)}
          >
            30COS 레터헤드로 발행하기
          </Button>
        </Col>
        <Col>
          <Button
            loading={
              changeFormLoading && formType === ECertificateInfoFormType.M
            }
            onClick={() => changeForm(ECertificateInfoFormType.M)}
          >
            제조사 레터헤드로 발행하기
          </Button>
        </Col>
        <Col>
          <Button
            loading={
              changeFormLoading && formType === ECertificateInfoFormType.B
            }
            onClick={() => changeForm(ECertificateInfoFormType.B)}
          >
            브랜드사 레터헤드로 발행하기
          </Button>
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        dataSource={certificateDocuments}
        rowKey="documentCode"
        pagination={false}
        rowSelection={{
          selectedRowKeys: checkedDocumentCodes,
          getCheckboxProps: ({ productDocStatusId, documentCode }) => {
            let disabled = false;
            if (
              disabledDocumentCodes.includes(documentCode) ||
              buyerDocument === null ||
              buyerDocument.status === ECertificateInfoStatus.INP
            ) {
              disabled = true;
            }
            if (
              !autoCreatedDocumentCodes.includes(documentCode) &&
              productDocStatusId === null
            ) {
              disabled = true;
            }
            return {
              disabled,
            };
          },
          onChange: (keys) => {
            setCheckedDocumentCodes(keys as string[]);
          },
        }}
        size="small"
        style={{ marginTop: 8 }}
      />
      {(countryName === '유럽연합' || countryName === '영국') && (
        <Table
          bordered
          columns={cpsrColumns}
          dataSource={cpsrDatas}
          rowKey="documentName"
          pagination={false}
          style={{ marginTop: 36 }}
          size="small"
        />
      )}
    </>
  );
};

export default CertificateDocumentList;
