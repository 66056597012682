export interface IAPIError {
  code: string;
  message: string;
}

export interface IAPIResponse<TData> {
  errors: IAPIError[];
  result: TData;
}

export interface IAPIPageableResponse<TData> {
  errors: IAPIError[];
  result: {
    content: TData;
    size: number;
    totalElements: number;
  };
}

export enum EProcessStage {
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
}

export interface IFile {
  name: string;
  url: string;
}

export interface ISavedFile {
  filename: string;
  attachUrl: string;
}
