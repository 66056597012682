import { Button, Col, Form, Input, Row } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';

import { Typography } from 'components/system';
import { requireRule } from 'lib/validate';
import ClinicalTrialModal from 'components/clinicalTrial/estimate/ClinicalTrialModal';
import { useStores } from 'stores/Context';
import { useEstimateRequestClientInfo } from 'hooks/clinicalTrial/process/estimateRequest';

const CustomerFormContainer = styled.div`
  width: 320px;
  margin: 24px auto 0;
`;

const ClinicalTrialEstimatePublishModal = ({
  onClose,
  onPublish,
}: {
  onClose: () => void;
  onPublish: ({
    clientCompanyName,
    clientName,
  }: {
    clientCompanyName: string;
    clientName: string;
  }) => void;
}) => {
  const [form] = Form.useForm<{
    clientCompanyName: string;
    clientName: string;
  }>();
  const { clinicalTrialStore } = useStores();
  const clinicalTrialEstimateRequest =
    clinicalTrialStore.clinicalTrialEstimateRequest;
  const { estimateRequestClientInfo } = useEstimateRequestClientInfo(
    clinicalTrialEstimateRequest?.clinicalTrialEstimateRequestId,
  );

  useEffect(() => {
    if (estimateRequestClientInfo) {
      form.setFieldsValue({
        clientCompanyName: estimateRequestClientInfo.clientCompanyName,
        clientName: estimateRequestClientInfo.clientName,
      });
    }
  }, [estimateRequestClientInfo]);

  return (
    <ClinicalTrialModal onClose={onClose}>
      <Typography.Title align="center" medium color="darkNavy">
        아래 정보 입력 후 견적서가 발행됩니다.
      </Typography.Title>
      <CustomerFormContainer>
        <Form
          form={form}
          layout="vertical"
          onFinish={({ clientCompanyName, clientName }) =>
            onPublish({ clientCompanyName, clientName })
          }
        >
          <Form.Item
            label={
              <Typography.Text bold color="darkNavy">
                업체명
              </Typography.Text>
            }
            rules={[requireRule]}
            name="clientCompanyName"
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label={
              <Typography.Text bold color="darkNavy">
                의뢰자
              </Typography.Text>
            }
            rules={[requireRule]}
            name="clientName"
          >
            <Input size="large" />
          </Form.Item>
          <Row justify="center">
            <Col>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                block
                style={{ width: 200, margin: '0 auto' }}
              >
                입력 완료
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomerFormContainer>
    </ClinicalTrialModal>
  );
};

export default ClinicalTrialEstimatePublishModal;
