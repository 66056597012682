import styled from 'styled-components';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Table = ({ children, style }: IProps) => {
  return (
    <div className="ant-table ant-table-bordered" style={style}>
      <div className="ant-table-container">
        <div className="ant-table-content">
          <table style={{ tableLayout: 'auto' }}>{children}</table>
        </div>
      </div>
    </div>
  );
};

Table.Thead = styled.thead.attrs({ className: 'ant-table-thead' })``;
Table.Tbody = styled.tbody.attrs({ className: 'ant-table-tbody' })``;
Table.Tr = styled.tr.attrs({ className: 'ant-table-cell' })``;
Table.Th = styled.th.attrs({ className: 'ant-table-cell' })``;
Table.Td = styled.td.attrs({ className: 'ant-table-cell' })`
  .ant-form-item {
    margin-bottom: 0 !important;
  }
`;

export default Table;
