import { Table } from 'antd';
import { useProductBuyers } from 'hooks/company';
import { useMemo } from 'react';

interface ProductNameModalProps {
  productId: number;
  countryId: number;
}

const BuyerDetailModal: React.FC<ProductNameModalProps> = ({
  productId,
  countryId,
}) => {
  const { data: buyers } = useProductBuyers({ productId, countryId });
  const isJapan = countryId === 5;
  const columns = useMemo(
    () => [
      {
        title: '회사명',
        dataIndex: ['buyer', 'companyName'],
        align: 'center' as const,
      },
      ...(isJapan
        ? [
            {
              title: '회사명 (일본어)',
              width: 130,
              align: 'center' as const,
              render: (_: any, { buyer }: any) =>
                buyer.countryCompanyName || '-',
            },
          ]
        : []),
      {
        title: '전화번호',
        dataIndex: ['buyer', 'companyTel'],
        align: 'center' as const,
      },
      {
        title: '주소',
        dataIndex: ['buyer', 'companyAddress'],
        align: 'center' as const,
      },
      ...(isJapan
        ? [
            {
              title: '주소 (일본어)',
              width: 120,
              align: 'center' as const,
              render: (_: any, { buyer }: any) =>
                buyer.countryCompanyAddress || '-',
            },
          ]
        : []),
      {
        title: '대표 성함',
        dataIndex: ['buyer', 'ceoName'],
        align: 'center' as const,
      },
      {
        title: '담당자 성함',
        dataIndex: ['buyer', 'representativeName'],
        align: 'center' as const,
      },
      {
        title: '이메일',
        dataIndex: ['buyer', 'companyEmail'],
        align: 'center' as const,
      },
    ],
    [],
  );
  return (
    <Table
      columns={columns}
      dataSource={buyers}
      rowKey={({ productBuyerId }) => productBuyerId}
    />
  );
};

export default BuyerDetailModal;
