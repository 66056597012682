import { Card, Col, List, Row, Tag } from 'antd';
import palette from 'lib/styles/palette';
import styled, { css } from 'styled-components';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Typography } from 'components/system';
import { useNotifications } from 'hooks/notification';
import { useStores } from 'stores/Context';
import { INotification } from 'types/notification';

const NotificationCardBlock = styled(Card)`
  .ant-card-body {
    padding: 32px 40px;
  }
`;

const NotificationList = styled(List)`
  height: 300px;
  overflow: auto;
`;

const NotificationItem = styled(List.Item)<{
  $isConfirm: boolean;
  $hasLink: boolean;
}>`
  display: block;
  cursor: ${(props) =>
    !props.$isConfirm || props.$hasLink ? 'pointer' : 'default'};
  padding: 16px;
  background-color: #fff;
  color: #4e5968;

  ${(props) =>
    props.$isConfirm &&
    css`
      .ant-tag {
        color: ${palette.text.disabled};
      }
    `}

  &:last-child {
    border-bottom: 1px solid rgb(240, 240, 240) !important;
  }

  p {
    margin-bottom: 0;
    max-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 16px;
  }
`;

const NotificationCard = () => {
  const history = useHistory();
  const { notificationStore } = useStores();
  const { notifications, readNotification } = useNotifications();

  const handleClickNotification = (notification: INotification) => {
    if (!notification.isConfirm) {
      readNotification(notification.userNoticeId);
    }
    if (notification.noticePath) {
      history.push(notification.noticePath);
      notificationStore.setNotificationDrawerVisible(false);
    }
  };

  return (
    <NotificationCardBlock style={{ marginBottom: 36 }}>
      <Typography.Headline type="secondary">알림 내역</Typography.Headline>
      <NotificationList
        dataSource={notifications}
        style={{ marginTop: 16 }}
        renderItem={(notification: any) => {
          return (
            <NotificationItem
              key={notification.userNoticeId}
              $isConfirm={notification.isConfirm}
              $hasLink={notification.noticePath !== null}
              onClick={() => handleClickNotification(notification)}
            >
              <Row
                align="top"
                gutter={8}
                style={{ marginBottom: 8 }}
                wrap={false}
              >
                <Col>
                  <Tag
                    color={
                      notification.isConfirm ? palette.disabled : '#4e5968'
                    }
                    style={{
                      margin: 0,
                      padding: '0 4px',
                      height: 24,
                      fontWeight: 500,
                      lineHeight: '24px',
                    }}
                  >
                    {notification.title || '알림'}
                  </Tag>
                </Col>
                <Col>
                  <Typography.Text style={{ color: '#4e5968' }}>
                    {parse(notification.content || '')}
                  </Typography.Text>
                </Col>
                <Col flex="1 0 auto">
                  <Typography.Text
                    style={{
                      fontSize: 10,
                      color: palette.text.disabled,
                      textAlign: 'right',
                    }}
                  >
                    {moment(notification.registerDt).fromNow()}
                  </Typography.Text>
                </Col>
              </Row>
            </NotificationItem>
          );
        }}
      />
    </NotificationCardBlock>
  );
};

export default NotificationCard;
