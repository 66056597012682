import {
  Col,
  Divider,
  Modal,
  Row,
  Form,
  InputNumber,
  Button,
  message,
} from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import { normalizeDate, pastDateRule, requireRule } from 'lib/validate';
import { Typography } from 'components/system';
import DateInput from 'components/system/form/DateInput';
import { useAddDeposit } from 'hooks/clinicalTrial/process/processingEstimate';
import { IClinicalTrialProcessingEstimate } from 'types/clinicalTrial/process/processingEstimate';

const EstimateDepositCheckModalBlock = styled(Modal)`
  .ant-form-item-label > label {
    font-size: 12px;
  }
  .ant-input-number input {
    width: 100%;
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const PriceBlock = styled.div`
  background-color: #f6f7f9;
  padding: 32px 0;
`;

const EstimateDepositCheckModal = ({
  estimate,
  onClose,
}: {
  estimate: IClinicalTrialProcessingEstimate;
  onClose: () => void;
}) => {
  const {
    clinicalTrialEstimateId,
    totalPrice,
    paidAmount: previousPaidAmount,
  } = estimate;
  const { addDeposit, addLoading } = useAddDeposit();
  const handleClickDeposit = ({
    depositDate,
    paidAmount,
  }: {
    depositDate: string;
    paidAmount: number;
  }) => {
    if (paidAmount === 0) return message.warn('금액을 입력해 주세요.');
    if (!Number.isInteger(paidAmount * (10 / 11)))
      return message.warn('VAT 별도 금액이 정수가 아닙니다.');
    Modal.confirm({
      content: '정말로 입금 처리를 하시겠습니까?',
      onOk: () => {
        addDeposit(
          {
            clinicalTrialEstimateId,
            depositDate,
            paidAmount: paidAmount * (10 / 11),
          },
          {
            onSuccess: () => {
              message.success('수정되었습니다.');
              onClose();
            },
          },
        );
      },
    });
  };
  return (
    <EstimateDepositCheckModalBlock
      width={560}
      title="입금 확인"
      visible
      onCancel={onClose}
      footer={null}
      bodyStyle={{ padding: '24px 40px' }}
    >
      <PriceBlock>
        <Row justify="center" align="middle" gutter={16}>
          <Col span={12}>
            <Typography.Text type="secondary" style={{ textAlign: 'right' }}>
              총 견적 금액
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Title>
              {totalPrice.toLocaleString()} 원
            </Typography.Title>
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          gutter={16}
          style={{ marginTop: 8 }}
        >
          <Col span={12}>
            <Typography.Text type="secondary" style={{ textAlign: 'right' }}>
              입금 처리된 금액
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Title>
              - {previousPaidAmount ? previousPaidAmount.toLocaleString() : 0}{' '}
              원
            </Typography.Title>
          </Col>
        </Row>
      </PriceBlock>
      <Row
        justify="center"
        align="middle"
        gutter={16}
        style={{ marginTop: 24 }}
      >
        <Col span={12}>
          <Typography.Text type="secondary" style={{ textAlign: 'right' }}>
            미입금 금액
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Title>
            {(totalPrice - (previousPaidAmount || 0)).toLocaleString()} 원
          </Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Typography.Title
        type="secondary"
        gutter={{ bottom: 16 }}
        style={{ textAlign: 'center' }}
      >
        입금 처리
      </Typography.Title>
      <Form onFinish={handleClickDeposit}>
        <Form.Item
          label="실 입금된 날짜"
          labelCol={{ span: 6 }}
          colon={false}
          name="depositDate"
          normalize={normalizeDate}
          rules={[requireRule, pastDateRule]}
          required={false}
        >
          <DateInput
            style={{ maxWidth: 275 }}
            checkDisabledDate={(date) => date.isAfter(moment())}
          />
        </Form.Item>
        <Form.Item
          label="입금된 금액"
          labelCol={{ span: 6 }}
          colon={false}
          style={{ marginBottom: 4 }}
        >
          <Row gutter={8} align="top">
            <Col flex="1 1 auto">
              <Form.Item name="paidAmount" rules={[requireRule]}>
                <InputNumber
                  min={0}
                  max={Math.floor(
                    (totalPrice - (previousPaidAmount || 0)) * 1.1,
                  )}
                  formatter={(value) =>
                    Number(value) === 0
                      ? ''
                      : Number(value).toLocaleString() || ''
                  }
                  parser={(value) => Number(value?.replace(/,/g, '')) || 0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Typography.Text type="secondary" style={{ lineHeight: '30px' }}>
                원 (VAT 포함)
              </Typography.Text>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="금액"
          labelCol={{ span: 6 }}
          colon={false}
          shouldUpdate={(prev, next) => prev.paidAmount !== next.paidAmount}
        >
          {({ getFieldValue }) => (
            <Row gutter={16} align="middle">
              <Col flex="1 1 auto" style={{ textAlign: 'right' }}>
                <Typography.Text type="secondary">
                  {(
                    (getFieldValue('paidAmount') || 0) *
                    (10 / 11)
                  ).toLocaleString()}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text type="secondary">
                  원 (VAT 별도)
                </Typography.Text>
              </Col>
            </Row>
          )}
        </Form.Item>
        <Row justify="center">
          <Col>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={addLoading}
            >
              입금 확인
            </Button>
          </Col>
        </Row>
      </Form>
    </EstimateDepositCheckModalBlock>
  );
};

export default EstimateDepositCheckModal;
