import { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import path from 'lib/path';
import HomeTemplate from 'templates/HomeTemplate';
import LoginPage from 'pages/LoginPage';
import { useStores } from 'stores/Context';
import PrivateRoute from 'components/PrivateRoute';
import { useDetectNewVersion } from 'hooks/etc';
import { useLogin } from 'hooks/auth';

const AppRouter = () => {
  const {
    authStore: { isLoggedIn },
  } = useStores();
  const login = useLogin();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      login.mutate({ username: '1111111110', password: '1111' });
    }
  }, []);

  useDetectNewVersion();

  return (
    <Switch>
      <PrivateRoute
        path={path.login}
        component={LoginPage}
        check={!isLoggedIn}
        redirect={path.main}
      />
      {(process.env.NODE_ENV !== 'development' || isLoggedIn) && (
        <PrivateRoute
          path={path.main}
          component={HomeTemplate}
          check={isLoggedIn}
          redirect={path.login}
        />
      )}
    </Switch>
  );
};

export default observer(AppRouter);
