import moment from 'moment';

import { DATE_FORMAT } from 'lib/consts';
import { IAPIResponse } from 'types/common';
import {
  ECategoryCertificateSearchType,
  ECertificateStatus,
  IBrandCategoryCertificateStat,
  IBuyerCertificateStat,
  ICategoryCertificateStat,
  ICompanySalesStat,
  ICountryCategoryCertificateStat,
  ICountryCertificateStat,
  ICountrySalesStat,
  IMainStat,
  IPeriod,
  IPeriodSalesStat,
  IProductRegisterStat,
} from 'types/stats';
import client from './client';

export const getTotalSales = () =>
  client.post<IAPIResponse<number>>('/admin/stats/sales/total');

export const getPeriodSalesStats = (
  { from, to }: IPeriod,
  periodType: 'monthly' | 'daily',
) =>
  client.post<IAPIResponse<IPeriodSalesStat[]>>(
    `/admin/stats/sales/${periodType}/${from}/${to}`,
  );
export const getCountrySalesStats = ({ from, to }: IPeriod) =>
  client.post<IAPIResponse<ICountrySalesStat[]>>(
    `/admin/stats/sales/country/${from}/${to}`,
  );

export const getCompanySalesStats = ({ from, to }: IPeriod) =>
  client.post<IAPIResponse<ICompanySalesStat[]>>(
    `/admin/stats/sales/company/${from}/${to}`,
  );

export const getTotalCountryCertificateStats = () =>
  client.post<IAPIResponse<ICountryCertificateStat[]>>(
    '/admin/stats/cert/total',
  );

export const getCountryCertificateStats = (
  status: ECertificateStatus,
  { from, to }: IPeriod,
) =>
  client.post<IAPIResponse<ICountryCertificateStat[]>>(
    `/admin/stats/cert/${status}/${from}/${to}`,
  );

export const getCountryCertificateStatsByCompany = (companyId: number) =>
  client.post<IAPIResponse<ICountryCertificateStat[]>>(
    `/admin/stats/cert/company/${companyId}/2021-03-23/${moment().format(
      DATE_FORMAT,
    )}`,
  );

export const getProductRegisterStats = ({ from, to }: IPeriod) =>
  client.post<IAPIResponse<IProductRegisterStat[]>>(
    `/admin/stats/manufacturer/product/${from}/${to}`,
  );

export const getProductRegisterStatsWithId = ({
  id,
  from,
  to,
  isManu,
}: {
  id?: number;
  from: string;
  to: string;
  isManu: boolean;
}) =>
  client.post<IAPIResponse<IProductRegisterStat[]>>(
    isManu
      ? `/admin/stats/manufacturer/product/brand/${id}/${from}/${to}`
      : `/admin/stats/brand/product/manufacturer/${id}/${from}/${to}`,
  );

export const getThisYearCategoryCertificateStats = () =>
  client.post<IAPIResponse<ICategoryCertificateStat[]>>(
    '/admin/stats/category/this-year',
  );

export const getCategoryCertificateStats = ({
  type,
  id,
  period: { from, to },
}: {
  type: ECategoryCertificateSearchType;
  id?: number;
  period: IPeriod;
}) =>
  client.post<IAPIResponse<ICategoryCertificateStat[]>>(
    `/admin/stats/category/${type}${id ? `/${id}` : ''}/${from}/${to}`,
  );

export const getCountryCategoryCertificateStats = ({
  type,
  id,
  period: { from, to },
}: {
  type: ECategoryCertificateSearchType;
  id: number[];
  period: IPeriod;
}) =>
  client.post<IAPIResponse<ICountryCategoryCertificateStat[]>>(
    `/admin/stats/category/country/${type}/${id[0]}${
      type === ECategoryCertificateSearchType.FUNCTIONAL ? `/${id[1]}` : ''
    }/${from}/${to}`,
  );

export const getBrandCategoryCertificateStats = ({
  companyId,
  period: { from, to },
}: {
  companyId: number;
  period: IPeriod;
}) =>
  client.post<IAPIResponse<IBrandCategoryCertificateStat[]>>(
    `/admin/stats/cert/company/category/${companyId}/${from}/${to}`,
  );

export const getBuyerCertificateStats = (countryId: number) =>
  client.post<IAPIResponse<IBuyerCertificateStat[]>>(
    `/admin/stats/buyer/${countryId}`,
  );

export const getBuyerDetails = ({
  countryId,
  buyerId,
}: {
  countryId: number;
  buyerId: number;
}) =>
  client.post<IAPIResponse<IBuyerCertificateStat[]>>(
    `/admin/stats/buyer/${countryId}/${buyerId}`,
  );

export const getMainStats = () =>
  client.get<IAPIResponse<IMainStat>>('/admin/stats/main');
